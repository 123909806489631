const appConfig = JSON.parse(import.meta.env.VITE_APP_CONFIG);

import {
    addCommercial,
    editCommercial
} from "./actions";

const isDOA = appConfig.appPrefix === 'doa';
// if doa
const warn = ({ store, items }) => {
    let _items = items.map(i => store.state[i]).filter(i => !!i);
    if (isDOA && _items.some(item => !item.Invoiced) ) {
        return [true, `Commercial ${_items.filter(item => !item.Invoiced).map(item => item.Number).join(", ")} not invoiced`];
    } else {
        return [false, ""];
    }
};

export default {
    "label": "Commercials",
    "key": "Commercials",
    "location": "Database/Commercials/",
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Title",
        "field": "Title"
    }, {
        "headerName": "Customer",
        "field": "Customer"
    }],
    "rowActions": [{
        "label": "Open Commercial",
        action({ router, rowItem }) {
            router.push(`/Database/Commercials/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Commercial",
        "action": editCommercial
    }],
    warn: warn
};
