import TrepRegs from "./TrepRegs.vue";
import { get } from "lodash";
export default {
    "label": "TREP Regulations",
    "field": true,
    "fields": [{
        "label": "Regulations",
        "type ": "text",
        "class": "md:col-span-3",
        "grow": true,
        "component": TrepRegs,
        compute({ pageItem, store }) {
            
            return pageItem?.Regulations?._computedRegs || "No regulations found";
        }
    }],
    "actions": [{
        "label": "Add / Remove Regulations",
        action({ form, pageItem, store }) {

            form.setValues({
                "Regulations[Amendment]": parseInt(pageItem?.Regulations?.Amendment),
                "Regulations[Regs]": pageItem?.Regulations?.Regs
            })

            const regsAsDropdown = Object.entries(store.state["Database/Regulations/CS25"]).map(([subpart, regs]) => {
                return {
                    "label": subpart,
                    "id": subpart,
                    "children": Object.values(regs).map(reg => {
                        return {
                            "label": reg.title,
                            "id": `${subpart}.${reg.ID}`
                        };
                    })
                };
            });

            // make an array of amendments from 0 - 22
            const amendments = Array.from({ length: 23 }, (v, k) => {
                return {
                    "id": k,
                    "label": k
                }
            });

            // form.setValues(...pageItem);

            form.setConfig({
                "title": "Add / Remove Regulations",
                "type": "update",
                "location": `Database/Documents/${pageItem.ID}/`,
                "set": true,
                "fields": [{
                    "label": "Amendment",
                    "name": "Regulations[Amendment]",
                    "type": "dropdown",
                    "dropdownData": amendments

                }, {
                    "label": "Regulations",
                    "name": "Regulations[Regs]",
                    "type": "dropdown",
                    "multiple": true,
                    "dropdownData": regsAsDropdown,
                    "divClass": "md:col-span-3"
                }, {
                    "label": "Regulations",
                    "type": "value",
                    "name": "_computedRegs",
                    "grow": true,
                    
                    value({ liveInputData }) {

                        let regs = get(liveInputData, "Regulations[Regs]", []);
                        console.log(regs);
                        let x = regs.map(reg => {


                            let foundReg = get(store.state["Database/Regulations/CS25"], reg, "No regulations found");

                            if (foundReg === "No regulations found") return foundReg;

                            const amendment = parseInt(get(liveInputData, "Regulations[Amendment]", 0));

                            let { amendments } = foundReg;

                            if (!amendments) {
                                return `
                                <p class="font-bold">${foundReg.title}</p>
                                <p>No regulations found</p>
                            `;
                            }


                            const closestAmendment = Object.values(amendments).map(a => {
                                return {
                                    ...a,
                                    "amendment": parseInt(a.amendment)
                                }
                            }).sort((a, b) => a.amendment - b.amendment).filter(i => {
                                return i.amendment <= amendment;
                            }).pop();

                            let {
                                text
                            } = get(amendments, closestAmendment.ID, { text: "No regulations found" });

                            let replacedText = text.replace("```html", "").replace("```", "");
                            return `
                                <p class="font-bold">${foundReg.title}</p>
                                <table class="border border-gray border-collapse">
                                <tr>
                                ${replacedText}
                                </tr>
                                <tr></tr>
                                </table>
                            `;
                        });
                        console.log(x);
                        return x.join("");
                    },
                    "divClass": "md:col-span-3"

                }],
                preSubmitHook({ data, liveInputData }) {
                    return {
                        "Regulations": {
                            "Amendment": parseInt(get(liveInputData, "Regulations[Amendment]", 0)),
                            "Regs": get(liveInputData, "Regulations[Regs]", []),
                            "_computedRegs": get(data, "_computedRegs", "")
                        }
                    };
                }

            });
            form.getDropdownData();
            form.toggle();
        }
    }],
    show({ pageItem }) {
        return pageItem.Type === "TREP";
    }
};
