import {
    addApprovedFunction,
    deleteApprovedFunction,
    editApprovedFunction
} from "./actions";

export default {
    "label": "Approved Functions",
    "key": "ApprovedFunctions",
    "location": "Matrices/SignatoryMatrix/ApprovedFunctions/",
    "headers": [{
        "headerName": "Approved Function",
        "field": "Name",
        "flex": 1
    }, {
        "headerName": "Points Threshold",
        "field": "Threshold",
        "flex": 1
    }, {
        "headerName": "Form Four Function",
        "field": "FormFourFunction",
        "type": "bool",
        "flex": 1
    }, {
        "headerName": "New Application",
        "field": "NewApplication",
        "type": "bool",
        "flex": 1
    }],
    "actions": [{
        "label": "Add Approved Function",
        "action": addApprovedFunction
    }],
    "rowActions": [{
        "label": "Edit Approved Function",
        "action": editApprovedFunction
    }, {
        "label": "Delete Approved Function",
        "action": deleteApprovedFunction
    }]
}
