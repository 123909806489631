const fields = [{
    "label": "Name",
    "name": "Name",
    "type": "text"
}, {
    "label": "Acronym",
    "name": "NameShort",
    "type": "text"
}, {
    "label": "Average Time to Complete (hours)",
    "name": "ExpectedTime",
    "type": "text"
}, {
    "label": "Procedure",
    "name": "ProcedureID",
    "type": "dropdown",
    "labelKey": "Title",
    "idKey": "ID",
    "dataLocation": "Audits/Procedures"
}, {
    "break": true,
    "divClass": "col-span-3"
}, {
    "label": "Can be added to Design Changes",
    "divClass": "col-span-3",
    "name": "DesignChanges",
    "type": "checkbox",
    "required": false
}, {
    "label": "Can be added to Flight Conditions",
    "divClass": "col-span-3",
    "name": "FlightConditions",
    "type": "checkbox",
    "required": false
}, {
    "label": "Can be added to Repairs",
    "divClass": "col-span-3",
    "name": "Repairs",
    "type": "checkbox",
    "required": false
}, {
    "label": "Can be added to Documents",
    "divClass": "col-span-3",
    "name": "Documents",
    "type": "checkbox",
    "required": false
}, {
    "label": "Can be added to Drawings",
    "divClass": "col-span-3",
    "name": "Drawings",
    "type": "checkbox",
    "required": false
}, {
    "label": "Can be added to DQN's",
    "divClass": "col-span-3",
    "name": "DQNs",
    "type": "checkbox",
    "required": false
}, {
    "label": "Can be added to Concessions",
    "divClass": "col-span-3",
    "name": "Concessions",
    "type": "checkbox",
    "required": false
}, {
    "label": "Can be added to DOI's",
    "divClass": "col-span-3",
    "name": "DOIs",
    "type": "checkbox",
    "required": false
}, {
    "label": "Can be added to Service Bulletins",
    "divClass": "col-span-3",
    "name": "ServiceBulletins",
    "type": "checkbox",
    "required": false
}, {
    "label": "Rquires Upload Status",
    "divClass": "col-span-3",
    "name": "RequiresUploadStatus",
    "type": "checkbox",
    "required": false
}];

export const addDocumentType = ({ form }) => {
    form.setConfig({
        "title": "Add Document Type",
        "type": "push",
        "location": "Database/DocumentTypes",
        "fields": fields,
        preSubmitHook() {
            return {
                Count: 1,
                Standard: {
                    DesignChanges: false,
                    FlightConditions: false,
                    Repairs: false
                }
            }
        }
    });
    form.getDropdownData();
    form.toggle();
}

export const editDocumentType = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Add Document Type",
        "type": "update",
        "location": `Database/DocumentTypes/${item.ID}`,
        "fields": fields,
    });
    form.getDropdownData();
    form.toggle();
}
