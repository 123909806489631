// SubDocumentTypes
import {
    addMFARQuestion,
    updateMFARQuestion
} from "./actions";

export default {
    "label": "MFAR Questions",
    "key": "MFARQuestions",
    "location": "Database/MFARQuestions/",
    "headers": [{
        "headerName": "Question",
        "field": "Question"
    }],
    "actions": [{
        "label": "Add MFAR Question",
        "action": addMFARQuestion
    }],
    "rowActions": [{
        "label": "Edit MFAR Question",
        "action": updateMFARQuestion
    }]
}
