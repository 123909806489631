import {
    addJobCard,
    addJobCardFinding,
    editJobCard,
    editJobCardFinding,
    jobCardSignoffCheckList
} from "./actions";

export default {
    "label": "Job Cards",
    "key": "JobCards",
    "location": "Database/JobCards/",
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Title",
        "field": "Title"
    }, {
        "headerName": "Colour",
        "field": "Type"
    }, {
        "headerName": "Parent",
        "field": "Parent"
    }, {
        "headerName": "Engineer Signed Off",
        "field": "Signedoff.Engineer",
        "type": "bool"
    }, {
        "headerName": "TS Signed Off",
        "field": "Signedoff.TechnicalSecretary",
        "type": "bool"
    }
    // {
    //     "headerName": "Auditor Signed Off",
    //     "field": "Signedoff.Auditor",
    //     "type": "bool"
    // }
],
    "actions": [{
        "label": "Add Job Card",
        "action": addJobCard,
        show({ meta }) {
            return [["DesignChanges", "FlightConditions", "Repairs"].includes(meta.ParentType), "You cannot add a Job Card here, it must be created in the Design Change, Flight Condition or Repair"]
        }
    }],
    "rowActions": [{
        "label": "View Job Card",
        action({ rowItem, router }) {
            router.push(`/Database/JobCards/${rowItem.ID}`)
        }
    }, {
        "break": true,
        "label": "Edit Job Card",
        "action": editJobCard
    }, {
        "break": true,
        "label": "Engineer Responsibility Checklist",
        "action": jobCardSignoffCheckList("Engineer", "EngineerResponsibilities")
    }],
    warn({ item, items, pageItem, store }) {
        let warningText = "";
        
        for(let itemId of items) {
            const item = store.state[itemId];
            if(!item) continue;
            if(item.Cancelled) continue;
            if(!item.EngineerResponsibilities) {
                warningText += `Job Card ${item.Number} has not had the Engineer Responsibility Checklist completed\n`
            }
            const isSignedOff = item.Signedoff.Engineer;
            if(isSignedOff && !item.EngineerResponsibilities) {
                warningText += `Job Card ${item.Number} has been signed off by the engineer but the Engineer Responsibility Checklist has not been completed\n`
            }

        }
        return [warningText.length > 0, warningText];
    }
}

export const findingsAttachable = {
    "label": "Findings",
    "key": "Findings",
    "location": "Database/JobCardFindings/",
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Finding Issue",
        "field": "Comment",
        valueFormatter(params) {
            return params.value?.replace(/<\/?[^>]+(>|$)/g, "")
        }
    }],
    "actions": [{
        "label": "Add Finding",
        "action": addJobCardFinding
    }],
    "rowActions": [{
        "label": "View Finding",
        action({ rowItem, router }) {
            router.push(`/Database/JobCardFindings/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Finding",
        "action": editJobCardFinding
    }]
}
