import {
    addSupplier,
    editSupplier
} from "./actions";

export default {
    "label": "Suppliers",
    "key": "Suppliers",
    "location": "Database/Suppliers/",
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "actions": [{
        "label": "Add Supplier",
        "action": addSupplier
    }],
    "rowActions": [{
        "label": "Edit Supplier",
        "action": editSupplier
    }]
}
