import {
    addDOI,
    cancelDOI,
    checkDOI,
    editDOI,
    linkDOI,
    reviseDOI,
    signoffDOI
} from "./actions";

import { get } from "lodash";
import store from "../../../store";

const canRevise = item => Object.values(item?.Revisions[item?.RevisionID]?.Quality || {}).every(i => i.Completed);

function warning(key) {
    let item = store.state[key];
    if (!item || item.Cancelled === true) return false;
    let warningMessage = "";
    if (canRevise(item) === false) {
        warningMessage = warningMessage += `${item.Number} is not approved.<br>`;
    }
    return warningMessage === "" ? false : warningMessage;
}

const cellClassRules = {
    "text-orange font-bold": params => {
        return warning(params.data);
    }
}


const actions = [{
    "label": "Add DOI",
    "action": addDOI,
    show({ meta }) {
        return [["DesignChanges", "FlightConditions"].includes(meta?.ParentType), "DOIs cannot be added to a Document, they must be linked."]
    }
}, {
    "label": "Link DOI",
    "action": linkDOI
}];

const headers = [{
    "headerName": "Number",
    "field": "Number",
    cellClassRules
}, {
    "headerName": "Revision",
    "field": "Revision",
    cellClassRules
}, {
    "headerName": "Comment",
    "field": "Comment",
    cellClassRules
}, {
    "headerName": "Compiler",
    "field": "Signedoff",
    "type": "bool",
    cellClassRules,
    valueFormatter(params) {
        return get(params.data, `Revisions.${params.data.RevisionID}.Quality.Compiler.Completed`);
    }
}, {
    "headerName": "Checker",
    "field": "Signedoff",
    "type": "bool",
    cellClassRules,
    valueFormatter(params) {
        return get(params.data, `Revisions.${params.data.RevisionID}.Quality.Checker.Completed`);
    }
}, {
    "headerName": "Approver",
    "field": "Signedoff",
    "type": "bool",
    cellClassRules,
    valueFormatter(params) {
        return get(params.data, `Revisions.${params.data.RevisionID}.Quality.Approver.Completed`);
    }
}];

const rowActions = [{
    "label": "View DOI",
    action({ router, rowItem }) {
        router.push(`/Database/DOIs/${rowItem.ID}`)
    }
}, {
    "label": "Update DOI",
    "break": true,
    "action": editDOI
}, {
    "label": "Revise DOI",
    "action": reviseDOI,
    show({ rowItem }) {
        return [canRevise(rowItem), "The DOI is not approved"]
    }
}, {
    "label": "Compiler Check",
    "action": checkDOI("Compiler"),
    "break": true,
    show({ rowItem }) {
        return rowItem.Revisions[rowItem.RevisionID]?.Quality?.Compiler?.Completed === true ? [false, "Already Complier Checked"] : [true, ""];
    }
},
{
    "label": "Checker Check",
    "action": checkDOI("Checker"),
    show({ rowItem }) {
        return rowItem.Revisions[rowItem.RevisionID]?.Quality?.Checker?.Completed === true ?
            [false, "Already Complier Checked"] :
            !rowItem.Revisions[rowItem.RevisionID]?.Quality?.Compiler?.Completed ?
                [false, "DOI not checked by the complier"] :
                [true, ""]
    }
},
{
    "label": "Approver Check",
    "action": checkDOI("Approver"),
    show({ rowItem }) {
        return rowItem.Revisions[rowItem.RevisionID]?.Quality?.Approver?.Completed === true ?
            [false, "Already Approver Checked"] :
            !rowItem.Revisions[rowItem.RevisionID]?.Quality?.Checker?.Completed ?
                [false, "DOI not checked by the checker"] :
                [true, ""];
    }
}, {
    "label": "Cancel DOI",
    "break": true,
    "action": cancelDOI,
    "class": "text-red"
}];

export default {
    "label": "DOIs",
    "key": "DOIs",
    "location": "Database/DOIs/",
    actions,
    rowActions,
    headers,
    warn({ items }) {
        let warningMessage = items.map(warning).filter(i => i !== false).join("");
        return [warningMessage !== "", warningMessage];
    }
};
