import {
    addRecord,
    removeRecord,
    updateRecord
} from "./actions";

export const records = {
    "label": "Records",
    "key": "Training.Records",
    "local": true,
    "headers": [{
        "headerName": "Date Start",
        "field": "Date.Start",
        "type": "date"
    }, {
        "headerName": "Date Complete",
        "field": "Date.Complete",
        "type": "date"
    }, {
        "headerName": "Training / Achievement / Experience",
        "field": "Training"
    }, {
        "headerName": "Outcomes / Comments",
        "field": "Outcomes"
    }, {
        "headerName": "Type",
        "field": "Type"
    }, {
        "headerName": "Approved By",
        "field": "ApprovedBy"
    }],
    "actions": [{
        "label": "Add Record",
        "action": addRecord
    }],
    "rowActions": [{
        "label": "Update Record",
        "action": updateRecord
    }, {
        "label": "Download Certificate",
        async action({ db, rowItem, router }) {
            let url = await db.download(`StaffTrainingRecords/${router.currentRoute.params.id}/${rowItem.ID}/${rowItem.Certificate}`);
            window.open(url, "__blank");
        },
        show({ rowItem }) {
            return [rowItem.Certificate, "Certificate not attached"];
        }
    },{
        "label": "Remove Record",
        "action": removeRecord
    }]
};

export const designChanges = {
    "label": "Design Change's Responsible For",
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Title",
        "field": "Title"
    }, {
        "headerName": "Mod Type",
        "field": "Type"
    }],
    "rowActions": [{
        "label": "View Design Change",
        action({ router, rowItem }) {
            router.push(`/Database/DesignChanges/${rowItem.ID}`)
        }
    }],
    computedData({ store, pageItem, item }){
        
        if(!pageItem) return [];
        return Object.values(store.state["Database/Refs/DesignChanges"] || {}).filter(i => {
            return i.ResponsibleEngineer === pageItem.Basic.name;
        });
    }
};
