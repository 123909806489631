<template>
	<div class="flex flex-col w-full h-full" style="max-height: 100%; height: 100%">
		<div class="m-2 p-2 bg-white rounded shadow flex-col flex" style="max-height: 100%; height: 100%">

			<div class="m-2 flex flex-row items-center">
				<p class="text-primary">Cycle</p>
				<button class="btn btn-primary" @click="yearDown">-</button>
				<p class="p-2 text-primary text-sm font-medium flex">
					{{ cycle }}
				</p>
				<button class="btn btn-primary" @click="yearUp">+</button>
				<span class="flex flex-grow"></span>
				<button class="btn btn-primary" @click="openRevisionList">Revision History</button>
			</div>
			<div class="bg-green flex flex-row p-2 rounded shadow m-2 items-center" v-if="currentCycleRevision.Locked && state.isApprovalBannerOpen">
				<font-awesome-icon icon="check-circle" class="text-white"></font-awesome-icon>
				<p class="p-2 text-white">This cycle revision has been approved</p>
				<span class="flex flex-grow"></span>
				<button class="btn btn-primary" @click="state.isApprovalBannerOpen = false">Close</button>
			</div>
			<div class="bg-red flex flex-row p-2 rounded shadow m-2 items-center" v-if="!currentCycleRevision.Locked && state.isApprovalBannerOpen">
				<font-awesome-icon icon="exclamation-circle" class="text-white"></font-awesome-icon>
				<p class="p-2 text-white">This cycle is currently unapproved</p>
			</div>
			<ActionValue class=" flex-grow" v-if="cycleRevisions.Revisions" label="Cycle Revision"
				:value="`${currentCycleRevision.Revision} (${cycleRevisionLocked ? 'Approved' : 'Open'})`"
				:actionText="currentCycleRevision.Locked ? 'Revise' : 'Approve'" actionClass="primary"
				@action="currentCycleRevision.Locked ? openReviseCycleForm() : openLockRevisionForm()" />

			<DataTable ref="cycleTable" @itemSelected="itemSelected" :tableSettings="tableConfigWithMonths"
				:items="procedures" :paginationPageSize="100" />
		</div>
		<DynamicForm ref="form" @submitted="formSubmitted" />
	</div>
</template>
<script setup>
import { db } from "../../../db";
import { useStore } from "vuex";
import reportPlannerTableConfig from "./reportPlannerTableConfig";
import { DataTable } from "@comp";
import { computed, onBeforeMount, reactive, ref } from "vue";
import { get } from "lodash";
import { useRouter } from "vue-router";

import DynamicForm from "../../../components/DynamicForm/DynamicForm.vue";



import {
	ActionValue
} from "@comp/index.js";

import { reviseReportPlanner, lockRevision, listRevisionChanges } from "./actions";

const router = useRouter();

const cycleTable = ref(null);

const store = useStore();

const form = ref(null);

const state = reactive({
	loading: false,
	year: new Date().getFullYear(),
	isApprovalBannerOpen: true
});

const yearUp = () => {
	state.year += 2;
	cycleTable.value.setTableSettings();
};

const yearDown = () => {
	state.year -= 2;
	cycleTable.value.setTableSettings();
};

const cycle = computed(() => {
	// round the year down to the nearest even number
	const year = (state.year = Math.floor(state.year / 2) * 2);
	return year + "/" + (parseInt(state.year) + 1);
});

const cycleId = computed(() => {
	return cycle.value.replace("/", "_");
});

const cycleRevisions = computed(() => {
	return get(store.state["Audits/Cycles"], cycleId.value, {});
});

const currentCycleRevision = computed(() => {
	return cycleRevisions.value.Revisions[cycleRevisions.value.RevisionID];
});

const cycleRevisionLocked = computed(() => {
	return currentCycleRevision.value.Locked;
});

const tableConfigWithMonths = computed(() => {
	let monthsOfCycle = [];

	let todaysDate = new Date();
	let todaysMonth = todaysDate.toLocaleString("default", {
		month: "long",
	});
	let todaysYear = (todaysDate.getFullYear()) - 2000;


	for (var i = 0; i < 24; i++) {
		let date = new Date(state.year, i, 1);

		const month = date.toLocaleString("default", {
			month: "long",
		});
		const year = (date.getFullYear()) - 2000;

		let isCurrentMonth = todaysMonth === month && todaysYear === year;

		monthsOfCycle.push({
			headerName: `${month} ${year}`,
			width: 100,
			valueGetter(params) {
				if (!params.data._Cycle) {
					return "-";
				};

				const dueDate = new Date(params.data._Cycle.Date.Due);
				const dueDateMonth = dueDate.getMonth();
				const dueDateYear = dueDate.getFullYear();

				if (params.data._Reports && params.data._Reports.Completed === true) {
					const completedDate = new Date(params.data._Reports.Date.Completed);
					if (completedDate.getMonth() === date.getMonth() && completedDate.getFullYear() === date.getFullYear()) {
						return "Completed";
					}
				}

				if (dueDateMonth === date.getMonth() && dueDateYear === date.getFullYear() && (!params.data._Reports || params.data._Reports.Completed === false)) {
					return params.data.hasIncompleteNonConformities ? "Active Non-Cons" : !!params.data._Reports ? "Due - In Progress" : "Due";
				}

				return "-";
			},
			cellClass(params) {
				switch (params.value) {
					case "Completed":
						return "bg-green text-black ";
					case "Due - In Progress":
						return "bg-primary text-white ";
					case "Due":
						return "bg-primary text-black ";
					case "Active Non-Cons":
						return "bg-orange text-black ";
					default:
						return isCurrentMonth ? "bg-primary-100 text-black " : "bg-gray-200 text-black";
				}
			}
		});
	}
	let tableConfig = JSON.parse(JSON.stringify(reportPlannerTableConfig));
	tableConfig.columnState = [...tableConfig.columnState, ...monthsOfCycle];
	return tableConfig;
});

const getProcedureStatus = (item) => {
	let status = "Not Started";
	if (!!item._Reports) {
		status = item.hasIncompleteNonConformities ? "Active Non-Conformities" : "In Progress";
		if (item?._Reports?.Completed === true) {
			status = "Completed";
		}
	}
	return status;
};

const procedures = computed(() => {
	if (!store.state["Audits/CoreProcedures"] || !store.state["Audits/Reports"] || !store.state["Audits/NonConformities"]) return [];
	const coreProcedures = Object.assign({}, store.state["Audits/CoreProcedures"]);
	const reports = Object.assign({}, store.state["Audits/Reports"]);
	const noncons = Object.assign({}, store.state["Audits/NonConformities"]);

	return Object.values(coreProcedures)
		.map((item) => {
			item._Reports = Object.keys(item.Reports || {})
				.map((reportId) => {
					return get(reports, reportId, null);
				})
				.filter((report) => !!report && report?.Cycle === cycle.value)[0];

			item._Cycle = Object.values(item.Cycles || {}).filter((c) => c.Name === cycle.value)[0];

			item.hasIncompleteNonConformities = Object.keys(item.NonConformities || {}).some(
				(nonConformityKey) => noncons[nonConformityKey]?.Completed === false
			);

			return {
				...item,
				Number: item?.Title?.split(" ")[0],
				TitleName: item?.Title?.split(" ")?.slice(1)?.join(" "),
				Status: getProcedureStatus(item),
			};
		});
});

async function getData() {
	await db.store("Audits/CoreProcedures", store);
	await db.store("Audits/Reports", store);
	await db.store("Audits/NonConformities", store);
	await db.store("Audits/Cycles", store);
}

const itemSelected = (row) => {
	router.push({
		name: "Core Procedure",
		params: {
			id: row.data.ID,
		},
	});
};

const openReviseCycleForm = async () => {
	await reviseReportPlanner({
		db,
		store,
		form: form.value,
		cycle: cycle.value,
		cycleId: cycleId.value,
		currentCycleRevision: currentCycleRevision.value
	});
};

const openLockRevisionForm = async () => {
	await lockRevision({
		db,
		store,
		form: form.value,
		cycle: cycle.value,
		cycleId: cycleId.value,
		currentCycleRevision: currentCycleRevision.value
	});
};

const openRevisionList = () => {
	listRevisionChanges(form.value, cycleRevisions.value);
}

const formSubmitted = async () => {
	await getData();
};

onBeforeMount(async () => {
	await getData();
	return;
});
</script>
