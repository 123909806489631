import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import EWIS from "./EWIS.vue"
import FormEWIS from "./FormEWIS.vue"
import { addEWIS } from "./actions";

export default [{
    "path": "/Database/EWIS",
    "component": {...ItemListView},
    "name": "EWIS's",
    "meta": {
        "app": "Database",
        "name": "EWIS's",
        "sideNav": true,
        "sideNavSection": "Design Items",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "EWIS",
        "dataPath": "Database/EWIS/saves",
        "selectionRoute": "Database/EWIS/{key}",
        "menuActions": [{
            "label": "Add EWIS",
            "action": addEWIS
        }]
    }
},  {
    "path": "/Database/EWIS/:id",
    "component": EWIS,
    "name": "EWIS",
    "meta": {
        "app": "Database",
        "name": "EWIS",
        "dataPath": "Database/EWIS",
        "selectionRoute": "Database/EWIS/{ID}"
    }
}];
