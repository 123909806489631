<template>
    <form ref="__form" v-if="!loading" @submit="submit" autocomplete="off">
        <div class="flex flex-col">
            <p class="p-2 mt-4">{{ title }}</p>
            <p class="p-2 mt-4 text-sm" v-html="description"></p>
            <div v-for="field in fields" :key="field.name">
                <p class="p-2 text-primary font-medium text-sm" v-if="field.type === 'sectionHeader'">
                    {{ field.label }}
                </p>
                <hr v-if="field.break === true" />

                <Input v-if="field.type === 'text' ||
                    field.type === 'number' ||
                    field.type === 'file' ||
                    field.type === 'date'" :label="field.label" :name="field.name" :min="field.min"
                    :max="field.max" :multiple="field.multiple" :type="field.type || 'text'" :accept="field.accept || ''"
                    :step="field.step || '0.25'" :required="field.required" :disabled="field.disabled === true"
                    :value="formData[field.name] || ''" v-model="formData[field.name]" />

                <Checkbox v-if="field.type === 'checkbox'" type="checkbox" :label="field.label" :name="field.name"
                    :required="field.required" :disabled="field.disabled === true"
                    :checked="formData[field.name] === 'true' || formData[field.name] === true" />

                <Value v-if="field.type === 'static-value'" :label="field.label" :value="formData[field.name]"
                    :grow="field.grow || false" :name="field.name" />

                <Dropdown v-if="field.type === 'dropdown'" :label="field.label" :name="field.name"
                    :required="field.required" :disabled="field.disabled === true" :value="formData[field.name]"
                    v-model="formData[field.name]" :options="dropdownData[field?.dropdownOptions] || []"
                    :disable-branch-nodes="field.disableBranchNodes || false" :multiple="field.multiple"
                    :search="field.search || false" :has-other="field.hasOther || false"
                    :showSelectAll="field.showSelectAll || false" :matchKeys="field.matchKeys || ['label']" />

                <ToggleWithInput v-if="field.type === 'confirmationInput'" :label="field.label" :name="field.name"
                    :inputName="field.inputName" :required="field.required" :disabled="field.disabled === true"
                    :value="formData[field.name]" v-model="formData[field.name]" :inputValue="formData[field.inputName]" />

                <Richtext :label="field.label" :name="field.name" :required="field.required"
                    :disabled="field.disabled === true" :minimised="field.minimised === true" :value="formData[field.name] || ''"
                    v-model="formData[field.name]" v-if="field.type === 'richtext'" />

                <div class="flex flex-col" v-if="field.type === 'list'">
                    <p class="p-2 text-primary font-medium text-sm">{{ field.label }}</p>
                    <ul class="p-2 list-inside list-disc">
                        <li v-for="(item, index) in formData[field.name]" :key="index">{{ item.File }}</li>
                    </ul>
                    <p v-if="!formData[field.name] || formData[field.name].length < 1" class="font-bold p-2">No Files
                        Uploaded</p>
                </div>

                <Toggle :label="field.label" :name="field.name" :value="formData[field.name]" v-model="formData[field.name]"
                    v-if="field.type === 'toggle'" />
            </div>
            <button class="bg-primary text-white" type="submit" v-if="submitButtonText?.length > 0">{{ submitButtonText }}</button>
        </div>
    </form>
</template>

<script setup>
import {
    Input,
    Value,
    Dropdown,
    Checkbox,
    Richtext,
    Toggle,
    ToggleWithInput,
} from "@comp";

import serialize from "form-serialize";

let props = defineProps({
    fields: Array,
    title: String,
    loading: Boolean,
    
    submitButtonText: String,
    formData: {
        type: Object,
        default: () => ({}),
    },
    description: String,
    dropdownData: {
        type: Object,
        default: () => ({}),
    },
});

const emit = defineEmits(['submit']);

async function submit(e) {
    e.preventDefault();
    const submittedData = serialize(e.target, { hash: true });

    for (let field in props.fields) {
        
        if (props.fields[field].type === 'file') {
            submittedData[props.fields[field].name] = e.target[props.fields[field].name].files;
        };
    };
    emit('submit', submittedData);
}

</script>
