import Pilot from "./Pilot.js";
import FlightEngineer from "./FlightEngineer.js";
import LeadFlightTestEngineer from "./LeadFlightTestEngineer.js";
import CrewMember from "./CrewMember.js";

const classFinder = data => {
  switch (data.Role) {
  case "Pilot":
    return new Pilot(data);
  case "Flight Engineer (Third crew member)":
    return new FlightEngineer(data);
  case "Lead Flight Test Engineer":
    return new LeadFlightTestEngineer(data);
  default:
    return new CrewMember(data);
  }
};

export default classFinder;
