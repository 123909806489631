<template>
    <Shell  :label="label">
        <template v-slot:prefix>
            <div @click="toggle">
                <p class="mr-2 p-1 shadow text-center px-2 bg-gradient-to-br from-green-300 to-green-400 rounded-full text-white cursor-pointer" style="height: 30px; width: 30px; text-shadow: 1px 1px 3px #999" v-if="value" >&#10004;</p>
                <p class="mr-2 p-1 shadow text-center px-2 bg-gradient-to-br from-red-300 to-red-400 rounded-full text-white cursor-pointer" style="height: 30px; width: 30px; text-shadow: 1px 1px 3px #999" v-if="!value" >&#10006;</p>
                <input type="hidden" style="outline: none" v-bind="$attrs" :value="value"/>
            </div>
        </template>
        <template v-slot:comp>
            <input
                class="border-b-2 border-gray focus:border-primary focus:border-b-2 w-full text-sm"
                style="line-height: 36px; background: rgba(0, 0, 0, 0); outline: none"
                :name="inputName"
                :value="value ? inputValue : ''"       
                ref="inputElement"
                :required="value"
                :disabled="!value"
            />
        </template>
    </Shell>
</template>
<script setup>
import Shell from "./Shell.vue";
import { get } from "lodash";
import {
    defineProps,
    defineExpose,
    defineEmits,
    reactive
} from "vue";

let props = defineProps({
    "value": Boolean,
    "label": String,
    "inputValue": String,
    "inputName": String
});

let emit = defineEmits(["update:modelValue"]);

const toggle = () => {
    emit("update:modelValue", !props.value);
};

</script>
