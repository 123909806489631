

function isValidDate(date) {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

export const dateCollumn = {
    // cellRenderer: DateValue,
    // This function compares two values, `a` and `b`, and returns their relative order.
    comparator(a, b) {
        // Check if `a` is a valid date and if so, convert it to a timestamp. 
        // If it's not a valid date, set the timestamp to 0.
        let dateA = new Date(a);
        let timeA = isValidDate(dateA) ? dateA.getTime() : 0;

        // Check if `b` is a valid date and if so, convert it to a timestamp. 
        // If it's not a valid date, set the timestamp to 0.
        let dateB = new Date(b);
        let timeB = isValidDate(dateB) ? dateB.getTime() : 0;

        // Compare the timestamps of `a` and `b`.
        // If they are equal, return 0.
        // If `a` is greater than `b`, return 1.
        // If `a` is less than `b`, return -1.
        return timeA === timeB ? 0 : timeA > timeB ? 1 : -1;
    },
    valueFormatter(params) {
        return new Date(params.value)
            .toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });
    },
    filter: "agDateColumnFilter"
};
