import {
    DateValue
} from "@comp/index.js";
import { get } from "lodash";
import store from "../store";

export const revisions = {
    "label": "Revisions",
    "key": "Revisions",
    "type": "number",
    "local": true,
    "headers": [{
        "headerName": "Revision",
        "field": "Revision"
    }, {
        "headerName": "Date",
        "field": "Date",
        "filter": "agDateColumnFilter",
        "cellRenderer": DateValue
    }, {
        "headerName": "Reason",
        "field": "Reason"
    }],
    "rowActions": []
};

export const files = {
    "label": "Files",
    "key": "Files",
    "local": true,
    "headers": [{
        "headerName": "File",
        "field": "File"
    }, {
        "headerName": "Date Uploaded",
        "field": "Date",
        "filter": "agDateColumnFilter",
        "type": "date",
    }]
};

export const time = {
    "label": "Time",
    "key": "Time",
    "local": true,
    "headers": [{
        "headerName": "Time Recorded (Hours)",
        "field": "Time"
    }, {
        "headerName": "Who",
        "field": "UserID",
        valueFormatter(params) {
            return get(store, `state.users[${params.value}].name`, '');
        }
    }, {
        "headerName": "Type",
        "field": "Type"
    }]
};
