import {
    cancelJobCard,
    editJobCard,
    jobCardSignoffCheckList
} from "./actions";
import {
    engineerResponsibilities,
    technicalSecretaryResponsibilities
} from "./internalAttachable";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Title",
    "key": "Title",
    "class": "col-span-2"
}, {
    "label": "Parent",
    "key": "Parent",
    "link": ":ParentLocation/:ParentID"
}, {
    "label": "Colour",
    "key": "Type"
}, {
    "label": "Responsible Engineer",
    "key": "ResponsibleEngineer"
}, {
    "label": "Responsible Engineer Signed Off",
    "key": "Signedoff.Engineer",
    "type": "bool"
}, {
    "label": "Technical Secretary Signed Off",
    "key": "Signedoff.TechnicalSecretary",
    "type": "bool"
}, {
    "label": "Auditor Signed Off",
    "key": "Signedoff.Auditor",
    "type": "bool"
}, {
    "label": "Responsible Engineer Signed Off Date",
    "key": "Date.Signedoff.Engineer",
    "type": "date"
}, {
    "label": "Technical Secretary Signed Off Date",
    "key": "Date.Signedoff.TechnicalSecretary",
    "type": "date"
}, {
    "label": "Auditor Signed Off Date",
    "key": "Date.Signedoff.Auditor",
    "type": "date"
}];

const menuActions = [
    {
        "label": "Edit Job Card",
        "action": editJobCard
    },
    {
        "label": "Engineer Signoff",
        "action": jobCardSignoffCheckList("Engineer", "EngineerResponsibilities"),
        "break": true,
        show({ pageItem }) {
            if (pageItem.Signedoff.Engineer) {
                return [false, "Already Signed Off"];
            }

            return [true, ""];
        }
    },
    {
        "label": "Technical Secretery Signoff",
        "action": jobCardSignoffCheckList("TechnicalSecretary", "TechnicalSecretaryResponsibilities"),
        "break": true,
        show({ pageItem }){
            if (!pageItem.Signedoff.Engineer) {
                return [false, "Responsible Engineer has not signed off this Job Card"];
            }
            
            if(pageItem.Signedoff.TechnicalSecretary) {
                return [false, "Already Signed Off"];
            }
            
            return [true, ""];
        }
    },
    // {
    //     "label": "Auditor Signoff",
    //     "action": jobCardSignoffCheckList("Auditor", "AuditorResponsibilities"),
    //     "break": true,
    //     show({ pageItem }) {
    //         if (!pageItem.Signedoff.Engineer) {
    //             return [false, "Responsible Engineer has not signed off this Job Card"];
    //         }

    //         if (!pageItem.Signedoff.TechnicalSecretary) {
    //             return [false, "Technical Secretary has not signed off this Job Card"];
    //         }

    //         if (pageItem.Signedoff.Auditor) {
    //             return [false, "Already Signed Off"];
    //         }

    //         return [true, ""];
    //     }
    // },
    {
        "label": "Cancel Job Card",
        "class": "text-red",
        "action": cancelJobCard,
        "break": true
    }
];

export default [{
    "path": "/Database/JobCards",
    "component": {...ItemListView},
    "name": "Job Cards",
    "meta": {
        "app": "Database",
        "name": "Job Cards",
        "sideNav": true,
        "sideNavSection": "Projects",
        "storeData": ["Database/EngineerResponsibilities", "Database/TechnicalSecretaryResponsibilities"],
        "appSwitcher": false,
        "tableSettings": "JobCards",
        "dataPath": "Database/JobCards",
        "selectionRoute": "Database/JobCards/{ID}",
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Database/JobCards/:id",
    "component": ItemView,
    "name": "Job Card",
    "meta": {
        "app": "Database",
        "name": "Job Cards",
        "dataPath": "Database/JobCards",
        "selectionRoute": "Database/JobCards/{ID}",
        "storeData": ["Database/EngineerResponsibilities", "Database/TechnicalSecretaryResponsibilities"],
        title(item) {
            return `Job Card: ${item.Number}`
        },
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        },
        "dataFields": dataFields,
        "menuActions": menuActions,
        "attachedItems": {
            "Responsibilities": {
                "attachables": [engineerResponsibilities, technicalSecretaryResponsibilities]
            }
        }
    }
}, {
    "path": "/Database/TecSecReady",
    "component": {
        ...ItemListView
    },
    "name": "Tec Sec Ready",
    "meta": {
        "app": "Database",
        "name": "Tec Sec Ready",
        "sideNav": true,
        "sideNavSection": "Quality Assurance",
        "appSwitcher": false,
        "tableSettings": "TecSecCheck",
        "dataPath": "Database/JobCards",
        "selectionRoute": "Database/JobCards/{ID}",
        lock(user) {
            return [
                user?.Security?.Database?.TechnicalSecretary !== true, 
                "Cannot view this area"
            ]
        },
        filter(item) {
            return item.Signedoff && item.Signedoff.Engineer && !item.Signedoff.TechnicalSecretary
        }
    }
}
// , 
// {
//     "path": "/Database/AuditorReady",
//     "component": {...ItemListView},
//     "name": "Auditor Ready",
//     "meta": {
//         "app": "Database",
//         "name": "Auditor Ready",
//         "sideNav": true,
//         "sideNavSection": "Quality Assurance",
//         "appSwitcher": false,
//         "icon": "database",
//         "tableSettings": "JobCards",
//         "dataPath": "Database/JobCards",
//         "selectionRoute": "Database/JobCards/{ID}",
//         lock(user) {
//             return [user?.Security?.Database?.TechnicalSecretary !== true, "Cannot view this area"]
//         },
//         filter(item) {
//             return item.Signedoff && item.Signedoff.Engineer && item.Signedoff.TechnicalSecretary && !item.Signedoff.Auditor
//         }
//     }
// }
];
