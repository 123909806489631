<template>
  <div class="relative inline-block">
    <div class="p-2 cursor-pointer flex flex-row items-center bg-primary rounded-full hover:shadow hover:bg-white group" @click="toggle">
      <p class="text-sm text-white mx-2 group-hover:text-primary">Actions</p>
      <font-awesome-icon
      class="mx-2 text-white group-hover:text-primary"
        icon="ellipsis-v"
      ></font-awesome-icon>
    </div>
    <div
      class="
        absolute
        bg-white
        rounded-lg
        shadow
        z-10
        border border-gray-100
        right-0
        top-0
        m-0
      "
      v-if="state.open"
      style="min-width: 200px"
    >
      <div class="flex flex-col" style="direction: rtl">
        <div
          class="cursor-pointer flex flex-row items-center px-2 py-1 bg-primary-100 rounded-tl-lg rounded-tr-lg"
          @click="toggle"
          style="direction: ltr"
        >
          <p class="flex-grow text-white font-medium p-1 text-sm">Menu</p>
          <font-awesome-icon icon="times" class="text-red"></font-awesome-icon>
        </div>
        <div class="flex flex-col" style="direction: ltr">
          <div v-for="action in actions" :key="action.label">
            <div >        
              <hr class="w-full" v-if="action.break" />
              <div v-if="canShow(action)[0]" @click="action.action({...contextData }); toggle();" >
                <p :class="`m-2 p-2 hover:bg-primary hover:text-white cursor-pointer rounded text-xs flex flex-row items-center leading-4 ${action.class || ''}`" v-html="action.label"></p>
              </div>
              <div v-if="!canShow(action)[0]" class="flex flex-row items-center rounded pl-2" v-tooltip="{ content: canShow(action)[1], html: true }">
                <font-awesome-icon class="text-gray-300" icon="lock" v-tooltip="{ content: canShow(action)[1]}"></font-awesome-icon>
                <p :class="`p-2 text-gray-300 text-xs ${action.class || ''}`" v-html="action.label"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive } from "vue";

let props = defineProps({
  actions: Array,
  contextData: Object,
});

let state = reactive({
  open: false,
});

const canShow = action => {
  if(action.show) return action.show(props.contextData);
  else return [true, ""];
}

const toggle = () => {
  state.open = !state.open;
  if (state.open) {
    const body = document.querySelector("body");
    body.addEventListener(
      "mouseup",
      (e) => {
        setTimeout(() => {
          state.open = false;
        }, 1);
      },
      {
        once: true,
      }
    );
  }
};
</script>


