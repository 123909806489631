export default {
    "title": "Airworthiness Restrictions",
    "submitButtonText": "Save & Next",
    "fields": [{
        "label": "Details of any operational restrictions other than those specified in the request e.g. ETOPS, RVSM, RNAV etc. (Enter “None” if N/A)",
        "name": "op_restrictions",
        "type": "richtext",
        "required": true
    }, {
        "label": "List any MMEL limiting dates/hours/cycles",
        "name": "mmel_limiting",
        "type": "richtext",
        "required": true
    }, {
        "label": "Name, address and approval number of organisation releasing the aircraft to service under Part 145",
        "name": "approval_org_pt_145",
        "type": "richtext",
        "required": true,
    }]
};
