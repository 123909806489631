

export default {
    "title": "Supporting Data",
    "submitButtonText": "Save & Next",
    "fields": [{
        "label": "Supporting Information",
        "name": "supporting_freetext",
        "type": "richtext",
        "required": true
    }, {
        "label": "Uploaded Documents and Files",
        "name": "files",
        "type": "list",
    }, {
        "label": "Upload Supporting Documents and Files",
        "name": "__supportingDocuments",
        "type": "file",
        "required": false,
        "multiple": true
    }],
    "description": `<p>Please describe the request by entering as much detail as possible. Typical data (but not limited to) should include:</p>
    <ul class="list-disc px-8">
        <li>All communications with TC Holder (and any resulting NTO)</li>
        <li>Photographs</li>
        <li>Sketches, Rubbings</li>
        <li>Inspections carried out e.g. hard landing, lightning strike etc</li>
        <li>Temporary repairs carried out</li><li>Quick Access Recorder data (if relevant and available)</li>
        <li>Technical Log/Work Card extracts</li><li>AMM/SRM/IPC extracts</li>
        <li>MMEL open items (e.g. ADD/CFD/HIL references) – if any</li><li>Details of missing parts as per the CDL</li>
    </ul>`
};
