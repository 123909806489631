import {
    addFile,
    addReport,
    completeReport,
    editReport,
    removeFile,
    removeProcedureFromReport
} from "./actions.js"

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import {
    files as _files
} from "../../standardAttachables.js";
import _procedures from "../Procedures/attachables.js";
import coreProcedureAttachable from "../CoreProcedures/attachables.js";
import nonConAttachable from "../NonCons/attachables.js";
import { reportItemsReport } from "./reports.js";
import {
    standardDateLocal
} from "../../../utils.js";

let files = {
    ..._files
};

let procedures = {
    ..._procedures
};

procedures.rowActions = [
    ...procedures.rowActions, 
    {
    "label": "Remove Procedure",
    "action": removeProcedureFromReport,
    show({ pageItem }) {
        return [Object.keys(pageItem.Procedures).length > 1, "There must be at least one procedure in the report."];
    }
}];

files.actions = [{
    "label": "Add File",
    "action": addFile
}];

files.rowActions = [{
    "label": "Download File",
    async action({ db, rowItem, pageItem }) {
        let url = await db.download(`Audits/Reports/${pageItem.ID}/Files/${rowItem.ID}/${rowItem.File}`);
        window.open(url, "fileView", "popup");
    }
}, {
    "label": "Remove File",
    "action": removeFile,
    "break": true,
    "class": "text-red font-bold"
}];

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Title",
    "key": "Title"
}, {
    "label": "Parent",
    compute({ pageItem }) {
        let parent = pageItem.ParentType;
        // if the last letter is an s, remove it
        if (parent && parent[parent.length - 1] == "s") {
            parent = parent.slice(0, -1);
        };
        
        return `${parent}: ${pageItem.Parent}`
    },
    "link": "Audits/:ParentType/:ParentID"
}, {
    "label": "HISM",
    "key": "DAM"
}, {
    "label": "HoD",
    "key": "HOD"
}, {
    "label": "Date Created > Date Completed",
    compute({ pageItem }) {
        let humanCreated = new Date(pageItem.Date.Created).toLocaleDateString("en-GB", standardDateLocal);
        let humanCompleted = new Date(pageItem.Date.Completed).toLocaleDateString("en-GB", standardDateLocal);
        return `${humanCreated} > ${humanCompleted}`
    }
}, {
    "label": "Report",
    "key": "Comment",
    "class": "col-span-3",
    "grow": true
}];

export default [{
    "path": "/Audits/Reports",
    "component": {...ItemListView},
    "name": "Reports",
    "meta": {
        "app": "Audits",
        "storeData": ["Audits/Procedures", "Audits/Cycles"],

        "name": "Reports",
        "sideNav": true,
        "sideNavSection": "Reports",
        "tableSettings": "Reports",
        "dataPath": "Audits/Reports",
        "selectionRoute": "Audits/Reports/{ID}",
        "security": "Audits.Read",
        "menuActions": [{
            "label": "Add Report",
            "action": addReport()
        }],
        "formMetadata": {
            "ParentType": "CoreProcedures"
        }
    }
}, {
    "path": "/Audits/Reports/:id",
    "component": ItemView,
    "name": "Report",
    "meta": {
        "storeData": ["Audits/Procedures", "Audits/Cycles"],
        "app": "Audits",
        "name": "Report",
        "ParentType": "Reports",
        title(item) {
            return `Audit Report: ${item?.Number}`
        },
        "attachedItems": {
            "Audited Data / Findings": {
                "attachables": [coreProcedureAttachable, procedures, nonConAttachable, files]
            }
        },
        "menuActions": [{
            "label": "Edit Report",
            "action": editReport
        }, {
            "label": "Complete Report",
            "action": completeReport,
            show({ attachedItems, store }) {
                return [
                    attachedItems?.NonConformities?.items.length === 0 ||
                    attachedItems?.NonConformities?.items.every(i => store.state[i].Completed == true),
                    "Non-Conformities must be all completed."]
            }
        }, {
            "label": "Download Report",
            "action": reportItemsReport
        }],
        "dataFields": dataFields,
        "formMetadata": {
            "ParentType": "Reports"
        }
    }
}];
