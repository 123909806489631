export default {
    "title": "Continued Airworthiness Schedule",
    "submitButtonText": "Save & Next",
    "fields": [{
        "label": "Date and details of next scheduled maintenance <br/> (excluding weekly/daily inspections)",
        "name": "next_maint_details",
        "type": "richtext",
        "required": true
    }, {
        "label": "Details of any ongoing scheduled/unscheduled maintenance <br/>(Enter “None” if N/A)",
        "name": "maint_details",
        "type": "richtext",
        "required": true
    }]
};
