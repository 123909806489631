<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0"
    style="background: rgba(0, 0, 0, 0.3)"
    v-show="state.open"
  >
    <div class="mx-auto container" style="max-width: 600px">
      <div
        class="
          bg-white
          rounded
          shadow
          p-2
          border border-gray-100
          opacity-100
          mt-12
        "
        id="_db_search_container"
      >
        <div class="flex flex-row items-center">
          <p class="p-2 text-text font-medium flex-grow text-primary text-sm">
            Database Recents
          </p>
          <IconButton icon="times" @click="toggle" class="cancel" />
        </div>

        <div class="flex flex-row m-2">
          <p class="py-1 font-bold text-sm" style="width: 140px">Type</p>
          <p class="py-1 font-bold text-sm flex-grow">Number</p>
        </div>
        <hr>
        <div
          class="
            py-1
            flex flex-row
            m-2
            odd:bg-gray-50
            hover:font-bold
            cursor-pointer
          "
          v-for="result in results"
          :key="result.link"
          @click="
            router.push(result.link);
            toggle();
          "
        >
          <p class="py-1 text-sm" style="width: 140px">{{ result.type }}</p>
          <p class="py-1 flex-grow text-sm">{{ result.name }}</p>
        </div>
        <hr>
        <Loading v-if="state.loading" />
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  onMounted,
  reactive,
  defineExpose,
  watch,
  getCurrentInstance,
  computed,
} from "vue";
import { Input, Loading, IconButton, Form } from "@comp";
import { get, merge, sortBy } from "lodash";
import serialize from "form-serialize";
import { db } from "../db.js";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const instance = getCurrentInstance();

const store = useStore();
const router = useRouter();

const results = computed(() => {
  return store.state.user?.Recents || [];
})

let state = reactive({
  open: false,
  loading: false,
  submitting: false,
  searchables: []
});

const toggle = () => {
  state.open = !state.open;
};


defineExpose({
  toggle,
});
</script>
