export const reviseReportPlanner = ({ form, db, store, cycle, cycleId, currentCycleRevision }) => {

    const nextRev = parseInt(currentCycleRevision.Revision) + 1;
    
    form.setConfig({
        "title": "Revise Report Planner",
        "description": `Revise Report Planner ${currentCycleRevision.Revision} to ${nextRev}`,
        "type": "push",
        "location": `Audits/Cycles/${cycleId}/Revisions`,
        "fields": [
            {
                "name": "Revision",
                "type": "value",
                "value": () => nextRev,
                "label": "Next Revision"
            },
            {
                "name": "Description",
                "type": "text",
                "required": true,
                "divClass": "col-span-3",
                "label": "Description / Reason for Changes",
            }
        ],
        preSubmitHook() {
            return {
                "Date": new Date().toISOString(),
                "Locked": false,
            }
        },
        async postSubmitHook({ typeRef }) {
            await db.set(`Audits/Cycles/${cycleId}/RevisionID`, typeRef.key);
        }
    });
    
    form.toggle();
};

export const lockRevision = ({ form, db, store, cycle, cycleId, currentCycleRevision }) => {
    form.setValues(currentCycleRevision);
    form.setConfig({
        "title": "Lock Revision",
        "description": `Lock Revision ${currentCycleRevision.Revision}`,
        "type": "update",
        "location": `Audits/Cycles/${cycleId}/Revisions/${currentCycleRevision.ID}`,
        "fields": [{
            "name": "Description",
            "type": "text",
            "required": true,
            "grow": true,
            "divClass": "col-span-3",
            "label": "Description / Reason for Changes",
        }],
        preSubmitHook() {
            return {
                "DateLocked": new Date().toISOString(),
                "Locked": true,
            }
        }
    });
    
    form.toggle();
};

export const listRevisionChanges = (form, cycleRevisions) => {

    console.log(cycleRevisions);

    let listofRevisions = Object.values(cycleRevisions.Revisions).filter(rev => !!rev.Date && !!rev.Description).map(rev => {
        console.log(rev);
        return `<strong>Revision ${rev.Revision} </strong> ${new Date(rev.Date).toLocaleDateString()} <br>Description: ${rev.Description}<br>`;
    });

    const joinedListOFRevisions =  `<ul>${listofRevisions.join('')}</ul>`;

    console.log(listofRevisions);

    form.setValues({
        "Description": joinedListOFRevisions
    });
    
    form.setConfig({
        "title": "List of Revision Changes",
        "type": "view",
        "submitButtonLabel": "Close",
        "fields": [{
            "name": "Description",
            "type": "value",
            "grow": true,
            "divClass": "col-span-3",
            value: () => joinedListOFRevisions
        }]
    });

    form.toggle();
};
