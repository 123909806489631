import { Convert } from "easy-currencies";

export const addCommercial = ({ form, store, db }) => {
    form.setConfig({
        "title": "Add New Commercial",
        "submitButtonLabel": "Save",
        "type": "push",
        "location": "Database/Commercials/",
        "successMessage": "Comercial Added",
        "fields": [{
            "label": "Number",
            "type": "value",
            "value": () => store.state.counts.database.commercials.count
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text"
        }, {
            "label": "Customer",
            "name": "Customer",
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/Customers"
        }],
        preSubmitHook() {
            return {
                "Number": store.state.counts.database.commercials.count,
                "Date": {
                    "Created": (new Date()).toISOString(),
                    "Completed": "N/A",
                    "Cancelled": "N/A"
                },
                "Quote": {
                    "Price": 0,
                    "Time": 0
                },
                "Completed": false,
                "Cancelled": false
            };
        },
        async postSubmitHook({ data }) {
            await db.utils.upCount("counts/database/commercials/count");
            await db.utils.updateRefs(data, "Database/Refs/Commercials", ["Number", "ID", "Title", "Date", "Customer", "Cancelled"]);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editCommercial = ({ form, pageItem, db }) => {
    form.setValues({
        "Title": pageItem.Title,
        "Customer": pageItem.Customer
    });
    form.setConfig({
        "title": "Edit Commercial",
        "submitButtonLabel": "Save",
        "type": "update",
        "location": `Database/Commercials/${pageItem.ID}`,
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text"
        }, {
            "label": "Customer",
            "name": "Customer",
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/Customers"
        }],
        postSubmitHook({ data }) {
            Object.keys(pageItem?.PurchaseOrders || {}).forEach(key => {
                db.set(`Database/PurchaseOrders/${key}/Customer`, data.Customer);
            });
            data.PurchaseOrders = !!data.PurchaseOrders;
            db.utils.updateRefs(data, "Database/Refs/Commercials", ["Number", "ID", "Title", "Date", "Customer", "Cancelled", "PurchaseOrders"]);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const amendQuote = ({ form, item }) => {
    form.setValues({
        "Quote[Price]": item.Quote.Price,
        "Quote[Currency]": item.Quote.Currency || "gbp"
    });
    form.setConfig({
        "title": "Amend Quote Price",
        "submitButtonLabel": "Save",
        "type": "update",
        "location": `Database/Commercials/${item.ID}`,
        "fields": [, {
            "label": "Currency",
            "name": "Quote[Currency]",
            "type": "dropdown",
            "dropdownData": [{
                "label": "£ GBP",
                "id": "gbp"
            }, {
                "label": "$ USD",
                "id": "usd"
            }, {
                "label": "€ EUR",
                "id": "eur"
            }]
        }, {
            "label": "Price",
            "name": "Quote[Price]",
            "step": "0.01",
            "type": "number"
        }, {
            "label": "Estimation of hours to complete job",
            "type": "value",
            "disabled": true,
            value({ liveInputData }) {
                return Math.ceil(liveInputData["Quote[Price]"] / 60);
            }
        }],
        async preSubmitHook({ data }) {
            let rate = data.Quote.Price;
            if (data.Quote.Currency !== "gbp") {
                let response = await Convert(data.Quote.Price).from(data.Quote.Currency.toUpperCase()).to("GBP");
                rate = Math.round(response * 100) / 100;
            }
            return {
                "Quote/Time": Math.ceil(data.Quote.Price / 60),
                "Quote/PriceGBP": rate
            }
        }
    });
    form.getDropdownData();
    form.toggle();
}

export const cancelCommercial = ({ form, item }) => {
    form.setItem(item)
    form.setConfig({
        "title": "Cancel Commercial",
        "description": "Are you sure you want to cancel this commercial?",
        "submitButtonLabel": "Cancel",
        "submitButtonClass": "cancel",
        "type": "update",
        "location": `Database/Commercials/${item.ID}`,
        preSubmitHook() {
            return {
                "Cancelled": true
            }
        }
    });
    form.toggle();
}
