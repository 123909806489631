import {
    addNonCon
} from "./actions";


const actions = [{
    "label": "Add Non Conformity",
    "action": addNonCon,
    show( { meta } ) {
        return [meta.ParentType === "Reports", "Can only add a non conformity to a Report"]
    }
}];

const headers = [{
    "headerName": "Number",
    "field": "Number"
}, {
    "headerName": "Procedure",
    "field": "Procedure"
}, {
    "headerName": "Cycle",
    "field": "Cycle"
}, {
    "headerName": "Completed",
    "field": "Completed",
    "type": "bool"
}];

const rowActions = [{
    "label": "View Non Conformitiy",
    action({ router, rowItem }) {
        router.push(`/Audits/NonConformities/${rowItem.ID}`)
    }
}];

export default {
    "label": "Non Conformities",
    "key": "NonConformities",
    "location": "/Audits/NonConformities/",
    actions,
    rowActions,
    headers
};
