<template>
  <p v-html="displayValue"></p>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "DateValue",
  computed: {
    displayValue() {
      function isDate(s) {
        if (isNaN(s) && !isNaN(Date.parse(s))) return true;
        else return false;
      }

      if (isDate(this.params.value) || this.params.value instanceof Date) {
        return new Date(this.params.value).toLocaleDateString("en-GB", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } else {
        return this.params.value;
      }
    },
  },
});
</script>
