// This code exports a named constant function buildLimitations, which takes in two arguments: item and store.

export const buildLimitations = (item, store) => {
    // The function first creates a let variable 'panelLimitations' by getting the entries from the object 'item.Panels'.
    let panelLimitations = Object
        .entries(item.Panels)
        // Then, the entries are filtered to only include the ones where the 'Answer' property of the panel object is not "Checker", "Approver", "CVE", or true, and the 'Limitations' property is not equal to "None".
        .filter(([key, panel]) => {
            return !["Checker", "Approver", "CVE", true].includes(panel.Answer) ? false : !panel.Limitations ? false : true;
        })
        // The filtered entries are then mapped to a new array of strings, each string being a formatted HTML list item with the NamePretty property of the corresponding object in the 'store.state["Matrices/SignatoryMatrix/Panels"]' object, and the Limitations property of the panel object.
        .map(([key, panel]) => {

            return `
                <li class="my-4">
                    <strong>${store?.state["Matrices/SignatoryMatrix/Panels"][key].NamePretty}</strong>
                    <p>${Object.values(panel.Limitations).map(i => i.Limitation).join("<br>")}</p>
                </li>
            `
        })
        // The mapped array is then joined to a single string with no separator.
        .join("");
    // Finally, the function returns a string in HTML format, which consists of the heading "Panel Limitations" and a list of the panel limitations in 'panelLimitations' wrapped in an unordered list, or "None" if there are no panel limitations. It also includes the heading "Additional Limitations" and the 'Limitations' property of the 'item' object or "None" if it is falsy.
    return `<strong class="my-4 text-lg">Panel Limitations</strong><br/><ul>${panelLimitations || "None"}</ul><strong class="my-4 text-lg">Additional Limitations</strong><br/>${item.Limitations || "None"}`
}

// This code exports a named constant function buildApprovedPanels, which takes in two arguments: item and store.

export const buildApprovedPanels = (item, store) => {
    // The function first creates a let variable 'panelLimitations' by getting the entries from the object 'item.Panels'.
    let panelLimitations = Object
        .entries(item.Panels)
        // Then, the entries are filtered to only include the ones where the 'Answer' property of the panel object is either "Checker", "Approver", "CVE", or true.
        .filter(([key, panel]) => {
            return ["Compiler", "Checker", "Approver", "CVE", true].includes(panel.Answer);
        })
        // The filtered entries are then mapped to a new array of strings, each string being a formatted HTML list item with the NamePretty property of the corresponding object in the 'store.state["Matrices/SignatoryMatrix/Panels"]' object, and the Answer property of the panel object. The 'Limitations' property of the panel object is also displayed, with a '*' symbol appended if it is not equal to "None" or an empty string.
        .map(([key, panel]) => {
            return `
              <li>
                  ${store?.state["Matrices/SignatoryMatrix/Panels"][key].NamePretty}${(panel.Limitations === "None" || panel.Limitations === "") ? "" : " <strong>*</strong>"} - ${panel.Answer}
              </li>
              `
        })
        // The mapped array is then joined to a single string with no separator.
        .join("");

    // Finally, the function returns a string in HTML format, which consists of the heading "Approved Panels" and a list of the approved panels in 'panelLimitations' wrapped in an unordered list, or "None" if there are no approved panels.
    return `<strong>Approved Panels</strong><br/><ul>${panelLimitations || "None"}</ul>`
}

// This code exports a named constant function buildApprovedFunctions, which takes in two arguments: item and store.

export const buildApprovedFunctions = (item, store) => {
    // The function first creates a constant variable 'approvedFunctions' by getting the keys from the object 'item.ApprovedFunctions' or an empty object if it is falsy.
    const approvedFunctions = Object.keys(item.ApprovedFunctions || {})
        // Then, the keys are filtered to only include the ones that exist in the 'store.state["Matrices/SignatoryMatrix/ApprovedFunctions"]' object.
        .filter(key => {
            return !!store.state["Matrices/SignatoryMatrix/ApprovedFunctions"][key];
        })
        // The filtered keys are then mapped to a new array of strings, each string being the 'Name' property of the corresponding object in the 'store.state["Matrices/SignatoryMatrix/ApprovedFunctions"]' object, and appending "(Post Holder)" if the 'FormFourFunction' property is truthy.
        .map(key => {
            let af = store.state["Matrices/SignatoryMatrix/ApprovedFunctions"][key];
            return `${af.Name} ${af.FormFourFunction ? "(Post Holder)" : ""}`
        })
        // The mapped array is then joined to a single string with the '</li><li>' separator.
        .join("</li><li>");

    // Finally, the function returns a string in HTML format, which consists of the heading "Approved Functions" and a list of the approved functions in 'approvedFunctions' wrapped in an unordered list.
    return `<strong>Approved Functions</strong><br/><ul><li>${approvedFunctions}</li></ul>`
};;  
