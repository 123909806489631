import get from 'lodash/get';
// 
import store from '../../../store';
// this function compare to objects and return the identifiers of the changes made
export default function (oldData, newData) {
    if(!oldData || !newData) {
        return "";
    }
    
    const users = Object.entries(newData || {});

    const changes = [];

    for(let [userId, user] of users) {
        
        if(!oldData[userId]) {
            changes.push(`User ${user.FirstName} ${user.LastName} added to the signatory matrix`);
            continue;
        }

        const oldUser = oldData[userId];
        const panelChanges = comparePanelChanges(Object.values(user.Panels), Object.values(oldUser.Panels));

        if(panelChanges?.length > 0) {
            changes.push(`Changes made to ${user.FirstName} ${user.LastName}: ${panelChanges.join(', ')}`);
        }
        
        const approvedFunctionChanges = compareApprovedFunctions(oldUser?.ApprovedFunctions || {}, user?.ApprovedFunctions || {});

        if(approvedFunctionChanges?.length > 0) {
            changes.push(`Changes made to ${user.FirstName} ${user.LastName}: ${approvedFunctionChanges.join(', ')}`);
        }
    }

    return changes.join('</br> ');
};

function comparePanelChanges(newPanels, oldPanels) {
    let allChanges = [];
    for (let newPanel of newPanels) {
        //get old panel by id
        let oldPanel = oldPanels.find(p => p.ID === newPanel.ID);

        //get panel meta
        const panelMeta = store.state["Matrices/SignatoryMatrix/Panels"][newPanel.ID];
        newPanel.meta = panelMeta;

        //add inital line for the changes of this panel

        //compare panel changes
        if (oldPanel) {
            let changes = comparePanel(oldPanel, newPanel);

            if (changes.length === 0) {
                continue;
            }

            //add changes to all changes
            allChanges = [...allChanges, ...changes];
        }
    };
    return allChanges
};

function comparePanel(oldPanel, newPanel) {

    let changes = [];
    
    if (oldPanel.Answer !== newPanel.Answer) {
        changes.push(`${newPanel.meta.Name} competency level changed from ${oldPanel.Answer} to ${newPanel.Answer}`);

    }
    if (newPanel.Limitations) {
        Object.entries(newPanel.Limitations).forEach(([key, value]) => {

            if(!get(oldPanel.Limitations, key, null)) {
                changes.push(`Limitiation added to ${newPanel.meta.Name}`);
                return;
            }

            if (oldPanel?.Limitations[key].Limitation !== value.Limitation) {
                changes.push(`${newPanel.meta.Name} changed to ${value}`);
            }
        });
    }

    return changes;
}

function compareApprovedFunctions(oldFunctions, newFunctions) {
    
    const oldKeys = Object.keys(oldFunctions);
    const newKeys = Object.keys(newFunctions);

    const allChanges = [];

    for (let key of newKeys) {
        if (!oldKeys.includes(key)) {
            allChanges.push(`Approved Function ${store.state["Matrices/SignatoryMatrix/ApprovedFunctions"][key].Name} added`);
        }
    };
    for(let key of oldKeys) {
        if(!newKeys.includes(key)) {
            allChanges.push(`Approved Function ${store.state["Matrices/SignatoryMatrix/ApprovedFunctions"][key].Name} removed`);
        }
    }
    return allChanges
};
