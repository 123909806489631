export const mainTableConfig = {
    hLineWidth: function (i, node) {
        return 1;
    },
    vLineWidth: function (i) {
        return 1;
    },
    hLineColor: function (i) {
        return '#333';
    },
    paddingLeft: function (i) {
        return 8;
    },
    paddingRight: function (i, node) {
        return 8;
    }
};
