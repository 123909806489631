import Settings from "./Settings.vue";
import approvedFunctions from "./ApprovedFunctions/attachable";
import panels from "./Panels/attachable";
import questions from "./InterviewQuestions/attachable";
import testerTypes from "./TesterTypes/attachable";
import trainingVideos from "./TrainingVideos/attachable";

export default [{
    "path": "/Matrices/SignatoryMatrix/Settings",
    "component": Settings,
    "name": "Signatory Matrix Settings",
    "meta": {
        "app": "Matrices",
        "name": "Signatory Matrix Settings",
        "sideNav": true,
        "sideNavSection": "Settings",
        title() {
            return "Signatory Matrix Settings"
        },
        "dataFields": [],
        "menuActions": [],
        "attachedItems": [panels, questions, approvedFunctions, testerTypes, trainingVideos],
        lock(user) {
            return [user?.Security?.Matrices?.Reviewer !== true, "Cannot view this area"]
        }
    }
}];
