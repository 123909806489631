import {
    addInterviewQuestion,
    editInterviewQuestion
} from "./actions";

export default {
    "label": "Application Questions",
    "key": "InterviewQuestions",
    "location": "Matrices/SignatoryMatrix/InterviewQuestions/",
    "headers": [{
        "headerName": "Question",
        "field": "Question",
        "flex": 1
    }],
    "actions": [{
        "label": "Add Question",
        "action": addInterviewQuestion
    }],
    "rowActions": [{
        "label": "Edit Question",
        "action": editInterviewQuestion
    }]
}
