import { makeAttached } from "../../../../../utils";

const fields = [{
    "label": "Question",
    "name": "Question",
    "type": "text",
    "divClass": "md:col-span-3"
}, {
    "label": "Approved Functions",
    "name": "Roles",
    "type": "dropdown",
    "multiple": true,
    "dataLocation": "Matrices/SignatoryMatrix/ApprovedFunctions",
    "labelKey": "Name",
    "idKey": "ID",
    "divClass": "md:col-span-3",
    dataFilter(i) {
        return i.NewApplication == true;
    }
}, {
    "label": "Requires Text Input",
    "name": "SupportingInformation",
    "type": "toggle"
}, {
    "label": "Yes / No",
    "name": "Answer",
    "type": "toggle"
}, {
    "label": "Upload File",
    "name": "File",
    "type": "toggle"
}, {
    "label": "Max Points",
    "name": "MaxPoints",
    "type": "number",
    "step": "1"
}];

export const addInterviewQuestion = ({ form }) => {
    form.setConfig({
        "title": "Add Question Type",
        "type": "push",
        "location": "Matrices/SignatoryMatrix/InterviewQuestions",

        "fields": fields,
        preSubmitHook({ data }) {
            return {
                "Roles": makeAttached(data.Roles)
            }
        },
        async postSubmitHook({ db }) {
            const _d = await db.once(`Matrices/SignatoryMatrix/InterviewQuestions`);
            db.set(`OpenForms/OpenData/InterviewQuestions`, _d);
        }
    });
    form.getDropdownData();
    form.toggle();
}

export const editInterviewQuestion = ({ form, rowItem }) => {
    form.setValues({
        ...rowItem,
        "Roles": Object.entries(rowItem.Roles).filter(([key, value]) => value === true).map(([key]) => key)
    });
    form.setConfig({
        "title": "Edit Question Type",
        "type": "update",
        "set": true,
        "location": `Matrices/SignatoryMatrix/InterviewQuestions/${rowItem.ID}`,
        "fields": fields,
        preSubmitHook({ data }) {
            return {
                "Roles": makeAttached(data.Roles)
            }
        },
        async postSubmitHook({ db }) {
            const _d = await db.once(`Matrices/SignatoryMatrix/InterviewQuestions`);
            db.set(`OpenForms/OpenData/InterviewQuestions`, _d);
        }
    });
    form.getDropdownData();
    form.toggle();
}
