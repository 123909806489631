import { addRiskRegisterForm, addSafetyManagementForm, addSafteyObjectiveResiterForm, editSafetyManagementForm, reviseRiskRegister, reviseSafteyObjectiveResiter } from "./actions";

import ItemListView from "../../components/ItemListView/ItemListView.vue";
import ItemView from "../../components/ItemView/ItemView.vue";
import {
    revisions as _revisions
} from "../standardAttachables.js";
import attachables from "./attachables.js";
import {
    getRiskColor
} from "./actions";
import {
    tooltip as overallRiskTooltip
} from "./overallRiskTooltip.js";
import settings from "./Settings/routes.js";
import {
    standardDateLocal
} from "../../utils.js";

const regRevisions = {
    ..._revisions,
    "headers": [_revisions.headers[0], _revisions.headers[1]],
    "actions": [{
        "label": "Revise Risk Register",
        "action": reviseRiskRegister
    }],
    "rowActions": [{
        "label": "Download",
        async action({ pageItem, rowItem, db }) {
            let url = await db.download(`Database/RiskReg/${pageItem.ID}/Revisions/${rowItem.ID}/${rowItem.File}`);
            window.open(url, "fileView", "popup");
        }
    }]
};

const objRevisions = {
    ..._revisions,
    "headers": [_revisions.headers[0], _revisions.headers[1]],
    "actions": [{
        "label": "Revise Saftey Objective Resiter",
        "action": reviseSafteyObjectiveResiter
    }],
    "rowActions": [{
        "label": "Download",
        async action({ pageItem, rowItem, db }) {
            let url = await db.download(`Database/SafteyObjectiveResiter/${pageItem.ID}/Revisions/${rowItem.ID}/${rowItem.File}`);
            window.open(url, "fileView", "popup");
        }
    }]
};

export default [{
    "path": "/SafetyManagement/Forms",
    "component": { ...ItemListView },
    "name": "Safety Management Forms",
    "meta": {
        "app": "SafetyManagement",
        "sideNav": true,
        "appSwitcher": true,
        "appSwitcherPosition": 7,
        "sideNavPosition": 1,
        "sideNavSection": "Safety and Risk Management",
        "icon": "helmet-safety",
        "name": "Safety Management Forms",
        "tableSettings": "SafetyManagementForms",
        "dataPath": "SafetyManagement/Forms",
        "storeData": ["Database/DocumentTypes", "SafetyManagement/Categories"],
        "selectionRoute": "SafetyManagement/Forms/{ID}",
        "security": "General.SafetyManagementUser",
        "menuActions": [{
            "label": "Add Safety Management Form",
            "action": addSafetyManagementForm
        }]
    }
}, {
    "path": "/SafetyManagement/RiskRegister",
    "component": { ...ItemListView },
    "name": "Risk Register",
    "meta": {
        "app": "SafetyManagement",
        "sideNav": true,
        "appSwitcher": false,
        "sideNavSection": "Safety and Risk Management",
        "name": "Risk Register",
        "tableSettings": "RiskRegister",
        "dataPath": "SafetyManagement/RiskRegister",
        "selectionRoute": "SafetyManagement/RiskRegister/{ID}",
        "menuActions": [{
            "label": "Add Risk Register Form",
            "action": addRiskRegisterForm
        }]
    }
}, {
    "path": "/SafetyManagement/SafteyObjectiveResiter",
    "component": { ...ItemListView },
    "name": "Saftey Objective Resiter",
    "meta": {
        "app": "SafetyManagement",
        "sideNav": true,
        "appSwitcher": false,
        "sideNavSection": "Safety and Risk Management",
        "name": "Saftey Objective Resiter",
        "tableSettings": "RiskRegister",
        "dataPath": "SafetyManagement/SafteyObjectiveResiter",
        "selectionRoute": "SafetyManagement/SafteyObjectiveResiter/{ID}",
        "menuActions": [{
            "label": "Add Risk Register Form",
            "action": addSafteyObjectiveResiterForm
        }]
    }
}, {
    "path": "/SafetyManagement/Forms/:id",
    "component": ItemView,
    "name": "Safety Management Form",
    "meta": {
        "app": "SafetyManagement",
        "name": "Safety Management Form",
        "dataPath": "SafetyManagement/Forms",
        "storeData": ["Database/DocumentTypes", "SafetyManagement/Categories"],
        "title": (item) => `Safety Management Form: ${item.Number}`,
        "dataFields": [{
            "label": "Number",
            "key": "Number"
        }, {
            "label": "Title",
            "key": "Title",
            "class": "md:col-span-2"
        }, {
            "label": "Category",
            "key": "Category"
        }, {
            "label": "Date Added",
            "key": "Date.Created",
            "type": "date"
        }, {
            "label": "Date Reviewed",
            "key": "Date.Reviewed",
            "type": "date"
        }, {
            "label": "Likelihood (Safety Manager)",
            "key": "SafetyManagerLikelihood"
        }, {
            "label": "Consequence (Safety Manager)",
            "key": "SafetyManagerConsequence"
        }, {
            "label": "Overall Risk",
            "tooltip": overallRiskTooltip,
            compute({ pageItem }) {
                return getRiskColor(pageItem?.SafetyManagerLikelihood?.split(" - ")[0], pageItem?.SafetyManagerConsequence?.split(" - ")[0]);
            }
        }, {
            "label": "Reportable Defect?",
            "tooltip": "Is this defect or occurrence classified as mandatory reportable?",
            "key": "ReportableDefect",
        }, {
            "label": "Voluntarily Submitted?",
            "tooltip": "Was this form voluntarily submitted?",
            "key": "VoluntarySubmission",
            show({ pageItem }) {
                return pageItem.ReportableDefect === "Yes";
            }
        }, {
            "label": "Date Submitted / Update Due / Closure ",
            "tooltip": "Enter the date this form was submitted",
            compute({ pageItem }) {
                const reportDate = new Date(pageItem.Date.ReportSubmitted);
                const updateDate = new Date(pageItem.Date.ReportSubmitted);
                updateDate.setDate(updateDate.getDate() + 30);
                const closureDate = new Date(pageItem.Date.ReportSubmitted);
                closureDate.setMonth(closureDate.getMonth() + 3);

                return `${reportDate.toLocaleDateString("en-gb", standardDateLocal)} / ${updateDate.toLocaleDateString("en-gb", standardDateLocal)} / ${closureDate.toLocaleDateString("en-gb", standardDateLocal)}`;
            },
            show({ pageItem }) {
                return pageItem.ReportableDefect === "Yes";
            }
        }],
        "menuActions": [{
            "label": "Edit Safety Management Form",
            "action": editSafetyManagementForm
        }],
        "attachedItems": {
            "Event Details": {
                "attachables": attachables
            }
        }
    }
}, {
    "path": "/SafetyManagement/RiskRegister/:id",
    "component": ItemView,
    "name": "Safety Management Reg",
    "meta": {
        "app": "SafetyManagement",
        "name": "Safety Management Reg",
        "dataPath": "SafetyManagement/RiskRegister",
        "storeData": ["Database/DocumentTypes", "SafetyManagement/Categories"],
        "title": (item) => `Safety Management Form: ${item.Number}`,
        "dataFields": [{
            "label": "Number",
            "key": "Number"
        }, {
            "label": "Title",
            "key": "Title",
            "class": "md:col-span-2"
        }, {
            "label": "Latest Revision",
            "key": "Revision",
            "type": "text",
            "actionButton": true,
            "actionText": "Download",
            async action({ pageItem, db }) {
                let url = await db.download(`Database/RiskReg/${pageItem.ID}/Revisions/${pageItem.RevisionID}/${pageItem.Revisions[pageItem.RevisionID].File}`);
                window.open(url, "fileView", "popup");
            }
        }],
        "menuActions": [],
        "attachedItems": {
            "Details": {
                "attachables": [regRevisions]
            }
        }
    }
}, {
    "path": "/SafetyManagement/SafteyObjectiveResiter/:id",
    "component": ItemView,
    "name": "Safety Management Reg",
    "meta": {
        "app": "SafetyManagement",
        "name": "Safety Management Reg",
        "dataPath": "SafetyManagement/SafteyObjectiveResiter",
        "storeData": ["Database/DocumentTypes", "SafetyManagement/Categories"],
        "title": (item) => `Safety Management Form: ${item.Number}`,
        "dataFields": [{
            "label": "Number",
            "key": "Number"
        }, {
            "label": "Title",
            "key": "Title",
            "class": "md:col-span-2"
        }, {
            "label": "Latest Revision",
            "key": "Revision",
            "type": "text",
            "actionButton": true,
            "actionText": "Download",
            async action({ pageItem, db }) {
                let url = await db.download(`Database/SafteyObjectiveResiter/${pageItem.ID}/Revisions/${pageItem.RevisionID}/${pageItem.Revisions[pageItem.RevisionID].File}`);
                window.open(url, "fileView", "popup");
            }
        }],
        "menuActions": [],
        "attachedItems": {
            "Details": {
                "attachables": [objRevisions]
            }
        }
    }
}, ...settings]
