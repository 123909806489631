import {
    checkDesignChangeJobCards
} from "../DesignChanges/actions.js";
import { pad } from "../../../utils";
import { responsibleEngineer } from "../../standardFields.js";

export const addJobCard = ({ form, pageItem, store, meta, db }) => {
    form.setConfig({
        "title": "Add Job Card",
        "type": "push",
        "location": "Database/JobCards",
        "fields": [{
            "label": "Title",
            "name": "Title",
            "divClass": "col-span-3",
            "type": "text"
        },
            responsibleEngineer,
        {
            "label": "Card Colour",
            "type": "dropdown",
            "name": "Type",
            "dropdownData": ["Yellow", "Blue"].map(i => ({ id: i, label: i }))
        }
        ],
        preSubmitHook() {
            return {
                "Cancelled": false,
                "Commercial": pageItem.Parent,
                "CommercialID": pageItem.ParentID,
                "Date": {
                    "Cancelled": "N/A",
                    "Created": (new Date()).toISOString(),
                    "Signedoff": {
                        "Auditor": "Not Checked",
                        "Engineer": "Not Checked",
                        "TechnicalSecretary": "Not Checked",
                    }
                },
                "Number": `C.${pad(store.state.counts.cards.count, 4)}`,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "ParentLocation": `Database/${meta.ParentType}/`,
                "ParentType": meta.ParentType,
                "Signedoff": {
                    "Auditor": false,
                    "Engineer": false,
                    "TechnicalSecretary": false
                },
                "Stage": "Awaiting Technical Secretary Check"
            }
        },
        async postSubmitHook({  newData }){
            await db.set(`Database/${meta.ParentType}/${pageItem.ID}/JobCards/${newData.ID}`, true);
            await db.set(`Database/Commercials/${newData.CommercialID}/JobCards/${newData.ID}`, true);
            await db.utils.upCount("counts/cards/count");
            if(meta.ParentType === "DesignChanges") {
                await checkDesignChangeJobCards(db, pageItem.ID);
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editJobCard = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Edit Job Card",
        "type": "update",
        "location": `Database/JobCards/${item.ID}`,
        "fields": [{
            "label": "Title",
            "name": "Title",
            "divClass": "col-span-3",
            "type": "text"
        },
            responsibleEngineer
        ],
        preSubmitHook() {
            return {
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const addJobCardFinding = ({ form, pageItem, store }) => {
    form.setConfig({
        "title": "Add Job Card Finding",
        "type": "push",
        "location": "Database/JobCardFindings",
        "fields": [{
            "label": "test",
            "name": "test",
            "type": "text",
            "divClass": "col-span-3"
        }, {
            "label": "Issue",
            "name": "Comment",
            "type": "richtext",
            "divClass": "col-span-3"
        }],
        preSubmitHook() {
            return {
                "JobCardID": pageItem.ID,
                "Number": `CF-${pad(store.state.counts.database.finding.count)}`,
                "Date": {
                    "Created": new Date().toISOString(),
                    "Signedoff": "N/A",
                    "Cancelled": "N/A"
                },
                "Signedoff": false,
                "Cancelled": false,
                "Parent": pageItem.Parent,
                "ParentID": pageItem.ParentID,
                "ResponsibleEngineer": pageItem.ResponsibleEngineer,
                "Colour": pageItem.Type,
                "AddedBy": pageItem.Signedoff.TechnicalSecretary ? "Auditor" : "Technical Secretary"
            }
        }
    });
    form.toggle();
};

export const editJobCardFinding = ({ form, item, store }) => {
    form.setValues({
        "Comment": item.Comment
    });
    form.setConfig({
        "title": "Update Job Card Finding",
        "type": "update",
        "location": `Database/JobCardFindings/${item.ID}`,
        "fields": [{
            "label": "Issue",
            "name": "Comment",
            "type": "richtext",
            "divClass": "col-span-3"
        }]
    });
    form.toggle();
};

export const cancelJobCard = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Cancel Job Card",
        "description": `Are you sure you want to cancel Job Card ${item.Number}?`,
        "submitButtonLabel": "Yes, Cancel Job Card",
        "submitButtonClass": "cancel",
        "type": "update",
        "location": `Database/JobCards/${item.ID}`,
        preSubmitHook() {
            return {
                "Cancelled": true
            }
        }
    });
    form.toggle();
};

export const jobCardSignoffCheckList = (type, typeResponsibilities) => async ({ form, item, store, db }) => {

    await db.store(`Database/${typeResponsibilities}`, store);
    await db.store("users", store);

    const requiredChecks = Object.values(store.state[`Database/${typeResponsibilities}`] || {}).filter(i => {
        return i.Colours.includes(item.Type.split(" ")[0]);
    }).map(i => {
        return {
            "type": "dropdown",
            "required": true,
            "label": i.Responsibility,
            "name": `${typeResponsibilities}[${i.ID}]`,
            "formStep": 1,
            "dropdownData": [{
                "label": "Yes",
                "id": "Yes"
            }, {
                "label": "N/A",
                "id": "N_A"
            }]
        };
    });

    const usersTime = Object.values(store.state.users).filter(i => i.main).map(i => {
        return {
            "label": `${i.name} Time`,
            "name": `Time/${i.key}`,
            "type": "number",
            "key": i.key,
            "formStep": 2
        };
    }).sort((a, b) => {
        return a.label.localeCompare(b.label);
    });

    let vals = {
        ...(Object.keys(item[typeResponsibilities] || {}).reduce((acc, i) => {
            return {
                [`${typeResponsibilities}[${i}]`]: item[typeResponsibilities][i] || null,
                ...acc,
            }
        }, {})),
        ...item[typeResponsibilities],
        ...Object.fromEntries(usersTime.map(i => [i.name, item.Time?.[i.key] || 0]))
    };
    
    form.setValues(vals);

    form.setConfig({
        "title": `${type} Signoff Checklist`,
        "type": "update",
        "location": `Database/JobCards/${item.ID}/`,
        "isSteppedForm": true,
        "fields": [
            ...requiredChecks, 
            ...usersTime
        ],
        async postSubmitHook() {
            await db.set(`Database/JobCards/${item.ID}/Date/Signedoff/${type}`, new Date().toISOString());
            await db.set(`Database/JobCards/${item.ID}/Signedoff/${type}`, true);
            if(item.ParentLocation.includes("DesignChanges")) {
                await checkDesignChangeJobCards(db, item.ParentID);
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const createBasicYellowCard = async ({ store, db, parent }) => {
    const count = store.state.counts.cards.count;
    return {
        "Number": `C.${pad(count, 4)}`,
        "Cancelled": false,
        "Commercial": parent.Parent,
        "CommercialID": parent.ParentID,
        "Date": {
            "Cancelled": "N/A",
            "Created": new Date().toISOString(),
            "Signedoff": {
                "Auditor": "Not Checked",
                "Engineer": "Not Checked",
                "TechincalSecretary": "Not Checked"
            }
        },
        "ID": parent.ID,
        "Parent": parent.Number,
        "ParentID": parent.ID,
        "ResponsibleEngineer": parent.ResponsibleEngineer,
        "ParentLocation": "Database/DesignChanges/",
        "Title": parent.Title,
        "Signedoff": {
            "Auditor": false,
            "Engineer": false,
            "TechincalSecretary": false
        },
        "Stage": "Awaiting Technical Secretary Check",
        "Type": "Yellow Card",
    };
};

export const createBasicGreenCard = ({ store, db, parent }) => {
    const count = store.state.counts.cards.count;
    return {
        "Number": `C.${pad(count, 4)}`,
        "Cancelled": false,
        "Commercial": "00000",
        "CommercialID": "--new00000",
        "Date": {
            "Cancelled": "N/A",
            "Created": new Date().toISOString(),
            "Signedoff": {
                "Auditor": "Not Checked",
                "Engineer": "Not Checked",
                "TechincalSecretary": "Not Checked"
            }
        },
        "ID": parent.ID,
        "Parent": parent.Number,
        "ParentID": parent.ID,
        "ResponsibleEngineer": "Needs Assigning",
        "ParentLocation": "Database/DesignChanges/",
        "Title": parent.Title,
        "Signedoff": {
            "Auditor": false,
            "Engineer": false,
            "TechincalSecretary": false
        },
        "Stage": "Awaiting Technical Secretary Check",
        "Type": "Green Card",
    };
};

export const createBasicRedCard = ({ store, db, parent }) => {
    const count = store.state.counts.cards.count;
    return {
        "Number": `C.${pad(count, 4)}`,
        "Cancelled": false,
        "Commercial": parent.Number,
        "CommercialID": parent.ID,
        "Date": {
            "Cancelled": "N/A",
            "Created": new Date().toISOString(),
            "Signedoff": {
                "Auditor": "Not Checked",
                "Engineer": "Not Checked",
                "TechincalSecretary": "Not Checked"
            }
        },
        "ID": parent.ID,
        "Parent": parent.Number,
        "ParentID": parent.ID,
        "ResponsibleEngineer": parent.ResponsibleEngineer,
        "ParentLocation": "Database/FlightConditions/",
        "Title": parent.Title,
        "Signedoff": {
            "Auditor": false,
            "Engineer": false,
            "TechincalSecretary": false
        },
        "Stage": "Awaiting Technical Secretary Check",
        "Type": "Red Card",
    };
}
