import { addProcedure, cancelProcedure, completeCycle, editProcedure } from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import coreProcedures from "../CoreProcedures/attachables.js";
import cyclesAttachable from "./cyclesAttachable";
import noncons from "../NonCons/attachables.js";
import reports from "../Reports/attachables.js";

export default [{
    "path": "/Audits/Procedures",
    "component": {...ItemListView},
    "name": "Procedures",
    "meta": {
        "app": "Audits",
        "storeData": ["Audits/Procedures", "Audits/Cycles"],

        "name": "Procedures",
        "sideNav": true,
        "sideNavSection": "Reports",
        "appSwitcher": false,
        "icon": "check-circle",
        "tableSettings": "Procedures",
        "dataPath": "Audits/Procedures",
        "selectionRoute": "Audits/Procedures/{ID}",
        "menuActions": [{
            "label": "Add Procedure",
            "action": addProcedure
        }]
    }
}, {
    "path": "/Audits/Procedures/:id",
    "component": ItemView,
    "name": "Procedure",
    "meta": {
        "app": "Audits",
        "storeData": ["Audits/Procedures", "Audits/Cycles"],

        "name": "Procedure",
        "sideNav": false,
        "sideNavSection": "Reports",
        "ParentType": "Procedures",
        "appSwitcher": false,
        "dataPath": "Audits/Procedures",
        "selectionRoute": "Audits/Procedures/{ID}",
        title(item) {
            return `Procedure: ${item.Title}`
        },
        "dataFields": [{
            "label": "Title",
            "key": "Title",
            "class": "col-span-3"
        }, {
            "label": "Current Cycle",
            "key": "CurrentCycle"
        }, {
            "label": "Last Completed Date",
            "key": "Date.Completed",
            "type": "date"
        }, {
            "label": "Next Due Date",
            "key": "Date.Due",
            "type": "date"
        }],
        "attachedItems": {
            "Items": {
                "attachables": [coreProcedures, noncons, reports, cyclesAttachable]
            }
        },
        "formMetadata": {
            "ParentType": "Procedures"
        },
        "menuActions": [{
            "label": "Update Procedure",
            "action": editProcedure
        }, {
            "label": "Complete Cycle",
            "action": completeCycle
        }, {
            "label": "Cancel Procedure",
            "class":"warn",
            "break": true,
            "action": cancelProcedure
        }]
    }
}
];
