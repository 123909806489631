// SubDocumentTypes
import {
    addSubDocumentType,
    editSubDocumentType
} from "./actions";

export default {
    "label": "Sub Document Types",
    "key": "SubDocumentTypes",
    "location": "Database/SubDocumentTypes/",
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "actions": [{
        "label": "Add Sub Document Type",
        "action": addSubDocumentType
    }],
    "rowActions": [{
        "label": "Edit Sub Document Type",
        "action": editSubDocumentType
    }]
}
