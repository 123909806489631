
export const addCustomer = ({ form, db }) => {
    form.setConfig({
        "title": "Add User",
        "type": "push",
        "location": "Home/Customers",
        "fields": [{
            "name": "name",
            "label": "Name",
            "type": "text"
        }, {
            "name": "email",
            "label": "Email",
            "type": "text"
        }, {
            "name": "customer",
            "label": "Customer",
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/Customers",
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const updateCustomer = ({ form, db, pageItem }) => {
    form.setValues(pageItem);
    form.setConfig({
        "title": "Update Customer",
        "type": "update",
        "location": `Userdata/${pageItem.ID}`,
        "fields": [{
            "name": "CustomerName",
            "label": "Customer",
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/Customers",
        }],
        postSubmitHook({ data }) {
            db.update(`users/${pageItem.ID}`, {
                "customer": data.department
            });
        }
    });
    form.getDropdownData();
    form.toggle();
};  

export const disableCustomer = ({ form, db, pageItem }) => {
    form.setConfig({
        "title": "Disable Customer",
        "description": `
            <p class="">Are you sure you want to disable this customer. This means the customer will not be able to use this application.</p>
        `,
        "location": `customers/${pageItem.ID}`,
        "type": "update",
        "fields":[],
        preSubmitHook(){
            return {
                "DISABLED": true
            };
        }
    });
    form.toggle();
};

