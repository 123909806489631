import {
    appConfig,
    makeAttached
} from "../../../utils.js";
import {
    createBasicYellowCard,
    jobCardSignoffCheckList
} from "../JobCards/actions.js";

import {
    createBasicDocuments
} from "../Documents/actions.js";

export const addDesignChange = ({ form, pageItem, store }) => {
    form.setItem(pageItem);
    form.setConfig({
        "title": "Add Design Change",
        "description": `Add a Design Change to Commercial ${pageItem.Number}`,
        "type": "push",
        "location": "Database/DesignChanges",
        "fields": [{
            "label": "Commercial",
            "type": "value",
            value() {
                return pageItem.Number
            }
        }, {
            "label": "Responsible Engineer",
            "name": "ResponsibleEngineer",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dropdownData": [{
                "label": "Minor",
                "id": "Minor"
            }, {
                "label": "Major",
                "id": "Major"
            }]
        }, {
            "label": "ATA Chapter",
            "name": "ATAChapter",
            "type": "dropdown",
            "labelKey": "NameFull",
            "sortKey": "meta.Number",
            "idKey": "Number",
            "dataLocation": "Database/ATAChapters"
        }, {
            "label": "Aircraft Code",
            "name": "AircraftCode",
            "sortKey": "meta.Number",
            "type": "dropdown",
            "labelKey": "NameFull",
            "idKey": "Number",
            "dataLocation": "Database/AircraftCodes"
        }, {
            "label": "New Design Change Number",
            "type": "value",
            value({ dropdownData, liveInputData }) {
                let ata = dropdownData.ATAChapter.find(i => i.meta.Number == liveInputData.ATAChapter);
                return `${liveInputData.ATAChapter || ""}-${liveInputData.AircraftCode || ""}-${ata?.meta?.CountDesignChanges || ""}`
            }
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text",
            "divClass": "col-span-3"
        }],
        preSubmitHook({ dropdownData, liveInputData }) {
            let ata = dropdownData.ATAChapter.find(i => i.meta.Number == liveInputData.ATAChapter);
            let ac = dropdownData.AircraftCode.find(i => i.meta.Number == liveInputData.AircraftCode);
            return {
                "ATAChapterID": ata.meta.ID,
                "AircraftCodeID": ac.meta.ID,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "Number": `${liveInputData.ATAChapter || ""}-${liveInputData.AircraftCode || ""}-${ata?.meta?.CountDesignChanges || ""}`,
                "Commercials": {
                    [pageItem.ID]: true
                },
                "Customer": pageItem.Customer,
                "Cancelled": false,
                "Date": {
                    "Cancelled": "N/A",
                    "Completed": "N/A",
                    "Transferred": "N/A",
                    "Created": (new Date()).toISOString()
                },
                "Transferred": false,
                "Completed": false,
                "SerialNumber": ata.meta.CountDesignChanges,
                "LockedForRelease": false,
                "EASANumber": liveInputData.Type == "Minor" ? appConfig.approvalNumber : "Input on Issue"
            };
        },
        async postSubmitHook({ db, typeRef, data }) {
            data.ID = typeRef.key;

            //up hte ata chapter count
            await db.set(`Database/ATAChapters/${data.ATAChapterID}/CountDesignChanges`, parseInt(data.SerialNumber) + 1);

            //add to commercial
            db.set(`Database/Commercials/${pageItem.ID}/DesignChanges/${data.ID}`, true);

            //update refs 
            db.utils.updateRefs(data, "Database/Refs/DesignChanges", ["Number", "ID", "Title", "Date", "Customer", "Cancelled", "Completed", "ResponsibleEngineer", "Parent", "EASANumber", "Type"]);
            db.utils.updateRefs(data, "Database/SuperRefs/DesignChanges", ["ID", "Title", "ATAChapter", "EASANumber", "Type"], "DesignChanges");

            // create basic documents    
            let newDocs = await createBasicDocuments({ store, db, parent: data, parentType: "DesignChanges" });
            for (let doc of newDocs) {
                let ref = await db.push(`Database/Documents`, doc);
                doc.ID = ref.key;
                db.utils.updateRefs(doc, "Database/Refs/Documents", ["Number", "ID", "Title", "Date", "Revision", "Parent", "ParentID", "SubDocumentType", "MPDS"]);
                db.utils.updateRefs({
                    "key": ref.key,
                    "c": doc.Number
                }, "Database/SuperRefs/Documents", ["k", "c"]);
                await db.set(`Database/DesignChanges/${typeRef.key}/Documents/${ref.key}`, true);
                //update the count for the document type
                db.set(`Database/DocumentTypes/${doc.TypeID}/Count`, doc.Count + 1);
            }

            // create the yellow card
            let yellowCard = await createBasicYellowCard({ store, db, parent: data, parentType: "DesignChanges" });
            await db.set(`Database/DesignChanges/${typeRef.key}/JobCards/${typeRef.key}`, true);
            await db.update(`Database/JobCards/${typeRef.key}/`, yellowCard);
            await db.set(`counts/cards/count`, store.state.counts.cards.count + 1);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const addTransferredDesignChange = ({ form, pageItem }) => {
    form.setItem(pageItem);
    form.setConfig({
        "title": "Add Design Change",
        "description": `Add a Design Change to Commercial ${pageItem.Number}`,
        "type": "push",
        "location": "Database/DesignChanges",
        "fields": [{
            "label": "Commercial",
            "type": "value",
            value() {
                return pageItem.Number
            }
        }, {
            "label": "Responsible Engineer",
            "name": "ResponsibleEngineer",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dropdownData": [{
                "label": "Minor",
                "id": "Minor"
            }, {
                "label": "Major",
                "id": "Major"
            }]
        }, {
            "label": "ATA Chapter",
            "name": "ATAChapter",
            "type": "dropdown",
            "labelKey": "NameFull",
            "sortKey": "meta.Number",
            "idKey": "Number",
            "dataLocation": "Database/ATAChapters"
        }, {
            "label": "Aircraft Code",
            "name": "AircraftCode",
            "sortKey": "meta.Number",
            "type": "dropdown",
            "labelKey": "NameFull",
            "idKey": "Number",
            "dataLocation": "Database/AircraftCodes"
        }, {
            "label": "New Design Change Number",
            "type": "text",
            "name": "Number"
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text",
            "divClass": "col-span-3"
        }],
        preSubmitHook({ dropdownData, liveInputData }) {
            let ata = dropdownData.ATAChapter.find(i => i.meta.Number == liveInputData.ATAChapter);
            let ac = dropdownData.AircraftCode.find(i => i.meta.Number == liveInputData.AircraftCode);
            return {
                "ATAChapterID": ata.meta.ID,
                "AircraftCodeID": ac.meta.ID,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "Commercials": {
                    [pageItem.ID]: true
                },
                "Customer": pageItem.Customer,
                "Cancelled": false,
                "Documents": true,
                "Date": {
                    "Cancelled": "N/A",
                    "Completed": "N/A",
                    "Transferred": "N/A",
                    "Created": (new Date()).toISOString()
                },
                "Transferred": true,
                "Completed": false,
                "SerialNumber": ata.meta.CountDesignChanges,
                "EASANumber": liveInputData.Type == "Minor" ? appConfig.approvalNumber : "Input on Issue"
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editDesignChange = ({ form, item }) => {
    form.setItem(item);
    form.setConfig({
        "title": "Edit Design Change",
        "description": `Edit Design Change ${item.Number}`,
        "type": "update",
        "location": `Database/DesignChanges/${item.ID}`,
        "fields": [{
            "label": "Responsible Engineer",
            "name": "ResponsibleEngineer",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dropdownData": [{
                "label": "Minor",
                "id": "Minor"
            }, {
                "label": "Major",
                "id": "Major"
            }]
        }, {
            "label": "Aircraft Code",
            "name": "AircraftCode",
            "sortKey": "meta.Number",
            "type": "dropdown",
            "labelKey": "NameFull",
            "idKey": "Number",
            "dataLocation": "Database/AircraftCodes"
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text",
            "divClass": "col-span-3"
        }],
        preSubmitHook({ dropdownData, liveInputData }) {
            let numSplit = item.Number.split("-");
            return {
                "EASANumber": liveInputData.Type == "Minor" ? appConfig.approvalNumber : "Input on Issue",
                "Number": `${numSplit[0]}-${liveInputData.AircraftCode}-${numSplit[2]}`
            }
        },
        async postSubmitHook({ db, newData }) {
            let refData = {
                ...item,
                ...newData,
                "ID": item.ID
            };
            
            
            await db.utils.updateRefs(refData, "Database/Refs/DesignChanges", ["Number", "ID", "Title", "Date", "Customer", "Cancelled", "Completed", "ResponsibleEngineer", "Parent", "EASANumber", "Type"]);
            await checkDesignChangeJobCards(db, item.ID);
        }
    });
    form.autoSetValues();
    form.getDropdownData();
    form.toggle();
};

export const cancelDesignChange = ({ form, item, attachedItems, store }) => {
    form.setItem(item)
    form.setConfig({
        "title": "Cancel Design Change",
        "description": `Are you sure you want to cancel Design Change ${item.Number}?`,
        "submitButtonLabel": "Cancel",
        "submitButtonClass": "cancel",
        "type": "update",
        "location": `Database/DesignChanges/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": true,
                "Date/Cancelled": new Date().toISOString()
            };
        },
        async postSubmitHook({ db }) {
            await db.update(`Database/JobCards/${item.ID}`, {
                "Cancelled": true,
                "Date/Cancelled": new Date().toISOString()
            });
            await db.set(`Database/Refs/DesignChanges/${item.ID}/Cancelled`, true);
            await checkDesignChangeJobCards(db, item.ID);
        }
    });
    form.toggle();
};

export const completeDesignChange = ({ form, item, store }) => {
    form.setItem(item)
    form.setConfig({
        "title": "Complete Design Change",
        "description": `Are you sure you want to complete this Design Change ${item.Number}?`,
        "submitButtonLabel": "Complete",
        "type": "update",
        "location": `Database/DesignChanges/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Completed": true,
                "Date/Completed": new Date().toISOString()
            };
        },
        async postSubmitHook({ db }) {
            const aircraft = await db.once("Database/AircraftCodes/");
            const ataChapters = await db.once("Database/ATAChapters/");
            await db.set(`Database/Refs/DesignChanges/${item.ID}/Completed`, true);
            await db.set(`OpenForms/OpenData/DesignChanges/${item.ID}`, {
                title: item.Title,
                aircraft: aircraft[item.AircraftCodeID].Name,
                ataChapter: ataChapters[item.ATAChapterID].Name,
                dateCompleted: new Date().toISOString()
            });
            const jobCard = await db.once(`Database/JobCards/${item.ID}`);
            jobCardSignoffCheckList("Engineer", "EngineerResponsibilities")({ form, item: jobCard, db, store });
        }
    });
    form.toggle();
};

export const linkDesignChange = ({ form, pageItem, db, meta }) => {
    form.setConfig({
        "title": "Link Design Change",
        "description": `Link a Design Change to ${pageItem.Number}`,
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/DesignChanges`,
        "fields": [{
            "label": "Design Change",
            "name": "_doc",
            "type": "dropdown",
            "dataLocation": "Database/Refs/DesignChanges",
            "labelKey": "Number",
            "idKey": "ID",
            "search": false
        }],
        async preSubmitHook({ data }) {
            return {
                "_doc": null,
                [data._doc]: true
            }
        },
        async postSubmitHook({ liveInputData }) {
            await db.set(`Database/DesignChanges/${liveInputData._doc}/${meta.ParentType}/${pageItem.ID}`, true);
            await checkDesignChangeJobCards(db, pageItem.ID);

        }
    });
    form.getDropdownData();
    form.toggle();
};

export const unlinkDesignChange = ({ form, pageItem, rowItem, meta, db }) => {
    form.setConfig({
        "title": "Unlink Design Change",
        "type": "delete",
        "location": `Database/DesignChanges/${rowItem.ID}/${meta.ParentType}/${pageItem.ID}`,
        "fields": [],
        async postSubmitHook() {
            db.delete(`Database/${meta.ParentType}/${pageItem.ID}/DesignChanges/${rowItem.ID}`);
            db.delete(`Database/${meta.ParentType}/${pageItem.ID}/Links/${rowItem.ID}`);
            await checkDesignChangeJobCards(db, pageItem.ID);

        }
    });
    form.toggle();
};

export const importDesignChangesJobCards = ({ form, pageItem, rowItem, meta }) => {

    form.setValues({
        _cards: Object.keys(rowItem.JobCards)
    });

    form.setConfig({
        "title": "Import Job Cards",
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/JobCards`,
        "fields": [{
            "label": "Job Cards",
            "name": "_cards",
            "type": "dropdown",
            "dataLocation": "Database/JobCards/",
            "dataKeys": Object.keys(rowItem.JobCards),
            "idKey": "ID",
            "multiple": true,
            "labelKey": "Number"
        }],
        preSubmitHook({ liveInputData }) {
            return {
                ...makeAttached(liveInputData._cards),
                _cards: null
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const hideDesignChange = ({ form, item, attachedItems, store }) => {
    form.setItem(item)
    form.setConfig({
        "title": "Hide Design Change from the main list of Design Changes",
        "submitButtonLabel": "Hide",
        "type": "update",
        "location": `Database/DesignChanges/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Hidden": true,
                "Date/Hidden": new Date().toISOString()
            };
        },
        async postSubmitHook({ db }) {
            await db.set(`Database/Refs/DesignChanges/${item.ID}/Hidden`, true);
            await checkDesignChangeJobCards(db, item.ID);

        },

    });
    form.toggle();
};

export const unhideDesignChange = ({ form, item, attachedItems, store }) => {
    form.setItem(item)
    form.setConfig({
        "title": "Unhide Design Change from the main list of Design Changes",
        "submitButtonLabel": "Unhide",
        "type": "update",
        "location": `Database/DesignChanges/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Hidden": false,
                "Date/Hidden": new Date().toISOString()
            };
        },
        async postSubmitHook({ db }) {
            await db.set(`Database/Refs/DesignChanges/${item.ID}/Hidden`, false);
            await checkDesignChangeJobCards(db, item.ID);
        },

    });
    form.toggle();
}

export const lockForRelease = ({ form, item, attachedItems, store }) => {
    form.setConfig({
        "title": "Lock For Release",
        "description": `Are you sure you want to lock this Design Change ${item.Number} for release?`,
        "location": `Database/DesignChanges/${item.ID}`,
        "type": "update",
        "fields": [],
        preSubmitHook() {
            return {
                "LockedForRelease": true
            };
        }
    });
    form.toggle();
};

export const unlockForRelease = ({ form, item, attachedItems, store }) => {
    form.setConfig({
        "title": "Unlock For Release",
        "description": `Are you sure you want to unlock this Design Change ${item.Number} for release?`,
        "location": `Database/DesignChanges/${item.ID}`,
        "type": "update",
        "fields": [],
        preSubmitHook() {
            return {
                "LockedForRelease": false
            };
        }
    });
    form.toggle();
};

export async function checkDesignChangeJobCards(db, id) {
    const designChange = await db.once(`Database/DesignChanges/${id}`);
    const jobCardKeys = Object.keys(designChange.JobCards);
    const jobCards = await Promise.all(jobCardKeys.map(key => db.once(`Database/JobCards/${key}`)));
    jobCards.filter(i => !!i).filter(card => card?.Cancelled !== true);
    
    const allCompleted = jobCards.every(card => card?.Signedoff?.Engineer === true);

    await db.set(`Database/Refs/DesignChanges/${id}/Completed`, allCompleted);
};

// export async function (db) {

// };
