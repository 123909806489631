import {
    addNonConformityCategory,
    editNonConformityCategory
} from "./actions";

export default {
    "label": "NonConformity Categories",
    "key": "NonConformityCategories",
    "location": "Audits/Settings/NonConformityCategories/",
    "actions": [{
        "label": "Add Non Conformity Category",
        "action": addNonConformityCategory
    }],
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "rowActions": [{
        "label": "Edit Non Conformity Category",
        action: editNonConformityCategory
    }]
};
