export default {
    "title": "Start New Application",
    "submitButtonText": "Start New Application",
    "description": `
        <p>
            This form is used to start a new application for a flight condition approval. </br> The following form will take around 5 minutes to complete. </br>You will need the following information to complete the form:
        </p>
        <ul class="list-disc px-8">
            <li>Company Name, Adress and liason contact information</li>
            <li>Reason for request</li>
            <li>Supporting documents</li>
            <li>Flight details</li>
        </ul>
    `,
    "fields": []
}
