import { addClaimItem, downloadReceipt, editClaimItem, removeClaimItem, uploadReceipt } from "./actions";

import store from "../../store";

export const items = {
    "label": "Items",
    "key": "Items",
    "location": "Claims/Items/",
    "actions": [{
        "label": "Add Item",
        "action": addClaimItem,
        show({ item }) {
            return [["Unsubmitted", "Returned"].includes(item.Stage), "You cannot add an item"];
        }
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number",
        "width": 50
    }, {
        "headerName": "Type",
        "field": "Type",
        valueFormatter(params) {
            let x =  store.state["Claims/Settings/ItemTypes"][params.data.Type];

            return x?.Name || "Unknown"
        }
    }, {
        "headerName": "Payment Method",
        "field": "PaymentType"
    }, {
        "headerName": "# People",
        "field": "People",
        "width": 50
    }, {
        "headerName": "Cost",
        "field": "Cost",
        "filter": 'agNumberColumnFilter',
        "type": "number",
        valueFormatter(params) {
            return `${params?.data?.Currency?.Symbol}${params?.data?.Cost}`
        },
        "width": 50
    }, {
        "headerName": "Receipt Attached",
        "field": "Receipt",
        "type": "bool",
        valueFormatter(params) {
            return !!params.data.Receipt
        },
        "width": 50
    }],
    "rowActions": [{
        "label": "Edit Item",
        "action": editClaimItem
    }, {
        "label": "Upload Reciept",
        "action": uploadReceipt
    },
    {
        "label": "Remove Item",
        "action": removeClaimItem,
        "break": true
    }]
};

export const receipts = {
    "label": "Receipts",
    "key": "Receipts",
    "location": "Claims/Receipts/",
    "headers": [{
        "headerName": "Receipt",
        "field": "File",
        valueFormatter(params) {
            return params?.data?.File?.split("/")[params?.data?.File?.split("/").length - 1]
        }
    }],
    "actions": [{
        "label": "Upload Receipt",
        "action": uploadReceipt
    }],
    "rowActions": [{
        "label": "Download Receipt",
        "action": downloadReceipt
    }]
};
