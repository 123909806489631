import { Convert } from "easy-currencies";
import {
    makeDropdown
} from "../../../utils";

export const addVariationOrder = ({ store, pageItem, form, attachedItems }) => {

    form.setItem(pageItem);
    form.setConfig({
        "title": "Add Variation Order",
        "description": `Add a Variation Order to Commercial ${pageItem.Number}`,
        "type": "push",
        "location": "Database/VariationOrders",
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text",
            "divClass": "col-span-1"
        }, {
            "label": "Quote",
            "name": "Quote[Price]",
            "type": "number",
            "divClass": "col-span-1"
        }, {
            "label": "Currency",
            "name": "Currency",
            "type": "dropdown",
            "dropdownData": [{
                "label": "£ GBP",
                "id": "gbp"
            }, {
                "label": "$ USD",
                "id": "usd"
            }, {
                "label": "€ EUR",
                "id": "eur"
            }]
        }, {
            "label": "Linked Purchase Order",
            "name": "PurchaseOrderID",
            "type": "dropdown",
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.PurchaseOrders.items, "Number", "ID")
        }],
        async preSubmitHook({ data }) {

            const selectedPurchaseOrder = store.state[`Database/PurchaseOrders/${data.PurchaseOrderID}`];

            let rate = data.Quote.Price;
            if (data.Currency !== "gbp") {
                let response = await Convert(data.Quote.Price).from(data.Currency.toUpperCase()).to("GBP");
                rate = Math.round(response * 100) / 100;
            }
            return {
                "PriceGBP": rate,
                "CommercialID": pageItem.ID,
                "Commercial": pageItem.Number,
                "Customer": pageItem.Customer,
                "Date": {
                    "Created": new Date()
                },
                "Quote": {
                    "Time": Math.floor(data.Quote.Price / 450) * 8,
                    "Price": data.Quote.Price
                },
                "PurchaseOrder": selectedPurchaseOrder ? selectedPurchaseOrder.Number : "No Purchase Order Linked",
            }
        },
        async postSubmitHook({ db, typeRef }) {
            await db.set(`Database/Commercials/${pageItem.ID}/VariationOrders/${typeRef.key}`, true);
            await db.set(`Database/VariationOrders/${typeRef.key}/Commercials/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editVariationOrder = ({ store, pageItem, item, form, attachedItems}) => {
    form.setValues({
        "Quote[Price]": item.Quote.Price,
        ...item
    });

    let fields = [{
        "label": "Number",
        "name": "Number",
        "type": "text"
    }, {
        "label": "Price",
        "name": "Quote[Price]",
        "type": "text"
    }, {
        "label": "Currency",
        "name": "Currency",
        "type": "dropdown",
        "dropdownData": [{
            "label": "£ GBP",
            "id": "GBP"
        }, {
            "label": "$ USD",
            "id": "USD"
        }, {
            "label": "€ EUR",
            "id": "EUR"
        }]
    }, {
        "label": "Percentage Invoiced",
        "name": "PercentageInvoiced",
        "type": "number",
        "min": 0,
        "required": false,
        "max": 100,
    }, {
        "label": "Percentage Paid",
        "name": "PercentagePaid",
        "type": "number",
        "min": 0,
        "required": false,
        "max": 100,
    }];

    if(pageItem.ID !== item.ID) {
        fields.push({
            "label": "Linked Purchase Order",
            "name": "PurchaseOrderID",
            "type": "dropdown",
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.PurchaseOrders.items, "Number", "ID")
        });
    }

    form.setConfig({
        "title": "Edit Variation Order",
        "type": "update",
        "location": `Database/VariationOrders/${item.ID}`,
        "fields": fields,
        async preSubmitHook({ data }) {

            const selectedPurchaseOrder = data.PurchaseOrderID ? store.state[`Database/PurchaseOrders/${data.PurchaseOrderID}`].Number : "No Purchase Order Linked";

            let rate = data.Quote.Price;
            if (data.Currency !== "gbp") {
                let response = await Convert(data.Quote.Price).from(data.Currency.toUpperCase()).to("GBP");
                rate = Math.round(response * 100) / 100;
            }
            return {
                "PriceGBP": rate,
                "Date": {
                    "Edited": new Date()
                },
                "PurchaseOrder": selectedPurchaseOrder,
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const addFile = ({ pageItem, form, store, db }) => {
    form.setConfig({
        "title": "Add File",
        "description": `Add a File to Variation Order ${pageItem.Number}`,
        "type": "push",
        "location": `Database/VariationOrders/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file"
        }],
        preSubmitHook({ files }) {
            return {
                "Date": (new Date()).toISOString(),
                "Name": files[0][0].name,
            }
        },
        postSubmitHook({ files, typeRef }) {
            db.upload(`Database/VariationOrders/${pageItem.ID}/Files/${typeRef.key}`, files[0][0]);

        }
    });
    form.toggle();
};

export const removeFile = ({ pageItem, rowItem, store, db, form }) => {
    form.setConfig({
        "title": "Remove File",
        "description": `Remove File ${rowItem.Name}`,
        "type": "delete",
        "location": `Database/VariationOrders/${pageItem.ID}/Files/${rowItem.ID}`,
        "fields": [],
        postSubmitHook() {
            db.remove(`Database/VariationOrders/${pageItem.ID}/Files/${rowItem.ID}`, rowItem.Name);
        }
    });
    form.toggle();
};

