<template>
  <div class="w-full flex flex-col">
    <p></p>
    <div
      class="m-2 p-2 bg-white rounded shadow flex flex-col"
      v-if="!state.item"
    >
      <Loading />
    </div>
    <div
      class="m-2 p-2 bg-white rounded shadow flex flex-col"
      v-if="state.item"
    >
      <div class="flex flex-col">
        <Fields
          :title="`EWIS: ${state.item.description}`"
          :contextData="state"
          :dataFields="state.dataFields"
          :menuActions="state.menuActions"
        />
      </div>
    </div>
    <div
      class="m-2 p-4 bg-white rounded shadow flex flex-col"
      v-for="(question, questionIndex) in state.item?.questions"
      :key="question.key"
    >
      <div class="flex flex-row items-center">
        <p class="flex-grow text-medium my-4">
          Step: {{ question.key.split("_")[1] }}
        </p>
        <IconButton
          icon="times"
          class="cancel"
          @click="rollback(questionIndex)"
          v-show="
            !state.item?.questions[state.item?.questions?.length - 1]?.answer
          "
        />
      </div>
      <p v-html="question?.message"></p>
      <!-- No answer provided -->
      <!-- No answer provided -->
      <!-- No answer provided -->
      <!-- No answer provided -->
      <div class="flex flex-col" v-if="!question.answer">
        <InputMultiline
          v-model="question.comment"
          name="comment"
          label="Comments"
          v-if="question.class !== 'end'"
        />
        <div class="flex flex-row items-center">
          <button
            v-for="button in question.buttons"
            :key="`_button_${button.answer}_${button.key}`"
            :class="`flex-grow rounded shadow  ${button.class}`"
            @click="setAnswer(question, questionIndex, button)"
          >
            {{ button.message }}
          </button>
        </div>
      </div>
      <!-- Answer provided -->
      <!-- Answer provided -->
      <!-- Answer provided -->
      <!-- Answer provided -->
      <!-- Answer provided -->
      <div class="flex flex-col" v-if="question.answer">
        <div class="flex flex-row items-center">
          <hr class="border border-bottom-2 border-grey flex-grow mx-2" />
          <p
            :class="`mt-4 ${
              question.answer === 'Yes'
                ? 'text-green text-xl font-bold'
                : question.answer === 'No'
                ? 'text-red text-xl font-bold'
                : 'text-primary'
            }`"
          >
            {{ question.answer }}
          </p>
          <hr class="border border-bottom-2 border-grey flex-grow mx-2" />
        </div>
        <p
          class="text-light text-sm text-gray text-center"
          v-show="question.class !== 'end'"
        >
          Go to step:
          {{ state.item.questions[questionIndex + 1]?.key.split("_")[1] }}
        </p>
      </div>
      <Value
        label="Comment"
        :value="question.comment"
        v-if="!!question.comment && question.class !== 'end' && question.answer"
        :grow="true"
      />
      <button
        v-if="question.class === 'end' && question.answer === undefined"
        :class="`flex-grow rounded shadow primary`"
        @click="finish(questionIndex)"
      >
        Save & Disable Editing
      </button>
    </div>
    <br />
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { db } from "../../../db";
import { useRoute, useRouter } from "vue-router";
import standardMenuActions from "../../standardMenuActions";
import { IconMenu, Loading, Value, InputMultiline, IconButton } from "@comp/index.js";
import Fields from "../../../components/Fields/Fields.vue";
import { take } from "lodash";
import htmlToPdfmake from "html-to-pdfmake";

const store = useStore();
const route = useRoute();
const router = useRouter();

// make them accessable in the template
const currentRoute = computed(() => route);

const state = computed(() => {
  return {
    masterQuestionList: store.state.ewisMasterQuestions,
    item: store.state[`Database/EWIS/saves/${currentRoute.value.params.id}`],
    menuActions: [
      {
        label: "Download",
        action: download
      },
      {
        label: "Edit",
        action: () => {
          db.update(`Database/EWIS/saves/${currentRoute.value.params.id}/questions/${state.value.item.questions.length - 1}`, {
            answer: null,
            answered: false,
          });
        },
      },
      standardMenuActions.back,
    ],
    dataFields: [
      {
        label: "Parent",
        key: "parent",
        link: `Database/DesignChanges/:parent_id`,
      },
    ],
    router,
    store,
  };
});

const lastAnswer = computed(() => {
  return state.value.item.questions[state.value.item.questions.length - 1].answer;
});

onMounted(() => {
  db.store("Database/EWIS/questions", store, "ewisMasterQuestions");
  db.watch(`Database/EWIS/saves/${currentRoute.value.params.id}`, store);
});

async function setAnswer(question, questionIndex, button) {
  await db.update(
    `Database/EWIS/saves/${state.value.item.key}/questions/${questionIndex}`,
    {
      answer: button.message,
      answered: true,
      comment: question.comment || null,
    }
  );

  await db.set(
    `Database/EWIS/saves/${state.value.item.key}/questions/${state.value.item.questions.length}`,
    store.state.ewisMasterQuestions[button.answer]
  );
}

async function rollback(questionIndex) {
  const newQuestions = take(state.value.item.questions, questionIndex + 1);
  newQuestions[newQuestions.length - 1].answer = null;
  newQuestions[newQuestions.length - 1].comments = null;
  await db.set(
    `Database/EWIS/saves/${state.value.item.key}/questions`,
    newQuestions
  );
}

async function finish(questionIndex) {
  await db.update(
    `Database/EWIS/saves/${state.value.item.key}/questions/${questionIndex}`,
    {
      answer: "Finished",
      answered: true,
    }
  );
}

async function download() {
  let item = state.value.item;

  let docDef = {
    pageOrientation: "landscape",
    content: [],
  };
  let con = docDef.content;

  // Design Chnage Report Title
  con.push({
    text: item.description,
    fontSize: 12,
    bold: true,
  });

  let table = {
    table: {
      headerRows: 1,
      widths: ["auto", "*", "auto", "*"],
      body: [["Step", "Question", "Answer", "Comments"]],
    },
  };

  for (let i = 0; i < item.questions.length; i++) {
    let question = item.questions[i];
    let nextQuestion = item.questions[i + 1];
    // create the row with the step prepopulated
    let row = [question.key.split("_")[1]];

  // Add the question
    row.push(htmlToPdfmake(question.message));

    // add the answer 
    row.push([{
      text: `${question.answer || ""}\n`,
      bold: true
    }, {
      text: !!nextQuestion ? `Go to step: ${nextQuestion.key.split("_")[1]}` : '',
      fontSize: 6
    }])
    // row.push(`${question.answer || ""}\n${!!nextQuestion ? `Go to step: ${nextQuestion.key.split("_")[1]}` : ''}`);
    row.push(question.comment || "");

    table.table.body.push(row);
  }

  con.push(table)

  const  { downloadPdf } = await import("../../../pdfDownloader");

  downloadPdf(docDef);
}
</script>
