export const addDrawingType = ({ form }) => {
    form.setConfig({
        "title": "Add Drawing Type",
        "type": "push",
        "location": "Database/DrawingTypes",
        "fields": [{
            "label": "Reason",
            "name": "Name"
        }]
    });
    form.toggle();
}

export const editDrawingType = ({ form, rowItem }) => {
    form.setValues(rowItem);
    form.setConfig({
        "title": "Edit Drawing Type",
        "type": "update",
        "location": `Database/DrawingTypes/${rowItem.ID}`,
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }, {
            "label": "Expected Time to Complete",
            "name": "ExpectedTime",
            "type": "text"
        }, {
            "label": "MDL Header",
            "name": "MDLHeader",
            "type": "dropdown",
            "dropdownData": [{
                "label": "Installation",
                "id": "Installation"
            }, {
                "label": "Manufacturing",
                "id": "Manufacturing"
            }, {
                "label": "Both",
                "id": "Both"
            }]
        },
        {
            "label": "Needs Stress Check",
            "name": "NeedsStress",
            "type": "toggle"
        }]
    });
    form.getDropdownData();
    form.toggle();
}
