import ItemView from "../../../components/ItemView/ItemView.vue"
export default [{
    "path": "/Home/Profile",
    "component": { ...ItemView },
    "name": "My Profile",
    "meta": {
        "app": "Home",
        "name": "My Profile",
        "sideNav": true,
        "sideNavPosition": 1,
        "sideNavSection": "Overview",
        "appSwitcher": false,
        "dataPathOverride": "user",
        "menuActions": [],
        "watch": false,
        title() {
            return "My Profile"
        },
        lock(user) {
            return [user?.Security?.General?.Training !== true, "Cannot view this area"]
        },
        "dataFields": [{
            "label": "Name",
            "key": "Basic.name"
        }, {
            "label": "Email",
            "key": "Basic.email"
        }, {
            "label": "Department",
            "key": "Basic.department"
        }]
    }
}];
