import { printDeclaration, uploadFile } from "./actions";

// Supporting Files
let supportingFiles = {
    "label": "Files",
    "key": "files",
    "local": true,
    "headers": [{
        "headerName": "File",
        "field": "File"
    }],
    "actions": [{
        "label": "Upload File",
        "action": uploadFile
    }]
};

supportingFiles.rowActions = [{
    "label": "Download",
    async action({ rowItem, pageItem, db }) {
        let url = await db.download(`OpenForms/FlightConditionApplications/${pageItem.ID}/files/${rowItem.ID}/${rowItem.File}`);
        window.open(url, "__blank");
    }
}];

export { supportingFiles };

export const supportingInformation = {
    "key": "supportingInformation",
    "label": "Supporting Information",
    "field": true,
    "fields": [{
        "label": "Supporting Information",
        "class": "md:col-span-3",
        "grow": true,
        "key": "supporting_freetext"
    }]
};

export const nextMainDetails = {
    "key": "nextMainDetails",
    "label": "Next Scheduled Maintenance",
    "field": true,
    "fields": [{
        "label": "Next Scheduled Maintenance",
        "class": "md:col-span-3",
        "grow": true,
        "key": "next_maint_details"
    }]
}

export const mainDetails = {
    "key": "mainDetails",
    "label": "Ongoing Scheduled / Unscheduled Maintenance",
    "field": true,
    "fields": [{
        "label": "Ongoing Scheduled / Unscheduled Maintenance",
        "class": "md:col-span-3",
        "grow": true,
        "key": "maint_details"
    }]
}

export const ongoingMods = {
    "key": "ongoingMods",
    "label": "Ongoing Mods",
    "field": true,
    "fields": [{
        "label": "Ongoing Mods",
        "class": "md:col-span-3",
        "grow": true,
        "key": "onging_mods"
    }]
}

export const ongoingRepairs = {
    "key": "ongoingRepairs",
    "label": "Ongoing Repairs",
    "field": true,
    "fields": [{
        "label": "Ongoing Repairs",
        "class": "md:col-span-3",
        "grow": true,
        "key": "ongoing_repairs"
    }]
}

export const opRestrictions = {
    "key": "opRestrictions",
    "label": "Operational Restrictions",
    "field": true,
    "fields": [{
        "label": "Operational Restrictions",
        "class": "md:col-span-3",
        "grow": true,
        "key": "op_restrictions"
    }]
}

export const mmelLimiting = {
    "key": "mmelLimiting",
    "label": "MMEL Limiting Dates",
    "field": true,
    "fields": [{
        "label": "MMEL Limiting Dates",
        "class": "md:col-span-3",
        "grow": true,
        "key": "mmel_limiting"
    }]
}

export const orgRelease = {
    "key": "orgRelease",
    "label": "Organisation Releasing the Aircraft",
    "field": true,
    "fields": [{
        "label": "Organisation Releasing the Aircraft",
        "class": "md:col-span-3",
        "grow": true,
        "key": "approval_org_pt_145"
    }]
}

export const declarations = {
    "key": "declarations",
    "label": "Declarations",
    "field": true,
    "fields": [{
        "label": "Signed Name",
        "key": "accepted_name"
    }, {
        "label": "Signed Position",
        "key": "accepted_position"
    }, {
        "label": "Signed Date",
        "key": "date_completed",
        "type": "date"
    }],
    "actions": [{"label": "Download Declaration","action": printDeclaration}]
}

export const notes = {
    "key": "notes",
    "label": "Notes",
    "local": true,
    "field": true,
    "fields": [{
        "label": "Notes",
        "key": "Notes",
        "grow": true,
        "class": "col-span-3"
    }],
    "actions": [{
        "label": "Edit Notes",
        action({ form, pageItem}){
            form.setValues({
                "Notes": pageItem.Notes
            });

            form.setConfig({ 
                "title": "Edit Notes",
                "type": "update",
                "location": `Database/FlightConditionsApprovalForms/${pageItem.ID}`,
                "fields": [{
                    "label": "Notes",
                    "name": "Notes",
                    "type": "richtext",
                    "divClass": "col-span-3"
                }]
            });
            form.toggle();
        }
    }]
};


