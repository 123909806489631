import { addCycle, completeCycle, updateCycle } from "./actions";

const actions = [{
    "label": "Add Cycle",
    "action": addCycle
}, {
    "label": "Complete Cycle",
    "action": completeCycle
}];

const headers = [{
    "headerName": "Cycle",
    "field": "Name"
}, {
    "headerName": "Completed",
    "field": "Completed",
    "type": "bool"
}, {
    "headerName": "Date Due",
    "field": "Date.Due",
    "type": "date"
}, {
    "headerName": "Date Completed",
    "field": "Date.Completed",
    "type": "date"
}];

const rowActions = [{
    "label": "Update Cycle",
    "action": updateCycle,

    show(x) {
        console.log(x);
        return [true]
    }
}];

export default {
    "label": "Cycles",
    "key": "Cycles",
    "local": true,
    actions,
    rowActions,
    headers
};
