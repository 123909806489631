import { get, groupBy } from "lodash";

import CrewMember from "./CrewMember";

export default class Pilot extends CrewMember {
  constructor(data) {
    super(data);
    this.$d = data;
  }

  get FlightHours() {

    let fltHours = {
      "Total": 0,
      "Test": 0,
      "CS-23": {
        "Total": 0,
        "Test": 0,
        "PIC": 0,
        "CO": 0,
        "PICTest": 0,
        "COTest": 0,
        "TotalLast": 0,
        "TestLast": 0
      },
      "CS-23P": {
        "Total": 0,
        "Test": 0,
        "PIC": 0,
        "CO": 0,
        "PICTest": 0,
        "COTest": 0,
        "TotalLast": 0,
        "TestLast": 0
      },
      "CS-25": {
        "Total": 0,
        "Test": 0,
        "PIC": 0,
        "CO": 0,
        "PICTest": 0,
        "COTest": 0,
        "TotalLast": 0,
        "TestLast": 0
      },
      "CS-27": {
        "Total": 0,
        "Test": 0,
        "PIC": 0,
        "CO": 0,
        "PICTest": 0,
        "COTest": 0,
        "TotalLast": 0,
        "TestLast": 0
      },
      "CS-29": {
        "Total": 0,
        "Test": 0,
        "PIC": 0,
        "CO": 0,
        "PICTest": 0,
        "COTest": 0,
        "TotalLast": 0,
        "TestLast": 0
      }
    };

    Object.values(this.$d.NewFlightHours).forEach(i => {

      if(i.CS === "CS-23*") i.CS = "CS-23P";

      fltHours.Total += parseInt(i.Hours);
      fltHours.Test += parseInt(i.Test);
      //all time
      fltHours[i.CS].Total += parseInt(i.Hours);
      fltHours[i.CS].Test += parseInt(i.Test);

      //last 12 months

      fltHours[i.CS].PIC += parseInt(i.FlightHoursPIC);
      fltHours[i.CS].CO += parseInt(i.FlightHoursCO);
      fltHours[i.CS].PICTest += parseInt(i.FlightHoursPICTest);
      fltHours[i.CS].COTest += parseInt(i.FlightHoursCOTest);
      fltHours[i.CS].TotalLast += parseInt(i.FlightHoursPIC) + parseInt(i.FlightHoursCO);
      fltHours[i.CS].TestLast += parseInt(i.FlightHoursPICTest) + parseInt(i.FlightHoursCOTest);
    });

    return fltHours;


    // if (!this.$d.FlightHours || !this.$d.FlightHours.Last) {
    //   return ;
    // }
    // const last = this.$d.FlightHours.Last;
    // let hist = {};
    // // const bestList = hist ? hist : last;
    // let csGrouped = merge({
    //   "CS-23": [],
    //   "CS-23*": [],
    //   "CS-25": [],
    //   "CS-27": [],
    //   "CS-29": []
    // }, groupBy(last, "CS"));
    // if (this.$d.FlightHours.Historical) {
    //   hist = merge({
    //     "CS-23": [],
    //     "CS-23*": [],
    //     "CS-25": [],
    //     "CS-27": [],
    //     "CS-29": []
    //   }, groupBy(this.$d.FlightHours.Historical, "CS"));
    // }
    // return {
    //   "Total": this.$d.FlightHours.Historical ? Object.values(this.$d.FlightHours.Historical).reduce((acc, i) => acc + parseInt(i.Hours), 0) : this.$d.FlightHours.Total ? this.$d.FlightHours.Total : 0,
    //   "Test": this.$d.FlightHours.Historical ? Object.values(this.$d.FlightHours.Historical).reduce((acc, i) => acc + parseInt(i.Test) || 0, 0) : this.$d.FlightHours.TotalTest ? this.$d.FlightHours.TotalTest : 0,
    //   // "Test": parseInt(this.$d.FlightHours.TotalTest),
    //   "CS23": getCSHours(csGrouped, "CS-23"),
    //   "CS23P": getCSHours(csGrouped, "CS-23*"),
    //   "CS25": getCSHours(csGrouped, "CS-25"),
    //   "CS27": getCSHours(csGrouped, "CS-27"),
    //   "CS29": getCSHours(csGrouped, "CS-29"),
    //   "Historical": {
    //     "CS23": getHistoricalCSHours(hist, "CS-23"),
    //     "CS23P": getHistoricalCSHours(hist, "CS-23*"),
    //     "CS25": getHistoricalCSHours(hist, "CS-25"),
    //     "CS27": getHistoricalCSHours(hist, "CS-27"),
    //     "CS29": getHistoricalCSHours(hist, "CS-29")
    //   }
    // };
  }

  get Competency() {
    let _this = this;
    function getCompLevel(type) {
        // comp level 1 test
        if(
          _this.FlightHours[type].Test >= 100 &&
          _this.FlightHours[type].TotalLast >= 50 &&
          _this.FlightHours[type].TestLast >= 20
        ) return 1;

        // comp level 2 test
        if(
          _this.FlightHours[type].Test >= 50 &&
          _this.FlightHours[type].TotalLast >= 50 &&
          _this.FlightHours[type].TestLast >= 20
        ) return 2;

        if(
          _this.FlightHours[type].MoreThan1000PiC === "true" &&
          _this.FlightHours[type].TotalLast >= 50
        ) return 4;
        
        return 0;
    }

    return {
      "CS-23": getCompLevel("CS-23"),
      "CS-23P": getCompLevel("CS-23P"),
      "CS-25": getCompLevel("CS-25"),
      "CS-27": getCompLevel("CS-27"),
      "CS-29": getCompLevel("CS-29")
    };
  }

  get TypeRatings() {
    let retObject = {};
    Object.entries(groupBy(this.$d.FlightHours.Last, "Type")).forEach(([key, value]) => {
      retObject[key] = value[0].Expiry || "Non Expiry";
    });
    return retObject;
  }

  get NewFlightHours() {
    return this.$d.NewFlightHours;
  }

  get EarliestRevalidation() {
    if (this.TestPilot) {
      return "Not Applicable";
    } else {
      const earliestAircraft = this.$d.FlightHours.Last.map(i => i.Expiry).sort((a, b) => (a < b) ? -1 : (a > b) ? 1 : 0).shift();
      return earliestAircraft < this.MedicalExpiry ? earliestAircraft : this.MedicalExpiry;
    }
  }

  get NextExpiry() {
    let medicalExp = new Date(this.MedicalExpiry);
    let approvedExp = new Date(this.$d.Date.Approved);

    // add one year to the expiry dates
    approvedExp.setFullYear(approvedExp.getFullYear() + 1);

    // return the earliest expiry date
    return medicalExp < approvedExp ? medicalExp : approvedExp;
  }

  get NextExpiryReason() {
    let medicalExp = new Date(this.MedicalExpiry);
    let approvedExp = new Date(this.$d.Date.Approved);

    // add one year to the expiry dates
    approvedExp.setFullYear(approvedExp.getFullYear() + 1);

    // return the earliest expiry date as a text type reason
    return medicalExp < approvedExp ? "Medical" : "Revalidation";
  }

  get Authority() {
    return this.$d.Authority;
  }

  get AeroplaneCPL() {
    return this.$d.Licences && ["LandAeroplaneCPL", "LandAeroplaneATPL", "SeaAeroplaneATPL", "SeaAeroplaneCPL"].some(i => {
      if (typeof this.$d.Licences === "string") return this.$d.Licences == i;

      return Object.values(this.$d.Licences || {}).join().includes(i);
    });
  }

  get HelicopterCPL() {
    return this.$d.Licences && ["HelicopterCPL", "HelicopterATPL"].some(i => {
      if (typeof this.$d.Licences === "string") return this.$d.Licences == i;
      return Object.values(this.$d.Licences || {}).join().includes(i);
    });
  }

  get Ratings() {
    if (!this.$d.Ratings) return "None";
    return Object.entries(this.$d.Ratings).map(([key, value]) => {
      return key === "AeroplaneCategory" ? `Aeroplane Category : ${value}` : key === "HelicopterCategory" ? `Helicopter Category : ${value}` : key;
    }).join(", ");
  }
  get Licences() {
    if (!this.$d.Licences) return "None";
    return Object.keys(this.$d.Licences).map(i => {
      return i.includes("Aeroplane") ? `${i.split("Aeroplane").join(" Aeroplane ")}` : `${i.split("Rotocraft").join(" Helicopter ")}`;
    }).join(", ");
  }

  get AeroplaneCategory() {
    return this.$d.Ratings ? this.$d.Ratings.AeroplaneCategory || "None" : "None";
  }

  get TestPilot() {
    return this.$d.Ratings && (this.$d.Ratings.AeroplaneCategory !== "None" || this.$d.Ratings.HelicopterCategory !== "None");
  }

  get HelicopterCategory() {
    return this.$d.Ratings ? this.$d.Ratings.HelicopterCategory || "None" : "None";
  }

  get Licence() {
    return this.$d.Credentials ? this.$d.Credentials.Number : "Not a pilot";
  }

  get MedicalClass() {
    return this.$d.Credentials ? this.$d.Credentials.Class : "Not a pilot";
  }

  get MedicalNumber() {
    return this.$d.Credentials ? this.$d.Credentials.Medical : "Not a pilot";
  }

  get MedicalExpiry() {
    return this.$d.Credentials ? this.$d.Credentials.Expiry : "Not a pilot";
  }

  get OverrideInfo() {
    return this.$d.Override?.OverrideInfo;
  }
};



// CAT ONE FUNCTIONS
// CAT ONE FUNCTIONS
// CAT ONE FUNCTIONS
// CAT ONE FUNCTIONS
// CAT ONE FUNCTIONS
function getCategoryOne(type) {
  return {
    "PIC": getCategoryOnePIC.call(this, type),
    "CO": getCategoryOneCO.call(this, type),
    "Advisory": getCategoryOneAdvisory.call(this, type),
    "Override": getOveride.call(this, type, "CatOne")
  };
};

function getCategoryOnePIC(type) {
  const correctCat = ["CS23", "CS23P", "CS25"].includes(type) ? this.AeroplaneCategory === "Category1" : ["CS27", "CS29"].includes(type) ? this.HelicopterCategory === "Category1" : false;
  return correctCat && (this.AeroplaneCPL || this.HelicopterCPL) && this.FlightHours[type].Test.PIC >= 20 && this.FlightHours.Total >= 100 && this.FlightHours.Test >= 50;
};

function getCategoryOneCO(type) {
  const correctCat = ["CS23", "CS23P", "CS25"].includes(type) ? this.AeroplaneCategory === "Category1" : ["CS27", "CS29"].includes(type) ? this.HelicopterCategory === "Category1" : false;
  return (this.AeroplaneCPL || this.HelicopterCPL) && correctCat && (this.FlightHours[type].Test.PIC >= 20 || this.FlightHours[type].Test.CO >= 20) && this.FlightHours.Total >= 100 && this.FlightHours.Test >= 50;
};

function getCategoryOneAdvisory(type) {
  const correctCat = ["CS23", "CS23P", "CS25"].includes(type) ? this.AeroplaneCategory === "Category1" : ["CS27", "CS29"].includes(type) ? this.HelicopterCategory === "Category1" : false;
  return (this.AeroplaneCPL || this.HelicopterCPL) && correctCat && this.FlightHours.Total >= 100 && this.FlightHours.Test >= 50;
};

// CAT TWO FUNCTIONS
// CAT TWO FUNCTIONS
// CAT TWO FUNCTIONS
// CAT TWO FUNCTIONS
// CAT TWO FUNCTIONS
function getCategoryTwo(type) {
  return {
    "PIC": getCategoryTwoPIC.call(this, type),
    "CO": getCategoryTwoCO.call(this, type),
    "Advisory": getCategoryTwoAdvisory.call(this, type),
    "Override": getOveride.call(this, type, "CatTwo")
  };
};

function getCategoryTwoPIC(type) {
  if (testBetters.call(this, [getCategoryOnePIC, getCategoryOneCO], type)) return true;
  return this.AeroplaneCategory !== "None" && (this.AeroplaneCPL || this.HelicopterCPL) && this.FlightHours[type].Test.PIC >= 20 && this.FlightHours.Total >= 50 && this.FlightHours.Test >= 50;
};

function getCategoryTwoCO(type) {
  if (testBetters.call(this, [getCategoryOnePIC, getCategoryOneCO, getCategoryTwoPIC], type)) return true;
  return (this.AeroplaneCPL || this.HelicopterCPL) && this.AeroplaneCategory !== "None" && (this.FlightHours[type].Test.PIC >= 20 || this.FlightHours[type].Test.CO >= 20) && this.FlightHours.Total >= 50 && this.FlightHours.Test >= 50;
};

function getCategoryTwoAdvisory(type) {
  const correctCat = ["CS23", "CS23P", "CS25"].includes(type) ? this.AeroplaneCategory === "Category2" : ["CS27", "CS29"].includes(type) ? this.HelicopterCategory === "Category1" : false;
  return (this.AeroplaneCPL || this.HelicopterCPL) && correctCat && (this.FlightHours[type].Test.PIC >= 20 || this.FlightHours[type].Test.CO >= 20) && this.FlightHours.Total >= 50 && this.FlightHours.Test >= 50;
};

// CAT FOUR FUNCTIONS
// CAT FOUR FUNCTIONS
// CAT FOUR FUNCTIONS
// CAT FOUR FUNCTIONS
// CAT FOUR FUNCTIONS
function getCategoryFour(type) {
  let x = {
    "PIC": getCategoryFourPIC.call(this, type),
    "CO": getCategoryFourCO.call(this, type),
    "Override": getOveride.call(this, type, "CatFour")
  };
  return x;
};

function getCategoryFourPIC(type) {
  if (testBetters.call(this, [getCategoryOnePIC, getCategoryOneCO, getCategoryTwoPIC], type)) return true;
  return this.FlightHours.Historical[type] >= 1000 && (this.AeroplaneCPL || this.HelicopterCPL) && this.FlightHours.Total >= 50;
};

function getCategoryFourCO(type) {
  if (testBetters.call(this, [getCategoryOnePIC, getCategoryOneCO, getCategoryTwoPIC, getCategoryFourPIC], type)) return true;
  return (this.AeroplaneCPL || this.HelicopterCPL) && this.FlightHours[type].Total >= 50 && this.FlightHours.Total >= 1000;
};

function testBetters(array, type) {
  return array.some(i => i.call(this, type));
}

// OVERRIDE
function getOveride(type, cat) {
  return this.$d?.Override && this.$d?.Override[`${cat}${type}`] === "No Override" ? false : get(this.$d?.Override, `${cat}${type}`, false)
}

function getCompetencyLevel(type) {

}
