export const addMFARQuestion = ({ form }) => {
    form.setConfig({
        "title": "Add MFAR Question",
        "location": `Database/MFARQuestions`,
        "type": "push",
        "fields": [{
            "label": "Question",
            "name": "Question",
            "type": "text",
            "divClass": "md:col-span-3"
        }]
    });
    form.toggle();
};

export const updateMFARQuestion = ({ form, rowItem }) => {
    form.setValues({
        "Question": rowItem.Question
    });
    form.setConfig({
        "title": "Update MFAR Question",
        "type": "update",
        "location": `Database/MFARQuestions/${rowItem.ID}`,
        "fields": [{
            "label": "Question",
            "name": "Question",
            "type": "text",
            "divClass": "md:col-span-3"
        }]
    });
    form.toggle();
};
