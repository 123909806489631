import { makeAttached } from "../../../utils";

export const addProcedure = ({ form }) => {
    form.setConfig({
        "title": "Add Procedure",
        "location": "Audits/Procedures",
        "type": "push",
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text"
        }]
    });
    form.toggle();
};

export const editProcedure = ({ form, pageItem }) => {
    form.setValues({
        "Title": pageItem.Title
    });

    form.setConfig({
        "title": "Update Procedure",
        "location": `Audits/Procedures/${pageItem.ID}`,
        "type": "update",
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text"
        }]
    });
    form.toggle();
};

export const cancelProcedure = ({ form, pageItem }) => {

    form.setConfig({
        "title": "Cancel Procedure",
        "description": "Are you sure you want to delete this procedure?",
        "location": `Audits/Procedures/${pageItem.ID}`,
        "type": "update",
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": true,
                "Date/Cancelled": (new Date()).toISOString()
            };
        }
    });
    form.toggle();
};

const cycleData = [
    "2016/2017",
    "2018/2019",
    "2020/2021",
    "2022/2023",
    "2024/2025",
    "2026/2027",
    "2028/2029",
    "2030/2031",
].map(cycle => {
    return {
        "id": cycle,
        "label": cycle
    }
})

export const addCycle = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add Cycle",
        "location": `Audits/Procedures/${pageItem.ID}/Cycles`,
        "type": "push",
        "fields": [{
            "label": "Cycle",
            "name": "Name",
            "type": "dropdown",
            "dropdownData": cycleData
        }, {
            "label": "Due Date",
            "name": "Date[Due]",
            "type": "date"
        }],
        preSubmitHook() {
            return {
                "Completed": false,
                "Overdue": false
            };
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const updateCycle = ({ form, rowItem, pageItem, db }) => {
    form.setValues({
        "Date[Due]": rowItem.Date.Due,
        "Completed": rowItem.Completed,
        "Overdue": rowItem.Overdue,
        "Date[Completed]": rowItem.Date.Completed
    });
    form.setConfig({
        "title": "Update Cycle",
        "location": `Audits/Procedures/${pageItem.ID}/Cycles/${rowItem.ID}`,

        "type": "update",
        "fields": [{
            "label": "Date Due",
            "name": "Date[Due]",
            "type": "date"
        }, {    
            "label": "Completed",
            "name": "Completed",
            "type": "checkbox",
            "required": false
        }, {
            "label": "Overdue",
            "name": "Overdue",
            "type": "checkbox",
            "required": false
        }, {
            "label": "Date Completed",
            "name": "Date[Completed]",
            "type": "date",
            show({ liveInputData}) {
                return liveInputData.Completed;
            }
        }]
    });
    form.toggle();
};


export const completeCycle = ({ form, pageItem, db }) => {
    let nextDueDate = new Date(pageItem.Date.Due);
    nextDueDate.setFullYear(nextDueDate.getFullYear() + 2);

    let firstYear = parseInt(pageItem.CurrentCycle.split("/")[0]);
    let secondYear = parseInt(pageItem.CurrentCycle.split("/")[1]);
    
    form.setValues({
        "Date[Due]": nextDueDate.toISOString().split("T")[0],
        "Name": `${firstYear + 2}/${secondYear + 2}`
    });



    form.setConfig({
        "title": "Complete Cycle",
        "type": "push",
        "location": `Audits/Procedures/${pageItem.ID}/Cycles`,
        "fields": [{
            "type": "text",
            "label": "Next Cycle",
            "name": "Name"
        }, {
            "type": "date",
            "label": "Next Due Date",
            "name": "Date[Due]"
        }],
        preSubmitHook(){
            return {
                "Overdue": false,
                "Completed": false
            };
        },
        postSubmitHook({ data, typeRef }){
            
            db.set(`Audits/Procedures/${pageItem.ID}/Cycles/${pageItem.CurrentCycleID}/Date/Completed`, (new Date()).toISOString());
            db.set(`Audits/Procedures/${pageItem.ID}/Cycles/${pageItem.CurrentCycleID}/Completed`, true);

            db.update(`Audits/Procedures/${pageItem.ID}`, {
                "CurrentCycleID": typeRef.key,
                "CurrentCycle": data.Name,
                "Date": {
                    "Due": data.Date.Due,
                    "Completed": (new Date()).toISOString()
                }
            });

        }
    });
    form.toggle();
};

export const linkProcedure = ({ form, pageItem, db, meta }) => {
    form.setValues({
        "Procedures": Object.keys(pageItem.Procedures || {})
    });
    form.setConfig({
        "title": "Link Procedure",
        "location": `Audits/${meta.ParentType}/${pageItem.ID}`,
        "type": "update",
        "set": true,
        "fields": [{
            "label": "Procedures",
            "name": "Procedures",
            "type": "dropdown",
            "multiple": true,
            "labelKey": "Title",
            "idKey": "ID",
            "dataLocation": "Audits/Procedures",
        }],
        preSubmitHook({ data }) {
            console.log(data, makeAttached(data.Procedures));
            return {
                "Procedures": makeAttached(data.Procedures)
            };
        },
        async postSubmitHook({ data }) {
            const procedureKeys = Object.keys(data.Procedures);
            for(let key of procedureKeys) {
                await db.set(`Audits/Procedures/${key}/CoreProcedures/${pageItem.ID}`, true);
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};
