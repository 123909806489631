import ItemListView from "../../../components/ItemListView/ItemListView.vue";
import ItemView from "../../../components/ItemView/ItemView.vue";
import { attachables as _attachables } from "./attachables";
import { completeBoxRequest } from "./actions";

const splitDocKeyString = ({ pageItem, itemLocations }) => {
   return itemLocations.map(itemLocation => {
     return (itemLocation.split("__REV__"))[0];
   });
};


const attachables = _attachables.map(attachable => {
    return {
        ...attachable,
        computedRows: splitDocKeyString
    };
});

export default [{
    "path": "/Database/BoxRequests",
    "component": { ...ItemListView },
    "name": "Box Requests",
    "meta": {
        "app": "Database",
        "name": "Box Requests",
        "sideNavSection": "Misc",
        "sideNav": true,
        "icon": "database",
        "tableSettings": "BoxRequests",
        "dataPath": "Database/BoxRequests",
        "selectionRoute": "Database/BoxRequests/{ID}",
        "menuActions": [],
        filter(item) {
            return item.Cancelled !== true && item.Completed !== true;
        }
    }
}, {
    "path": "/Database/BoxRequests/:id",
    "component": ItemView,
    "name": "Box Request",
    "meta": {
        "app": "Database",
        "name": "Box Request",
        "dataPath": "Database/BoxRequests",
        "selectionRoute": "Database/BoxRequests/{ID}",
        title(item) {
            return "Box Request"
        },
        "attachedItems": {
            "Installation": {
                "attachables": attachables
            }
        },
        "menuActions": [{
            "label": "Complete Box Request",
            "action": completeBoxRequest
        }],
        "dataFields": [
            {
                "label": "Design Change",
                "key": "DesignChange"
            }, {
                "label": "Customer",
                "key": "Customer"
            }, {
                "label": "Customer Contact Name",
                "key": "CustomerContactName"
            }, {
                "label": "Customer Contact Email",
                "key": "CustomerContactEmail"
            }, {
                "label": "Location of Documents",
                "key": "LocationOfDocuments",
                "class": "md:col-span-2"
            }
        ]
    }
}];
