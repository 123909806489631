

export default {
    "label": "Regulations CS25",
    "key": "Regulations",
    "location": "Database/Regulations/CS25",
    "headers": [{
        "headerName": "Subpart",
        "field": "subpart"
    }, {
        "headerName": "title",
        "field": "title"
    }],
    computedItems (items) {
        
            let its = Object.keys(items).map(subpart => {
                
                let vals = Object.values(items[subpart]).map(r => {
                    
                    r.subpart = subpart
                    return r
                });
                return vals
            }).flat();
            
            return its
    },
    "actions": [],
    "rowActions": [{
        "label": "Edit",
        "action": ( { item, router }) => {
            router.push(`/Database/Settings/Regulations?subpart=${item.subpart}&id=${item.ID}`);
        }
    }],
}
