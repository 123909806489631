import {
    addReportType,
    editReportType
} from "./actions";

export default {
    "label": "Report Types",
    "key": "ReportTypes",
    "location": "Audits/Settings/ReportTypes/",
    "actions": [{
        "label": "AddReport Type",
        "action": addReportType
    }],
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "rowActions": [{
        "label": "Edit Report Type",
        action: editReportType
    }]
};
