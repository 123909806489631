import {
    editEventReport,
    editSafetyComitteeReport,
    editSafetyManagerReport
} from './actions';

const eventReport = {
    "label": "User Report",
    "key": "EventReport",
    "local": true,
    "field": true,
    "actions": [{
        "label": "Edit Event Report",
        "action": editEventReport
    }],
    "fields": [{
        "label": "Likelihood (User)",
        "key": "Likelihood"
    }, {
        "label": "Consequence (User)",
        "key": "Consequence"
    }, {
        "label": "User Report",
        "key": "UserReport",
        "type": "text",
        "grow": true,
        "required": true,
        "class": "md:col-span-3"
    }]
};

const safetyManagerReport = {
    "label": "Safety Manager Report",
    "key": "SafetyManagerReport",
    "local": true,
    "field": true,
    "actions": [{
        "label": "Edit Safety Manager Report",
        "action": editSafetyManagerReport
    }],
    "fields": [
        {
            "label": "Likelihood (Safety Manager)",
            "key": "SafetyManagerLikelihood"
        }, {
            "label": "Consequence (Safety Manager)",
            "key": "SafetyManagerConsequence"
        },
        {
            "label": "Safety Manager Report",
            "key": "SafetyManagerReport",
            "type": "text",
            "grow": true,
            "required": true,
            "class": "md:col-span-3"
        }]
};

const safetyComitteeReport = {
    "label": "Safety Comittee Agreement",
    "key": "SafetyComitteeReport",
    "local": true,
    "field": true,
    "actions": [{
        "label": "Safety Comittee Agreement",
        "action": editSafetyComitteeReport
    }],
    "fields": [{
        "label": "Safety Comittee Agreement Date",
        "key": "Date.ComitteeReviewed",
        "type": "date"
    },
    {
        "label": "Safety Comittee Notes",
        "key": "SafetyComitteeReport",
        "type": "text",
        "grow": true,
        "required": true,
        "class": "md:col-span-3"
    }],
    lock({ pageItem }) {
        if (!pageItem.Date.Reviewed) return [true, "Safety Manager Report is required before Safety Comittee can review this event."];
        return [pageItem.ConsequnceValue + pageItem.LikelihoodValue < 5, "Not required to review this event."];
    }
};

export default [eventReport, safetyManagerReport, safetyComitteeReport];
