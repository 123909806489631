import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

export default [{
    "path": "/Home/ChangeRequests",
    "component": { ...ItemListView },
    "name": "Change Requests",
    "meta": {
        "app": "Home",
        "name": "Change Requests",
        "sideNav": true,
        "sideNavSection": "Forms, Handbooks & Work Instructions",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "ChangeRequests",
        "dataPath": "Database/ChangeRequests",
        "selectionRoute": "Home/ChangeRequests/{ID}",
        "menuActions": []
    }
}, {
    "path": "/Home/ChangeRequests/:id",
    "component": ItemView,
    "name": "Change Request",
    "meta": {
        "app": "Home",
        "name": "Change Request",
        "dataPath": "Database/ChangeRequests",
        "dataPathOverride": "Database/ChangeRequests/:id",
        "selectionRoute": "Database/ChangeRequests/{ID}",
        "storeData": ["Database/MFARQuestions"],
        title(item) {
            return `ChangeRequests: ${item.Name}`;
        },
        "attachedItems": {

        },
        "dataFields": [{
            "label": "Work Instruction",
            "key": "WorkInstruction",
            "link": "Home/WorkInstructions/:WorkInstructionID"
        }, {
            "label": "Handbook",
            "key": "Handbook",
            "link": "Home/Handbooks/:HandbookID"
        }, {
            "label": "Date Created",
            "key": "Date",
            "type": "date"
        }, {
            "label": "Status",
            "key": "Status"
        }, {
            "label": "Change Request",
            "key": "Note",
            "grow": true,
            "class": "col-span-3"
        }],
        "menuActions": []
    }
}];
