export const addAircraftCode = ({ form, store }) => {
    form.setConfig({
        "title": "Add Aircraft Code",
        "type": "push",
        "location": "Database/AircraftCodes",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return store?.state?.counts?.database?.ac?.count
            }
        }, {
            "label": "Name",
            "name": "Name",
            "type": "text"
        }, {
            "label": "Acronym",
            "name": "NameShort",
            "type": "text"
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "dropdownData": [{
                "label": "Aeroplane",
                "id": "Aeroplane"
            }, {
                "label": "Rotocraft",
                "id": "Rotocraft"
            }]
        }],
        preSubmitHook({ liveInputData }) {
            return {
                "Number": store?.state?.counts?.database?.ac?.count,
                "NameFull": `${store?.state?.counts?.database?.ac?.count} - ${liveInputData.Name}`
            }
        },
        postSubmitHook({ db }){
            db.set(`counts/database/ac/count`, store?.state?.counts?.database?.ac?.count + 1)
        }
    });
    form.getDropdownData();
    form.toggle();
}
