import { appConfig, makeAttached, pad } from "../../../utils";

export const addReport = setValues => (cd) => {

    let parentType = cd.meta.ParentType;
    const {
        form, store
    } = cd;

    if(setValues) form.setValues(setValues(cd));

    form.setConfig({
        "title": "Add Audit Report",
        "type": "push",
        "location": "Audits/Reports",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}QAR-${pad(store.state.counts.auditor.reports.count)}`
            }
        }, {
            "label": "Parent",
            "name": "ParentID",
            "type": "dropdown",
            "labelKey": "Title",
            "idKey": "ID",
            "dataLocation": `Audits/${parentType}`
        }, {
            "label": "Cycle",
            "name": "Cycle",
            "type": "dropdown",
            "dropdownData": [{
                "label": "2024/2025",
                "id": "2024/2025"
            }, {
                "label": "2026/2027",
                "id": "2026/2027"
            }]
        }, {
            "label": "Type",
            "name": "Title",
            "type": "dropdown",
            "idKey": "Name",
            "labelKey": "Name",
            "dataLocation": "Audits/Settings/ReportTypes",
        }, {
            "label": "HOD",
            "name": "HOD",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }
            , {
            "label": "HISM",
            "name": "DAM",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }
        ],
        preSubmitHook({ data,selectedDropdowns }) {
            
            let procedures = [];
            if(parentType === "Procedures") {
                procedures.push(selectedDropdowns.ParentID.id);
            } else if (parentType === "CoreProcedures") {
                procedures = Object.keys(selectedDropdowns.ParentID.meta.Procedures);
            }

            return {
                "Number": `${appConfig.docPrefix}QAR-${pad(store.state.counts.auditor.reports.count)}`,
                "Date": {
                    "Created": (new Date()).toISOString(),
                    "Completed": "N/A"
                },
                "Completed": false,
                "Parent": selectedDropdowns.ParentID.meta.Title,
                "ParentType": parentType,
                "Procedures": makeAttached(procedures),
                "Comment": "Start Report Here",
                [parentType]: {
                    [data.ParentID]: true
                }
            };
        },
        async postSubmitHook({ db, typeRef, data }) {
            await db.update(`counts/auditor/reports`, { count: store.state.counts.auditor.reports.count + 1 });
            let procedureIDs = Object.keys(data.Procedures);
            await db.set(`Audits/${parentType}/${data.ParentID}/Reports/${typeRef.key}`, true);
            for (let procedureID of procedureIDs) {
                await db.set(`Audits/Procedures/${procedureID}/Reports/${typeRef.key}`, true);
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const linkReport = ({ db, form, pageItem, meta }) => {

    form.setConfig({
        "title": "Link Procedure",
        "type": "update",
        "location": `Audits/${meta.ParentType}/${pageItem.ID}/Reports`,
        "fields": [{
            "label": "Report",
            "name": "Report",
            "type": "dropdown",
            "labelKey": "Number",
            "idKey": "ID",
            "dataLocation": "Audits/Reports"
        }],
        preSubmitHook({ selectedDropdowns }) {
            return {
                [selectedDropdowns.Report.id]: true
            };
        },
        async postSubmitHook({ selectedDropdowns }) {
            const procedures = Object.keys(pageItem.Procedures || {});
            for(const procedure of procedures) {
                await db.set(`Audits/Reports/${selectedDropdowns.Report.id}/Procedures/${procedure}`, true);
            }

            const coreProcedures = Object.keys(pageItem.CoreProcedures || {});

            for(const coreProcedure of coreProcedures) {
                await db.set(`Audits/Reports/${selectedDropdowns.Report.id}/CoreProcedures/${coreProcedure}`, true);
            }

            await db.set(`Audits/Reports/${selectedDropdowns.Report.id}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editReport = ({ form, pageItem }) => {
    form.setValues(pageItem);
    form.setConfig({
        "title": "Add Audit Report",
        "type": "update",
        "location": `Audits/Reports/${pageItem.ID}`,
        "fields": [{
            "label": "Cycle",
            "name": "Cycle",
            "type": "dropdown",
            dropdownData: [{
                label: "2022/2023",
                id: "2022/2023"
            }, {
                label: "2024/2025",
                id: "2024/2025"
            }, {
                label: "2026/2027",
                id: "2026/2027"
            }]
        }, {
            "label": "Title",
            "name": "Title",
            "type": "dropdown",
            "dropdownData": ["Scheduled Audit", "AD-HOC", "Audit Review"].map(i => ({ label: i, id: i }))
        }, {
            "label": "HOD",
            "name": "HOD",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }, {
            "label": "HISM",
            "name": "DAM",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }, {
            "label": "Report",
            "name": "Comment",
            "type": "richtext",
            "divClass": "md:col-span-3"
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const completeReport = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Complete Report",
        "type": "update",
        "location": `Audits/Reports/${pageItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Completed": true,
                "Date/Completed": (new Date()).toISOString()
            }
        },
        async postSubmitHook({ db, typeRef }) {
            const procedures = Object.keys(pageItem.Procedures);
            const parent = await db.once(`Audits/${pageItem.ParentType}/${pageItem.ParentID}`);
            await db.set(`Audits/${pageItem.ParentType}/${pageItem.ParentID}/Cycles/${parent.CurrentCycleID}/Completed`, true);
            await db.set(`Audits/${pageItem.ParentType}/${pageItem.ParentID}/Cycles/${parent.CurrentCycleID}/Date/Completed`, (new Date()).toISOString());
            await db.set(`Audits/${pageItem.ParentType}/${pageItem.ParentID}/Date/Completed`, (new Date()).toISOString());
            for(const procedure of procedures) {
                const p = await db.once(`Audits/Procedures/${procedure}`);
                await db.set(`Audits/Procedures/${procedure}/Cycles/${p.CurrentCycleID}/Completed`, true);
                await db.set(`Audits/Procedures/${procedure}/Cycles/${p.CurrentCycleID}/Date/Completed`, (new Date()).toISOString());
                await db.set(`Audits/Procedures/${procedure}/Date/Completed`, (new Date()).toISOString());
            }
        }
    });
    form.toggle();
};

export const addFile = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add File",
        "submitButtonLabel": "Add",
        "type": "push",
        "location": `Audits/Reports/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file"
        }],
        preSubmitHook({ files }) {
            return {
                "Date": (new Date()).toISOString(),
                "File": files[0][0].name
            };
        },
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.upload(`Audits/Reports/${pageItem.ID}/Files/${typeRef.key}`, file[0]);
            }
        }
    });
    form.toggle();
}

export const removeFile = ({ form, rowItem, pageItem }) => {
    form.setConfig({
        "title": "Remove File",
        "submitButtonLabel": "Remove",
        "submitButtonClass": "cancel",
        "type": "delete",
        "location": `Audits/Reports/${pageItem.ID}/Files/${rowItem.ID}`,
        "fields": [],
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.remove(`Audits/Reports/${pageItem.ID}/Files/${rowItem.ID}`, rowItem.File);
            }
        }
    });
    form.toggle();
}

export const removeProcedureFromReport = ({ form, pageItem, rowItem }) => {
    form.setConfig({
        "title": "Remove Procedure",
        "type": "delete",
        "location": `Audits/Reports/${pageItem.ID}/Procedures/${rowItem.ID}`,
        "fields": [],
        async postSubmitHook({ db }) {
            await db.delete(`Audits/Procedures/${rowItem.ID}/Reports/${pageItem.ID}`);
        }
    });
    form.toggle();
};
