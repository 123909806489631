import {
    addRepair,
    editRepair,
    importRepairJobCards,
    linkRepair
} from "./actions";

export default {
    "label": "Repairs",
    "key": "Repairs",
    "location": "Database/Repairs/",
    "actions": [{
        "label": "Add Repair",
        "action": addRepair
    }, {
        "label": "Link Repair",
        "action": linkRepair
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Title",
        "field": "Title"
    }, {
        "headerName": "Responsible Engineer",
        "field": "ResponsibleEngineer"
    }, {
        "headerName": "Mod Type",
        "field": "Type"
    }],
    "rowActions": [{
        "label": "Open Repair",
        action({ router, rowItem }) {
            router.push(`/Database/Repairs/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Repair",
        "action": editRepair
    }, {
        "label": "Import Job Cards",
        "action": importRepairJobCards
    }]
};
