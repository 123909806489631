<template>
  <div class="w-12">
    <p :class="displayClasses" v-html="displayValue" style="height: 30px; width: 30px; ">
    </p>
  </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "BooleanValue",
  computed: {
    displayValue() {
      switch (this.params.value) {
        case "Compiler":
          return "Co";
        case "Checker":
          return "Ck";
        case "Approver":
          return "Ap";
        case "CVE":
          return "CVE";
        case "Compliance Verification Engineer":
          return "CVE";
        default:
          return "&#10006"
      }
    },
    displayClasses() {

      let cssClass = "shadow text-center px-2  rounded-full  ";

      switch (this.params.value) {
        case "Compiler":
          return `${cssClass} bg-gradient-to-br from-gray-50 to-gray-200 text-text`;
        case "Checker":
          return `${cssClass} bg-gradient-to-br from-blue-200 to-blue-400  text-white`;
        case "Approver":
          return `${cssClass} bg-gradient-to-br from-orange-300 to-orange-400 text-white`;
        case "CVE":
          return `${cssClass} bg-gradient-to-br from-green-300 to-green-400 text-xxs font-bold text-white`;
        case "Compliance Verification Engineer":
          return `${cssClass} bg-gradient-to-br from-green-300 to-green-400 text-xxs font-bold text-white`;
        default:
          return `${cssClass} bg-red-100 text-red`;
      }
    },
  },
});
</script>
