import {
    linkProcedure
} from "./actions";

const actions = [{
    "label": "Link Procedure",
    "action": linkProcedure
}];

const headers = [{
    "headerName": "Title",
    "field": "Title"
}, {
    "headerName": "Last Completed Date",
    "field": "Date.Completed",
    "type": "date"
}];

const rowActions = [{
    "label": "View Procedure",
    action({ router, rowItem }) {
        router.push(`/Audits/Procedures/${rowItem.ID}`)
    }
}];

export default {
    "label": "Procedures",
    "key": "Procedures",
    "location": "Audits/Procedures/",
    actions,
    rowActions,
    headers
};
