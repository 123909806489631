export default {
    "title": "Airline Details",
    "submitButtonText": "Save & Next",
    "fields": [
        { "type": "text", "label": "Airline Name", "name": "airline", "required": true },
        { "type": "text", "label": "Address Line 1", "name": "address_l1", "required": true },
        { "type": "text", "label": "Address Line 2", "name": "address_l2", "required": false },
        { "type": "text", "label": "City", "name": "address_city", "required": true },
        { "type": "text", "label": "State/Province/Region", "name": "address_state", "required": true },
        { "type": "text", "label": "ZIP/Post Code", "name": "address_code", "required": true },
        { "type": "text", "label": "Country", "name": "address_country", "required": true },
        { "type": "text", "label": "Liaison Contact Email", "name": "email", "required": true },
        { "type": "text", "label": "Liaison Contact Phone (inc. international dialing code)", "name": "phone", "required": true }
    ]
};
