export const addNonConformityLevel = ({ form, store }) => {
    form.setConfig({
        "title": "Add Non Conformity Level",
        "type": "push",
        "location": "/Audits/Settings/NonConformityLevels",
        "fields": [{
            "label": "Name",
            "type": "text",
            "name": "Name"
        }, {
            "label": "Days",
            "name": "Days",
            "type": "number",
            "min": 0,
            "step": 1
        }]
    });
    
    form.toggle();
};

export const editNonConformityLevel = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Edit Non Conformity Level",
        "type": "update",
        "location": `/Audits/Settings/NonConformityLevels/${item.ID}`,
        "fields": [{
            "label": "Name",
            "type": "text",
            "name": "Name"
        }, {
            "label": "Days",
            "name": "Days",
            "type": "number",
            "min": 0,
            "step": 1
        }]
    });
    form.toggle();
};
