import { makeAttached, pad } from "../../../utils";

export const addCoreProcedure = ({ form, store }) => {
    form.setConfig({
        "title": "Add CoreProcedure",
        "location": "Audits/CoreProcedures",
        "type": "push",
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text"
        }],
        preSubmitHook() {
            return {
                "Number": pad(Object.keys(store.state["Audits/CoreProcedures"] || {}).length + 1, 2),
                "Date": {
                    "Created": (new Date()).toISOString()
                }
            };
        }
    });
    form.toggle();
};

export const editCoreProcedure = ({ form, pageItem }) => {
    form.setValues({
        "Title": pageItem.Title
    });

    form.setConfig({
        "title": "Update CoreProcedure",
        "location": `Audits/CoreProcedures/${pageItem.ID}`,
        "type": "update",
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text"
        }]
    });
    form.toggle();
};

export const cancelCoreProcedure = ({ form, pageItem }) => {

    form.setConfig({
        "title": "Cancel CoreProcedure",
        "description": "Are you sure you want to delete this Coreprocedure?",
        "location": `Audits/CoreProcedures/${pageItem.ID}`,
        "type": "delete",
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": true,
                "Date/Cancelled": (new Date()).toISOString()
            };
        }
    });
    form.toggle();
};

const cycleData = [
    "2016/2017",
    "2018/2019",
    "2020/2021",
    "2022/2023",
    "2024/2025",
    "2026/2027",
    "2028/2029",
    "2030/2031",
].map(cycle => {
    return {
        "id": cycle,
        "label": cycle
    }
})

export const addCycle = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add Cycle",
        "location": `Audits/CoreProcedures/${pageItem.ID}/Cycles`,
        "type": "push",
        "fields": [{
            "label": "Cycle",
            "name": "Name",
            "type": "dropdown",
            "dropdownData": cycleData
        }, {
            "label": "Due Date",
            "name": "Date[Due]",
            "type": "date"
        }],
        preSubmitHook() {
            return {
                "Completed": false,
                "Overdue": false
            };
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const setCycle = ({ form, pageItem }) => {
    const cycles = Object.values(pageItem.Cycles || {}).map(cycle => {
        return {
            "id": cycle.ID,
            "label": cycle.Name
        }
    });
    form.setConfig({
        "title": "Set Current Cycle",
        "location": `Audits/CoreProcedures/${pageItem.ID}`,
        "type": "update",
        "fields": [{
            "label": "Current Cycle",
            "name": "CurrentCycleID",
            "type": "dropdown",
            "dropdownData": cycles
        }],
    });
    form.getDropdownData();
    form.toggle();
};


export const updateCycle = ({ form, rowItem, pageItem, db }) => {
    form.setValues({
        "Date[Due]": rowItem.Date.Due,
        "Completed": rowItem.Completed,
        "Overdue": rowItem.Overdue,
        "Date[Completed]": rowItem.Date.Completed
    });
    form.setConfig({
        "title": "Update Cycle",
        "location": `Audits/CoreProcedures/${pageItem.ID}/Cycles/${rowItem.ID}`,

        "type": "update",
        "fields": [{
            "label": "Date Due",
            "name": "Date[Due]",
            "type": "date"
        }, {    
            "label": "Completed",
            "name": "Completed",
            "type": "checkbox",
            "required": false
        }, {
            "label": "Overdue",
            "name": "Overdue",
            "type": "checkbox",
            "required": false
        }, {
            "label": "Date Completed",
            "name": "Date[Completed]",
            "type": "date",
            show({ liveInputData}) {
                return liveInputData.Completed;
            }
        }]
    });
    form.toggle();
};


export const completeCycle = ({ form, pageItem, db }) => {
    
    let currentCycle = pageItem.Cycles[pageItem.CurrentCycleID];
    let nextDueDate = new Date(currentCycle.Date.Due);
    nextDueDate.setFullYear(nextDueDate.getFullYear() + 2);

    let CurrentCycle = pageItem.Cycles[pageItem.CurrentCycleID];

    let firstYear = parseInt(CurrentCycle.Name.split("/")[0]);
    let secondYear = parseInt(CurrentCycle.Name.split("/")[1]);
    
    form.setValues({
        "Date[Due]": nextDueDate.toISOString(),
        "Name": `${firstYear + 2}/${secondYear + 2}`
    });



    form.setConfig({
        "title": "Complete Cycle",
        "type": "push",
        "location": `Audits/CoreProcedures/${pageItem.ID}/Cycles`,
        "fields": [{
            "type": "text",
            "label": "Next Cycle",
            "name": "Name"
        }, {
            "type": "date",
            "label": "Next Due Date",
            "name": "Date[Due]"
        }],
        preSubmitHook(){
            return {
                "Overdue": false,
                "Completed": false
            };
        },
        postSubmitHook({ data, typeRef }){
            
            db.set(`Audits/CoreProcedures/${pageItem.ID}/Cycles/${pageItem.CurrentCycleID}/Date/Completed`, (new Date()).toISOString());
            db.set(`Audits/CoreProcedures/${pageItem.ID}/Cycles/${pageItem.CurrentCycleID}/Completed`, true);

            db.update(`Audits/CoreProcedures/${pageItem.ID}`, {
                "CurrentCycleID": typeRef.key,
                "CurrentCycle": data.Name,
                "Date": {
                    "Due": data.Date.Due,
                    "Completed": (new Date()).toISOString()
                }
            });

        }
    });
    form.toggle();
};

export const linkCoreProcedure = ({ form, pageItem }) => {
    form.setValues({
        "CoreProcedures": Object.keys(pageItem.CoreProcedures || {})
    });
    
    form.setConfig({
        "title": "Link Procedure",
        "location": `Audits/Procedures/${pageItem.ID}`,
        "type": "update",
        "set": true,
        "fields": [{
            "label": "Core Procedures",
            "name": "CoreProcedures",
            "type": "dropdown",
            "multiple": true,
            "labelKey": "Title",
            "idKey": "ID",
            "dataLocation": "Audits/CoreProcedures",
        }],
        preSubmitHook({ data }) {
            return {
                "CoreProcedures": makeAttached(data.CoreProcedures)
            };
        },
        async postSubmitHook({ data, db }) {
            const procedureKeys = Object.keys(data.CoreProcedures);
            for (let key of procedureKeys) {
                await db.set(`Audits/CoreProcedures/${key}/Procedures/${pageItem.ID}`, true);
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};
