import {
    addAircraftCode
} from "./actions";

export default {
    "label": "Aircraft Codes",
    "key": "AircraftCodes",
    "location": "Database/AircraftCodes/",
    "actions": [{
        "label": "Add Aircraft Code",
        "action": addAircraftCode
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Name",
        "field": "Name"
    }, {
        "headerName": "Acronym",
        "field": "NameShort"
    }],
    "rowActions": [{
        "label": "Edit Aircraft Code",
        action({ router, rowItem }) {
            router.push(`/Database/AircraftCodes/${rowItem.ID}`);
        }
    }]
};
