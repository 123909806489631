import {
    makeAttached,
    makeDropdown
} from "../../../utils.js";

const dropdownToIncludedRevisions = (dropdownData) => {
    return dropdownData.map(item => {
        let revisions = Object.values(item.meta.Revisions);
        return revisions.map(revision => {
            return {
                "label": `${item.label} (Revision: ${revision.Revision})`,
                "id": `${item.id}__REV__${revision.ID}`,
                "meta": item.meta
            };
        });
    }).flat();
};

export const createBoxRequest = ({ form, pageItem, store, attachedItems }) => {
    form.setValues(pageItem);
    
    form.setConfig({
        "title": "Create Box.com Request",
        "type": "push",
        "location": `Database/BoxRequests`,
        "isSteppedForm": true,
        "fields": [{
            "label": "Design Change",
            "name": "DesignChange",
            "type": "value",
            "formStep": 1,
            value() {
                return pageItem.Number;
            }
        }, {
            "label": "Customer",
            "name": "Customer",
            "type": "value",
            "formStep": 1,
            value() {
                return pageItem.Customer;
            }
        }, {
            "label": "Customer Contact Name",
            "name": "CustomerContactName",
            "formStep": 1,
            "type": "text",
        }, {
            "label": "Customer Contact Email",
            "name": "CustomerContactEmail",
            "formStep": 1,
            "type": "text",
        }, {
            "label": "Location of Documents",
            "type": "text",
            "name": "LocationOfDocuments",
            "formStep": 1,
            "divClass": "md:col-span-2"
        }, {
            "label": "Installation Folder",
            "type": "sectionHeader",
            "formStep": 2,
            "divClass": "md:col-span-3 border-t-2 border-primary-300 mt-8"
        }, {
            "label": "Installation Recipient Name",
            "name": "InstallationRecipientName",
            "type": "text",
            "formStep": 2,
        }, {
            "label": "Installation Recipient Email",
            "name": "InstallationRecipientEmail",
            "type": "text",
            "formStep": 2,
        }, {
            "label": "Select Installation  Documents",
            "name": "InstallationDocuments",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "divClass": "md:col-span-3",
            "formStep": 2,
            "dropdownData": dropdownToIncludedRevisions(makeDropdown(store, attachedItems.Documents.items, "Number", "ID"))
        }, {
            "label": "Select Installation Drawings",
            "name": "InstallationDrawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "formStep": 2,
            "divClass": "md:col-span-3",
            "dropdownData": dropdownToIncludedRevisions(makeDropdown(store, attachedItems.Drawings.items, "Number", "ID"))
        }, {
            "label": "Notes for Installer",
            "name": "InstallationRecipientNotes",
            "type": "text",
            "formStep": 2,
            "divClass": "md:col-span-3"
        }, {
            "label": "Manufacterer Folder",
            "type": "sectionHeader",
            "formStep": 3,
            "divClass": "md:col-span-3 border-t-2 border-primary-300 mt-8"
        }, {
            "label": "Manufacterer Recipient Name",
            "name": "ManufactererRecipientName",
            "formStep": 3,
            "type": "text",
        }, {
            "label": "Manufacterer Recipient Email",
            "name": "ManufactererRecipientEmail",
            "formStep": 3,
            "type": "text",
        }, {
            "label": "Select Manufacterer Documents",
            "name": "ManufactererDocuments",
            "formStep": 3,
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "divClass": "md:col-span-3",
            "dropdownData": dropdownToIncludedRevisions(makeDropdown(store, attachedItems.Documents.items, "Number", "ID"))
        }, {
            "label": "Select Manufacterer Drawings",
            "name": "ManufactererDrawings",
            "formStep": 3,
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "divClass": "md:col-span-3",
            "dropdownData": dropdownToIncludedRevisions(makeDropdown(store, attachedItems.Drawings.items, "Number", "ID"))
        }, {
            "label": "Notes for Manufacterer",
            "name": "ManufactererRecipientNotes",
            "formStep": 3,
            "type": "text",
            "divClass": "md:col-span-3"
        }, {
            "label": "Operator Folder",
            "type": "sectionHeader",
            "formStep": 4,
            "divClass": "md:col-span-3 border-t-2 border-primary-300 mt-8"
        }, {
            "label": "Operator Recipient Name",
            "name": "OperatorRecipientName",
            "formStep": 4,
            "type": "text",
        }, {
            "label": "Operator Recipient Email",
            "name": "OperatorRecipientEmail",
            "formStep": 4,
            "type": "text",
        }, {
            "label": "Select Operator Documents",
            "name": "OperatorDocuments",
            "formStep": 4,
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "divClass": "md:col-span-3",
            "dropdownData": dropdownToIncludedRevisions(makeDropdown(store, attachedItems.Documents.items, "Number", "ID"))
        }, {
            "label": "Select Operator Drawings",
            "name": "OperatorDrawings",
            "type": "dropdown",
            "multiple": true,
            "formStep": 4,
            "required": false,
            "divClass": "md:col-span-3",
            "dropdownData": dropdownToIncludedRevisions(makeDropdown(store, attachedItems.Drawings.items, "Number", "ID"))
        }, {
            "label": "Notes for Operator",
            "name": "OperatorRecipientNotes",
            "formStep": 4,
            "type": "text",
            "divClass": "md:col-span-3"
        }],
        preSubmitHook({ liveInputData }) {
            return {
                "Status": "Pending",
                "DesignChangeID": pageItem.ID,
                "Date": {
                    "Created": (new Date()).toISOString()
                },
                "ManufactererDocuments": makeAttached(liveInputData.ManufactererDocuments),
                "ManufactererDrawings": makeAttached(liveInputData.ManufactererDrawings),
                "InstallationDocuments": makeAttached(liveInputData.InstallationDocuments),
                "InstallationDrawings": makeAttached(liveInputData.InstallationDrawings),
                "OperatorDocuments": makeAttached(liveInputData.OperatorDocuments),
                "OperatorDrawings": makeAttached(liveInputData.OperatorDrawings),
                "CreatedBy": store.state.user.ID
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const completeBoxRequest = ({ form, pageItem, store, attachedItems }) => {
    form.setConfig({
        "title": "Complete Box.com Request",
        "type": "update",
        "location": `Database/BoxRequests/${pageItem.ID}`,
        preSubmitHook() {
            return {
                "Status": "Complete",
                "Completed": true
            };
        }
    });
    form.toggle();
};
