export const addPanelType = ({ form }) => {
    form.setConfig({
        "title": "Add Panel Type",
        "type": "push",
        "location": "Matrices/SignatoryMatrix/Panels/",
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "number",
            "step": 1
        }, {
            "label": "Name",
            "name": "Name",
            "type": "text"
        }, {
            "label": "Name",
            "type": "value",
            value({ liveInputData }) {
                return `${liveInputData.Number || 'ENTER NUMBER'} - ${liveInputData.Name || 'ENTER NAME'}`
            }
        }, {
            "label": "STC Approved",
            "name": "STCApproved",
            "type": "checkbox",
            "required": false
        
        }]
    });
    form.toggle();
}

export const editPanelType = ({ form, rowItem }) => {
    form.setValues(rowItem);
    form.setConfig({
        "title": "Edit Panel Type",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Panels/${rowItem.ID}`,
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }, {
                "label": "STC Approved",
                "name": "STCApproved",
                "type": "checkbox",
                "required": false

            }]
    });
    form.toggle();
}
