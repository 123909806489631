import { cancelServiceBulletin, editServiceBulletin, reviseServiceBulletin, signoffServiceBulletin } from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import { revisions as _revisions } from "../../standardAttachables";
import documentsAttachable from "../Documents/attachable.js";
import dqnAttachable from "../DQNs/attachable.js";
import drawingAttachable from "../Drawings/attachable.js";
import effectivitiesAttachable from "../Effectivities/attachable.js";

const revisions = {
    ..._revisions
};

revisions.actions= [{
    "label": "Revise Service Bulletin",
    "action": reviseServiceBulletin
}];

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Parent",
    "key": "Parent",
    "link": "Database/:ParentType/:ParentID"
}, {
    "label": "Is Resolved?",
    compute( {pageItem} ) {
        return `<span class="font-bold ${pageItem.Signedoff ? 'text-green' : 'text-red'}">${pageItem.Signedoff ? 'Resolved' : 'Unresolved'}</span>`
    }
}, {
    "label": "Comment",
    "key": "Comment",
    "class": "md:col-span-3"
}];

const menuActions = [
    {
        "label": "Edit Service Bulletin",
        "action": editServiceBulletin
    },
    {
        "label": "Resolve Service Bulletin",
        "action": signoffServiceBulletin
    },
    {
        "label": "Revise Service Bulletin",
        "action": reviseServiceBulletin
    },
    {
        "label": "Cancel Service Bulletin",
        "class": "text-red",
        "break": true,
        "action": cancelServiceBulletin
    }
];

export default [{
    "path": "/Database/ServiceBulletins",
    "component": {...ItemListView},
    "name": "Service Bulletins",
    "meta": {
        "app": "Database",
        "name": "Service Bulletins",
        "sideNav": true,
        "sideNavSection": "Design Items",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "ServiceBulletins",
        "dataPath": "Database/ServiceBulletins",
        "selectionRoute": "Database/ServiceBulletins/{ID}"
    }
}, {
    "path": "/Database/ServiceBulletins/:id",
    "component": ItemView,
    "name": "Service Bulletin",
    "meta": {
        "app": "Database",
        "name": "Service Bulletins",
        "dataPath": "Database/ServiceBulletins",
        "selectionRoute": "Database/ServiceBulletins/{ID}",
        title(item) {
            return `Service Bulletins: ${item.Number}`
        },
        "attachedItems": {
            "Revisions": {
                "attachables": [revisions]
            },
            "Attached Items": {
                "attachables": [documentsAttachable, drawingAttachable, effectivitiesAttachable, dqnAttachable]
            }
        },
        "dataFields": dataFields,
        "menuActions": menuActions,
        "formMetadata": {
            "ParentType": "ServiceBulletins"
        }
    }
}];
