import _ from "lodash";

function postSubmitHook(db) {
    return async function () {

        let appFuncs = await db.once("Matrices/SignatoryMatrix/ApprovedFunctions");
        let filteredAppFuncs = _
            .chain(appFuncs)
            .toPairs()
            .filter(([key, value]) => {
                return value.NewApplication === true;
            })
            .fromPairs()
            .value();
        await db.set("OpenForms/OpenData/Types", filteredAppFuncs);
        return;
    }
}

export const addApprovedFunction = ({ form, db }) => {
    form.setConfig({
        "title": "Add new ApprovedFunction",
        "type": "push",
        "location": "Matrices/SignatoryMatrix/ApprovedFunctions",
        "fields": [{
            "label": "Approved Function",
            "type": "text",
            "name": "Name"
        }, {
            "label": "Points Required",
            "type": "number",
            "name": "Threshold"
        }, {
            "label": "Form Four Function",
            "type": "toggle",
            "name": "FormFourFunction"
        }, {
            "label": "New Application",
            "type": "toggle",
            "name": "NewApplication",
            "info": "Selecting this option means it can be selected during a new application."
        }, {
            "label": "Panel Function",
            "type": "toggle",
            "name": "PanelFunction"
        }, {
            
        }, {
            "type": "value",
            "label": "Panel Function Icon",
            value({ liveInputData }) {

            },
            show({ liveInputData }) {
                return liveInputData.PanelFunction === true;
            }
        }],
        "postSubmitHook": postSubmitHook(db)

    });
    form.toggle();
};

export const editApprovedFunction = ({ form, rowItem, db }) => {
    form.setValues(rowItem);
    form.setConfig({
        "title": "Update Approved Function",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/ApprovedFunctions/${rowItem.ID}`,
        "fields": [{
            "label": "Approved Function",
            "type": "text",
            "name": "Name"
        }, {
            "label": "Points Required",
            "type": "number",
            "name": "Threshold"
        }, {
            "label": "Form Four Function",
            "type": "toggle",
            "name": "FormFourFunction"
        }, {
            "label": "New Application",
            "type": "toggle",
            "name": "NewApplication",
            "info": "Selecting this option means it can be selected during a new application. This is replacing TYPE."
        }],
        "postSubmitHook": postSubmitHook(db)
    });
    form.toggle();
};


export const deleteApprovedFunction = ({ form, rowItem, db }) => {
    form.setConfig({
        "title": "Delete Approved Function",
        "type": "delete",
        "location": `Matrices/SignatoryMatrix/ApprovedFunctions/${rowItem.ID}`,
        "fields": [],
        "postSubmitHook": postSubmitHook(db)
    });
    form.toggle();
};  
