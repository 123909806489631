import {
    addResponsibility,
    editResponsibility,
    deleteResponsibility
} from "./actions";


export default {
    "label": "Engineer Responsibilities",
    "key": "EngineerResponsibilities",
    "location": "Database/EngineerResponsibilities/",
    "headers": [{
        "headerName": "Responsibility",
        "field": "Responsibility"
    }, {
        "headerName": "Colours",
        "field": "Colours"
    }],
    "actions": [{
        "label": "Add Responsibility",
        "action": addResponsibility
    }],
    "rowActions": [{
        "label": "Edit Responsibility",
        "action": editResponsibility
    }, {
        "label": "Delete Responsibility",
        "action": deleteResponsibility
    }]
}
