import Application from "./Application.vue";

export default [{
    "path": "/___/FlightConditionApplications/:id?",
    "components": {
        "open-forms" : {
            ...Application
        }
    },
    "name": "NewFlightConditionApplication",
    "meta": {
        "open": true
    }
}];
