import { acceptApplication, addApplicationBackToOpen } from "./actions";
import {
    declarations,
    mainDetails,
    mmelLimiting,
    nextMainDetails,
    notes,
    ongoingMods,
    ongoingRepairs,
    opRestrictions,
    orgRelease,
    supportingFiles,
    supportingInformation
} from "./internalAttachable";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

const dataFields = [{
    "label": "Airline",
    "key": "airline"
}, {
    "label": "Contact Name",
    "key": "accepted_name"
}, {
    "label": "Contact Position",
    "key": "accepted_position"
}, {
    "label": "Contact Email",
    "key": "email"
}, {
    "label": "Contact Phone",
    "key": "phone"
}, {
    "label": "Last Updated Date",
    "key": "accepted_date",
    "type": "date"
}, {
    "label": "Reason",
    "key": "checked_reason"
}, {
    "label": "Aircraft Information",
    "break": true,
    "class": "md:col-span-3"
}, {
    "label": "Aircraft Serial Number / Registration",
    "key": "ac_sn"
}, {
    "label": "Aircraft Location",
    "key": "ac_location_from"
}, {
    "label": "Ferry Location",
    "key": "ac_location_to"
}, {
    "label": "Aircraft Hours",
    "key": "ac_hours"
}, {
    "label": "Aircraft Cycles",
    "key": "ac_cycles"
}, {
    "label": "CAME / CAMO / AMPS",
    "break": true,
    "class": "md:col-span-3"
}, {
    "label": "CAME Ref",
    "key": "came_ref"
}, {
    "label": "CAME Issue",
    "key": "came_issue"
}, {
    "label": "CAME Issue Date",
    "key": "came_date"
}, {
    "label": "CAME Ref",
    "key": "came_ref"
}, {
    "label": "CAMO Issue",
    "key": "camo_name"
}, {
    "label": "CAMO Ref",
    "key": "camo_number"
}, {
    "label": "AMP Ref",
    "key": "amps_ref"
}, {
    "label": "AMP Issue",
    "key": "amps_issue"
}, {
    "label": "Link to Application (send to applicants for application updates)",
    "class": "md:col-span-3",
    compute({ pageItem }) {
        if (pageItem.status === "Accepted") return "Application is closed, no link available"
        // get the current domain
        let domain = window.location.origin;
        return `${domain}/___/FlightConditionApplications/${pageItem.ID}?step=1`
    }
}];

const menuActions = [

];



export default [{
    "path": "/Database/FlightConditionsApprovalForms",
    "component": { ...ItemListView },
    "name": "Flight Condition Approval's",
    "meta": {
        "app": "Database",
        "name": "Flight Condition Approval's",
        "sideNav": true,
        "sideNavSection": "Projects",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "FlightConditionsApprovalForms",
        "dataPath": "Database/FlightConditionsApprovalForms",
        "selectionRoute": "Database/FlightConditionsApprovalForms/{ID}",
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Database/FlightConditionsApprovalFormsApplications",
    "component": { ...ItemListView },
    "name": "Flight Condition Approval Applications",
    "meta": {
        "app": "Database",
        "name": "Flight Condition Approval Applications",
        "sideNav": true,
        "sideNavSection": "Projects",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "FlightConditionsApprovalForms",
        "dataPath": "OpenForms/FlightConditionApplications",
        "selectionRoute": "Database/FlightConditionsApprovalApplications/{ID}",
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        },
        filter(item) {
            return item.completed === true;
        }
    }
}, {
    "path": "/Database/FlightConditionsApprovalForms/:id",
    "component": ItemView,
    "name": "Flight Condition Approval Form",
    "meta": {
        "app": "Database",
        "name": "Flight Condition Approval Forms",
        "dataPath": "Database/FlightConditionsApprovalForms",
        "selectionRoute": "Database/FlightConditionsApprovalForms/{ID}",
        title(item) {
            return `Flight Condition Approval Form: ${item.airline}`
        },
        "attachedItems": {
            "Supporting Data": {
                "attachables": [
                    supportingFiles,
                    supportingInformation,
                    nextMainDetails,
                    mainDetails,
                    ongoingMods,
                    ongoingRepairs,
                    opRestrictions,
                    mmelLimiting,
                    orgRelease,
                    declarations,
                    notes
                ]
            }
        },
        "dataFields": dataFields,
        "menuActions": [{
            "label": "Add Application Back To Open",
            "action": addApplicationBackToOpen
        }]
    }
}, {
    "path": "/Database/FlightConditionsApprovalApplications/:id",
    "component": ItemView,
    "name": "Flight Condition Approval Application",
    "meta": {
        "app": "Database",
        "name": "Flight Condition Approval Application",
        "dataPathOverride": "OpenForms/FlightConditionApplications/:id",
        "selectionRoute": "Database/FlightConditionsApprovalForms/{ID}",
        title(item) {
            return `Flight Condition Approval Form: ${item.airline}`
        },
        "attachedItems": {
            "Supporting Data": {
                "attachables": [
                    supportingFiles,
                    supportingInformation,
                    nextMainDetails,
                    mainDetails,
                    ongoingMods,
                    ongoingRepairs,
                    opRestrictions,
                    mmelLimiting,
                    orgRelease,
                    declarations,
                    notes
                ]
            }
        },
        "dataFields": dataFields,
        "menuActions": [{
            "label": "Accept Application",
            "action":acceptApplication
        }]
    }
}];
