import {
    cancelFlightCondition,
    completeFlightCondition,
    editFlightCondition,
    linkFlightConditionToDesignChange
} from "./actions";
import {
    thirdPartyDocs,
    thirdPartyDraws,
    thirdPartyOther
} from "../Miscellaneous/attachable.js";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import commercialsAttachable from "../Commercials/attachable.js";
import concessionAttachable from "../Concessions/attachable.js";
import documentsAttachable from "../Documents/attachable.js?query";
import doiAttachable from "../DOIs/attachable.js";
import dqnsAttachable from "../DQNs/attachable.js";
import drawingAttachable from "../Drawings/attachable.js?query";
import effectivitiesAttachable from "../Effectivities/attachable.js";
import { flightConditionItemsReport } from "./reports.js";
import jobCardsAttachable from "../JobCards/attachable.js";

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Title",
    "key": "Title",
    "class": "md:col-span-2"
}, {
    "label": "Primary Commercial / Job Number",
    "key": "Parent",
    "link": "Database/Commercials/:ParentID"
}, {
    "label": "Linked Design Change",
    "key": "DesignChange",
    "link": "Database/DesignChanges/:DesignChangeID"
}, {
    "label": "Responsible Engineer",
    "key": "ResponsibleEngineer"
}, {
    "label": "Customer",
    "key": "Customer"
}, {
    "label": "Aircraft",    
    "key": "Aircraft"
}, {
    "label": "SerialNumber",
    "key": "SerialNumber"
}, {
    "label": "Registration",
    "key": "Registration"
}, {
    "label": "Purpose",
    "key": "Purpose",
    "grow": true
}, {
    "label": "Category",
    "key": "Category",
    "grow": true
}, {
    "label": "IssueType",
    "key": "IssueType",
    "grow": true
}, {
    "label": "Date Created",
    "key": "Date.Created",
    "type": "date"
}, {
    "label": "Date Completed",
    "key": "Date.Completed",
    "type": "date"
}];

const menuActions = [
    {
        "label": "Download Flight Condition Report",
        "action": flightConditionItemsReport
    },
    {
        "label": "Edit Flight Condition",
        "action": editFlightCondition,
        "break": true,
    },
    {
        "label": "Link to a Design Change",
        "action": linkFlightConditionToDesignChange
    },
    {
        "label": "Complete Flight Condition",
        "action": completeFlightCondition,
        show({ item }) {
            return [!item.Completed, "Flight Condition already complete"]
        },
        show({ attachedItems, pageItem }) {
            if (pageItem.Completed) return [false, 'The Flight Condition has already been completed'];
            
            let warnings = Object
                .values(attachedItems)
                .filter(ai => {
                    if(pageItem.Purpose === "Development" && ai.warn[1].toLowerCase().includes("prototype")) return false;
                    else return ai.warn[0];
                })
                .map(ai => `${ai.label}<br>${ai.warn[1]}`)
                .join("<br>");

            if (warnings.length > 0) return [false, warnings];
            else return [true, ''];
        }
    },
    {
        "label": "Cancel Flight Condition",
        "action": cancelFlightCondition,
        show({ item }) {
            return [!item.Cancelled, "Flight Condition already cancelled"]
        }
    }
];

export default [{
    "path": "/Database/FlightConditions",
    "component": { ...ItemListView },
    "name": "Flight Conditions",
    "meta": {
        "app": "Database",
        "name": "Flight Conditions",
        "sideNav": true,
        "sideNavSection": "Designs",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "FlightConditions",
        "dataPath": "Database/FlightConditions",
        "selectionRoute": "Database/FlightConditions/{ID}"
    }
}, {
    "path": "/Database/FlightConditions/:id",
    "component": ItemView,
    "name": "Flight Condition",
    "meta": {
        "app": "Database",
        "name": "Flight Conditions",
        "dataPath": "Database/FlightConditions",
        "selectionRoute": "Database/FlightConditions/{ID}",
        title(item) {
            return `Flight Condition: ${item.Number}`
        },
        "attachedItems": {},
        "dataFields": dataFields,
        "menuActions": menuActions,
        "attachedItems": {
            "Design Items": {
                "attachables": [documentsAttachable, drawingAttachable, concessionAttachable, dqnsAttachable, doiAttachable, effectivitiesAttachable, thirdPartyDocs,
                    thirdPartyDraws,
                    thirdPartyOther]
            },
            "Jobs": {
                "attachables": [commercialsAttachable, jobCardsAttachable]
            }
        },
        "formMetadata": {
            "ParentType": "FlightConditions"
        }
    }
}];
