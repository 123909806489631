import {
    addDrawingRevisionReason,
    editDrawingRevisionReason
} from "./actions";

export default {
    "label": "Drawing Revision Reasons",
    "key": "DrawingRevisionTypes",
    "location": "Database/DrawingRevisionTypes/",
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "actions": [{
        "label": "Add Revision Reason",
        "action": addDrawingRevisionReason
    }],
    "rowActions": [{
        "label": "Edit Revision Reason",
        "action": editDrawingRevisionReason
    }]
}
