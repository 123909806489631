import { addClaim, approveClaim, completeClaim, rejectClaim, submitClaim } from "./actions";
import { items, receipts } from "./internalAttachables";
import ItemListView from "../../components/ItemListView/ItemListView.vue"
import ItemView from "../../components/ItemView/ItemView.vue"
import {
    groupBy
} from "lodash";
import store from "../../store";

// Main Home View
export default [{
    "path": "/Claims/Claims",
    "component": {...ItemListView},
    "name": "My Claims",
    "meta": {
        "app": "Claims",
        "sideNav": true,
        "appSwitcher": true,
        "appSwitcherPosition": 4,
        "icon": "pound-sign",
        "name": "My Claims",
        "sideNavSection": "Claims",
        "security": "Claims.User",
        "tableSettings": "MyClaims",
        "dataPath": "Claims/Claims",
        "selectionRoute": "Claims/Claims/{ID}",
        "storeData": ["Claims/Settings/ItemTypes", "Claims/Settings/ClaimReasons"],
        filter(claim) {
            return claim?.User?.UID === store.getters.userID
        },
        "menuActions": [{
            "label": "Add Claim",
            "action": addClaim
        }]
    }
}, {
    "path": "/Claims/Claims/:id",
    "component": ItemView,
    "name": "Claim",
    "meta": {
        "storeData": ["Claims/Settings/ItemTypes", "Claims/Settings/ClaimReasons"],
        "app": "Claims",
        "sideNav": false,
        "appSwitcher": false,
        "name": "Claim",
        title(item) {
            return `${item.Type} - ${item.Number}`
        },
        "menuActions": [{
            "label": "Submit Claim",
            "action": submitClaim,
            show({ pageItem }) {
                return [pageItem.Stage === "Unsubmitted" || pageItem.Stage === "Returned", "This has already been submitted"];
            }
        }, {
            "label": "Authorise Claim",
            "action": approveClaim,
                show({ pageItem, store }) {
                    if (store.getters.userID === pageItem.User.UID) return [false, "You cannot authorise your own claim"];
                    if (!store.state.user.Security.Claims.Authoriser) return [false, "You cannot authorise this claim"];
                    return [pageItem.Stage === "Waiting for Authorisation", "This is not ready to be authorised or it already has been authroised"];
                }
            }, {
            "label": "Reject Claim",
            "action": rejectClaim,
            show({ pageItem, store }) {
                if (store.getters.userID === pageItem.User.UID) return [false, "You cannot reject your own claim"];
                if (!store.state.user.Security.Claims.Authoriser) return [false, "You cannot reject this claim"];
                return [pageItem.Stage === "Waiting for Authorisation", "This is cannot rejected"];
            }
        }, {
            "label": "Complete Claim",
            "action": completeClaim,
            show({ pageItem, store }) {
                if (store.getters.userID === pageItem.User.UID) return [false, "You cannot complete your own claim"];
                if (!store.state.user.Security.Claims.Payer) return [false, "You cannot complete this claim"];
                return [pageItem.Stage === "Waiting for Payment", ""];
            }
        }],
        "dataFields": [{
            "label": "Number",
            "key": "Number"
        }, {
            "label": "Description",
            "key": "Description",
            "class": "md:col-span-2"
        }, {
            "label": "Total Cost",
            "grow": true,
            compute({ attachedItems, store }) {
                let items = attachedItems?.Items?.items.map(i => store.state[i]);
                let groupedByCurrency = groupBy(items, "Currency.Symbol");
                return Object.entries(groupedByCurrency || {}).map(([key, value]) => {
                    return `${key}${(value.reduce((acc, i) => (acc += parseFloat(i?.Cost || 0)), 0)).toFixed(2)}`
                }).join("<br />");
            }
        }, {
            "label": "Out of Office Expense Information",
            "break": true,
            "class": "md:col-span-3",
            show({ pageItem }) {
                return pageItem?.TypeID === "02";
            }
        }, {
            "label": "Reason",
            compute({ pageItem, store }) {
                return store.state["Claims/Settings/ClaimReasons"][pageItem.Reason]?.Name
            },
            show({ pageItem }) {
                return pageItem?.TypeID === "02";
            }
        }, {
            "label": "Start Date",
            "key": "Date.Start",
            "type": "date",
            show({ pageItem }) {
                return pageItem?.TypeID === "02";
            }
        }, {
            "label": "End Date",
            "key": "Date.End",
            "type": "date",
            show({ pageItem }) {
                return pageItem?.TypeID === "02";
            }
        }, {
            "label": "Job Number",
            "key": "Commercial.Number",
            "link": `Database/Commercials/:Commercial.ID`,
            show({ pageItem }) {
                return pageItem?.TypeID === "02";
            }
        }],
        "attachedItems": {
            "Attached Items": { "attachables": [items, receipts] }
        }
    }
}, {
    "path": "/Claims/Auth",
    "component": {...ItemListView},
    "name": "Waiting for Authorisation",
    "storeData": ["Claims/Settings/ItemTypes", "Claims/Settings/ClaimReasons"],
    "meta": {
        "app": "Claims",
        "sideNav": true,
        "name": "Waiting for Authorisation",
        "sideNavSection": "Admin",
        "security": "Claims.User",
        "tableSettings": "MyClaims",
        "dataPath": "Claims/Claims",
        "selectionRoute": "Claims/Claims/{ID}",
        "storeData": ["Claims/Settings/ClaimReasons"],
        filter(claim) {
            return claim?.Stage === "Waiting for Authorisation";
        },
        "menuActions": [],
        lock(user) { 
            return [user?.Security?.Claims?.Authoriser !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Claims/Payment",
    "component": {...ItemListView},
    "name": "Waiting for Payment",
    "storeData": ["Claims/Settings/ItemTypes", "Claims/Settings/ClaimReasons"],
    "meta": {
        "app": "Claims",
        "sideNav": true,
        "name": "Waiting for Payment",
        "sideNavSection": "Admin",
        "security": "Claims.User",
        "tableSettings": "MyClaims",
        "dataPath": "Claims/Claims",
        "selectionRoute": "Claims/Claims/{ID}",
        "storeData": ["Claims/Settings/ClaimReasons"],
        filter(claim) {
            return claim?.Stage === "Waiting for Payment";
        },
        "menuActions": [],
        lock(user) {
            return [user?.Security?.Claims?.Payer !== true, "Cannot view this area"]
        }
    }
    }, {
    "path": "/Claims/Completed",
        "component": { ...ItemListView },
        "name": "Completed",
        "storeData": ["Claims/Settings/ItemTypes", "Claims/Settings/ClaimReasons"],
        "meta": {
            "app": "Claims",
            "sideNav": true,
            "name": "Completed",
            "sideNavSection": "Admin",
            "security": "Claims.User",
            "tableSettings": "MyClaims",
            "dataPath": "Claims/Claims",
            "selectionRoute": "Claims/Claims/{ID}",
            "storeData": ["Claims/Settings/ClaimReasons"],
            filter(claim) {
                return claim?.Stage === "Completed";
            },
            "menuActions": [],
            lock(user) {
                return [user?.Security?.Claims?.Payer !== true, "Cannot view this area"]
            }
        }
    }]
