import { addPurchaseOrder, editPurchaseOrder } from "./actions";

export default {
    "label": "Purchase Orders",
    "key": "PurchaseOrders",
    "location": "Database/PurchaseOrders/",
    show() {
        return true
    },
    "actions": [{
        "label": "Add Purchase Order",
        "action": addPurchaseOrder
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Price",
        valueFormatter(params) {
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: params.data.Currency || "GBP",
            });

            let ogPrice = formatter.format(parseFloat(params.data.Price));
            let gbpPrice = "";
            if(params.data.Currency !== "gbp") {
                var gbpFormatter = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "GBP",
                });
                gbpPrice = gbpFormatter.format(parseFloat(params.data.PriceGBP));
            }

            return `${ogPrice} ${gbpPrice.length > 0 ? `(${gbpPrice})` : ""}`;
        }
    }, {
        "headerName": "Percentage Invoiced",
        "field": "PercentageInvoiced"
    }, {
        "headerName": "Percentage Paid",
        "field": "PercentagePaid"
    }, {
        "headerName": "Date Received",
        "field": "Date.Created",
        "type": "date"
    }],
    "rowActions": [{
        "label": "Open Purchase Order",
        action({ router, rowItem }) {
            router.push(`/Database/PurchaseOrders/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Purchase Order",
        "action": editPurchaseOrder
    }]
};
