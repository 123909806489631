import flightConditionApplication from "./FlightConditionApplications/routes.js";

// Main Home View
export default [{
    "path": "/___/Signatories",
    "components": {
        "open-forms": () => import("./Signatories/NewApplication.vue")
    },
    "name": "open-form-sig",
    "meta": {
        "open": true
    }
}, {
    "path": "/___/Signatories/:id",
    "components": {
        "open-forms": () => import("./Signatories/FormContainer.vue")
    },
    "name": "open-form-sig-id",
    "meta": {
        "open": true
    },
    "children": [{
        "path": "contact",
        "component": () => import("./Signatories/forms/Contact.vue"),
        "name": "contact"
    }, {
        "path": "address",
        "component": () => import("./Signatories/forms/Address.vue"),
        "name": "address"
    }, {
        "path": "role",
        "component": () => import("./Signatories/forms/Role.vue"),
        "name": "role"
    }, {
        "path": "questions",
        "component": () => import("./Signatories/forms/Questions.vue"),
        "name": "questions"
    }, {
        "path": "supportingDocuments",
        "component": () => import("./Signatories/forms/SupportingDocuments.vue"),
        "name": "supportingDocuments"
    }]
}, {
    "path": "/___/Signatories/end",
    "components": {
        "open-forms": () => import("./Signatories/End.vue")
    },
    "name": "open-form-sig-end",
    "meta": {
        "open": true
    }
}, {
    "path": "/___/FlightTestCrew",
    "components": {
        "open-forms": () => import("./FlightTestCrew/NewApplication.vue")
    },
    "name": "open-form-ftc",
    "meta": {
        "open": true
    }
}, {
    "path": "/___/FlightTestCrew/:id",
    "components": {
        "open-forms": () => import("./FlightTestCrew/FormContainer.vue")
    },
    "name": "open-form-ftc-id",
    "meta": {
        "open": true
    },
    "children": [{
        "path": "ftc-contact",
        "component": () => import("./FlightTestCrew/forms/Contact.vue"),
        "name": "ftc-contact"
    }, {
        "path": "ftc-em-contact",
        "component": () => import("./FlightTestCrew/forms/EmergencyContact.vue"),
        "name": "ftc-em-contact"
    }, {
        "path": "ftc-supportingDocuments",
        "component": () => import("./FlightTestCrew/forms/SupportingDocuments.vue"),
        "name": "ftc-supportingDocuments"
    }, {
        "path": "roleExp",
        "component": () => import("./FlightTestCrew/forms/Role.vue"),
        "name": "roleExp"
    }, {
        "path": "ftc-licences",
        "component": () => import("./FlightTestCrew/forms/AircraftLicences.vue"),
        "name": "ftc-licences"
    }, {
        "path": "ftc-licence-med",
        "component": () => import("./FlightTestCrew/forms/LicenceMed.vue"),
        "name": "ftc-licence-med"
    }, {
        "path": "ftc-ratings",
        "component": () => import("./FlightTestCrew/forms/Ratings.vue"),
        "name": "ftc-ratings"
    }, {
        "path": "ftc-fh-hist",
        "component": () => import("./FlightTestCrew/forms/FlightHoursTotal.vue"),
        "name": "ftc-fh-hist"
    }, {
        "path": "ftc-fh-last",
        "component": () => import("./FlightTestCrew/forms/FlightHoursLast12.vue"),
        "name": "ftc-fh-last"
    }]
}, {
    "path": "/___/FlightTestCrew/end",
    "components": {
        "open-forms": () => import("./FlightTestCrew/End.vue")
    },
    "name": "open-form-ftc-end",
    "meta": {
        "open": true
    }
}, ...flightConditionApplication]


