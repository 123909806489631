<template>
  <div class="grid grid-cols-3 gap-2">
    <div class="flex flex-row items-center" v-if="store.state.appConfig.appPrefix === 'doa'">
      <p class="shadow text-center text-sm bg-gradient-to-br rounded-full from-red-300 to-red-400 text-white" style="
                height: 30px;
                width: 30px;
                padding-top: 5px;
                text-shadow: 1px 1px 3px #999;
              ">
        &#10006;
      </p>
      <p class="p-1 text-sm font-medium text-gray mr-4">None</p>
    </div>
    <div class="flex flex-row items-center">
      <p class="shadow text-center text-sm bg-gradient-to-br rounded-full from-gray-50 to-gray-200 text-gray" style="
                height: 30px;
                width: 30px;
                padding-top: 5px;
                text-shadow: 1px 1px 3px #999;
              ">
        Co
      </p>
      <p class="p-1 text-sm text-gray mr-4">Compiler</p>
    </div>
    <div class="flex flex-row items-center">
      <p class="shadow text-center text-sm bg-gradient-to-br rounded-full from-blue-200 to-blue-400 text-white"
        style="
                height: 30px;
                width: 30px;
                padding-top: 5px;
                text-shadow: 1px 1px 3px #999;
              ">
        Ck
      </p>
      <p class="p-1 text-sm font-medium text-gray mr-4">Checker</p>
    </div>
    <div class="flex flex-row items-center">
      <p class="shadow text-center text-sm bg-gradient-to-br rounded-full from-orange-200 to-orange-400 text-white"
        style="
                height: 30px;
                width: 30px;
                padding-top: 5px;
                text-shadow: 1px 1px 3px #999;
              ">
        Ap
      </p>
      <p class="p-1 text-sm font-medium text-gray mr-4">Approver</p>
    </div>
    <div class="flex flex-row items-center">
      <p class="shadow text-center text-sm bg-gradient-to-br rounded-full from-green-300 to-green-400 text-white text-xxs font-bold"
        style="
                height: 30px;
                width: 30px;
                padding-top: 8px;
                text-shadow: 1px 1px 3px #999;
              ">
        CVE
      </p>
      <p class="p-1 text-sm font-medium text-gray">CVE</p>
    </div>
  </div>
</template>
<script setup>

import { useStore } from "vuex";

const store = useStore();

</script>
