export const addSupplier = ({ form }) => {
    form.setConfig({
        "title": "Add Supplier",
        "type": "push",
        "location": "Database/Suppliers",
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
}

export const editSupplier = ({ form, rowItem }) => {
    form.setValues({
        "Name": rowItem.Name
    });
    form.setConfig({
        "title": "Edit Supplier",
        "type": "update",
        "location": `Database/Suppliers/${rowItem.ID}`,
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
}
