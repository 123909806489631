<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0"
    style="background: rgba(0, 0, 0, 0.3)"
    v-show="state.open"
  >
  
    <div class="mx-auto container" style="max-width: 600px">
      <div
        class="
          bg-white
          rounded
          shadow
          p-2
          border border-gray-100
          opacity-100
          mt-12
        "
        id="_db_search_container"
      >
        <div class="flex flex-row items-center">
          <p class="p-2 text-text font-medium flex-grow text-primary text-sm">
            Database Quick Find
          </p>
          <IconButton icon="times" @click="toggle" class="cancel" />
        </div>
        <Form @submit="search">
          <Input
            label="Search"
            placeholder="Type at least 3 characters to search..."
            name="searchTerm"
            @enter="search"
          />
        </Form>
        <div class="flex flex-row m-2">
          <p class="py-1 font-bold text-sm" style="width: 140px">Type</p>
          <p class="py-1 font-bold text-sm flex-grow">Number</p>
        </div>
        <div class="overflow-y-auto" style="max-height: 400px">
          <div
            class="
              py-1
              flex flex-row
              m-2
              odd:bg-gray-50
              hover:font-bold
              cursor-pointer
            "
            v-for="result in state.results"
            :key="result.link"
            @click="
              router.push(result.link);
              toggle();
            "
          >
            <p class="py-1 text-sm" style="width: 140px">{{ result.type }}</p>
            <p class="py-1 flex-grow text-sm">{{ result.name }}</p>
          </div>
        </div>
        <Loading v-if="state.loading" />
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  onMounted,
  reactive,
  defineExpose,
  watch,
  getCurrentInstance,
} from "vue";
import serialize from "form-serialize";
import { db } from "../db.js";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Loading, Input, Form, IconButton } from "@comp";

const instance = getCurrentInstance();

const store = useStore();
const router = useRouter();

let state = reactive({
  open: false,
  loading: false,
  submitting: false,
  searchables: [],
  results: [],
});

const toggle = () => {
  state.open = !state.open;
  let inputContainer = document.getElementById("_db_search_container");
  let inputs = inputContainer.getElementsByTagName("INPUT");
  if (state.open) {
    setTimeout(() => {
      inputs[0].focus();
    }, 100);
  } else {
    inputs[0].value = "";
  }
};

async function getSearchables() {
  state.loading = true;
  if (!store.state.search && store.getters.authenticated) {
    await db.store("Database/Search", store, "search");
  }
  state.searchables = Object.entries(store.state.search || {});
  state.loading = false;
}

const search = (data) => {
  let searchTerm = data.searchTerm.toLowerCase();
  let results = 0;
  state.results = state.searchables
    .filter(([key, value]) => {
      if (results > 15) return false;

      if (value.toString().toLowerCase().includes(searchTerm)) {
        results++;
        return true;
      } else return false;
    })
    .map(([key, value]) => {
      return {
        type: key
          .split("|")[1]
          .split(/(?=[A-Z])/)
          .join(" "),
        name: value,
        link: `/${key.replace(/\|/g, "/")}`,
      };
    });
};

getSearchables();

defineExpose({
  toggle,
});
</script>
