export default {
    "title": "Aircraft Details",
    "submitButtonText": "Save & Next",
    "fields": [{
        "label": "Aircraft Registration",
        "name": "ac_reg",
        "type": "text",
        "required": true
    }, {
        "label": "Aircraft Serial Number",
        "name": "ac_sn",
        "type": "text",
        "required": true
    }, {
        "label": "Aircraft Airframe Hours",
        "name": "ac_hours",
        "type": "number",
        "required": true
    }, {
        "label": "Aircraft Airframe Cycles",
        "name": "ac_cycles",
        "type": "number",
        "required": true
    }]
}
