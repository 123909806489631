export const responsibleEngineer = {
    "label": "Responsible Engineer",
    "name": "ResponsibleEngineer",
    "type": "dropdown",
    "labelKey": "name",
    "idKey": "name",
    "dataLocation": "users",
    dataFilter(user) {
        return user.main;
    }
};
