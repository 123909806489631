import {
    correctiveAction,
    extension,
    issue,
    preventativeAction,
    rootCause
} from "./internalAttachables.js";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import {
    accept
} from "./actions.js";
import jobCardAttachable from "../../Database/JobCards/attachable.js";

const accepted = bool => bool === true ? "<span class='font-bold text-green'>Accepted</span>" : "<span class='font-bold text-red'>Not Accepted</span>";

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Report",
    "key": "Report",
    "link": "Audits/Reports/:ReportID"
}, {
    "label": "Procedure",
    "key": "Procedure"
}, {
    "label": "Type and Severity",
    compute({item}) {
        return `${item.Type} - ${item.Severity}`
    }
}, {
    "label": "HoD Accepted Issue",
    compute({item}) {
        return accepted(item.Issue.HOD)
    }
}, {
    "label": "Date Due",
    "key": "Date.Due",
    "type": "date"
}, {
    "label": "Root Cause",
    compute({item}) {
        return `HISM: ${accepted(item.RootCause.DAM)}<br>HoD: ${accepted(item.RootCause.HOD)}`
    },
    "grow": true
}, {
    "label": "Corrective Action",
    compute({item}) {
        return `HISM: ${accepted(item.CorrectiveAction.DAM)}<br>HoD: ${accepted(item.CorrectiveAction.HOD)}`
    },
    "grow": true
}, {
    "label": "Preventative Action",
    compute({item}) {
        return `HISM: ${accepted(item.PreventativeAction.DAM)}<br>HoD: ${accepted(item.PreventativeAction.HOD)}`
    },
    "grow": true
}];

const menuActions = [{
    "label": "HoD Accept Issue",
    "action": accept("HOD", "Issue"),
    show({ pageItem }) {
        return [!pageItem.Issue.HOD, "HoD has already accepted this issue."]
    }
}]

export default [{
    "path": "/Audits/SubNonConformities",
    "component": {...ItemListView},
    "name": "Sub Non Conformities",
    "meta": {
        "app": "Audits",
        "name": "Sub Non Conformities",
        "sideNav": true,
        "sideNavSection": "Sub Reports",
        "appSwitcher": false,
        "icon": "check-circle",
        "tableSettings": "NonConformities",
        "dataPath": "Audits/SubNonConformities",
        "selectionRoute": "Audits/SubNonConformities/{ID}"
    }
}, {
    "path": "/Audits/SubNonConformities/:id",
    "component": ItemView,
    "name": "Sub Non Conformity",
    "meta": {
        "app": "Audits",
        "name": "Sub Non Conformity",
        title(item) {
            return `Sub Non Conformity: ${item?.Number}`
        },
        "attachedItems": {
            "Issue": {
                "attachables": [issue]
            },
            "Actions Taken": {
                "attachables": [rootCause, correctiveAction, preventativeAction]
            },
            "Extension": {
                "attachables": [extension]
            },
            "Projects": {
                "attachables": [jobCardAttachable]
            }
        },
        dataFields,
        menuActions
    }
}];
