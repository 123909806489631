import {
    addDrawingType,
    editDrawingType
} from "./actions";

export default {
    "label": "Drawing Types",
    "key": "DrawingTypes",
    "location": "Database/DrawingTypes/",
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "actions": [{
        "label": "Add Type",
        "action": addDrawingType
    }],
    "rowActions": [{
        "label": "Edit Type",
        "action": editDrawingType
    }]
}
