import {
    addSOW
} from "./actions";

export default {
    "label": "SOWs",
    "key": "SOWs",
    "location": "Database/SOWs/",
    "actions": [{
        "label": "Add SOW",
        "action": addSOW
    }],
    "headers": [{
        "headerName": "Job Card",
        "field": "JobCard"
    }, {
        "headerName": "Job Card Title",
        "field": "Title"
    }, {
        "headerName": "Job Card Colour",
        "field": "Colour"
    }, {
        "headerName": "Parent",
        "field": "Parent"
    }],
    "rowActions": [{
        "label": "Open SOW",
        async action({ db, rowItem }) {
            let url = await db.download(rowItem.File);
            window.open(url, "__blank");
        }
    }]
};
