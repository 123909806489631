<template>
  <div class="m-2 flex flex-row items-center">
    <div
      :class="`p-4 mr-4 rounded shadow cursor-pointer ${
        yes ? 'bg-green-300' : 'bg-gray-100'
      }`"
      @click="toggleYes"
    >
      &#10004;
    </div>
    <div
      :class="`p-4 rounded shadow cursor-pointer ${
        no ? 'bg-red-100' : 'bg-gray-100'
      }`"
      @click="toggleNo"
    >
      &#10006;
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";
import { get } from "lodash";
export default defineComponent({
  name: "BooleanFilter",
  data() {
    return {
      yes: false,
      no: false,
    };
  },
  methods: {
    updateFilter() {
      this.params.filterChangedCallback();
    },

    doesFilterPass(params) {
      const valueTtoFilter = get(params.data, this.params.column.colId);
      return valueTtoFilter === (this.yes ? true : false);
    },

    isFilterActive() {
      return this.active;
    },

    toggleYes() {
      this.yes = !this.yes;
      this.no = false;
      this.updateFilter();
    },

    toggleNo() {
      this.no = !this.no;
      this.yes = false;
      this.updateFilter();
    }
  },
  computed: {
    active() {
      return this.yes || this.no;
    },
  },
});
</script>
