import { appConfig, pad, standardDateLocal } from "../../utils";

import { tooltip as overallRiskTooltip } from "./overallRiskTooltip";

const likelihoods = [{
    "label": "0 - Undecided",
    "id": "0 - Undecided"
}, {
    "label": "1 - Extremely Improbable",
    "id": "1 - Extremely Improbable"
}, {
    "label": "2 - Improbable",
    "id": "2 - Improbable"
}, {
    "label": "3 - Remote",
    "id": "3 - Remote"
}, {
    "label": "4 - Occasional",
    "id": "4 - Occasional"
}, {
    "label": "5 - Frequent",
    "id": "5 - Frequent"
}];

const consequences = [{
    "label": "0 - Undecided",
    "id": "0 - Undecided"
}, {
    "label": "A - Negligible",
    "id": "A - Negligible"
}, {
    "label": "B - Minor",
    "id": "B - Minor"
}, {
    "label": "C - Major",
    "id": "C - Major"
}, {
    "label": "D - Hazardous",
    "id": "D - Hazardous"
}, {
    "label": "E - Catastrophic",
    "id": "E - Catastrophic"
}];

const yesNoUnknown = [{
    "label": "Yes",
    "id": "Yes"
}, {
    "label": "No",
    "id": "No"
}, {
    "label": "Unknown",
    "id": "Unknown"
}];

export function getRiskColor(likelihood, severity) {

    // Convert severity to uppercase to make it case-insensitive
    severity = severity?.toUpperCase() || "0";

    let green = "GREEN - Acceptable risk levels";
    let amber = "AMBER - Tolerable risk levels";
    let red = "RED - Unacceptable risk levels";

    // Define the risk matrix based on the image
    const riskMatrix = {
        5: { A: amber, B: red, C: red, D: red, E: red },
        4: { A: green, B: amber, C: red, D: red, E: red },
        3: { A: green, B: green, C: amber, D: red, E: red },
        2: { A: green, B: green, C: green, D: amber, E: amber },
        1: { A: green, B: green, C: green, D: green, E: green }
    };

    // Ensure the likelihood and severity are valid
    if (likelihood < 1 || likelihood > 5 || !riskMatrix[likelihood]?.hasOwnProperty(severity)) {
        return "Select a likelihood and consequence";
    }

    if (likelihood === 0 || severity === "0") {
        return "Select a likelihood and consequence";
    };

    // Return the corresponding risk color
    return riskMatrix[likelihood][severity];
};

export const addSafetyManagementForm = ({ form, store, router, db }) => {

    const safteyForm = Object.values(store.state["Database/DocumentTypes"]).find(doc => doc.NameShort === "SRF");
    const nextCount = safteyForm.Count + 1;

    const nextNumber = `${appConfig.docPrefix}${safteyForm.NameShort}-${nextCount.toString().padStart(4, '0')}`;

    form.setConfig({
        "title": "Add Saftey Management Form",
        "type": "push",
        "location": "SafetyManagement/Forms",
        "fields": [{
            "label": "Number",
            "tooltip": "This will be automatically generated",
            "type": "value",
            "value": () => nextNumber,
            "divClass": "md:col-span-1"
        }, {
            "label": "Title",
            "tooltip": "Enter a title that best describes the the saftey event",
            "name": "Title",
            "type": "text",
            "required": true,
            "divClass": "md:col-span-2"
        }, {
            "label": "Category",
            "tooltip": "Select the category that best describes the saftey event",
            "name": "Category",
            "type": "dropdown",
            "dataLocation": "SafetyManagement/Categories",
            "idKey": "Name",
            "labelKey": "Name",
            "required": true,
        }, {
            "label": "Reportable Defect?",
            "tooltip": "Is this defect or occurrence classified as mandatory reportable?",
            "name": "ReportableDefect",
            "type": "dropdown",
            "required": true,
            "dropdownData": yesNoUnknown
        }, {
            "label": "Voluntarily Submitted?",
            "tooltip": "Was this form voluntarily submitted?",
            "name": "VoluntarySubmission",
            "type": "dropdown",
            "required": true,
            "dropdownData": yesNoUnknown
        }, {
            "label": "Date Submitted",
            "tooltip": "Enter the date this form was submitted",
            "name": "Date[ReportSubmitted]",
            "type": "date",
            show({ liveInputData }) {
                return liveInputData.ReportableDefect === "Yes";
            }
        }, {
            "label": "30 Day Update Due",
            "tooltip": "Is a 30 day update required?",
            "type": "value",
            value({ liveInputData }) {
                console.log(liveInputData);

                if (!liveInputData["Date[ReportSubmitted]"]) return "Report Date not set";
                let reportedDate = new Date(liveInputData["Date[ReportSubmitted]"]);
                reportedDate.setDate(reportedDate.getDate() + 30);
                return reportedDate.toLocaleDateString(standardDateLocal);
            },
            show({ liveInputData }) {
                return liveInputData.ReportableDefect === "Yes";
            }
        }, {
            "label": "3 Month Closure Due",
            "tooltip": "Is a 3 month closure required?",
            "type": "value",
            value({ liveInputData }) {
                if (!liveInputData["Date[ReportSubmitted]"]) return "Report Date not set";
                let reportedDate = new Date(liveInputData["Date[ReportSubmitted]"]);
                reportedDate.setMonth(reportedDate.getMonth() + 3);
                return reportedDate.toLocaleDateString(standardDateLocal);
            },
            show({ liveInputData }) {
                return liveInputData.ReportableDefect === "Yes";
            }
        }],
        preSubmitHook() {
            return {
                "Number": nextNumber,
                "Date": {
                    "Created": new Date().toISOString()
                },
                "SubmitterID": store.getters.userID,
                "SafetyManagerConsequence": "0 - Undecided",
                "SafetyManagerLikelihood": "0 - Undecided",
                "SafetyComitteeReport": "No notes provided."
                
            }
        },
        async postSubmitHook({ typeRef, newData, route }) {
            await db.utils.upCount(`Database/DocumentTypes/${safteyForm.ID}/Count`);
            await router.push({ name: "Safety Management Form", params: { id: newData.ID } });
            let newForm = router.currentRoute.value.matched[0].instances.default.form;
            editEventReport({ form: newForm, pageItem: newData, router });
        }
    });
    form.getDropdownData();

    form.toggle();
};

export const editSafetyManagementForm = ({ form, pageItem }) => {
    form.setValues(pageItem);
    form.setConfig({
        "title": "Edit Safety Management Form",
        "type": "update",
        "location": `SafetyManagement/Forms/${pageItem.ID}`,
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text",
            "required": true,
            "divClass": "md:col-span-3"
        }, {
            "label": "Category",
            "name": "Category",
            "type": "dropdown",
            "dataLocation": "SafetyManagement/Categories",
            "idKey": "Name",
            "labelKey": "Name",
            "required": true,
        }, {
            "label": "Reportable Defect?",
            "tooltip": "Is this defect or occurrence classified as mandatory reportable?",
            "name": "ReportableDefect",
            "type": "dropdown",
            "required": true,
            "dropdownData": yesNoUnknown
        }, {
            "label": "Voluntarily Submitted?",
            "tooltip": "Was this form voluntarily submitted?",
            "name": "VoluntarySubmission",
            "type": "dropdown",
            "required": true,
            "dropdownData": yesNoUnknown
        }, {
            "label": "Date Submitted",
            "tooltip": "Enter the date this form was submitted",
            "name": "Date[ReportSubmitted]",
            "type": "date",
            show({ liveInputData }) {
                return liveInputData.ReportableDefect === "Yes";
            }
        }, {
            "label": "30 Day Update Due",
            "tooltip": "Is a 30 day update required?",
            "type": "value",
            value({ liveInputData }) {
                console.log(liveInputData);

                if (!liveInputData["Date[ReportSubmitted]"]) return "Report Date not set";
                let reportedDate = new Date(liveInputData["Date[ReportSubmitted]"]);
                reportedDate.setDate(reportedDate.getDate() + 30);
                return reportedDate.toLocaleDateString(standardDateLocal);
            },
            show({ liveInputData }) {
                return liveInputData.ReportableDefect === "Yes";
            }
        }, {
            "label": "3 Month Closure Due",
            "tooltip": "Is a 3 month closure required?",
            "type": "value",
            value({ liveInputData }) {
                if (!liveInputData["Date[ReportSubmitted]"]) return "Report Date not set";
                let reportedDate = new Date(liveInputData["Date[ReportSubmitted]"]);
                reportedDate.setMonth(reportedDate.getMonth() + 3);
                return reportedDate.toLocaleDateString(standardDateLocal);
            },
            show({ liveInputData }) {
                return liveInputData.ReportableDefect === "Yes";
            }
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const editEventReport = ({ form, pageItem, router }) => {

    form.setValues(pageItem);
    form.setConfig({
        "title": "Edit Event Report",
        "type": "update",
        "location": `SafetyManagement/Forms/${pageItem.ID}`,
        "fields": [{
            "label": "Likelihood",
            "tooltip": "Select the likelihood of this event happening again.",
            "name": "Likelihood",
            "type": "dropdown",
            "required": true,
            "dropdownData": likelihoods
        }, {
            "label": "Consequence",
            "tooltip": "Select the best description of the consequences if this event were to occur.",
            "name": "Consequence",
            "type": "dropdown",
            "required": true,
            "dropdownData": consequences
        }, {
            "label": "User Report / Description of Event",
            "tooltip": "Enter a detailed description of the event, including all relevant information.",
            "name": "UserReport",
            "type": "richtext",
            "required": true,
            "divClass": "md:col-span-3"
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const editSafetyManagerReport = ({ form, pageItem, store }) => {
    form.setValues(pageItem);
    form.setConfig({
        "title": "Edit Safety Manager Report",
        "type": "update",
        "location": `SafetyManagement/Forms/${pageItem.ID}`,
        "fields": [{
            "label": "Likelihood",
            "name": "SafetyManagerLikelihood",
            "type": "dropdown",
            "required": true,
            "dropdownData": likelihoods
        }, {
            "label": "Consequence",
            "name": "SafetyManagerConsequence",
            "type": "dropdown",
            "required": true,
            "dropdownData": consequences
        }, {
            "label": "Overall Risk",
            "type": "value",
            "tooltip": overallRiskTooltip,
            value: ({ liveInputData }) => getRiskColor(liveInputData?.SafetyManagerLikelihood?.split(" - ")[0], liveInputData?.SafetyManagerConsequence?.split(" - ")[0])
        }, {
            "label": "Safety Manager Report / Notes",
            "name": "SafetyManagerReport",
            "type": "richtext",
            "required": true,
            "divClass": "md:col-span-3"
        }],
        preSubmitHook({ liveInputData }) {
            return {
                "Date": {
                    "Reviewed": new Date().toISOString()
                },
                "SafetyManagerID": store.getters.userID,
                "ConsequnceValue": liveInputData.SafetyManagerConsequence.split(" - ")[0],
                "LikelihoodValue": parseInt(liveInputData.SafetyManagerLikelihood.split(" - ")[0])
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editSafetyComitteeReport = ({ form, pageItem, store }) => {
    form.setValues({
        "SafetyComitteeReport": pageItem.SafetyComitteeReport || "No notes provided.",
        ...pageItem
    });
    form.setConfig({
        "title": "Edit Safety Comittee Report",
        "type": "update",
        "location": `SafetyManagement/Forms/${pageItem.ID}`,
        "description": "By submitting this form you are agreeing to the safety manager's report and the likelihood and consequence of this event.",
        "fields": [
            {
                "label": "Safety Comittee Notes",
                "name": "SafetyComitteeReport",
                "type": "richtext",
                "required": true,
                "divClass": "md:col-span-3"
            }],
        preSubmitHook() {
            return {
                "Date": {
                    "ComitteeReviewed": new Date().toISOString()
                },
                "SafetyComitteeID": store.getters.userID
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const addRiskRegisterForm = ({ form, store, router, db }) => {
    const nextRevision = 1;
    const nextRevisionID = `--${pad(nextRevision, 2)}`;
    
    form.setConfig({
        "title": "Add Risk Register Form",
        "type": "push",
        "location": "SafetyManagement/RiskRegister",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}RR-${pad(store.state.counts.riskreg.count)}`
            }
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text",
        },{
            "label": "File",
            "name": "File",
            "type": "file",
            "required": true
        }],
        preSubmitHook() {
            return {
                "Number": `${appConfig.docPrefix}RR-${pad(store.state.counts.riskreg.count)}`,
            }
        },
        async postSubmitHook({ files, typeRef }) {
            for (const file of files) {
                await db.upload(`Database/RiskReg/${typeRef.key}/Revisions/${nextRevisionID}`, file[0]);
            }

            await db.update(`SafetyManagement/RiskRegister/${typeRef.key}/`, {
                "Revision": nextRevision,
                "RevisionID": nextRevisionID,
                [`Revisions/${nextRevisionID}`]: {
                    "Revision": nextRevision,
                    "By": store.state.user.Basic.name,
                    "Date": new Date().toISOString(),
                    "ID": nextRevisionID,
                    "File": files[0][0].name,
                }
            });

            await db.utils.upCount("counts/riskreg/count");
            return;
        }
    });
    form.toggle();
};

export const reviseRiskRegister = ({ form, pageItem, store, db }) => {
    const nextRevision = parseInt(pageItem.Revision) + 1;
    const nextRevisionID = `--${pad(nextRevision, 2)}`;

    form.setValues(pageItem);
    form.setConfig({
        "title": "Revise Risk Register Form",
        "type": "update",
        "location": `SafetyManagement/RiskRegister/${pageItem.ID}`,
        "fields": [{
            "label": "Next Revision",
            "type": "value",
            value() {
                return nextRevision;
            } 
        },{
            "label": "File",
            "name": "File",
            "type": "file",
            "required": true
        }],
        async postSubmitHook({ files  }) {
            for (const file of files) {
                await db.upload(`Database/RiskReg/${pageItem.ID}/Revisions/${nextRevisionID}`, file[0]);
            }

            await db.update(`SafetyManagement/RiskRegister/${pageItem.ID}/`, {
                "Revision": nextRevision,
                "RevisionID": nextRevisionID,
                [`Revisions/${nextRevisionID}`]: {
                    "Revision": nextRevision,
                    "Date": new Date().toISOString(),
                    "By": store.state.user.Basic.name,
                    "ID": nextRevisionID,
                    "File": files[0][0].name,
                }
            });

            return;
        }
    });
    form.toggle();
}


export const addSafteyObjectiveResiterForm = ({ form, store, router, db }) => {
    const nextRevision = 1;
    const nextRevisionID = `--${pad(nextRevision, 2)}`;

    form.setConfig({
        "title": "Add Saftey Objective Resiter Form",
        "type": "push",
        "location": "SafetyManagement/SafteyObjectiveResiter",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}SOR-${pad(store.state.counts.safeobj.count)}`
            }
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text",
        }, {
            "label": "File",
            "name": "File",
            "type": "file",
            "required": true
        }],
        preSubmitHook() {
            return {
                "Number": `${appConfig.docPrefix}SOR-${pad(store.state.counts.safeobj.count)}`,
            }
        },
        async postSubmitHook({ files, typeRef }) {
            for (const file of files) {
                await db.upload(`Database/SafteyObjectiveResiter/${typeRef.key}/Revisions/${nextRevisionID}`, file[0]);
            }

            await db.update(`SafetyManagement/SafteyObjectiveResiter/${typeRef.key}/`, {
                "Revision": nextRevision,
                "RevisionID": nextRevisionID,
                [`Revisions/${nextRevisionID}`]: {
                    "Revision": nextRevision,
                    "By": store.state.user.Basic.name,
                    "Date": new Date().toISOString(),
                    "ID": nextRevisionID,
                    "File": files[0][0].name,
                }
            });

            await db.utils.upCount("counts/safeobj/count");
            return;
        }
    });
    form.toggle();
};

export const reviseSafteyObjectiveResiter = ({ form, pageItem, store, db }) => {
    const nextRevision = parseInt(pageItem.Revision) + 1;
    const nextRevisionID = `--${pad(nextRevision, 2)}`;

    form.setValues(pageItem);
    form.setConfig({
        "title": "Revise Saftey Objective Resiter Form",
        "type": "update",
        "location": `SafetyManagement/SafteyObjectiveResiter/${pageItem.ID}`,
        "fields": [{
            "label": "Next Revision",
            "type": "value",
            value() {
                return nextRevision;
            }
        }, {
            "label": "File",
            "name": "File",
            "type": "file",
            "required": true
        }],
        async postSubmitHook({ files }) {
            
            for (const file of files) {
                await db.upload(`Database/SafteyObjectiveResiter/${pageItem.ID}/Revisions/${nextRevisionID}`, file[0]);
            };

            await db.update(`SafetyManagement/SafteyObjectiveResiter/${pageItem.ID}/`, {
                "Revision": nextRevision,
                "RevisionID": nextRevisionID,
                [`Revisions/${nextRevisionID}`]: {
                    "Revision": nextRevision,
                    "Date": new Date().toISOString(),
                    "By": store.state.user.Basic.name,
                    "ID": nextRevisionID,
                    "File": files[0][0].name,
                }
            });

            return;
        }
    });
    form.toggle();
}
