<template>
  <div ref="contextMenu" class="context-menu">
    <div
          class="cursor-pointer flex flex-row items-center mx-2 my-1"
          @click="hide"
          style="direction: ltr"
        >
          <p class="flex-grow text-text font-medium p-1 text-sm">{{state.menuTitle}}</p>
          <font-awesome-icon icon="times" class="text-red"></font-awesome-icon>
        </div>
        <hr class="w-full my-1" />
    <div
      v-for="(action, index) in state.actions"
      :key="`_contextt_action_${index}`"
    >
      <hr class="w-full my-1" v-if="action.break" />
      <div
        :class="`context-menu-action-item ${action.class}`"
        @click="action.action(state.contextData)"
        v-if="action.canShow[0]"
      >
        {{ action.label }}
      </div>
      <div
        v-if="!action.canShow[0]"
        class="flex flex-row items-center rounded pl-2 context-menu-action-item"
        v-tooltip="{ content: action.canShow[1] }"
      >
        <font-awesome-icon
          class="text-gray-300"
          icon="lock"
        ></font-awesome-icon>
        <div class="ml-2 text-gray-300">{{ action.label }}</div>
      </div>
    </div>
    <div v-show="!state.actions || state.actions.length < 1">
      <div class="flex flex-row items-center rounded pl-2 context-menu-action-item pointer-events-none">
        <font-awesome-icon class="text-gray-300" icon="lock"></font-awesome-icon>
        <div class="ml-2 text-gray-300">No actions available</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";
import { get } from "lodash";

const contextMenu = ref(null);

const store = useStore();

const user = computed(() => store.state.user);

// The function `normalizePosition` takes in two arguments: mouseX and mouseY, which represent the x and y coordinates of the mouse pointer
const normalizePosition = (mouseX, mouseY) => {
  // Select the body element as the container (scope)
  const scope = document.querySelector("body");

  // Compute the mouse position relative to the container element (scope) by subtracting the offset of the scope from the mouse coordinates
  let { left: scopeOffsetX, top: scopeOffsetY } = scope.getBoundingClientRect();

  // Check if the offset on the x-axis is negative and set it to zero if it is
  scopeOffsetX = scopeOffsetX < 0 ? 0 : scopeOffsetX;

  // Check if the offset on the y-axis is negative and set it to zero if it is
  scopeOffsetY = scopeOffsetY < 0 ? 0 : scopeOffsetY;

  // Compute the mouse position within the scope by subtracting the scope offset from the mouse coordinates
  const scopeX = mouseX - scopeOffsetX;
  const scopeY = mouseY - scopeOffsetY;

  // Check if the context menu will go out of bounds on the x-axis
  const outOfBoundsOnX =
    scopeX + contextMenu?.value?.clientWidth > scope.clientWidth;

  // Check if the context menu will go out of bounds on the y-axis
  const outOfBoundsOnY =
    scopeY + contextMenu?.value?.clientHeight > scope.clientHeight;

  let normalizedX = mouseX;
  let normalizedY = mouseY;

  // Normalize the x-coordinate of the context menu if it goes out of bounds on the x-axis
  if (outOfBoundsOnX) {
    normalizedX =
      scopeOffsetX + (scope.clientWidth - contextMenu?.value?.clientWidth) - 20;
  }

  // Normalize the y-coordinate of the context menu if it goes out of bounds on the y-axis
  if (outOfBoundsOnY) {
    normalizedY =
      scopeOffsetY + (scope.clientHeight - contextMenu?.value?.clientHeight) - 20;
  }

  // Return an object with the normalized x and y coordinates
  return { normalizedX, normalizedY };
};

let state = reactive({
  contextData: {},
  actions: [],
  menuTitle: "Menu"
});

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms)); 

const toggle = async (e, attachedItem, contextData, menuTitle = "Menu") => {
  // First loaded the actions and context data in the component state
  let actionsToShow = [...attachedItem?.rowActions];
  state.contextData = contextData;
  state.menuTitle = menuTitle;

  for(let i = 0; i < actionsToShow.length; i++) {
      actionsToShow[i].canShow = await canShow(actionsToShow[i]);
  }

  state.actions = actionsToShow;
  
  contextMenu?.value?.classList.add("visible");
  //   Get the best location for the context menu and render
  await wait(10)
  const { 
    clientX: mouseX, 
    clientY: mouseY 
  } = e.event;

  const { 
    normalizedX, normalizedY 
  } = normalizePosition(mouseX, mouseY);
  
  contextMenu.value.style.top = `${normalizedY}px`;
  contextMenu.value.style.left = `${normalizedX}px`;


  const body = document.querySelector("body");
  body.addEventListener(
    "mouseup", hide,
    {
      once: true,
    }
  );
};

const hide = () => {
  contextMenu?.value?.classList.remove("visible");
};

const canShow = async (action) => {
  if (action.security && get(user.value.Security, action.security, false) === false) return [false, "You cannot perform this action."]
  if (action.show) return await action.show(state.contextData);
  else return [true, ""];
};

defineExpose({
  toggle,
});
</script>
