import NonconCategiriesAttachable from "./NonconCategories/attachable";
import NonconLevelsAttachable from "./NonconLevels/attachable";
import ReportTypes from "./ReportTypes/attachable";
import Settings from "./Settings.vue";

export default [{
    "path": "/Audits/Settings",
    "component": Settings,
    "name": "Audits Settings",
    "meta": {
        "app": "Audits",
        "name": "Audits Settings",
        "sideNav": true,
        "sideNavSection": "Settings",
        title() {
            return "Audits Settings"
        },
        "dataFields": [],
        "menuActions": [],
        "attachedItems": [ReportTypes, NonconLevelsAttachable, NonconCategiriesAttachable]
    }
}];
