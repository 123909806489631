import {
    linkCoreProcedure
} from "./actions";

const actions = [{
    "label": "Link Core Procedure",
    "action": linkCoreProcedure,
    show({ router }) {
        return [router.currentRoute.meta.parentType ];
    }
}];

const headers = [{
    "headerName": "Title",
    "field": "Title"
}];

const rowActions = [{
    "label": "View Core Procedure",
    action({ router, rowItem }) {
        router.push(`/Audits/CoreProcedures/${rowItem.ID}`)
    }
}];

export default {
    "label": "Core Procedures",
    "key": "CoreProcedures",
    "location": "Audits/CoreProcedures/",
    actions: [],
    rowActions,
    headers
};

