<template>
  <form ref="form">
    <slot></slot>
  </form>
</template>
<script setup="props">
import serialize from "form-serialize";
import { ref, onMounted, defineEmits } from "vue";

// Get the form reference
let form = ref();

// setup the emitter
const emit = defineEmits(['submit']);

onMounted(() => {
  form.value.addEventListener("submit", (e) => {
    e.preventDefault();
    const data = serialize(form.value, { hash: true });
    emit("submit", data);
  });
});

defineExpose({
  form
})
</script>
