import {
    editArrangement,
    linkArrangement
} from "./actions";

export default {
    "label": "Linked Arrangements",
    "key": "Arrangements",
    "location": "Database/Arrangements/",
    "actions": [
        {
            "label": "Link Arrangement",
            "action": linkArrangement,
            show({ meta }) {
                return [true, ''];
            }
        }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Requested",
        "field": "Requested"
    }, {
        "headerName": "With",
        "field": "With"
    }, {
        "headerName": "Revision",
        "field": "Revision"
    }
],
    "rowActions": [{
        "label": "Open Arrangement",
        action({ router, rowItem }) {
            router.push(`/Database/Arrangements/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Arrangement",
        "action": editArrangement
    }]
};
