export const revisionQuality = {
    "Approver": {
        "By": "N/A",
        "Completed": false,
        "Date": "N/A"
    },
    "Checker": {
        "By": "N/A",
        "Completed": false,
        "Date": "N/A"
    },
    "Compiler": {
        "By": "N/A",
        "Completed": false,
        "Date": "N/A"
    },
    "StressChecker": {
        "By": "N/A",
        "Completed": true,
        "Date": "N/A"
    }
};
