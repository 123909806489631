import {
    accept,
    addNewApplicationUser,
    approve,
    reappove,
    sendApprovalEmail,
    setLimitations,
    updateApplication,
    uploadFile
} from "./actions";
import {
    emergencyContact,
    flightHours,
    flightTestPermissions,
    generatedFiles,
    licenceAndRatings,
    notes,
    uploadedFiles
} from "./internalAttachables";
import ItemListView from "../../components/ItemListView/ItemListView.vue"
import ItemView from "../../components/ItemView/ItemView.vue"
import classFinder from "../../../../resources/classes/FlightTestCrew/classFinder";
import { get } from "lodash";
import { standardDateLocal } from "../../utils.js";

const dataFields = [{
    "label": "Name",
    compute({ item }) {
        return `${item?.raw?.User?.FirstName} ${item?.raw?.User?.LastName}`
    }
}, {
    "label": "Email",
    "key": "Email"
}, {
    "label": "Date of Birth",
    "key": "DOB",
    "type": "date"
}, {
    "label": "Role",
    "key": "Role"
}, {
    "label": "Next Revalidation Date",
    compute({ item }) {
        return `${(new Date(item.NextExpiry)).toLocaleDateString("en-gb", standardDateLocal)} - ${item.NextExpiryReason}`
    },
    show({ pageItem }) {
        return pageItem?.Approved === true;
    }
}, {
    "label": "CRM Completed Date",
    compute({ item }) {

        if (item.CRM?.Date) {
            return (new Date(item.CRM.Date)).toLocaleDateString("en-gb", standardDateLocal);
        } else {
            return `<span class="text-orange font-bold">Not Completed</span>`
        }
    },
    show({ pageItem }) {
        return pageItem?.Approved === true;
    }
}, {
    "label": "Accepted",
    compute({ item }) {
        return item?.Accepted ? `By ${item?.AcceptedBy || "UNKNOWN"} on ${(new Date(item?.Date?.Accepted)).toLocaleDateString("en-gb", standardDateLocal)}` : "Not Accepted";
    }
}, {
    "label": "Approved",
    compute({ item }) {
        return item.Approved ? `By ${item.ApprovedBy || "UNKNOWN"} on ${(new Date(item.Date.Approved)).toLocaleDateString("en-gb", standardDateLocal)}` : "Not Approved";
    }
},{
    "label": "Role Experience / Supporting Information",
    "key": "RoleExperience",
    "class": "col-span-3"
}, {
    "label": "Limitations",
    "key": "Limitations",
    "class": "col-span-3"
}, {
    "label": "Link to application, for failed email delivery.",
    "class": "md:col-span-3",
    compute({ item }) {
        return `${window.origin}/___/FlightTestCrew/${item.ID}/ftc-contact`;
    },
    show({ item, store }) {
        return get(store.state, `OpenForms/FlightTestCrew/${item.ID}`, false)
    }
}];

const menuActions = [
    {
        "label": "Update Application",
        "action": updateApplication
    },
    {
        "label": "Set Limitations",
        "action": setLimitations
    },
    {
        "label": "Upload File",
        "break": true,
        "action": uploadFile
    },
    {
        "label": "Accept Application",
        "break": true,
        "action": accept,
        show({ pageItem }) {
            return [!pageItem.Accepted, "This application is already accepted"]
        }
    },
    {
        "label": "Approve Application",
        "action": approve,
        show({ pageItem }) {
            if (!pageItem.Accepted) return [false, "This application has not been accepted."];
            return [!pageItem.Approved, "This application is already approved"]
        }
    },
    {
        "label": "Re-Approve",
        "action": reappove,
        show({ pageItem }) {
            const lastApproved = new Date(pageItem.Date.Approved);
            
            return [pageItem.Approved && (new Date()).getTime() > (lastApproved.setMonth(lastApproved.getMonth() + 12)), "This application is not due for re-approval"]


        }
    },
    {
        "label": "Send Approval Email",
        "break": true,
        "action": sendApprovalEmail,
        show({ pageItem }) {
            return [pageItem.Approved, "This application is not approved"]
        }
    }
];

export default [{
    "path": "/FlightTestCrew/Applications",
    "component": { ...ItemListView },
    "name": "Approved Applications",
    "meta": {
        "app": "FlightTestCrew",
        "name": "Approved Applications",
        "sideNav": true,
        "sideNavSection": "Approved Applications",
        "appSwitcher": true,
        "appSwitcherPosition": 3,
        "security": "FlightTest.Read",
        "icon": "plane",
        "tableSettings": "FlightTestAutomation",
        "dataPath": "FlightTestCrew",
        filter(i) {
            i = classFinder(i);
            return (i.Approved === true && i.Completed === true) && (new Date()).getTime() < (new Date(i.NextExpiry)).getTime();
        },
        "selectionRoute": "FlightTestCrew/Applications/{ID}",
        "menuActions": [{
            "label": "Add New Application User",
            "action": addNewApplicationUser
        
        }]
    }
}, {
    "path": "/FlightTestCrew/Expired",
    "component": { ...ItemListView },
    "name": "Expired Applications",
    "meta": {
        "app": "FlightTestCrew",
        "name": "Expired Applications",
        "sideNav": true,
        "sideNavSection": "Approved Applications",
        "appSwitcher": false,
        "tableSettings": "FlightTestAutomation",
        "dataPath": "FlightTestCrew",
        filter(i) {
            i = classFinder(i);
            return (i.Approved === true && i.Completed === true) && (new Date()).getTime() > (new Date(i.NextExpiry)).getTime();
        },
        "selectionRoute": "FlightTestCrew/Applications/{ID}"
    }
}, {
    "path": "/FlightTestCrew/Unapproved",
    "component": { ...ItemListView },
    "name": "Awaiting Approval",
    "meta": {
        "app": "FlightTestCrew",
        "name": "Awaiting Approval",
        "sideNav": true,
        "sideNavSection": "Unapproved Applications",
        "appSwitcher": false,
        "tableSettings": "FlightTestAutomation",
        "dataPath": "FlightTestCrew",
        filter(i) {
            i = classFinder(i);
            return i.Approved === false && i.Completed === true && i.Accepted === true;
        },
        "selectionRoute": "FlightTestCrew/Applications/{ID}"
    }
}, {
    "path": "/FlightTestCrew/AwaitingReview",
    "component": { ...ItemListView },
    "name": "Awaiting Review",
    "meta": {
        "app": "FlightTestCrew",
        "name": "Awaiting Review",
        "sideNav": true,
        "sideNavSection": "Unapproved Applications",
        "appSwitcher": false,
        "tableSettings": "FlightTestAutomation",
        "dataPath": "FlightTestCrew",
        filter(i) {
            i = classFinder(i);
            return i.Completed === true && !i.Accepted;
        },
        "selectionRoute": "FlightTestCrew/Applications/{ID}"
    }
}, {
    "path": "/FlightTestCrew/Applications/:id",
    "component": ItemView,
    "name": "Application",
    "meta": {
        "app": "FlightTestCrew",
        "name": "Application",
        "dataPathOverride": "FlightTestCrew/:id",
        "selectionRoute": "FlightTestCrew/Applications/{ID}",
        itemOverride(pageItem) {
            if (!pageItem) return null;
            return classFinder(pageItem)
        },
        title(item) {
            return `Applicant: ${item.raw.User.FirstName} ${item.raw.User.LastName}`
        },
        "attachedItems": {
            "Data Sections": {
                "attachables": [generatedFiles, uploadedFiles, flightTestPermissions, licenceAndRatings, flightHours,  emergencyContact("FlightTestCrew"), notes]
            }
        },
        "dataFields": dataFields,
        "menuActions": menuActions
    }
}];

// , {
//     "path": "/FlightTestCrew/Incomplete",
//         "component": { ...ItemListView },
//     "name": "Incomplete Reviews",
//         "meta": {
//      
//             "app": "FlightTestCrew",
//                 "name": "Incomplete Review",
//                     "sideNav": true,
//                         "sideNavSection": "Incomplete Applications (failed email delivery)",
//                             "appSwitcher": false,
//                                 "tableSettings": "FlightTestAutomation",
//                                     "dataPath": "OpenForms/FlightTestCrew",
//                                         filter(i) {
//             return !i.Completed;
//         },
//         "selectionRoute": "FlightTestCrew/Incomplete/{ID}"
//     }
// }, {
//     "path": "/FlightTestCrew/Incomplete/:id",
//         "component": ItemView,
//             "name": "Incomplete Application",
//                 "meta": {
//      
//             "app": "FlightTestCrew",
//                 "name": "Incomplete Application",
//                     "dataPathOverride": "OpenForms/FlightTestCrew/:id",
//                         "selectionRoute": "FlightTestCrew/Applications/{ID}",
//                             title(item) {
//             return `Applicant: ${item.User.Email}`
//         },
//         "attachedItems": {

//         },
//         "dataFields": [{
//             "label": "Link to application, for failed email delivery.",
//             "class": "md:col-span-3",
//             compute({ item }) {
//                 return `${window.origin}/___/FlightTestCrew/${item.ID}/ftc-contact`;
//             }
//         }],
//             "menuActions": [{
//                 "label": "Delete Application",
//                 "action": deleteIncompleteApplication,
//                 "break": true,
//                 "class": "text-red"
//             }]
//     }
// }
