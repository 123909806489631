export const addTrainingVideo = ({ form, db }) => {
    form.setConfig({
        "title": "Add Training Video",
        "type": "push",
        "location": "TrainingVideos/",
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text",
            "required": true
        }, {
            "label": "Description",
            "name": "Description",
            "type": "text",
            "required": true
        }, {
            "label": "File",
            "name": "File",
            "type": "file",
            "required": true
        }],
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.upload(`TrainingVideos`, file[0]);
            };
        }
    });
    form.toggle();
};

export const deleteTrainingVideo = ({ form, item }) => {
    form.setValues(item);

    form.setConfig({
        "title": "Delete Tester Type",
        "type": "delete",
        "location": `TrainingVideos/${item.ID}`,
        "description": "Are you sure you want to delete this tester type?"
    });
    form.toggle();
};
