<template>
    <div class="fixed top-0 bottom-0 left-0 right-0 z-top z-50 pb-12"
        style="background: rgba(0, 0, 0, 0.3); overflow-y: auto" v-if="state.open">
        <div class="mx-auto container">
            <div class="bg-white rounded shadow p-2 border border-gray-100 opacity-100 mt-12 mx-auto">
                <div class="flex flex-row items-center">
                    <p class="p-2 font-medium flex-grow text-primary">
                        {{ state.title }}
                    </p>
                    <IconButton icon="times" @click="toggle" class="cancel" />
                </div>
                <video class="mx-auto" :src="state.src" controls preload="none"></video>
            </div>
        </div>
    </div>
</template>
<script setup>
import {
    reactive,
    defineExpose
} from "vue";

import {
    IconButton
} from "@comp/index.js";

defineProps({
    src: String
});


let state = reactive({
    open: false,
    loading: false,
    src: "",
    title: ""
});

const emit = defineEmits(["submitted"]);

const toggle = () => {
    state.open = !state.open;
};

const setSrc = (src) => {
    state.src = src;
};

const setTitle = (title) => {
    state.title = title;
};

defineExpose({
    toggle,
    setSrc,
    setTitle
});
</script>
