import {
    addMiscellaneous,
    check,
    editMiscellaneous,
    linkMiscellaneous,
    removeMiscellaneous,
    reviseMiscellaneous,
    uploadFile
} from "./actions";



const actions = [{
    "label": "Add Miscellaneous",
    "action": addMiscellaneous
}, {
    "label": "Link Miscellaneous",
    "action": linkMiscellaneous
}];

const headers = [{
    "headerName": "Type",
    "field": "Type"
}, {
    "headerName": "Number",
    "field": "Number"
}, {
    "headerName": "Title",
    "field": "Title"
}, {
    "headerName": "Revision",
    "field": "Revision"
}, {
    "headerName": "Checked",
    "field": "Checked",
    "type": "bool"
}, {
    "headerName": "Approved",
    "field": "Approved",
    "type": "bool"
    }, {
        "headerName": "Parent",
        "field": "Parent",
        "type": "text"
    }];

const rowActions = [{
    "label": "View Miscellaneous",
    action({ router, rowItem }) {
        router.push(`/Database/Miscellaneous/${rowItem.ID}`)
    }
}, {
    "break": true,
    "label": "Edit Miscellaneous",
    "action": editMiscellaneous
}, {
    "label": "Revise Miscellaneous",
    "action": reviseMiscellaneous
}, {
    "label": "Upload Miscellaneous File",
    "action": uploadFile,
    "break": true
    }, {
        "label": "Check Miscellaneous",
        "action": check("Checked"),
    "break": true,

    }, {
        "label": "Approve Miscellaneous",
        "action": check("Approved"),
    }, {
        "label": "Download Miscellaneous File",
        "break": true,
        async action({ toast, db, item }) {
        if (!item.File) {
            toast.error("No file found");
            return;
        }
        let url = await db.download(`Database/Miscellaneous/${item.ID}/${item.File}`)
        window.open(url, "__blank");
    }
}, {
    "break": true,
    "label": "Remove Miscellaneous",
    "action": removeMiscellaneous
}];

const warn = type => ({ items, store }) => {
    
    let _items = items.map(i => store.state[i]).filter(i => !!i).filter(i => i.Type === type);

    let warningMessage = "";
    
    _items.forEach(item => {
        if(!item.Checked) {
            warningMessage += `${item.Number} not Checked.<br/>`;
            return; 
        }
        if (!item.Approved) {
            warningMessage += `${item.Number} not Approved.<br/>`;
            return;
        }
    });
    
    return [warningMessage !== "", warningMessage]
}

export const thirdPartyDocs = {
    "label": "3<sup>rd</sup> Party Documents",
    "id": "miscdocs",
    "key": "Miscellaneous",
    "location": "Database/Miscellaneous/",
    dataFilter(item) {
        
        return item?.Type === "Document"
    },
    actions,
    rowActions,
    headers,
    warn: warn("Document")
};

export const thirdPartyDraws = {
    "label": "3<sup>rd</sup> Party Drawings",
    "id": "miscdrwss",
    "key": "Miscellaneous",
    "location": "Database/Miscellaneous/",
    dataFilter(item) {
        return item?.Type === "Drawing"
    },
    actions,
    rowActions,
    headers,
    warn: warn("Drawing")
};

export const thirdPartyOther = {
    "label": "3<sup>rd</sup> Party Miscellaneous Items",
    "id": "miscother",
    "key": "Miscellaneous",
    "location": "Database/Miscellaneous/",
    dataFilter(item) {
        return item?.Type === "Other"
    },
    actions,
    rowActions,
    headers,
    warn: warn("Other")
};

