import { revisions as _revisions, time } from "../../standardAttachables";
import {
    cancelDrawing,
    checkDrawing,
    editDrawing,
    revisionRollback,
    switchParent,
    toggleStressCheckRequirement
} from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import conAt from "../Concessions/attachable";
import designAttachables from "../DesignChanges/attachable";
import doiAt from "../DOIs/attachable";
import dqnAt from "../DQNs/attachable";
import flightAttachables from "../FlightConditions/attachable";
import sbAt from "../ServiceBulletins/attachable";

const revisions = {
    ..._revisions,
    "headers": [
        ..._revisions.headers,
        {
            "headerName": "Compiler",
            "field": "Quality.Compiler.By"
        }, {
            "headerName": "Checker",
            "field": "Quality.Checker.By"
        }, {
            "headerName": "Approver",
            "field": "Quality.Approver.By"
        }, {
            "headerName": "Stress Checked",
            "field": "Quality.StressChecker.By"
        }
    ],
    "actions": [{
        "label": "Rollback Revision",
        "action": revisionRollback,
        show({ pageItem }) {
            return [Object.keys(pageItem.Revisions).length > 1, "No previous revisions found"];
        }
    }],
    "rowActions": [{
        "label": "Download Archive File",
        async action({ db, pageItem, rowItem, toast }) {
            try {
                let rev = rowItem.Revision;
                let url = await db.download(`Database/Drawings/${pageItem.ID}/${pageItem.Number}_${rev}.pdf`);
                window.open(url, "__blank")
            } catch (err) {
                toast.error("Archive file found");
            }
        }
    }]
};

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Parent",
    "key": "Parent",
    "link": "Database/:ParentType/:ParentID"
}, {
    "label": "Mod Type",
    "key": "Type"
}, {
    "label": "Date Created",
    "key": "Date.Created"
}, {
    "label": "Date Completed",
    "key": "Date.Completed"
}, {
    "label": "Title",
    "key": "Title",
    "class": "md:col-span-3"
}];

const menuActions = [
    {
        "label": "Edit Drawing",
        "action": editDrawing
    },
    {
        "label": "Compiler Check",
        "action": checkDrawing("Compiler"),
        "break": true,
        show({ item }) {
            return item.Compiler === true ? [false, "Already Complier Checked"] : [true, ""];
        }
    },
    {
        "label": "Checker Check",
        "action": checkDrawing("Checker"),
        show({ item }) {
            return item.Checker === true ?
                [false, "Already Complier Checked"] :
                !item.Compiler ?
                    [false, "Drawing not checked by the complier"] :
                    [true, ""]
        }
    },
    {
        "label": "Approver Check",
        "action": checkDrawing("Approver"),
        show({ item }) {
            return item.Approver === true ?
                [false, "Already Approver Checked"] :
                !item.Checker ?
                    [false, "Drawing not checked by the checker"] :
                    [true, ""];
        }
    },
    {
        "label": "Stress Check",
        "action": checkDrawing("StressChecker"),
        show({ item }) {
            return item.RequiresStressCheck === false ?
                [false, "Drawing does not require stress checking."] :
                item.StressChecker === true ?
                    [false, "Already stress checked"] :
                    [true, ""];
        }
    },
    {
        "label": "Toggle Stress Check Requirement",
        "action": toggleStressCheckRequirement
    },
    {
        "label": "Change Main Parent",
        "action": switchParent,
        "break": true
    },
    {
        "label": "Cancel Drawing",
        "class": "font-bold text-red",
        "action": cancelDrawing,
        "break": true
    }
];

export default [{
    "path": "/Database/Drawings",
    "component": {...ItemListView},
    "name": "Drawings",
    "meta": {
        "app": "Database",
        "name": "Drawings",
        "sideNav": true,
        "sideNavSection": "Design Items",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "Drawings",
        "dataPath": "Database/Refs/Drawings",
        "selectionRoute": "Database/Drawings/{ID}"
    }
}, {
    "path": "/Database/Drawings/:id",
    "component": ItemView,
    "name": "Drawing",
    "meta": {
        "app": "Database",
        "name": "Drawings",
        "dataPath": "Database/Drawings",
        "selectionRoute": "Database/Drawings/{ID}",
        title(item) {
            return `Drawings: ${item.Number}`
        },
        "attachedItems": {
            "Revisions": {
                "attachables": [revisions]
            },
            "Designs": {
                "attachables": [designAttachables, flightAttachables]
            },
            "Design Items": {
                "attachables": [conAt, dqnAt, doiAt, sbAt]
            },
            "Additional Information": {
                "attachables": [time]
            }
        },
        "dataFields": dataFields,
        "menuActions": menuActions,
        "formMetadata": {
            "ParentType": "Drawings"
        }
    }
}];
