import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

import nonConAttachable from "../SubNonCons/attachables.js";

import {
    addReport,
    completeReport,
    editReport,
    addFile,
    removeFile
} from "./actions.js"

import {
    files as _files
} from "../../standardAttachables.js";


let files = {
    ..._files
};


files.actions = [{
    "label": "Add File",
    "action": addFile
}];

files.rowActions = [{
    "label": "Download File",
    async action({ db, rowItem, pageItem }) {
        let url = await db.download(`Audits/SubReports/${pageItem.ID}/Files/${rowItem.ID}/${rowItem.File}`);
        window.open(url, "fileView", "popup");
    }
}, {
    "label": "Remove File",
    "action": removeFile,
    "break": true,
    "class": "text-red font-bold"
}];

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Title",
    "key": "Title"
}, {
    "label": "Procedure",
    "key": "Procedure",
    "link": "Audits/Procedures/:ProcedureID"
}, {
    "label": "HISM",
    "key": "DAM"
}, {
    "label": "HoD",
    "key": "HOD"
}, {
    "label": "Date Completed",
    "key": "Date.Completed",
    "type": "date"
}, {
    "label": "Report",
    "key": "Comment",
    "class": "col-span-3",
    "grow": true
}];

export default [{
    "path": "/Audits/SubReports",
    "component": {...ItemListView},
    "name": "Sub Reports",
    "meta": {
        "app": "Audits",
        "name": "Sub Reports",
        "sideNav": true,
        "sideNavSection": "Sub Reports",
        "appSwitcher": false,
        "appSwitcherPosition": 3,
        "icon": "check-circle",
        "tableSettings": "Reports",
        "dataPath": "Audits/SubReports",
        "selectionRoute": "Audits/SubReports/{ID}",
        "security": "Audits.Read",
        "menuActions": [{
            "label": "Add Sub Report",
            "action": addReport
        }]
    }
}, {
    "path": "/Audits/SubReports/:id",
    "component": ItemView,
    "name": "Sub Report",
    "meta": {
        "app": "Audits",
        "name": "Sub Report",
        title(item) {
            return `Audit Sub Report: ${item?.Number}`
        },
        "attachedItems": {
            "Non Conformities": {
                "attachables": [nonConAttachable, files]
            }
        },
        "menuActions": [{
            "label": "Edit Sub Report",
            "action": editReport
        }, {
            "label": "Complete Sub Report",
            "action": completeReport,
            show({ attachedItems, store }) {
                return [
                    attachedItems.NonConformities.items.length === 0 ||
                    attachedItems.NonConformities.items.every(i => store.state[i].Completed == true),
                    "Non-Conformities must be all completed."]
            }
        }],
        "dataFields": dataFields,
        "formMetadata": {
            "ParentType": "SubReports"
        }
    }
}];
