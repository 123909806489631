export default {
    "title": "Continuing Airworthiness Maintenance Exposition (CAME) Details",
    "submitButtonText": "Save & Next",
    "fields": [{
        "label": "CAME Reference",
        "name": "came_ref",
        "type": "text"
    }, {
        "label": "CAME Issue Number",
        "name": "came_issue",
        "type": "text"
    }, {
        "label": "CAME Issue Date",
        "name": "came_date",
        "type": "date"
    }]
};
