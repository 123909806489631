import {
    mainTableConfig
} from "./pdfComponents/tableConfigs";
import pdfFonts from "./pdfComponents/fonts";
import pdfMake from "pdfmake/build/pdfmake";
pdfMake.vfs = pdfFonts


pdfMake.tableLayouts = {
    mainTableConfig: mainTableConfig
};

export const downloadPdf = docdef => {
    docdef.defaultStyle = {
        "fontSize": 8
    };
    pdfMake.createPdf(docdef).download();
}
