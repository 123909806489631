import {
    addConcession,
    cancelConcession,
    editConcession,
    linkConcession,
    signoffConcession
} from "./actions";

import store from "../../../store";

function warning(key) {
    let item = store.state[key];
    if (!item || item.Cancelled === true) return false;
    let warningMessage = "";
    if (item && item.Signedoff === false) {
        warningMessage = warningMessage += `${item.Number} is not signedoff.<br>`;
    }
    return warningMessage === "" ? false : warningMessage;
}

const cellClassRules = {
    "text-orange font-bold": params => {
        return warning(params.data);
    }
}


const actions = [{
    "label": "Add Concession",
    "action": addConcession,
    show({ meta }) {
        return [["DesignChanges", "FlightConditions"].includes(meta?.ParentType), "DQNs cannot be added to a Document, they must be linked."]
    }
}, {
    "label": "Link Concession",
    "action": linkConcession
}];

const headers = [{
    "headerName": "Number",
    "field": "Number",
    cellClassRules
}, {
    "headerName": "Comment",
    "field": "Comment",
    cellClassRules
}, {
    "headerName": "Signed Off",
    "field": "Signedoff",
    "type": "bool",
    cellClassRules
}];

const rowActions = [{
    "label": "View Concession",
    action({ router, rowItem }) {
        router.push(`/Database/Concessions/${rowItem.ID}`)
    }
}, {
    "label": "Update Concession",
    "break": true,
    "action": editConcession
}, {
    "label": "Signoff Concession",
    "action": signoffConcession
}, {
    "label": "Cancel Concession",
    "break": true,
    "action": cancelConcession,
    "class": "text-red"
}];

export default {
    "label": "Concessions",
    "key": "Concessions",
    "location": "Database/Concessions/",
    actions,
    rowActions,
    headers,
    warn({ items }) {
        let warningMessage = items.map(warning).filter(i => i !== false).join("");
        return [warningMessage !== "", warningMessage];
    }
};
