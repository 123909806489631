<template>
  <!--  -->

  <Shell :label="label">
    <template v-slot:label>
      <div ref="editorToolbar"></div>
    </template>
    <template v-slot:comp>
      <div :class="`flex flex-col w-full ${fullScreenClass}`">

        <div v-bind="$attrs" ref="inputElement">
        </div>
        <textarea style="height: 1px; background-color: #ddd; color: #ddd; width: 100%" ref="textareaInputElement"
          v-bind="$attrs"></textarea>
        <button type="button" @click="toggleFullScreen" v-if="!readonly">Full Screen Mode {{ state.fullScreen }}</button>
        <button 
          class="fixed top-12 right-12 p-2 m-2 rounded-full shadow bg-red text-white w-12 h-12" 
          type="button"
          @click="toggleFullScreen" 
          v-if="state.fullscreen">X</button>
      </div>
    </template>
  </Shell>
</template>
<script setup="props">
import {
  defineProps,
  defineComponent,
  onMounted,
  ref,
  defineExpose,
  computed,
  reactive,
} from "vue";

import Shell from "./Shell.vue";
import Quill from "quill";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
// import "quill/dist/quill.bubble.css"; // for bubble theme

// define the emiters
let emit = defineEmits(["update:modelValue"]);

let inputElement = ref(null);
let editorToolbar = ref(null);
let textareaInputElement = ref(null);

let state = reactive({
  "fullscreen": false
});

const toggleFullScreen = () => {
  state.fullscreen = !state.fullscreen;
}
const fullScreenClass = computed(() => {
  return state.fullscreen === true ? "fixed top-0 bottom-0 left-0 right-0 bg-white" : "";
});

// disable attributte inheritence
defineComponent({
  inheritAttrs: false,
});

// define the component props
let props = defineProps({
  label: String,
  value: String,
  placeholder: String,
  minimised: Boolean,
  readonly: Boolean,
});

var toolbarOptions = [
  // [{ header: [1, 2, false] }],
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ align: [] }],
  // ['link', 'image']
];

var toolbarOptionsMinimised = [
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [{ list: "ordered" }, { list: "bullet" }],
];

onMounted(() => {
  var editor = new Quill(inputElement.value, {
    modules: {
      toolbar: props.readonly ? null : props.minimised ? toolbarOptionsMinimised : toolbarOptions,
    },
    placeholder: !props.readonly ? (props.placeholder || "type some information here...") : "",
    readOnly: props.readonly || false,
    theme: "snow"
  });

  let div = new RegExp("\<div\>", "mg");

  let divend = new RegExp("\<\\div\>", "mg");

  editor.root.innerHTML = props?.value?.replace(div, "")?.replace(divend, "");

  textareaInputElement.value.value = editor.container.firstChild.innerHTML;

  editor.on("text-change", (delta, oldDelta, source) => {
    textareaInputElement.value.value = editor.container.firstChild.innerHTML;
  });
});

defineExpose({ inputElement });
</script>
