export default {
    "title": "Ongoing Modifications",
    "submitButtonText": "Save & Next",
    "fields": [{
        "label": "Details of any ongoing modifications <br/>(Enter “None” if N/A)",
        "name": "onging_mods",
        "type": "richtext",
        "required": true
    }, {
        "label": "Details of any ongoing repairs other than those specified in the request <br> (Enter “None” if N/A)",
        "name": "ongoing_repairs",
        "type": "richtext",
        "required": true
    }]
}
