<template>
  <div class="rounded shadow p-2 bg-white">

    <p v-html="displayValue"></p>
  </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "HTMLTooltip",
  computed: {
    displayValue() {

        return this.params.value;
    }
  },
});
</script>
