import {
    addCustomer,
    editCustomer,
    removeCustomer
} from "./actions";

export default {
    "label": "Customers",
    "key": "Customers",
    "location": "Database/Customers/",
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "actions": [{
        "label": "Add Customer",
        "action": addCustomer
    }],
    "rowActions": [{
        "label": "Edit Customer",
        "action": editCustomer
    }, {
        "label": "Remove Customer",
        "action": removeCustomer
    }]
}
