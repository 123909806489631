import { next } from "../../../utils";

export const addMiscellaneous = ({ form, pageItem, meta, db }) => {
    form.setConfig({
        "title": "Add Miscellaneous (commas separate to add multiple)",
        "type": "pushMulti",
        "pushMultiKey": "Number",
        "location": "Database/Miscellaneous",
        "description": `Add a Miscellaneous / 3<sup>rd</sup> Party Document or Drawing to ${pageItem.Number}`,
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "dropdownData": [{
                "label": "Document",
                "id": "Document"
            }, {
                "label": "Drawing",
                "id": "Drawing"
            }, {
                "label": "Other",
                "id": "Other"
            }]
        }, {
            "label": "Revision",
            "name": "Revision",
            "type": "text"
        }, {
            "label": "Title",
            "name": "Title",
            "dicClass": "md:col-span-3",
            "type": "text"
        }],
        preSubmitHook({ data }) {
            return {
                "Date": {
                    "Created": new Date().toISOString()
                },
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "ParentType": meta.ParentType,
                "Checked": false,
                "Approved": false,
                "Links": {
                    [pageItem.ID]: {
                        "Type": meta.ParentType,
                        "Number": pageItem.Number 
                    }
                }
            };
        },
        postSubmitHook({ typeRef }) {
            db.set(`Database/${meta.ParentType}/${pageItem.ID}/Miscellaneous/${typeRef.key}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
}

export const uploadFile = ({ form, pageItem, rowItem }) => {
    form.setConfig({
        "title": "Add File",
        "type": "push",
        "location": `Database/Miscellaneous/${pageItem.ID}`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file",
            "accept": ".pdf,.doc,.docx"
        }],
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.set(`Database/Miscellaneous/${rowItem.ID}/File`, file[0].name);
                await db.upload(`Database/Miscellaneous/${rowItem.ID}`, file[0]);
            }
        }
    });
    form.toggle();
};

export const editMiscellaneous = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Edit Miscellaneous",
        "type": "update",
        "location": `Database/Miscellaneous/${item.ID}`,
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "dropdownData": [{
                "label": "Document",
                "id": "Document"
            }, {
                "label": "Drawing",
                "id": "Drawing"
            }, {
                "label": "Other",
                "id": "Other"
            }]
        }, {
            "label": "Revision",
            "name": "Revision",
            "type": "text"
        }, {
            "label": "Title",
            "name": "Title",
            "dicClass": "md:col-span-3",
            "type": "text"
        }]
    });
    form.getDropdownData();
    form.toggle();
}

export const removeMiscellaneous = ({ form, pageItem, rowItem, meta }) => {
    form.setConfig({
        "title": "Remove Miscellaneous",
        "description": `Remove ${rowItem.Number} from ${pageItem.Number}`,
        "type": "delete",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/Miscellaneous/${rowItem.ID}`
    });
    form.toggle();
};

export const reviseMiscellaneous = ({ form, pageItem, rowItem, meta }) => {
    let nextRevision = next(rowItem.Revision);
    // if next revision isn't a number or a letter then set is as A
    if (!nextRevision.match(/^[0-9a-zA-Z]+$/)) {
        nextRevision = "A";
    }

    form.setConfig({
        "title": "Revise Miscellaneous",
        "description": `Revise this ${rowItem.Type} from Revision ${rowItem.Revision} to ${nextRevision}.`,
        "type": "update",
        "location": `Database/Miscellaneous/${rowItem.ID}`,
        preSubmitHook() {
            return {
                "Revision": nextRevision,
                "Checked": false,
                "Approved": false,
                "Date": {
                    "Revised": (new Date()).toISOString()
                }
            }
        }
    });
    form.toggle();
};

export const linkMiscellaneous = ({ form, pageItem, db, meta }) => {
    form.setConfig({
        "title": `Link a Miscellaneous Item to this ${meta.ParentType}`,
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/Miscellaneous/`,
        "fields": [{
            "label": "Miscellaneous Item",
            "name": "_doc",
            "type": "dropdown",
            "dataLocation": "Database/Miscellaneous",
            "labelKey": "Number",
            "idKey": "ID",
            "search": true
        }],
        preSubmitHook({ data }) {
            return {
                [data._doc]: true
            }
        },
        postSubmitHook({ data }) {
            db.set(`Database/Miscellaneous/${data._doc}/Links/${pageItem.ID}`, {
                "Type": meta.ParentType,
                "Number": pageItem.Number,
                "ID": pageItem.ID
            });
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const linkDocument = ({ form, pageItem, db }) => {
    form.setConfig({
        "title": `Link a Document Item to ${pageItem.Number}`,
        "type": "update",
        "location": `Database/Miscellaneous/${pageItem.ID}/Links/`,
        "fields": [{
            "label": "Miscellaneous Item",
            "name": "_doc",
            "type": "dropdown",
            "dataLocation": "Database/Refs/Documents",
            "labelKey": "Number",
            "idKey": "ID",
            "search": true
        }],
        preSubmitHook({ data, selectedDropdowns }) {
            return {
                [data._doc]: {
                    "Type": "Documents",
                    "ID": data._doc,
                    "Number": selectedDropdowns._doc.meta.Number
                }
            }
        },
        postSubmitHook({ data }) {
            db.set(`Database/Documents/${data._doc}/Miscellaneous/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const linkDesignChange = ({ form, pageItem, db }) => {
    form.setConfig({
        "title": `Link a Design Change Item to ${pageItem.Number}`,
        "type": "update",
        "location": `Database/Miscellaneous/${pageItem.ID}/Links/`,
        "fields": [{
            "label": "Miscellaneous Item",
            "name": "_doc",
            "type": "dropdown",
            "dataLocation": "Database/Refs/DesignChanges",
            "labelKey": "Number",
            "idKey": "ID",
            "search": true
        }],
        preSubmitHook({ data, selectedDropdowns }) {
            
            return {
                [data._doc]: {
                    "Type": "DesignChanges",
                    "ID": data._doc,
                    "Number": selectedDropdowns._doc.meta.Number,
                    "_doc": null
                }
            }
        },
        postSubmitHook({ data }) {
            db.set(`Database/DesignChanges/${data._doc}/Miscellaneous/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const check = type => ({ form, rowItem }) => {
    form.setConfig({
        "title": `${type} Miscellaneous`,
        "location": `Database/Miscellaneous/${rowItem.ID}`,
        "type": "update",
        "fields": [],
        preSubmitHook() {
            return {
                [type]: true,
                [`Date/${type}`]: new Date().toISOString()
            }
        }
    });
    form.toggle();
};
