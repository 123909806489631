import { makeAttached } from "../../../utils";

export const addUser = ({ form, db }) => {
    form.setConfig({
        "title": "Add User",
        "type": "push",
        "location": "Home/NewUser",
        "fields": [{
            "name": "name",
            "label": "Name",
            "type": "text"
        }, {
            "name": "email",
            "label": "Email",
            "type": "text"
        }, {
            "name": "department",
            "label": "Department",
            "type": "text"
        }, {
            "name": "job_title",
            "label": "Job Title",
            "type": "text"
        }]
    });
    form.toggle();
};

export const updateUser = ({ form, db, pageItem }) => {
    form.setValues(pageItem.Basic);
    form.setConfig({
        "title": "Update User",
        "type": "update",
        "location": `Userdata/${pageItem.ID}/Basic`,
        "fields": [{
            "name": "name",
            "label": "Name",
            "type": "text"
        }, {
            "name": "department",
            "label": "Department",
            "type": "text"
        }, {
            "name": "job_title",
            "label": "Job Title",
            "type": "text"
        }, {
            "label": "Manager",
            "name": "ManagerID",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "key",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }, {
            "name": "main",
            "label": "Is Main User?",
            "type": "checkbox"

        }],
        postSubmitHook({ data }) {
            db.update(`users/${pageItem.ID}`, {
                "department": data.department,
                "job_title": data.job_title,
                "name": data.name
            });
        }
    });
    form.getDropdownData();
    form.toggle();
};  

export const disableUser = ({ form, db, pageItem }) => {
    form.setConfig({
        "title": "Disable User",
        "description": `
            <p class="">Are you sure you want to disable this user. This means thee user will not be able to use this application and will result in some userdata loss.</p>
            <p>The following data will be lost from the user profile.</p>
            <ul class="list-disc list-inside">
                <li><i>Hidden</i> from all Leave Events</li>
                <li>Users Recents</li>
                <li>Security Settings all set to FALSE</li>
            </ul>
        `,
        "location": `users/${pageItem.ID}`,
        "type": "update",
        "fields":[],
        preSubmitHook(){
            return {
                "DISABLED": true
            };
        }
    });
    form.toggle();
};

export const setDesignChangeFilter = ({ form, db, pageItem }) => {

    form.setValues({
        "DesignChangeFilter":  Object.keys(pageItem.DesignChangeFilter || {}) 
    })

    form.setConfig({
        "title": "Set Design Change Filter",
        "location": `Userdata/${pageItem.ID}/`,
        "type": "update",
        "fields": [{
            "name": "DesignChangeFilter",
            "label": "Design Changes",
            "type": "dropdown",
            "labelKey": "Number",
            "idKey": "ID",
            "dataLocation": "Database/DesignChanges",
            "multiple": true,
        }],
        preSubmitHook({ data }) {
            return {
                "DesignChangeFilter": makeAttached(data.DesignChangeFilter)
            };
        }

    });

    form.getDropdownData();
    form.toggle();
};
