

export const addChangeRequest = ({ form, pageItem,db, store  }) => {
    form.setConfig({
        "title": "Add Change Request",
        "location": "Database/ChangeRequests",
        "type": "push",
        "fields": [{
            "type": "richtext",
            "name": "Note",
            "label": "Change Request",
            "tooltip": "How do you think the handbook could be improved?",
            "divClass": "md:col-span-3"
        }],
        preSubmitHook() {
            return {
                "Number": `WICR-${parseInt(store.state.counts.wicr.count).toString().padStart(4, "0")}`,
                "Revision": pageItem.Revision,
                "Status": "Pending",
                "WorkInstructionID": pageItem.ID,
                "WorkInstruction": pageItem.Number,
                "HandbookID": pageItem.HandbookID,
                "Handbook": pageItem.Handbook,
                "Date": new Date().toISOString()
            };
        },
        async postSubmitHook({ typeRef }) {
            await db.utils.upCount("counts/wicr/count");
            await db.set(`Database/WorkInstructions/${pageItem.ID}/ChangeRequests/${typeRef.key}`, true);
            await db.set(`Database/Handbooks/${pageItem.HandbookID}/ChangeRequests/${typeRef.key}`, true);
        }
    });
    form.toggle();
};

export const editChangeRequest = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Update Change Request",
        "location": `Database/ChangeRequests/${item.ID}`,
        "type": "update",
        "fields": [{
            "type": "richtext",
            "name": "Note",
            "label": "Change Request",
            "tooltip": "How do you think the handbook could be improved?",
            "divClass": "md:col-span-3"
        }]
    });
    form.toggle();
};

export const updateChangeRequestStatus = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Update Change Request Status",
        "location": `Database/ChangeRequests/${item.ID}`,
        "type": "update",
        "fields": [{
            "type": "dropdown",
            "name": "Status",
            "label": "Change Request",
            "dropdownData": [{
                "label": "Approved",
                "id": "Approved"
            }, {
                "label": "Rejected",
                "id": "Rejected"
            }]
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const viewChangeRequest = ({ form, rowItem }) => {
    form.setConfig({
        "title": "Change Request",
        "submitButtonClass": "primary",
        "submitButtonLabel": "Close",
        "fields": [{
            "type": "value",
            "divClass": "md:col-span-3",
            "grow": true,
            value() {
                return rowItem.Note
            }
        }]
    });
    form.toggle();
};
