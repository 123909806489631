import { buildApprovedFunctions, buildApprovedPanels, buildLimitations } from "./utils.js";

import htmlToPdfmake from "html-to-pdfmake";
import {
    standardDateLocal
} from "../../../utils.js";

const appConfig = JSON.parse(import.meta.env.VITE_APP_CONFIG);

export const matrixReport = async (revision, { store }) => {

    // get the revID and download the revision signatory data
    
    let docDef = {
        "content": [],
        "pageOrientation": 'landscape',
        "header":{ 
            "text": `Signatory Matrix REV ${revision.Revision} - Dated: ${(new Date(revision.Date)).toLocaleDateString("en-gb", standardDateLocal)}`,
            "margin": [42, 16, 0, 0],
            "bold": true
        },
        footer: function (currentPage, pageCount) {
            return {
                "text": currentPage.toString() + ' of ' + pageCount,
                "margin": [42, 16, 0, 0],
                "bold": true
            }
        },
    };
    let con = docDef.content;

    con.push({
        "text": "Revision Changes - HoD Approved Date: " + (revision.Date ? (new Date(revision.Date)).toLocaleDateString("en-gb", standardDateLocal) : "Not Approved"),
        "bold": true,
        html: true
    });
    let changes = htmlToPdfmake(revision.ChangesMade);
    if(!changes.length) changes = [changes];
    changes.forEach(i => con.push(i));
    con.push({
        "text": "\n\n"
    })

    let tableRows = [];

    Object.values(revision.Signatories).filter(i => !i.Cancelled).forEach(i => {
        const name = `CSR: ${i.CSR} - ${i.FirstName} ${i.LastName}`;
        const limitations = buildLimitations(i, store);
        const approvedFunctions = buildApprovedFunctions(i, store);
        const approvedPanels = buildApprovedPanels(i, store);
        tableRows.push([
            name,
            i.Position,
            [{
                "columns": [htmlToPdfmake(approvedPanels), htmlToPdfmake(approvedFunctions)]
            }, ...htmlToPdfmake(limitations)]
        ]);
    });

    con.push({
        "table": {
            "headerRows": 1,
            "body": [
                [{
                    "text": "Name",
                    "fillColor": appConfig.primaryColour,
                    "color": "#fff",
                    "bold": true
                }, {
                    "text": "Position",
                    "fillColor": appConfig.primaryColour,
                    "color": "#fff",
                    "bold": true
                }, {
                    "text": "Approved Panels, Functions and Limitations",
                    "fillColor": appConfig.primaryColour,
                    "color": "#fff",
                    "bold": true
                }],
                ...tableRows
            ]
        }
    });

    const {
        downloadPdf
    } = await import("../../../pdfDownloader.js");

    downloadPdf(docDef);
};
