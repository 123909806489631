import CrewMember from "./CrewMember";
import { merge, groupBy } from "lodash";

export default class LFTE extends CrewMember {
    constructor(data) {
        super(data);
        this.$d = data;
    }

    get FlightHours() {
        let hist = {};
        hist = merge({
            "CS-23":  [],
            "CS-23*": [],
            "CS-25":  [],
            "CS-27":  [],
            "CS-29":  []
        }, groupBy(this.$d.FlightHours.Historical, "CS"));
        return {
            "Total": parseInt(this.$d.FlightHours.Total),
            "Test": parseInt(this.$d.FlightHours.TotalTest),
            "CS23": getHistoricalCSHours(hist, "CS-23"),
            "CS23P": getHistoricalCSHours(hist, "CS-23*"),
            "CS25": getHistoricalCSHours(hist, "CS-25"),
            "CS27": getHistoricalCSHours(hist, "CS-27"),
            "CS29": getHistoricalCSHours(hist, "CS-29")

        };
    }

    get TestFlightPermissions() {
        return {
            "CategoryOne": {
                "CS23": getCategoryOne.call(this, "CS23"),
                "CS23P": getCategoryOne.call(this, "CS23P"),
                "CS25": getCategoryOne.call(this, "CS25"),
                "CS27": getCategoryOne.call(this, "CS27"),
                "CS29": getCategoryOne.call(this, "CS29")
            },
            "CategoryTwo": {
                "CS23": getCategoryTwo.call(this, "CS23"),
                "CS23P": getCategoryTwo.call(this, "CS23P"),
                "CS25": getCategoryTwo.call(this, "CS25"),
                "CS27": getCategoryTwo.call(this, "CS27"),
                "CS29": getCategoryTwo.call(this, "CS29")
            },
            "CategoryFour": {
                "CS23": getCategoryFour.call(this, "CS23"),
                "CS23P": getCategoryFour.call(this, "CS23P"),
                "CS25": getCategoryFour.call(this, "CS25"),
                "CS27": getCategoryFour.call(this, "CS27"),
                "CS29": getCategoryFour.call(this, "CS29")
            }
        };
    }

    get EarliestRevalidation() {
        return "Not Applicable"
    }

    get NextExpiry() {
        const approved = this.$d.Approved && this.Date.Approved.split("-").map(i => i.length === 4 ? (parseInt(i) + 1).toString() : i).join("-");
        return approved < this.MedicalExpiry ? approved : this.MedicalExpiry;
    }

    get NextExpiryReason() {
    return this.$d.Approved ? "HoD Approval" : "Not Approved";
  }

    get Licence() {
        return this.$d.Credentials ? this.$d.Credentials.Number : "Not a pilot";
    }

    get MedicalClass() {
        return this.$d.Credentials ? this.$d.Credentials.Class : "Not a pilot";
    }

    get MedicalNumber() {
        return this.$d.Credentials ? this.$d.Credentials.Medical : "Not a pilot";
    }

    get MedicalExpiry() {
        return this.$d.Credentials ? this.$d.Credentials.Expiry : "Not a pilot";
    }
};
4

function getHistoricalCSHours(data, type) {
    if (!data[type]) return 0;
    return data[type].reduce((acc, i) => 0 + acc + parseInt(i.Hours), 0);
}

// CAT ONE FUNCTIONS
// CAT ONE FUNCTIONS
function getCategoryOne(type) {
    return this.FlightHours[type] >= 100;
};

// CAT TWO FUNCTIONS
// CAT TWO FUNCTIONS
function getCategoryTwo(type) {
    return this.FlightHours[type] >= 50;
};

// CAT FOUR FUNCTIONS
// CAT FOUR FUNCTIONS
function getCategoryFour(type) {
    return this.FlightHours[type] > 0;
};
