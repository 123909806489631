export default {
    "title": "Approved Aircraft Maintenance Programme (AMP) Details",
    "submitButtonText": "Save & Next",
    "fields": [{
        "label": "AMP Reference",
        "name": "amps_ref",
        "type": "text"
    }, {
        "label": "AMP Issue Number",
        "name": "amps_issue",
        "type": "text"
    }]
};
