import {
    accept,
    editIssueType,
    grantExtension
} from "./actions.js";

export const issue = {
    "label": "Issue",
    "key": "Issue",
    "local": true,
    "fields": [{
        "label": "HoD",
        "grow": true,
        compute({item}) {
            const accepted = item?.Issue?.HOD === true ? "<span class='text-green font-bold'>Yes</span>" : "<span class='text-red font-bold'>No</span>";
            const date = item?.Issue?.HOD === true ? new Date(item?.Date?.HOD).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }) : "<span class='text-orange font-bold'>N/A</span>";
            return `Accepted: <span>${accepted}</span><br />Date: <span>${date}</span>`
        }
    }, {
        "label": "Issue",
        "key": "Issue.Comment",
        "class": "md:col-span-3",
        "grow": true
    }],
    "field": true,
    "actions": [{
        "label": "Edit Issue",
        "action": editIssueType("Issue.Comment"),
        show({ pageItem }) {
            //onlt show if this issue has not been accepted by HOD
            return [!pageItem?.Issue?.HOD, 'This cannot be edited after it has been accepted by the HoD.']
        }
    }],
    "rowActions": []
}

export const correctiveAction = {
    "label": "Corrective Action",
    "key": "Corrective Action",
    "local": true,
    "fields": [{
        "label": "HISM",
        "grow": true,
        compute({item}) {
            const accepted = item?.CorrectiveAction?.DAM === true ? "<span class='text-green font-bold'>Yes</span>" : "<span class='text-red font-bold'>No</span>";
            const date = item?.CorrectiveAction?.DAM === true ? new Date(item?.Date?.CorrectiveAction?.DAM).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }) : "<span class='text-orange font-bold'>N/A</span>";
            return `Accepted: <span>${accepted}</span><br />Date: <span>${date}</span>`
        }
    }, {
        "label": "HoD",
        "grow": true,
        compute({item}) {
            const accepted = item?.CorrectiveAction?.HOD === true ? "<span class='text-green font-bold'>Yes</span>" : "<span class='text-red font-bold'>No</span>";
            const date = item?.CorrectiveAction?.HOD === true ? new Date(item?.Date?.CorrectiveAction?.HOD).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }) : "<span class='text-orange font-bold'>N/A</span>";
            return `Accepted: <span>${accepted}</span><br />Date: <span>${date}</span>`
        }
    }, {
        "label": "Corrective Action",
        "key": "CorrectiveAction.Comment",
        "class": "md:col-span-3",
        "grow": true
    }],
    "field": true,
    "actions": [{
        "label": "Edit Corrective Action",
        "action": editIssueType("CorrectiveAction.Comment"),
        show({ pageItem }) {
            //onlt show if this issue has not been accepted by HOD and HISM
            return [!(pageItem?.CorrectiveAction?.HOD && pageItem?.CorrectiveAction?.DAM), 'This cannot be edited after it has been accepted by the HISM and HoD.']
        }
    }, {
        "label": "HISM Accept",
        "action": accept("DAM", "CorrectiveAction"),
        "break": true,
        show({ pageItem }) {
            return [!pageItem?.CorrectiveAction?.DAM, 'HISM has already accepted.']
        }
    }, {
        "label": "HoD Accept",
        "action": accept("HOD", "CorrectiveAction"),
        show({ pageItem }) {
            return [!pageItem?.CorrectiveAction?.HOD, 'HOD has already accepted.']
        }
    }],
    "rowActions": [],
    show({ pageItem }) {
        return pageItem?.Issue.HOD
    },
    warn({ pageItem }) {
        if (pageItem?.CorrectiveAction?.DAM === false) return [true, "HISM has not accepted this Corrective Action."];
        if (pageItem?.CorrectiveAction?.HOD === false) return [true, "HoD has not accepted this Corrective Action."];
        else return [false, '']
    }
}

export const preventativeAction = {
    "label": "Preventative Action",
    "key": "Preventative Action",
    "field": true,
    "fields": [{
        "label": "HISM",
        "grow": true,
        compute({item}) {
            const accepted = item?.PreventativeAction?.DAM === true ? "<span class='text-green font-bold'>Yes</span>" : "<span class='text-red font-bold'>No</span>";
            const date = item?.PreventativeAction?.DAM === true ? new Date(item.Date.PreventativeAction?.DAM).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }) : "<span class='text-orange font-bold'>N/A</span>";
            return `Accepted: <span>${accepted}</span><br />Date: <span>${date}</span>`
        }
    }, {
        "label": "HoD",
        "grow": true,
        compute({item}) {
            const accepted = item?.PreventativeAction?.HOD === true ? "<span class='text-green font-bold'>Yes</span>" : "<span class='text-red font-bold'>No</span>";
            const date = item?.PreventativeAction?.HOD === true ? new Date(item.Date.PreventativeAction?.HOD).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }) : "<span class='text-orange font-bold'>N/A</span>";
            return `Accepted: <span>${accepted}</span><br />Date: <span>${date}</span>`
        }
    }, {
        "label": "Preventative Action",
        "key": "PreventativeAction.Comment",
        "class": "md:col-span-3",
        "grow": true
    }],
    "actions": [{
        "label": "Edit Preventative Action",
        "action": editIssueType("PreventativeAction.Comment"),
        show({ pageItem }) {
            //onlt show if this issue has not been accepted by HOD and HISM
            return [!(pageItem?.PreventativeAction?.HOD && pageItem?.PreventativeAction?.DAM), 'This cannot be edited after it has been accepted by the HISM and HoD.']
        }
    }, {
        "label": "HISM Accept",
        "action": accept("DAM", "PreventativeAction"),
        "break": true,
        show({ pageItem }) {
            return [!pageItem?.PreventativeAction?.DAM, 'HISM has already accepted.']
        }
    }, {
        "label": "HoD Accept",
        "action": accept("HOD", "PreventativeAction"),
        show({ pageItem }) {
            return [!pageItem?.PreventativeAction?.HOD, 'HOD has already accepted.']
        }
    }],
    "rowActions": [],
    show({ pageItem }) {
        return pageItem?.Issue.HOD
    },
    warn({ pageItem }) {
        if (pageItem?.PreventativeAction?.DAM === false) return [true, "HISM has not accepted this Preventative Action."];
        if (pageItem?.PreventativeAction?.HOD === false) return [true, "HoD has not accepted this Preventative Action."];
        else return [false, '']
    }
}

export const rootCause = {
    "field": true,
    "label": "Root Cause",
    "key": "Root Cause",
    "fields": [{
        "label": "HISM",
        "grow": true,
        compute({item}) {
            const accepted = item?.RootCause?.DAM === true ? "<span class='text-green font-bold'>Yes</span>" : "<span class='text-red font-bold'>No</span>";
            const date = item?.RootCause?.DAM === true ? new Date(item?.Date?.RootCause?.DAM).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }) : "<span class='text-orange font-bold'>N/A</span>";
            return `Accepted: <span>${accepted}</span><br />Date: <span>${date}</span>`
        }
    }, {
        "label": "HoD",
        "grow": true,
        compute({item}) {
            const accepted = item?.RootCause.HOD === true ? "<span class='text-green font-bold'>Yes</span>" : "<span class='text-red font-bold'>No</span>";
            const date = item?.RootCause?.HOD === true ? new Date(item?.Date?.RootCause?.HOD).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }) : "<span class='text-orange font-bold'>N/A</span>";
            return `Accepted: <span>${accepted}</span><br />Date: <span>${date}</span>`
        }
    }, {
        "label": "Root Cause",
        "key": "RootCause.Comment",
        "class": "md:col-span-3",
        "grow": true
    }],
    "actions": [{
        "label": "Edit Root Cause",
        "action": editIssueType("RootCause.Comment"),
        show({ pageItem }) {
            //onlt show if this issue has not been accepted by HOD and HISM
            return [!(pageItem?.RootCause?.HOD && pageItem?.RootCause?.DAM), 'This cannot be edited after it has been accepted by the HISM and HoD.']
        }
    }, {
        "label": "HISM Accept",
        "action": accept("DAM", "RootCause"),
        "break": true,
        show({ pageItem }) {
            return [!pageItem?.RootCause?.DAM, 'HISM has already accepted.']
        }
    }, {
        "label": "HoD Accept",
        "action": accept("HOD", "RootCause"),
        show({ pageItem }) {
            return [!pageItem?.RootCause.HOD, 'HOD has already accepted.']
        }
    }],
    "rowActions": [],
    show({ pageItem }) {
        return pageItem?.Issue.HOD
    },
    warn({ pageItem }) {
        if (pageItem?.RootCause?.DAM === false) return [true, "HISM has not accepted this Root Cause."];
        if (pageItem?.RootCause.HOD === false) return [true, "HoD has not accepted this Root Cause."];
        else return [false, '']
    }
}

export const extension = {
    "field": true,
    "key": "Extension",
    "label": "Extension",
    "local": true,
    "fields": [{
        "label": "Requested",
        "grow": true,
        compute({item}) {
            const accepted = item.Extension.Requested === true ? "<span class='text-green font-bold'>Yes</span>" : "<span class='text-red font-bold'>No</span>";
            const date = item.Extension.Requested === true ? new Date(item.Date.Extension.Requested).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }) : "<span class='text-orange font-bold'>N/A</span>";
            return `Requested: <span>${accepted}</span><br />Date: <span>${date}</span>`
        }
    }, {
        "label": "Granted",
        "grow": true,
        compute({item}) {
            const accepted = item.Extension.Granted === true ? "<span class='text-green font-bold'>Yes</span>" : "<span class='text-red font-bold'>No</span>";
            const date = item.Extension.Granted === true ? new Date(item.Date.Extension.Granted).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }) : "<span class='text-orange font-bold'>N/A</span>";
            return `
            Granted: <span>${accepted}</span>
            <br />
            ${item.Extension.Granted === true
                    ? `New Due Date: ${new Date(item.Date.Due).toLocaleDateString("en-gb", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })}<br />`
                    : ""}
                Date Granted: <span>${date}</span>`
        }
    }, {
        "label": "Extension",
        "key": "Extension.Comment",
        "class": "md:col-span-3",
        "grow": true
    }],
    "actions": [{
        "label": "Edit Extension Reason",
        "action": editIssueType("Extension.Comment")
    }, {
        "label": "HoD Grant Extension",
        "action": grantExtension,
        "break": true,
        show({ pageItem }) {
            return [!pageItem.Extension?.Granted, 'HoD has already granted this extension.']
        }
    }],
    "rowActions": [],
    show({ pageItem }) {
        return pageItem.Extension.Granted
    }
}
