import {
    addReport,
    linkReport
} from "./actions";

const actions = [{
    "label": "Add Report",
    "action": addReport(({ pageItem }) => { 
        return {
            "ParentID": pageItem.ID
        }   
    })
}, {
    "label": "Link Report",
    "action": linkReport
}];

const headers = [{
    "headerName": "Number",
    "field": "Number"
}, {
    "headerName": "Title",
    "field": "Title"
}, {
    "headerName": "Cycle",
    "field": "Cycle"
}, {
    "headerName": "Completed",
    "field": "Completed",
    "type": "bool"
}];

const rowActions = [{
    "label": "View Report",
    action({ router, rowItem }) {
        router.push(`/Audits/Reports/${rowItem.ID}`)
    }
}];

export default {
    "label": "Reports",
    "key": "Reports",
    "location": "Audits/Reports/",
    actions,
    rowActions,
    headers
};
