import {
    addChangeRequest,
    editChangeRequest,
    updateChangeRequestStatus,
    viewChangeRequest,
} from "./actions";

export default {
    "label": "Change Requests",
    "key": "ChangeRequests",
    "location": "Database/ChangeRequests/",
    "headers": [{
        "headerName": "Number",
        "field": "Number",
        "type": "text"
    }, {
        "headerName": "Work Instruction",
        "field": "WorkInstruction",
        "type": "text"
    }, {
        "headerName": "Note",
        "field": "Note",
        "type": "html"
    },{
        "headerName": "Status",
        "field": "Status"
    }],
    "actions": [{
        "label": "Add Change Request",
        "action": addChangeRequest
    }],
    "rowActions": [{
        "label": "View Change Request",
        "action": viewChangeRequest
    }, {
        "label": "Update Change Request",
        "action": editChangeRequest
    }, {
        "label": "Update Status",
        "action": updateChangeRequestStatus,
        show({ store }) {
            return [store?.state?.user?.Security?.Audits?.HOD, "Only HoD can change the status"];
        }
    }]
};
