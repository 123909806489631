import { makeDropdown, next } from "../../../utils";

import {
    revisionQuality
} from "./drawingUtils";

export const addDrawing = ({ form, pageItem, meta }) => {

    form.setValues({ "ATAChapter": parseInt(pageItem.ATAChapter) });
    form.setConfig({
        "title": "Add Drawing",
        "type": "push",
        "location": "Database/Drawings",
        "fields": [{
            "label": "Parent",
            "type": "value",
            value() {
                return pageItem.Number
            }
        }, {
            "label": "New Drawing Number",
            "type": "value",
            value({ liveInputData, dropdownData }) {
                const selectedATA = dropdownData.ATAChapter.find(ata => {
                    return ata.meta.Number === liveInputData.ATAChapter
                });

                return `${selectedATA?.meta?.Number}-${pageItem.AircraftCode || pageItem.Aircraft.split(" -")[0]}-${selectedATA?.meta?.CountDrawings}`
            }
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/DrawingTypes"
        }, {
            "label": "ATA Chapter",
            "name": "ATAChapter",
            "type": "dropdown",
            "labelKey": "NameFull",
            "idKey": "Number",
            "dataLocation": "Database/ATAChapters"
        }, {
            "label": "Is Prototype",
            "name": "IsPrototype",
            "type": "dropdown",
            "dropdownData": [{
                "label": "Yes",
                "id": "Yes"
            }, {
                "label": "No",
                "id": "No"
            }]
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text",
            "divClass": "col-span-3"
        }],
        async preSubmitHook({ data, dropdownData, liveInputData }) {
            const selectedATA = dropdownData.ATAChapter.find(type => {
                return type?.meta.Number == liveInputData.ATAChapter
            });
            const drawingType = dropdownData.Type.find(ata => {
                return ata?.meta.Name === liveInputData.Type
            });

            return {
                "AircraftCode": pageItem.AircraftCode || pageItem.Aircraft.split(" -")[0],
                "ATAChapterID": selectedATA.meta.ID,
                "ExpectedTime": drawingType.meta.ExpectedTime,
                "Serial": selectedATA.meta.CountDrawings,
                "Title": `${drawingType.meta.Name} - ${data.Title}`,
                "Date": {
                    "Created": new Date().toISOString(),
                    "Cancelled": "N/A",
                    "Completed": "N/A"
                },
                "RequiresStressCheck": drawingType.meta.NeedsStress,
                "TypeID": drawingType.meta.ID,
                "Revisions": {
                    "---R1": {
                        "ID": "---R1",
                        "Date": new Date().toISOString(),
                        "Reason": "Drawing Created",
                        "Revision": data.IsPrototype === "No" ? "A" : "P1",
                        "Quality": {
                            "Approver": {
                                "Completed": false,
                                "By": "Not Approved",
                                "Date": "Not Approved"
                            },
                            "Checker": {
                                "Completed": false,
                                "By": "Not Checked",
                                "Date": "Not Checked"
                            },
                            "StressChecker": {
                                "Completed": !drawingType.meta.NeedsStress,
                                "By": drawingType.meta.NeedsStress ? "Not Approved" : "N/A",
                                "Date": drawingType.meta.NeedsStress ? "Not Approved" : "N/A",
                            },
                            "Compiler": {
                                "Completed": false,
                                "By": "Not Completed",
                                "Date": "Not Completed"
                            }
                        }
                    }
                },
                "Revision": data.IsPrototype === "No" ? "A" : "P1",
                "RevisionID": "---R1",
                "Links": {
                    [pageItem.ID]: "---R1"
                },
                "ParentID": pageItem.ID,
                "Parent": pageItem.Number,
                "ParentType": meta.ParentType,
                [meta.ParentType]: {
                    [pageItem.ID]: true
                },
                "Number": `${selectedATA.meta.Number}-${pageItem.AircraftCode || pageItem.Aircraft.split(" -")[0]}-${selectedATA.meta.CountDrawings}`
            }
        },
        async postSubmitHook({ db, dropdownData, newData, liveInputData, typeRef }) {
            
            const selectedATA = dropdownData.ATAChapter.find(type => {
                return type?.meta.Number == liveInputData.ATAChapter
            });
            
            await db.set(`Database/${meta.ParentType}/${pageItem.ID}/Drawings/${typeRef.key}`, true);
            await db.set(`Database/ATAChapters/${selectedATA.meta.ID}/CountDrawings`, selectedATA.meta.CountDrawings + 1);
            await db.utils.updateRefs({ ...newData, ...liveInputData }, "Database/Refs/Drawings", ["Number", "ID", "Date", "Revision", "Parent", "ParentID", "Type", "Title"]);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editDrawing = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Update Drawing",
        "type": "update",
        "location": `Database/Drawings/${item.ID}`,
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text",
            "divClass": "col-span-2"
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/DrawingTypes"
        }, {
            "label": "Aircraft Code",
            "name": "AircraftCode",
            "sortKey": "meta.Number",
            "type": "dropdown",
            "labelKey": "NameFull",
            "idKey": "Number",
            "dataLocation": "Database/AircraftCodes"
        }],
        preSubmitHook({ data }) {
            
            if(data.AircraftCode !== item.AircraftCode) {
                let split = item.Number.split("-");
                return {
                    "Number": `${split[0]}-${data.AircraftCode}-${split[2]}`
                };
            }
        },
        async postSubmitHook({ db, newData }) {
            db.utils.updateRefs(newData, "Database/Refs/Drawings", ["Number", "ID", "Date", "Revision", "Parent", "ParentID", "Type", "Title", "Cancelled", "Completed"]);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const cancelDrawing = ({ form, item, db }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Cancel Drawing",
        "description": `Are you sure you want to cancel drawing ${item.Number}`,
        "type": "update",
        "location": `Database/Drawings/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": true,
                "Date" : {
                    "Cancelled": new Date().toISOString()
                }
            }
        },
        postSubmitHook() {
            db.update(`Database/Refs/Drawings/${item.ID}/`, {
                "Cancelled": true
            });
        }

    });
    form.toggle();
};

export const reviseDrawing = ({ form, pageItem, rowItem, store }) => {

    // Calculate the next revision
    let nextRevision = next(rowItem.Revision);

    if (["I", "O", "Q", "S", "X", "Z"].includes(nextRevision)) {
        nextRevision = next(nextRevision);
    };

    let nextRevisionID = `--R${nextRevision}`;

    form.setItem(rowItem);

    form.setConfig({
        "title": "Revise Drawing",
        "description": `Revise Drawing <strong class="text-primary">${rowItem.Number}</strong> from Revision <strong class="text-primary">${rowItem.Revision}</strong> to Revision <strong class="text-primary">${nextRevision}</strong>.<br />Make sure all time has been recorded for this revision.`,
        "location": `Database/Drawings/${rowItem.ID}`,
        "type": "update",
        "fields": [{
            "label": "Reason",
            "name": `Revisions[${nextRevisionID}][Reason]`,
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/DrawingRevisionTypes",
            "divClass": "cols-span-3"
        }],
        preSubmitHook({ data }) {
            return {
                "Revision": nextRevision,
                "RevisionID": nextRevisionID,
                "Signedoff": false,
                [`Revisions/${nextRevisionID}`]: {
                    "Date": (new Date()).toISOString(),
                    "Revision": nextRevision,
                    "RevisedBy": store.state.user.Basic.name,
                    "ID": nextRevisionID,
                    "Quality": revisionQuality
                },
                "Links": {
                    [pageItem.ID]: nextRevisionID
                }
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const checkDrawing = type => ({ form, item, store, db, attachedItems }) => {

    form.setValues(item);
    form.setConfig({
        "title": `${type} Drawing`,
        "description": `<a class="font-bold text-primary cursor-pointer" href="${store.state.handbook}" target="_blank">Open Procedure</a> - Have you read the procedure and does it comply?`,
        "type": "update",
        "location": `Database/Drawings/${item.ID}`,
        "submitButtonLabel": "I confirm",
        "fields": [],
        preSubmitHook() {
            return {
                [type]: true,
                [`Revisions/${item.RevisionID}/Quality/${type}/By`]: store.getters.userName,
                [`Revisions/${item.RevisionID}/Quality/${type}/Completed`]: true,
                [`Revisions/${item.RevisionID}/Quality/${type}/Date`]: (new Date()).toISOString()
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const reviseDrawingToA = ({ form, item, pageItem }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Revise Prototype to Revision A",
        "type": "update",
        "location": `Database/Drawings/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Revision": "A",
                "RevisionID": "---RA",
                "Revisions/---RA": {
                    "Date": new Date().toISOString(),
                    "ID": "---RA",
                    "Quality": revisionQuality,
                    "Reason": "From Prototype to Rev A",
                    "Revision": "A"
                },
                [`Links/${pageItem.ID || item.ParentID}`]: "---RA"
            }
        }
    });
    form.toggle()
};

export const changeLinkedRevision = ({ form, pageItem, rowItem, meta }) => {
    form.setConfig({
        "title": "Change linked revision",
        "type": "update",
        "location": `Database/Drawings/${rowItem.ID}/Links/`,
        "fields": [{
            "label": "Revision",
            "name": pageItem.ID,
            "type": "dropdown",
            dropdownData: Object.entries(rowItem.Revisions).map(([key, value]) => ({ id: key, label: value.Revision }))
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const linkDrawing = ({ form, pageItem, meta, db }) => {
    form.setConfig({
        "title": "Link a Drawing",
        "description": `Link a drawing to ${pageItem.Number}`,
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/Drawings/`,
        "fields": [{
            "label": "Drawing",
            "name": "_doc",
            "type": "dropdown",
            "dataLocation": "Database/Refs/Drawings",
            "labelKey": "Number",
            "idKey": "ID",
            "search": true
        }],
        preSubmitHook({ data }) {
            return {
                "_doc": null,
                [data._doc]: true
            }
        },
        async postSubmitHook({ liveInputData, selectedDropdowns }) {
            
            await db.update(`Database/Drawings/${liveInputData._doc}/`, {
                [meta.ParentType]: {
                    [pageItem.ID]: true
                },
                "Links": {
                    [pageItem.ID]: `--${selectedDropdowns._doc.meta.Revision}`
                }
            });
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const unlinkDrawing = ({ form, pageItem, rowItem, meta, db }) => {
    form.setConfig({
        "title": "Link a Drawing",
        "description": `Unlink a drawing to ${pageItem.Number}`,
        "type": "delete",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/Drawings/${rowItem.ID}`,
        "fields": [],
        postSubmitHook() {
            db.delete(`Database/Drawings/${rowItem.ID}/${meta.ParentType}/${pageItem.ID}`);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const revisionRollback = ({ form, item, db, meta, toast }) => {

    let revisions = Object.values(item.Revisions);

    let previousRevision = revisions[revisions.length - 2];

    if (!previousRevision) {
        toast.warn("No previous revision found");
        return;
    }

    form.setConfig({
        "title": "Rollback Revision",
        "description": `Rollback revision from ${item.Revision} to ${previousRevision.Revision}.`,
        "type": "update",
        "location": `Database/Drawings/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                [`Revisions/${item.RevisionID}`]: null,
                "RevisionID": previousRevision.ID,
                "Revision": previousRevision.Revision
            }
        }
    });
    form.toggle();
};

export const switchParent = async ({ form, item, store, attachedItems }) => {
    form.setConfig({
        "title": "Change Parent",
        "type": "update",
        "location": `Database/Drawings/${item.ID}/`,
        "fields": [{
            "label": "New Parent",
            "type": "dropdown",
            "name": "ParentID",
            "dropdownData": makeDropdown(store, attachedItems[item.ParentType].items, "Number", "ID")
        }],
        preSubmitHook({ selectedDropdowns }) {
            return {
                "Parent": selectedDropdowns.ParentID.label,
                [`Links/${selectedDropdowns.ParentID.id}`]: item.RevisionID
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const makePrototype = async ({ form, rowItem, pageItem, store }) => {
    form.setConfig({
        "title": "Make this Drawing a Prototype",
        "type": "update",
        "location": `Database/Drawings/${rowItem.ID}/`,
        "fields": [],
        preSubmitHook({ data }) {
            return {
                "Revision": "P1",
                "RevisionID": "---P1",
                "Signedoff": false,
                "Revisions": {
                    "---P1": {
                        "Date": (new Date()).toISOString(),
                        "Revision": "P1",
                        "RevisedBy": store.state.user.Basic.name,
                        "Reason": "Set to Prototype",
                        "ID": "---P1",
                        "Quality": revisionQuality
                    }
                },
                "Links": {
                    [pageItem.ID]: "---P1"
                }
            }
        }
    });

    form.toggle();
};

export const toggleStressCheckRequirement = async ({ form, item }) => {
    form.setConfig({
        "title": "Toggle Stress Check Requirement",
        "type": "update",
        "description": `Toggle the stress check requirement to ${!item.RequiresStressCheck ? "Required" : "Not Required"}.`,
        "location": `Database/Drawings/${item.ID}/`,
        "fields": [],
        preSubmitHook() {
            return {
                "RequiresStressCheck": !item.RequiresStressCheck
            }
        }
    });

    form.toggle();
};
