import {
    addFlightConditionPurpose,
    editFlightConditionPurpose
} from "./actions";

export default {
    "label": "Flight Condition Purposes",
    "key": "FlightConditionPurposes",
    "location": "Database/FlightConditionPurposes/",
    "headers": [{
        "headerName": "Name",
        "field": "name"
    }],
    "actions": [{
        "label": "Add Flight Condition Purpose",
        "action": addFlightConditionPurpose
    }],
    "rowActions": [{
        "label": "Edit Flight Condition Purpose",
        "action": editFlightConditionPurpose
    }]
}
