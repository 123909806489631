export default {
    "title": "Confirmation",
    "submitButtonText": "Submit",
    "description": `
        <p>Unless otherwise specified (in the request) I hereby confirm the following:</p>
        <ol class="list-disc px-8">
            <li>There is a valid Certificate of Airworthiness (C of A) for the aircraft</li><li>There is a valid Airworthiness Review Certificate (ARC) for the aircraft.</li>
            <li>All applicable Airworthiness Directives have been identified and complied with.</li>
            <li>All life limited and hard time components are within the specified MPD life limitations.</li>
            <li>The aircraft has been maintained to an approved maintenance programme and that there is no outstanding maintenance.</li>
            <li>All repairs approved.</li>
            <li>All aircraft Design Changes (Modifications) are approved.</li>
            <li>The aircraft is free from any defects (including MMEL items).</li>
            <li>There are no missing items as per the configurations deviations list (CDL).</li>
        </ol>
    `,
    "additionalData": {
        "completed": true,
        "date_completed": new Date().toISOString()
    },
    "fields": [{
        "label": "Your Name",
        "name": "accepted_name",
        "type": "text"
    }, {
        "label": "Your Position",
        "name": "accepted_position",
        "type": "text"
    }, {
        "label": "I confirm that the information provided is accurate and complete",
        "name": "declaration",
        "type": "checkbox",
        "required": true
    }]
};
