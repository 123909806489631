import { appConfig, pad } from "../../../utils";

import { add } from "date-fns";

export const addMOR = ({ form, pageItem, store, db }) => {
    form.setConfig({
        "title": "Add MOR",
        "type": "push",
        "location": `Database/MORs`,
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}MOR-${pad(store.state.counts.mor.count)}`
            }
        }],
        preSubmitHook({ }) {
            return {
                "Number": `${appConfig.docPrefix}MOR-${pad(store.state.counts.mor.count)}`,
                "Occurrence": pageItem.Number,
                "OccurrenceID": pageItem.ID,
                "Reported": false,
                "Updated": false,
                "Completed": false,
                "Date": {
                    "Created": (new Date()).toISOString(),
                    "Reported": "Not Reported",
                    "Completed": "Not Completed",
                    "Updated": "Not Updated",
                    "Deadline": {
                        "Reported": (add(new Date(), {
                            "days": 3
                        })).toISOString(),
                        "Completed": (add(new Date(), {
                            "month": 1
                        })).toISOString(),
                        "Updated":( add(new Date(), {
                            "month": 3
                        })).toISOString(),
                    }
                }
            }
        },
        postSubmitHook({ typeRef, data }) {
            db.update(`Database/Occurrences/${pageItem.ID}`, {
                "MOR": data.Number,
                "MORID": typeRef.key
            });
            db.utils.upCount("counts/mor/count");
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const checkMOR = type => ({ form, pageItem }) => {
    
    form.setConfig({
        "title": "Add Occurrence",
        "type": "update",
        "location": `Database/MORs/${pageItem.ID}`,
        "fields": [],
        preSubmitHook({ data }) {
            return {
                [type]: true,
                [`Date/${type}`]: (new Date()).toISOString()
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};
