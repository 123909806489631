import { addCycle, completeCycle, updateCycle } from "./actions";

const actions = [{
    "label": "Add Cycle",
    "action": addCycle
}, {
    "label": "Complete Cycle",
    "action": completeCycle
}];

const headers = [{
    "headerName": "Cycle",
    "field": "Name"
}, {
    "headerName": "Completed",
    "field": "Completed",
    "type": "bool"
}, {
    "headerName": "Date Due",
    "field": "Date.Due",
    "type": "date"
}, {
    "headerName": "Date Completed",
    "field": "Date.Completed",
    "type": "date"
}];

const rowActions = [{
    "label": "Update Cycle",
    "action": updateCycle,
    show({ store, rowItem }) {
        console.log(rowItem);
        const cycleId = rowItem.Name.replace("/", "_");
        const cycleRevisionId = store.state["Audits/Cycles"][cycleId].RevisionID;
        const revision = store.state["Audits/Cycles"][cycleId].Revisions[cycleRevisionId];
        return [!revision.Locked, "This cycle revision is approved. You must revise the cycle to update it."]
    }
}];

export default {
    "label": "Cycles",
    "key": "Cycles",
    "local": true,
    actions,
    rowActions,
    headers
};
