import { addCoreProcedure, cancelCoreProcedure, completeCycle, editCoreProcedure } from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import cyclesAttachable from "./cyclesAttachable";
import { get } from "lodash";
import noncons from "../NonCons/attachables.js";
import procedures from "../Procedures/attachables.js";
import reports from "../Reports/attachables.js";

export default [{
    "path": "/Audits/CoreProcedures",
    "component": {...ItemListView},
    "name": "Core Procedures",
    "meta": {
        "app": "Audits",
        "name": "Core Procedures",
        "storeData": ["Audits/Procedures", "Audits/Cycles"],

        "sideNav": true,
        "sideNavSection": "Reports",
        "appSwitcher": false,
        "icon": "check-circle",
        "tableSettings": "CoreProcedures",
        "dataPath": "Audits/CoreProcedures",
        "selectionRoute": "Audits/CoreProcedures/{ID}",
        "menuActions": [{
            "label": "Add Core Procedure",
            "action": addCoreProcedure
        }]
    }
}, {
    "path": "/Audits/CoreProcedures/:id",
    "component": ItemView,
    "name": "Core Procedure",
    "meta": {
        "app": "Audits",
        "name": "Core Procedure",
        "ParentType": "CoreProcedures",
        "storeData": ["Audits/Procedures", "Audits/Cycles"],
        
        "sideNav": false,
        "sideNavSection": "Reports",
        "appSwitcher": false,
        "dataPath": "Audits/CoreProcedures",
        "selectionRoute": "Audits/CoreProcedures/{ID}",
        title(item) {
            return `Core Procedure: ${item.Title}`
        },
        "dataFields": [{
            "label": "Title",
            "key": "Title",
            "class": "col-span-3"
        }, {
            "label": "Current Cycle",
            compute({ pageItem}) {
                let cc =  get(pageItem.Cycles, pageItem.CurrentCycleID, null)
                return cc ? cc.Name : "No Current Cycle"
            }
        }, {
            "label": "Last Completed Date",
            "key": "Date.Completed",
            "type": "date"
        }, {
            "label": "Next Due Date",
            compute({ pageItem }) {
                let cc = get(pageItem.Cycles, pageItem.CurrentCycleID, null)
                return cc ? cc.Date.Due : "No Current Cycle"
            },
            "type": "date"
        }],
        "attachedItems": {
            "Procedures": {
                "attachables" : [procedures]
            },
            "Items": {
                "attachables": [noncons, reports, cyclesAttachable]
            }
        },
        "formMetadata": {
            "ParentType": "CoreProcedures"
        },
        "menuActions": [{
            "label": "Update Procedure",
            "action": editCoreProcedure
        }, {
            "label": "Complete Cycle",
            "action": completeCycle
        },{
            "label": "Cancel Procedure",
            "class":"warn",
            "break": true,
            "action": cancelCoreProcedure
        }]
    }
}
];
