import {
    downloadPdf
} from "../../../pdfDownloader.js";
import { get } from "lodash";

const keys = { 
    Documents(items, pageItem){
        let body = [
            ["Number", "Title", "Revision"]
        ];
        items.forEach(item => {
            body.push([item.Number, item.Title, get(item, `Revisions.${item.Links[pageItem.ID]}.Revision`, item.Revision)])
        });
        return body;
    },
    Drawings(items, pageItem) {
        let body = [
            ["Number", "Title", "Revision"]
        ];
        items.forEach(item => {
            body.push([item.Number, item.Title, get(item, `Revisions.${item.Links[pageItem.ID]}.Revision`, item.Revision)])
        });
        return body;
    },
    SADDs(items, pageItem) {
        let body = [
            ["Number", "POA Number", "Date of Issue"]
        ];
        items.forEach(item => {
            body.push([item.Number, item.To, (new Date(item.Date.Issued).toLocaleDateString("en-gb"))])
        });
        return body;
    },
    Effectivities(items, pageItem) {
        let body = [
            ["Number", "Commercial"]
        ];
        items.forEach(item => {
            body.push([item.Number, item.Commercial])
        });
        return body;
    },
    Concessions(items, pageItem) {
        let body = [
            ["Number", "Comment"]
        ];
        items.forEach(item => {
            body.push([item.Number, item.Comment])
        });
        return body;
    },
    DQNs(items, pageItem) {
        let body = [
            ["Number", "Comment"]
        ];
        items.forEach(item => {
            body.push([item.Number, item.Comment])
        });
        return body;
    },
    ServiceBulletins(items, pageItem) {
        let body = [
            ["Number", "Comment"]
        ];
        items.forEach(item => {
            body.push([item.Number, item.Comment])
        });
        return body;
    },
    DOIs(items, pageItem) {
        let body = [
            ["Number", "Comment"]
        ];
        items.forEach(item => {
            body.push([item.Number, item.Comment])
        });
        return body;
    },
    JobCards(items, pageItem) {
        let body = [
            ["Number", "Title", "Colour"]
        ];
        items.forEach(item => {
            body.push([item.Number, item.Title, item.Type])
        });
        return body;
    }
}

export const repairItemsReport = ({ pageItem, attachedItems, store }) => {
    let docDef = {
        "content": []
    };
    let con = docDef.content;

    // Design Chnage Report Title
    con.push({
        "text": `S21.${pageItem.Number}  - ${pageItem.Title}`,
        "fontSize": 12
    });

    // Add some basic infomation about the Design Change
    con.push({
        "columns": [{
            "width": "25%",
            "text": "Parent",
            "bold": true
        }, {
            "width": "25%",
            "text": "Customer",
            "bold": true
        }, {
            "width": "25%",
            "text": "Responsible Engineer",
            "bold": true
        }, {
            "width": "25%",
            "text": "Major Repair Number",
            "bold": true
        }],
        "margin": [0, 12]
    });
    con.push({
        "columns": [{
            "width": "25%",
            "text": pageItem.Parent
        }, {
            "width": "25%",
            "text": pageItem.Customer
        }, {
            "width": "25%",
            "text": pageItem.ResponsibleEngineer
        }, {
            "width": "25%",
            "text": pageItem.EASANumber
        }]
    });

    // Add all the tables
    for (let [key, value] of Object.entries(attachedItems)) {
        if (value.items.length < 1) continue;
        if (!keys[key]) continue;

        let items = value.items.map(i => store.state[i]).filter(i => !!i).filter(i => !i.Cancelled);

        let body = keys[key](items, pageItem);
        con.push({
            "text": key,
            "fontSize": 12,
            "margin": [0, 12, 0, 12]
        });

        con.push({
            "table": {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                "headerRows": 1,
                "body": body,
            }
        })
    };
    downloadPdf(docDef)
};
