const percentages = ["0%", "20%", "40%", "60%", "80%", "100%"].map(i => ({ id: i, label: i }));

import { Convert } from "easy-currencies";

export const addPurchaseOrder = ({ pageItem, form }) => {

    // get the current date in yyyy-mm-dd format
    let today = new Date();
    let yyyyMmDd = today.toISOString().split("T")[0];


    form.setValues({
        "Price": pageItem.Quote.Price,
        "Date[Created]": yyyyMmDd
    });

    form.setConfig({
        "title": "Add Purchase Order",
        "description": `Add a Purchase Order to Commercial ${pageItem.Number}`,
        "type": "push",
        "location": "Database/PurchaseOrders",
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text",
            "divClass": "col-span-3"
        }, {
            "label": "Price",
            "name": "Price",
            "type": "number"
        }, {
            "label": "Currency",
            "name": "Currency",
            "type": "dropdown",
            "dropdownData": [{
                "label": "£ GBP",
                "id": "gbp"
            }, {
                "label": "$ USD",
                "id": "usd"
            }, {
                "label": "€ EUR",
                "id": "eur"
            }]
        }, {
            "label": "Date Received",
            "name": "Date[Created]",
            "type": "date"
        }],
        async preSubmitHook({ data }) {
            let rate = data.Price;
            if (data.Currency !== "gbp") {
                let response = await Convert(data.Price).from(data.Currency.toUpperCase()).to("GBP");
                rate = Math.round(response * 100) / 100;
            }
            return {
                "PriceGBP": rate,
                "CommercialID": pageItem.ID,
                "Commercial": pageItem.Number,
                "Customer": pageItem.Customer,
                "PercentagePaid": 0,
                "Time": pageItem.Quote.Time
            }
        },
        async postSubmitHook({ data, db, typeRef }) {
            db.set(`Database/Commercials/${pageItem.ID}/PurchaseOrders/${typeRef.key}`, true);
            db.set(`Database/Refs/Commercials/${pageItem.ID}/PurchaseOrders`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editPurchaseOrder = ({ item, form, db }) => {
    form.setValues({
        ...item,
        "Date[Created]": (new Date(item.Date.Created)).toISOString().split("T")[0]
    });
    form.setConfig({
        "title": "Edit Purchase Order",
        "type": "update",
        "location": `Database/PurchaseOrders/${item.ID}`,
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }, {
            "label": "Price",
            "name": "Price",
            "type": "text"
        }, {
            "label": "Currency",
            "name": "Currency",
            "type": "dropdown",
            "dropdownData": [{
                "label": "£ GBP",
                "id": "gbp"
            }, {
                "label": "$ USD",
                "id": "usd"
            }, {
                "label": "€ EUR",
                "id": "eur"
            }]
        }, {
            "label": "Percentage Invoiced",
            "name": "PercentageInvoiced",
            "type": "number",
            "min": 0,
            "max": 100,
        }, {
            "label": "Percentage Paid",
            "name": "PercentagePaid",
            "type": "number",
            "min": 0,
            "max": 100,
        }, {
            "label": "Customer",
            "name": "Customer",
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/Customers"
        }, {
            "label": "Date Received",
            "name": "Date[Created]",
            "type": "date"
        }],
        async preSubmitHook({ data }) {
            let rate = data.Price;
            if (data.Currency !== "gbp") {
                let response = await Convert(data.Price).from(data.Currency.toUpperCase()).to("GBP");

                rate = Math.round(response * 100) / 100;
            }
            return {
                "PriceGBP": rate,
                "Date": {
                    "Edited": new Date()
                }
            }
        },
        postSubmitHook({ data }) {
            if (parseFloat(data?.PercentageInvoiced) >= 100) {
                db.update(`Database/Commercials/${item.CommercialID}`, {
                    "Invoiced": true
                });
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const addFile = ({ pageItem, form, store, db }) => {
    form.setConfig({
        "title": "Add File",
        "description": `Add a File to Purchase Order ${pageItem.Number}`,
        "type": "push",
        "location": `Database/PurchaseOrders/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file"
        }],
        preSubmitHook({ files }) {
            return {
                "Date": (new Date()).toISOString(),
                "Name": files[0][0].name,
            }
        },
        postSubmitHook({ files, typeRef }) {
            db.upload(`Database/PurchaseOrders/${pageItem.ID}/Files/${typeRef.key}`, files[0][0]);

        }
    });
    form.toggle();
};

export const removeFile = ({ pageItem, rowItem, store, db, form }) => {
    form.setConfig({
        "title": "Remove File",
        "description": `Remove File ${rowItem.Name}`,
        "type": "delete",
        "location": `Database/PurchaseOrders/${pageItem.ID}/Files/${rowItem.ID}`,
        "fields": [],
        postSubmitHook() {
            db.remove(`Database/PurchaseOrders/${pageItem.ID}/Files/${rowItem.ID}`, rowItem.Name);
        }
    });
    form.toggle();
};
