import {
    addTesterType,
    editTesterType,
    deleteTesterType
} from "./actions";

export default {
    "label": "Tester Types",
    "key": "Tester Types",
    "location": "Matrices/SignatoryMatrix/TesterTypes/",
    "headers": [{
        "headerName": "Name",
        "field": "Name",
        "flex": 1
    }],
    "actions": [{
        "label": "Add Tester Type",
        "action": addTesterType
    }],
    "rowActions": [{
        "label": "Edit Tester Type",
        "action": editTesterType
    }, {
        "label": "Delete Tester Type",
        "action": deleteTesterType
    }]
}
