import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

import drawingAttachable from "../Drawings/attachable.js";
import documentsAttachable from "../Documents/attachable.js";
import designChangesAttachable from "../DesignChanges/attachable.js";
import effectivitiesAttachable from "../Effectivities/attachable.js";
import { time } from "../../standardAttachables";
import { revisions as _revisions } from "../../standardAttachables";
import { checkDOI, reviseDOI } from "./actions";

const revisions = {
    ..._revisions,
    "headers": [
        ..._revisions.headers,
        {
            "headerName": "Compiler",
            "field": "Quality.Compiler.By"
        }, {
            "headerName": "Checker",
            "field": "Quality.Checker.By"
        }, {
            "headerName": "Approver",
            "field": "Quality.Approver.By"
        }
    ]
};

const canRevise = item => Object.values(item?.Revisions[item?.RevisionID]?.Quality || {}).every(i => i?.Completed);

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Parent",
    "key": "Parent",
    "link": "Database/:ParentType/:ParentID"
}, {
    "label": "Current Revision Approved",
    "type": "bool",
    "compute": ({item}) => canRevise(item)
}, {
    "label": "Comment",
    "key": "Comment",
    "class": "md:col-span-3"
}, {
    "label": "External Items",
    "key": "Externals",
    "class": "md:col-span-3"
}];



export default [{
    "path": "/Database/DOIs",
    "component": {...ItemListView},
    "name": "DOIs",
    "meta": {
        "app": "Database",
        "name": "DOIs",
        "sideNav": true,
        "sideNavSection": "Design Items",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "DOIs",
        "dataPath": "Database/DOIs",
        "selectionRoute": "Database/DOIs/{ID}"
    }
}, {
    "path": "/Database/DOIs/:id",
    "component": ItemView,
    "name": "DOI",
    "meta": {
        "app": "Database",
        "name": "DOIs",
        "dataPath": "Database/DOIs",
        "selectionRoute": "Database/DOIs/{ID}",
        title(item) {
            return `DOIs: ${item.Number}`
        },
        "attachedItems": {
            "Revisions": {
                "attachables": [revisions]
            },
            "Attached Items": {
                "attachables": [documentsAttachable, drawingAttachable, effectivitiesAttachable]
            },
            "Additional Information": {
                "attachables": [time]
            }
        },
        "preventAttachedItemsActions": true,
        "preventAttachedItemsRowActions": false,
        "dataFields": dataFields,
        "menuActions": [{
            "label": "Revise DOI",
            "action": reviseDOI,
            show({ pageItem }){ 
                return [canRevise(pageItem), "The DOI is not approved"]
            }
        }, {
        "label": "Compiler Check",
        "action": checkDOI("Compiler"),
        "break": true,
        show({ pageItem }) {
            return pageItem.Revisions[pageItem.RevisionID]?.Quality?.Compiler?.Completed === true ? [false, "Already Complier Checked"] : [true, ""];
        }
    },
        {
            "label": "Checker Check",
            "action": checkDOI("Checker"),
            show({ pageItem }) {
                return pageItem.Revisions[pageItem.RevisionID]?.Quality?.Checker?.Completed === true ?
                    [false, "Already Complier Checked"] :
                    !pageItem.Revisions[pageItem.RevisionID]?.Quality?.Compiler?.Completed ?
                        [false, "Document not checked by the complier"] :
                        [true, ""]
            }
        },
        {
            "label": "Approver Check",
            "action": checkDOI("Approver"),
            show({ pageItem }) {
                return pageItem.Revisions[pageItem.RevisionID]?.Quality?.Approver?.Completed === true ?
                    [false, "Already Approver Checked"] :
                    !pageItem.Revisions[pageItem.RevisionID]?.Quality?.Checker?.Completed ?
                        [false, "Document not checked by the checker"] :
                        [true, ""];
            }
        }],
        "formMetadata": {
            "ParentType": "DesignChanges"
        }
    }
}];
