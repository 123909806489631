import {
    addTrainingVideo
} from "./actions";

export default {
    "label": "Training Videos",
    "key": "Training Videos",
    "location": "TrainingVideos",
    "headers": [{
        "headerName": "Name",
        "field": "Name",
        "flex": 1
    }],
    "actions": [{
        "label": "Add Training Video",
        "action": addTrainingVideo
    }],
    "rowActions": [{
        "label": "Watch",
        async action({ item, videoViewer, db }) {
            let url = await db.download(`TrainingVideos/${item.File}`);
            videoViewer.setSrc(url);
            videoViewer.setTitle(item.Name);
            videoViewer.toggle();
        }
    }]
}
