export const addDrawingRevisionReason = ({ form }) => {
    form.setConfig({
        "title": "Add Drawing Revision Reason",
        "type": "push",
        "location": "Database/DrawingRevisionTypes",
        "fields": [{
            "label": "Reason",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
}

export const editDrawingRevisionReason = ({ form, rowItem }) => {
    form.setValues({
        "Name": rowItem.Name
    });
    form.setConfig({
        "title": "Edit Drawing Revision Reason",
        "type": "update",
        "location": `Database/DrawingRevisionTypes/${rowItem.ID}`,
        "fields": [{
            "label": "Reason",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
}
