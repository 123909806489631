import {
    makeDropdown
} from "../../../utils.js";

export const addSOW = ({ form, pageItem, store, attachedItems }) => {
    form.setConfig({
        "title": "Add SOW",
        "submitButtonLabel": "Add",
        "type": "push",
        "location": "Database/SOWs",
        "fields": [{
            "label": "Job Card",
            "name": "JobCardID",
            "type": "dropdown",
            "labelKey": "Number",
            "idKey": "ID",
            "dropdownData": makeDropdown(store, attachedItems.JobCards.items, "Number", "ID")
        }, {
            "label": "Statement of Work",
            "name": "File",
            "type": "file"
        }],
        preSubmitHook({ dropdownData, liveInputData }) {
            
            let jobCard = dropdownData.JobCardID.find(jc => jc.id === liveInputData.JobCardID).meta;
            
            return {
                "JobCard": jobCard.Number,
                "Colour": jobCard.Type,
                "Title": jobCard.Title,
                "ParentLocation": jobCard.ParentLocation,
                "Parent": jobCard.Parent,
                "ParentID": jobCard.ParentID,
                "Commercial": pageItem.Number,
                "CommercialID": pageItem.ID
            };
        },  
        async postSubmitHook({ db, typeRef, files, data }){
            for(const file of files) {       
                await db.upload(`Database/SOWs/${typeRef.key}`, file[0]);
                await db.set(`Database/SOWs/${typeRef.key}/File`, `Database/SOWs/${typeRef.key}/${file[0].name}`);
            };
            await db.set(`${data.ParentLocation}/${data.ParentID}/SOWs/${typeRef.key}`, true);
            await db.set(`Database/SOWs/${typeRef.key}/Commercial/${pageItem.ID}`, true);
            await db.set(`Database/JobCards/${data.JobCardID}/SOWs/${typeRef.key}`, true);
            
        }
    });
    form.getDropdownData();
    form.toggle();
};    
