import { addServiceOrder, editServiceOrder } from "./actions";

export default {
    "label": "Service Orders",
    "key": "ServiceOrders",
    "location": "Database/ServiceOrders/",
    show() {
        return true
    },
    "actions": [{
        "label": "Add Service Order",
        "action": addServiceOrder
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Price",
        valueFormatter(params) {
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: params.data.Currency || "GBP",
            });

            let ogPrice = formatter.format(parseFloat(params.data.Price));
            let gbpPrice = "";
            if (params.data.Currency !== "gbp") {
                var gbpFormatter = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "GBP",
                });
                gbpPrice = gbpFormatter.format(parseFloat(params.data.PriceGBP));
            }

            return `${ogPrice} ${gbpPrice.length > 0 ? `(${gbpPrice})` : ""}`;
        }
    }, {
        "headerName": "Customer",
        "field": "Customer"
    }, {
        "headerName": "Supplier",
        "field": "Supplier"
    }, {
        "headerName": "Supplier Reference",
        "field": "SupplierReference"
    }],
    "rowActions": [{
        "label": "Open Service Order",
        action({ router, rowItem }) {
            router.push(`/Database/ServiceOrders/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Service Order",
        "action": editServiceOrder
    }]
};
