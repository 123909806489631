import {
    addPanelType,
    editPanelType
} from "./actions";

export default {
    "label": "Panels",
    "key": "Panels",
    "location": "Matrices/SignatoryMatrix/Panels/",
    "headers": [{
        "headerName": "Name",
        "field": "Name",
        "flex": 1
    }],
    "actions": [{
        "label": "Add Panel",
        "action": addPanelType
    }],
    "rowActions": [{
        "label": "Edit Panel",
        "action": editPanelType
    }]
}
