export default {
    "title": "Continuing Airworthiness Management Organisation (CAMO) Details",
    "submitButtonText": "Save & Next",
    "fields": [{
        "label": "CAMO Name",
        "name": "camo_name",
        "type": "text"
    }, {
        "label": "CAMO Number",
        "name": "camo_number",
        "type": "text"
    }]
};
