import {
    addNonConformityLevel,
    editNonConformityLevel
} from "./actions";

export default {
    "label": "NonConformity Levels",
    "key": "NonConformityLevels",
    "location": "Audits/Settings/NonConformityLevels/",
    "actions": [{
        "label": "Add NonConformity Level",
        "action": addNonConformityLevel
    }],
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }, {
        "headerName": "Number of Days",
        "field": "Days"
    }],
    "rowActions": [{
        "label": "Edit NonConformity Level",
        action: editNonConformityLevel
    }]
};
