import {
    addHandbook,
    reviseHandbook
} from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import changeRequestAttachable from "../ChangeRequests/attachable";
import {
    revisions
} from "./internalAttachable";
import workInstructionAttachable from "../WorkInstructions/attachable";

const dataFields = [{
    "label": "Name",
    "key": "Name"
}, {
    "label": "Revision",
    "key": "Revision",
    "actionButton": true,
    "action": async ({ db, pageItem }) => {
        let rev = pageItem.Revisions[pageItem.RevisionID];
        let url = await db.download(`Database/Handbooks/${pageItem.ID}/Revisions/${pageItem.RevisionID}/${rev.File}`);
        window.open(url, "__blank")
    },
    "actionText": "Download",
    "actionClass": "bg-primary text-white hover:text-primary"
}, {
    "label": "Last Update",
    compute({ pageItem }) {
        return (new Date(pageItem.Revisions[pageItem.RevisionID].Date)).toLocaleDateString("en-gb", "en-gb", );
    }
} 
];

const menuActions = [
    {
        "label": "Revise Handbook",
        "action": reviseHandbook
    },
    {
        "label": "Download Latest",
        async action({ db,  pageItem }) {
            let rev = pageItem.Revisions[pageItem.RevisionID];
            let url = await db.download(`Database/Handbooks/${pageItem.ID}/Revisions/${pageItem.RevisionID}/${rev.File}`);
            window.open(url, "__blank");
        }
    }
];

export default [{
    "path": "/Home/Handbooks",
    "component": {...ItemListView},
    "name": "Handbooks",
    "meta": {
        "app": "Home",
        "name": "Handbooks",
        "sideNav": true,
        "sideNavSection": "Forms, Handbooks & Work Instructions",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "Handbooks",
        "dataPath": "Database/Handbooks",
        "selectionRoute": "Home/Handbooks/{ID}",
        "menuActions": [{
            "label": "Add Handbook",
            "action": addHandbook
        }],
        lock(user) {
            return [user?.Security?.Database?.Read !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Home/Handbooks/:id",
    "component": ItemView,
    "name": "Handbook",
    "meta": {
        "app": "Home",
        "name": "Handbook",
        "dataPath": "Database/Handbooks",
        "dataPathOverride": "Database/Handbooks/:id",
        "selectionRoute": "Database/Handbooks/{ID}",
        "storeData": ["Database/MFARQuestions"],
        "formMetadata": {
            "parentType": "Handbooks",
        },
        title(item) {
            return `Handbooks: ${item.Name}`;
        },
        "attachedItems": {
            "Additional Data": {
                "attachables": [revisions, workInstructionAttachable, changeRequestAttachable]
            }
        },
        "dataFields": dataFields,
        "menuActions": menuActions
    }
}];
