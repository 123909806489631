import {
    addWorkInstruction,
    deleteWorkInstruction,
    editWorkInstruction,
    reviseWorkInstruction
} from "./actions";

export default {
    "label": "Work Instructions",
    "key": "WorkInstructions",
    "location": "Database/WorkInstructions/",
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Title",
        "field": "Title"
    }, {
        "headerName": "Revision",
        "field": "Revision"
    }],
    "actions": [{
        "label": "Add Work Instruction",
        "action": addWorkInstruction
    }],
    "rowActions": [{
        "label": "Edit Work Instruction",
        "action": editWorkInstruction
    }, {
        "label": "Revise Work Instruction",
        "action": reviseWorkInstruction
    }, {
        "label": "Download Work Instruction",
        async action({ pageItem, rowItem, db }) {
            let file = rowItem.Revisions[rowItem.RevisionID].File;
            if (file) {
                let url = await db.download(`Database/Handbooks/${pageItem.ID}/Procedures/${rowItem.ID}/Revisions/${rowItem.RevisionID}/${file}`);
                window.open(url, "__blank");
            }
        },
        show({ rowItem }) {
            return [!!rowItem?.Revisions[rowItem?.RevisionID]?.File, "No File Found"]
        }
    }, {
        "label": "Cancel Procedure",
        "action": deleteWorkInstruction,
        "break": true,
        "class": "text-red"
    }]
};
