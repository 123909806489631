import {
    addFlightCondition,
    editFlightCondition,
    linkFlightCondition
} from "./actions.js";

export default {
    "label": "Flight Conditions",
    "key": "FlightConditions",
    "location": "Database/FlightConditions/",
    show() {
        return true
    },
    "actions": [{
        "label": "Add Flight Condition",
        "action": addFlightCondition,
        show({ meta }) {
            return meta?.ParentType === "Commercials" ? [true, ''] : [false, 'Can only add a new Flight Condition to a Commercial']
        }
    }, {
        "label": "Link Flight Condition",
        "action": linkFlightCondition
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Title",
        "field": "Title"
    }, {
        "headerName": "Responsible Engineer",
        "field": "ResponsibleEngineer"
    }],
    "rowActions": [{
        "label": "Open Flight Condition",
        action({ router, rowItem }) {
            router.push(`/Database/FlightConditions/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Flight Condition",
        "action": editFlightCondition
    }]
}
