<template>
  <div class="w-full flex flex-col">
    <div class="m-2 p-2 bg-white rounded shadow flex flex-col" style="height: 100%; max-height: 100%;">
      <div class="flex flex-row items-center">
        <p class="p-2 text-lg font-medium text-primary flex-grow">
          Appendix 2 - {{ state.showLatest ? "Unapproved" : "Approved" }} Signatory Matrix
        </p>
        <!-- <div class="
            mr-4
          " v-if="changesDetected">
          <div class="flex flex-row items-center">
            <p class="font-bold">TEST</p>
          </div>
        </div> -->
        <IconMenu :actions="menuActions" :contextData="{ store, form, db }" />
      </div>
      <div class="grid grid-cols-1 grid-gap-2 md:grid-cols-3">
        <Value label="Current Revision" :value="currentRevision?.Revision" v-if="!state.showLatest" />
        <Value label="Date Revised" :value="new Date(currentRevision?.Date).toLocaleDateString(
            'en-gb',
            standardDateLocal
          )
            " v-if="!state.showLatest" />
        <SignatoryKey />
      </div>
      <Value label="Changes" :value="changeIdentifier(store?.state?.currentSignatoryMatrixRevision?.Signatories, store?.state['Matrices/SignatoryMatrix/Signatories'])" v-if="state.showLatest" grow />
      <DataTable :tableSettings="{ ...signatoryMatrixTableConfig, columnState: signatoryMatrixTableConfig.columnDefs }"
        :items="people" @itemSelected="itemSelected" :paginationPageSize="50" ref="table" />
    </div>
  </div>
  <DynamicForm ref="form" @submitted="getData" />
</template>
<script setup>
import { ref, computed, onMounted, watch, reactive } from "vue";
import DynamicForm from "../../../components/DynamicForm/DynamicForm.vue";
import { DataTable, ConfirmationInput, IconMenu, Value } from "@comp";
import _signatoryMatrixTableConfig from "./signatoryMatrixTableConfig.js";
import SignatoryKey from "./SignatoryKey.vue";
import {
  addEmployee,
  reviseSignatoryMatrix,
} from "./actions.js";
import changeIdentifier from "./changeIdentifier.js";
import { db } from "../../../db.js";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { matrixReport } from "./reports";
import { next, standardDateLocal } from "../../../utils";
import { isEqual } from "lodash";
// import {
//   Value
// } from "@comps/index"
let form = ref(null);

const store = useStore();
const router = useRouter(null);
const route = useRoute();
const table = ref(null);
const state = reactive({
  showCancelled: false,
  showLatest: route.meta.latest,
});

const people = computed(() => {

  state.showLatest = route.meta.latest;

  if (!store.state["Matrices/SignatoryMatrix/Signatories"]) return null;
  else {

    let arrayOfSignatories = state.showLatest ? Object.values(
      store.state["Matrices/SignatoryMatrix/Signatories"]
    ) : Object.values(store?.state?.currentSignatoryMatrixRevision?.Signatories || {});

    let x = arrayOfSignatories.filter((i) => {
      return i.Cancelled === state.showCancelled;
    }).map((form) => {

      const panels = Object.fromEntries(
        Object.entries(form.Panels || {}).map(([key, value]) => {
          return [key, value.Answer === "true"];
        })
      );

      return {
        ...form,
        ...panels,
        Name: `${form.FirstName} ${form.LastName}`,
        Initial: `${form.FirstName[0]} ${form.LastName[0]}`,
      };
    });
    
    return x;
  }
});

const approvedFunctions = computed(
  () => store.state["Matrices/SignatoryMatrix/ApprovedFunctions"] || {}
);

const signatoryMatrixTableConfig = computed(() => {
  let newS = {
    ...(_signatoryMatrixTableConfig(store)),
  };
  return newS;
});

const menuActions = computed(() => {
  return [
    {
      label: "Add Form",
      action: addEmployee,
    },
    {
      label: `Download Current Matrix (Rev ${store?.state?.currentSignatoryMatrixRevision?.Revision})`,
      break: true,
      show() {

          return [!!store?.state?.currentSignatoryMatrixRevision.Signatories, "No signatories in the current revision"];
        
      },
      action() {
        download(store.state.currentSignatoryMatrixRevision, {
          store,
          form,
          db,
        });
      },
    },
    {
      label: `Download Next Matrix (Rev ${next(store?.state?.currentSignatoryMatrixRevision?.Revision) || 1
        })`,
      action() {
        download(
          {
            Signatories: store.state["Matrices/SignatoryMatrix/Signatories"],
            ChangesMade: changeIdentifier(store?.state?.currentSignatoryMatrixRevision?.Signatories, store?.state['Matrices/SignatoryMatrix/Signatories']),
            Revision: `PROPOSED NEXT REVISIONS (${next(store?.state?.currentSignatoryMatrixRevision?.Revision) ||
              1
              }) - NOT ACCEPTED`,
            Date: new Date(),
          },
          { store, form, db }
        );
      },
      show() {
 
        return [changesDetected.value, "No changes have been detected"];
      },
    },
    ...viewRevisionChanges.value,
    ...downloadOldRevisions.value,
    {
      label: "HoA Accept Changes - Revise Signatory Matrix",
      break: true,
      action: reviseSignatoryMatrix,
      show() {
        if (!store?.state?.user?.Security?.Matrices?.Reviewer) {
          return [false, "Must be HoA to revise the signatory matrix"];
        }
        
        return [changesDetected.value, "No changes have been detected"];
      },
    },
    {
      label: state.showCancelled
        ? "View Active Signatories"
        : "View Inactive Signatories ",
      break: true,
      action() {
        state.showCancelled = !state.showCancelled;
      },
      show() {
        return [
          store.state?.user?.Security?.Matrices?.HeadOfDesign === true,
          "You cannot perform this action",
        ];
      },
    },
  ];
});

const downloadOldRevisions = computed(() => {
  return Object.entries(
    store?.state["Matrices/SignatoryMatrix/Revisions"]?.Revisions || {}
  )
    .filter(([key, value]) => {
      return currentRevision.value?.Revision !== value && currentRevision.value;
    })
    .map(([key, value], index) => {
      return {
        label: `Download Matrix (Rev ${value.Revision})`,
        break: index === 0,
        async action() {
          let rev = await db.once(
            `Matrices/SignatoryMatrix/Revisions/Revisions/${key}`
          );
          download(rev, {
            store,
            form,
            db,
          });
        },
      };
    });
});

const viewRevisionChanges = computed(() => {
  return Object.entries(
    store?.state["Matrices/SignatoryMatrix/Revisions"]?.Revisions || {}
  )
    .filter(([key, value]) => {
      return currentRevision.value?.Revision !== value && currentRevision.value;
    })
    .map(([key, value], index) => {
      return {
        label: `View Matrix (Rev ${value.Revision}) Changes`,
        break: index === 0,
        async action() {
            console.log(value);

            form.value.setConfig({
              "title": "List of Revision Changes",
              "type": "view",
              "submitButtonLabel": "Close",
              "fields": [{
                "name": "changes",
                "type": "value",
                "grow": true,
                "divClass": "col-span-3",
                value: () => value.ChangesMade
              }]
            });

            form.value.toggle()
        },
      };
    });
});

const itemSelected = (e, item) => {
  router.push(`/Matrices/SignatoryMatrix/Signatories/${e.data.ID}/${state.showLatest}`);
};

const changesDetected = computed(() => {
  return !isEqual(
    store?.state?.currentSignatoryMatrixRevision?.Signatories,
    store?.state["Matrices/SignatoryMatrix/Signatories"]
  );
});

const download = (rev, cd) => {
  matrixReport(rev, cd);
};

const currentRevisionId = computed(
  () => store.state["Matrices/SignatoryMatrix/Revisions/RevisionID"]
);
const currentRevision = computed(
  () => store.state.currentSignatoryMatrixRevision
);

const getData = async () => {
  db.watch("Matrices/SignatoryMatrix/ApprovedFunctions", store);
  db.watch("Matrices/SignatoryMatrix/Signatories", store);
  db.watch("Matrices/SignatoryMatrix/Panels", store);
  db.watch("Matrices/SignatoryMatrix/Types", store);
  let id = await db.watch(
    "Matrices/SignatoryMatrix/Revisions/RevisionID",
    store
  );
  await db.watch(
    `Matrices/SignatoryMatrix/Revisions/Revisions/${id}`,
    store,
    "currentSignatoryMatrixRevision"
  );
  await db.watch("Matrices/SignatoryMatrix/Revisions/Revision", store);
  db.watch("Matrices/SignatoryMatrix/Revisions/Refs", store);

};

onMounted(() => {
  getData();
});
</script>
