export const revisionQuality = (completed = true) => {
    return {
        "Approver": {
            "By": "N/A",
            "Completed": completed,
            "Date": "N/A"
        },
        "Checker": {
            "By": "N/A",
            "Completed": completed,
            "Date": "N/A"
        },
        "Compiler": {
            "By": "N/A",
            "Completed": completed,
            "Date": "N/A"
        }
    }
};
