<template>
  <Shell :label="label" :tooltip="tooltip">
    <template v-slot:comp>
      <input
        class="
          border-b-2 border-gray
          focus:border-primary focus:border-b-2
          w-full
          text-sm
        "
        style="line-height: 36px; background: rgba(0, 0, 0, 0); outline: none"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="inputElement"
    /></template>
  </Shell>
</template>
<script setup="props">
import { defineProps, defineComponent, ref, defineExpose } from "vue";
import Shell from "./Shell.vue";

// disable attributte inheritence
defineComponent({
  inheritAttrs: false,
});

// define the component props
defineProps({
  label: String,
  tooltip: String
});

const inputElement = ref(null);
defineExpose({ inputElement });

// get the attrb on the component and bind them to the input element
// const attrs = useAttrs();
</script>
