// write a ag-grid config for a table, there should be 24 columns, each column will be a month spanning 2 years, each row will be a procedure number, with a due date. if the due date falls within the month, the cell should be green, if it is 30 days before the due date, the cell should be yellow, if it is 30 days after the due date, the cell should be red.

export default {
    paginationPageSize: 150,
    alwaysShowHorizontalScroll: true,
    paginationAutoPageSize: false,
    domLayout: "normal",
    defaultColDef: {
        sortable: true,
        filterFramework: true,
        filter: true,
        resizable: true
    },
    columnState: [{
        headerName: "Title",
        field: "Title",
        pinned: "left",
        width: 125
    }, {
        headerName: "Due Date",
        field: "_Cycle.Date.Due",
        type: "date",
        pinned: "left",
        
    }, {
        headerName: "Status",
        field: "Status",
        pinned: "left",
        width: 125
    }],
}
