const base = {
    "label": "[BASE] [OTHER]",
    "key": "[BASE][OTHER]",
    "location": "Database/[OTHER]/",
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }], 
    "fields": [{
        "label": "[BASE] Recipient Name",
        "key": "[BASE]RecipientName",
    }, {
        "label": "[BASE] Recipient Email",
        "key": "[BASE]RecipientEmail",
        }, {
        "label": "[BASE] Recipient Notes",
        "key": "[BASE]RecipientNotes",
        "class": "md:col-span-3",
        "grow": true
    }]
};

const types = ["Installation", "Manufacturer", "Operator"];
const otherTypes = ["Documents", "Drawings"];

let attachables  = [];

for(let type of types) {
    for(let otherType of otherTypes) {
        attachables.push(
            JSON.parse(
                JSON.stringify(base).replace(/\[BASE\]/g, type).replace(/\[OTHER\]/g, otherType)
            )
        );
    }
};

export { attachables };
