<template>
    <div class="bg-orange flex flex-row p-2 rounded shadow m-2 items-center" v-if="!compState.hidden">
        <font-awesome-icon icon="exclamation-circle" class="text-black"></font-awesome-icon>
        <p class="p-2 text-black">WARNING: DO NOT RELEASE. This Design Change has been locked for release. </p>
    </div>
</template>
<script setup>
import { db } from '../../../db.js';  
import { watch, reactive, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

watch(() => route.params.id, async (id) => {
    await checkDC();
});

const compState = reactive({
    hidden: false
});

async function checkDC() {
    const designChange = await db.once(`Database/DesignChanges/${route.params.id}`);
    if (!designChange?.LockedForRelease) compState.hidden = true;
    else if(designChange?.LockedForRelease === true) compState.hidden = false;
};

onMounted(async () => {
    await checkDC();
});

</script>
