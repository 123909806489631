export const addTesterType = ({form}) => {
    form.setConfig({
        "title": "Add Tester Type",
        "type": "push",
        "location": "Matrices/SignatoryMatrix/TesterTypes/",
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text",
            "required": true
        }]
    });
    form.toggle();
};

export const editTesterType = ({form, item}) => {
    form.setValues(item);
    form.setConfig({
        "title": "Edit Tester Type",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/TesterTypes/${item.ID}`,
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text",
            "required": true
        }]
    });
    form.toggle();
};

export const deleteTesterType = ({form, item}) => {
    form.setValues(item);

    form.setConfig({
        "title": "Delete Tester Type",
        "type": "delete",
        "location": `Matrices/SignatoryMatrix/TesterTypes/${item.ID}`,
        "description": "Are you sure you want to delete this tester type?"
    });
    form.toggle();
};
