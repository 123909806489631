export const addNonConformityCategory = ({ form, store }) => {
    form.setConfig({
        "title": "Add Non Conformity Category",
        "type": "push",
        "location": "/Audits/Settings/NonConformityCategories",
        "fields": [{
            "label": "Name",
            "type": "text",
            "name": "Name"
        }]
    });
    
    form.toggle();
};

export const editNonConformityCategory = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Edit Non Conformity Category",
        "type": "update",
        "location": `/Audits/Settings/NonConformityCategories/${item.ID}`,
        "fields": [{
            "label": "Name",
            "type": "text",
            "name": "Name"
        }]
    });
    form.toggle();
};
