export const addReportType = ({ form, store }) => {
    form.setConfig({
        "title": "Add Report Type",
        "type": "push",
        "location": "/Audits/Settings/ReportTypes",
        "fields": [{
            "label": "Name",
            "type": "text",
            "name": "Name"
        }]
    });
    
    form.toggle();
};

export const editReportType = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Edit Report Type",
        "type": "update",
        "location": `/Audits/Settings/ReportTypes/${item.ID}`,
        "fields": [{
            "label": "Name",
            "type": "text",
            "name": "Name"
        }]
    });
    form.toggle();
};
