<template>
  <Shell :label="label">
    <template v-slot:comp>
      <p
        :class="`w-full text-sm ${grow ? '' : 'whitespace-nowrap truncate'}`"
        :style="`line-height: 36px; ${
          !grow
            ? 'height: 36px'
            : 'height: 100%; max-height: 600px; overflow-y: auto'
        }; background: rgba(0, 0, 0, 0); outline: none;`"
        v-html="value"
      ></p>
      <button
        :class="`action ${actionClass}`"
        @click="$emit('action')"
        :disabled="disabled"
      >{{actionText}}</button>
    </template>
  </Shell>

  
</template>
<script setup="props">
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import { IconButton } from "../index.js";
import Shell from "./Shell.vue";
const router = useRouter();
// define the component props
defineProps({
  link: String,
  label: String,
  value: [String, Number],
  grow: Boolean,
  disabled: Boolean,
  actionText: String,
  actionClass: String
});

const goto = (address) => {
  router.push(address);
};

// get the attrb on the component and bind them to the input element
// const attrs = useAttrs();
</script>
