export const addUploadStatus = ({ form }) => {
    form.setConfig({
        "title": "Add Upload Status",
        "type": "push",
        "location": "Database/UploadStatuses",
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
}

export const editUploadStatus = ({ form, rowItem }) => {
    form.setValues({
        "Name": rowItem.Name
    });
    form.setConfig({
        "title": "Edit Upload Status",
        "type": "update",
        "location": `Database/UploadStatuses/${rowItem.ID}`,
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
}
