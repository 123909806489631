<template>
  <div class="w-full flex flex-col">
    <div class="m-2 p-2 bg-white rounded shadow flex flex-col" v-if="loading">
      <Loading />
    </div>
    <div class="m-2 bg-white rounded shadow flex flex-col" v-if="!loading && !isEmpty(attachedItems)" style="max-height: 100%; height: 100%">
      <div class="grid grid-cols-4" style="max-height: 100%; height: 90%">
        <div class="col-span-1">

          <p class="text-primary font-medium pl-4 my-4 text-xs">
            Settings
          </p>
          <div class="
                rounded-lg
                bg-gradient-to-br
                from-gray-100
                to-gray-150
                shadow-inner
                mx-4
                my-2
                py-2
              ">
            <div v-for="(attachedItem, index) in Object.values(attachedItems)" :key="attachedItem.key">
              <p :class="` cursor-pointer flex-1 hover:text-primary hover:font-medium transition py-1 pl-3 text-sm ${selectedAttachedItemIndex === index
                  ? 'text-primary font-medium'
                  : 'text-text'
                }`" @click="selectedAttachedItemIndex = index">
                {{ attachedItem.label }}
              </p>
            </div>
          </div>
        </div>
        <div class="rounded-lg shadow-inner my-2 col-span-3">
          <div v-for="(attachedItem, index) in Object.values(attachedItems)" :key="`_table_${attachedItem.key}`"
            v-show="selectedAttachedItemIndex === index" style="max-height: 100%; height: 100%">
            <div class="px-2 flex flex-row items-center bg-white">
              <p class="text-primary text-sm font-medium flex-grow">{{ attachedItem.label }}</p>
              <IconMenu :actions="[
                ...(attachedItem.actions || [])
              ]" :contextData="contextData" v-if="!currentRoute.meta.preventAttachedItemsActions" />
            </div>
            <DataTable :tableSettings="{ columnState: attachedItem.headers }"
              :rowClassRules="attachedItem.rowClassRules || {}" :cellClassRules="attachedItem.cellClassRules || {}"
              :items="attachedItem.items" @itemSelected="(e) => attachedItemSelected(e, attachedItem)" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <ContextMenu ref="contextMenu" />
  <DynamicForm ref="form" @submitted="getItemAndAttachedItems" />
  <VideoViewer ref="videoViewer" />
</template>
<script setup>
// import all the vue stuff
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";
// load firebase helper
import { db } from "../../../../db.js";
// Load components
import {
  IconMenu,
  Value,
  Loading,
  DataTable,
  ContextMenu,
} from "@comp/index.js";
import DynamicForm from "../../../../components/DynamicForm/DynamicForm.vue";
import VideoViewer from "../../../../components/VideoViewer/VideoViewer.vue";
// extra
import { get, has, isEmpty, sortBy, chunk, uniqBy } from "lodash";

// inistalise vue objects
const store = useStore();
const route = useRoute();
const router = useRouter();

// make them accessable in the template
const currentRoute = computed(() => route);
const dataFields = computed(() => get(currentRoute.value, "meta.dataFields"));
const menuActions = computed(() => {
  let ma = get(currentRoute.value, "meta.menuActions", []);
  return [...ma, standardMenuActions.openInFirebase, standardMenuActions.back];
});

// set the component refs
let contextMenu = ref(null);
let form = ref(null);
let videoViewer = ref(null);
let selectedAttachedItemIndex = ref(0);
let showCancelled = ref(false);

// init page item and attached items
let loading = ref(true);
let item = computed(() => store.state[currentRoute.value.path]);
let attachedItems = ref({});

// init context data, this object is passed to the page configs, attached items configs and all actions
let contextData = computed(() => {
  return {
    pageItem: item.value,
    attachedItems: attachedItems.value,
    currentRoute: currentRoute.value,
    form: form.value,
    videoViewer: videoViewer.value,
    contextMenu: contextMenu.value,
    store,
    router,
    db,
    meta: currentRoute.value.meta.formMetadata,
  };
});

const toggleShowCancelled = () => {
  showCancelled.value = !showCancelled.value;
  getItemAndAttachedItems();
};

async function getItemAndAttachedItems() {
  // set loading to true
  loading.value = true;
  attachedItems.value = {};

  // if no attached items then kill function here
  if (!has(currentRoute.value, "meta.attachedItems")) return;

  // get the attached items of the item;
  const metaAttachedItems = get(currentRoute.value, "meta.attachedItems");

  // now make the calls to get tghe items we wantt tto view
  await Promise.all(
    metaAttachedItems.map((ai) => db.watch(ai.location, store))
  );

  attachedItems.value = Object.fromEntries(
    metaAttachedItems.map((ai) => {
      return [ai.key, {
        ...ai, items: Object.values(
          get(store.state, ai.location, {}) || {}
        )
      }];
    })
  );

  loading.value = false;
}

// When an item is selected in the attached items tables
const attachedItemSelected = (e, attachedItem) => {
  contextMenu.value.toggle(
    e,
    attachedItem,
    {
      ...contextData.value,
      rowItem: e.data,
      item: e.data,
    },
    e.data.Number
  );
};

const buildHeaders = (headers) => {
  return headers.map((attachedItemToGetHeader) => {
    if (attachedItemToGetHeader.fieldGetter) {
      attachedItemToGetHeader.field = attachedItemToGetHeader.fieldGetter(
        contextData.value
      );
      delete attachedItemToGetHeader.fieldGetter;
    }
    return attachedItemToGetHeader;
  });
};

// compute the value from the list of data points
const getValue = (item, field) => {
  let v = field.compute ? field.compute({ item }) : get(item, field.key);

  switch (field.type) {
    case "date":
      return new Date(v).toLocaleDateString("en-gb", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    default:
      return v;
  }
};

// compute the link in the Value components
const getLink = (link) => {
  if (!link) return null;
  else {
    let linkParts = link
      .split("/")
      .map((part) => {
        if (part.includes(":")) return get(item.value, part.replace(":", ""));
        else return part;
      })
      .join("/");
    return `/${linkParts}`;
  }
};

watch(currentRoute.value, getItemAndAttachedItems);
watch(item, getItemAndAttachedItems);
// watch(store.state, getItemAndAttachedItems);

onMounted(getItemAndAttachedItems);
</script>
