import { makeAttached, standardDateLocal } from "../../utils";

import { differenceInBusinessDays } from "date-fns";

const doesEventSpanEntitlementYear = (startDate, endDate) => {
    console.log(startDate, endDate);

    let startYear = getFinancialYear(startDate);
    let endYear = getFinancialYear(endDate);

    console.log(startYear, endYear);

    return startYear !== endYear;
};

export const addEvent = ({ form, meta, store, db }) => {
    form.setValues({
        "Users": [store.state.user.ID]
    })
    form.setConfig({
        "title": "Add Leave Event",
        "type": "push",
        "location": "Leave/Events",
        "fields": [{
            "label": "Start Date",
            "type": "value",
            value() {
                return meta.startDate.toLocaleDateString("en-gb", standardDateLocal) + ` (${getFinancialYear(meta.startDate)})`
            }
        },
        {
            "label": "End Date",
            "type": "value",
            value() {
                let shownDate = new Date(meta.endDate.getTime());
                shownDate.setTime(shownDate.getTime() - 1000);
                return shownDate.toLocaleDateString("en-gb", standardDateLocal)
            }
        }, {
            "label": "Days Requested",
            "type": "value",
            value() { return differenceInBusinessDays(meta.endDate, meta.startDate) }
        }, {
            "label": "Leave Type",
            "type": "dropdown",
            "name": "Type",
            "dataLocation": "business/leave/events",
            "labelKey": "name",
            "idKey": "key"
        }, {
            "label": "Reason (Required for site visit)",
            "name": "Reason",
            "type": "text",
            "divClass": "col-span-2",
            "required": false
        }, {
            "label": "Who",
            "name": "Users",
            "type": "dropdown",
            "multiple": true,
            "dataLocation": "users/",
            dataFilter(user) {


                return user.main === true
            },
            "labelKey": "name",
            "idKey": "key",
            // "valueFormat": "object"
        }, {
            "label": "Job Card",
            "name": "JobCardID",
            "type": "dropdown",
            "dataLocation": "Database/JobCards",
            "labelKey": "Number",
            "idKey": "ID",
            "required": false,
            show({ liveInputData }) {
                return liveInputData.Type === "--event04"
            }
        }, {
            "label": "Duration",
            "name": "Days[Name]",
            "type": "dropdown",
            "dropdownData": [{
                "label": "All Day - AD",
                "id": "All Day"
            }, {
                "label": "Morning - AM",
                "id": "Morning"
            }, {
                "label": "Afternoon - PM",
                "id": "Afternoon"
            }],
            show({ liveInputData }) {
                return differenceInBusinessDays(meta.endDate, meta.startDate) === 1
            }
        }, {
            "label": "Reoccuring",
            "name": "Reoccuring",
            "type": "dropdown",
            "dropdownData": [{
                "label": "No",
                "id": 0
            }, {
                "label": "Every Week",
                "id": 1
            }, {
                "label": "Every 2 Weeks",
                "id": 2
            }, {
                "label": "Every 4 Weeks",
                "id": 4
            }]
        }],
        preSubmitHook({ data, liveInputData, dropdownData }) {
            let eventType = dropdownData.Type.find(et => et.id === liveInputData.Type);
            let dayType = data.Days?.Name || "All Day";

            let eventSpansEntitlementYear = doesEventSpanEntitlementYear(meta.startDate, meta.endDate);
            if (eventSpansEntitlementYear) {
                let copyOfOriginalEndDate = new Date(meta.endDate.getTime());
                let newStartDate = new Date(getFinancialYear(meta.endDate), 3, 1);

                meta.endDate.setMonth(2);
                meta.endDate.setDate(31);

                db.push(`Leave/Events`, {
                    "Approved": !eventType.meta.needs_approval,
                    "Date": {
                        "End": copyOfOriginalEndDate.toISOString(),
                        "Start": newStartDate.toISOString(),
                        "Time": newStartDate.getTime(),
                        "Year": getFinancialYear(copyOfOriginalEndDate)
                    },
                    "Type": eventType.id,
                    "Days": {
                        "Amount": dayType !== "All Day" ? 0.5 : differenceInBusinessDays(copyOfOriginalEndDate, newStartDate),
                        "Name": dayType || "All Day"
                    },
                    "Users": makeAttached(data.Users)
                })
            }

            return {
                "Approved": !eventType.meta.needs_approval,
                "Date": {
                    "End": meta.endDate.toISOString(),
                    "Start": meta.startDate.toISOString(),
                    "Time": meta.startDate.getTime(),
                    "Year": getFinancialYear(meta.startDate),
                    "Created": new Date().toISOString()
                },
                "Type": eventType.id,
                "Days": {
                    "Amount": dayType !== "All Day" ? 0.5 : differenceInBusinessDays(meta.endDate, meta.startDate),
                    "Name": dayType || "All Day"
                },
                "Users": makeAttached(data.Users)
            }
        },
        async postSubmitHook({ db, newData }) {
            newData.Reoccuring = parseInt(newData.Reoccuring);
            if (newData.Reoccuring > 0) {
                for (let i = 1; i < 10; i++) {
                    //now add 10 records every x weeks, where x is the reoccuring value
                    let startDate = new Date(newData.Date.Start);
                    startDate.setDate(startDate.getDate() + ((i * 7) * newData.Reoccuring));
                    let endDate = new Date(newData.Date.End);
                    endDate.setDate(endDate.getDate() + ((i * 7) * newData.Reoccuring));
                    await db.push(`Leave/Events`, {
                        ...newData,
                        "ID": null,
                        "Date": {
                            "Start": startDate.toISOString(),
                            "End": endDate.toISOString(),
                            "Time": startDate.getTime(),
                            "Year": getFinancialYear(startDate),
                            "Created": new Date().toISOString()
                        }
                    });
                }
            }
        }

    });
    form.getDropdownData();
    form.toggle();
}

function getFinancialYear(date = new Date()) {
    date = date.toISOString().split("T")[0];
    const year = date.split("-")[0];
    return (date < `${year}-04-01` ? year - 1 : year).toString();
}

export const cancelEventFromCalendar = ({ form, item, db }) => {
    form.setConfig({
        "title": "Cancel Leave Event",
        "description": "Are you sure you want to cancel this leave event?",
        "type": "delete",
        "location": `Leave/Events/${item.extendedProps.meta.ID}`,
        "fields": [],
        postSubmitHook() {
            let users = Object.keys(item.extendedProps.meta.Users);
            for (let id of users) {
                db.delete(`Userdata/${id}/Leave/-${item.extendedProps.meta.Date.Year}/Events/${item.extendedProps.meta.ID}`);
            }
            item.remove()
        }
    });
    form.toggle();
};

export const cancelEvent = ({ form, item, db, instance }) => {
    form.setConfig({
        "title": "Cancel Leave Event",
        "description": "Are you sure you want to cancel this leave event?",
        "type": "delete",
        "location": `Leave/Events/${item.ID}`,
        "fields": [],
        "submitButtonLabel": "Cancel Leave Event",
        "submitButtonClass": "cancel",

        postSubmitHook() {
            let users = Object.keys(item.Users);
            for (let id of users) {
                db.delete(`Userdata/${id}/Leave/-${item.Date.Year}/Events/${item.ID}`);
            }

            if (instance) {
                instance.setupState.getListData();
                // instance.remove();
            }

        }
    });
    form.toggle();
};

export const approveEventFromCalendar = ({ form, item }) => {
    form.setConfig({
        "title": "Approve Leave Event",
        "type": "update",
        "location": `Leave/Events/${item.extendedProps.meta.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Approved": true,
                "ApprovedBy": store.getters.userID,
                "Date": {
                    "Approved": new Date().toISOString()
                }
            };
        }
    });
    form.toggle();
};

export const approveEvent = ({ form, item, db, instance, store }) => {
    form.setConfig({
        "title": "Leave Event",
        "description": `Are you sure you want to approve this leave event?`,
        "type": "update",
        "submitButtonLabel": "Approve",
        "location": `Leave/Events/${item.ID}`,
        "fields": [],
        "additionalButtons": [{
            "label": "Cancel Leave Event",
            "action": () => {
                form.toggle();
                cancelEvent({ form, item, db, instance });
            },
            "class": "cancel"
        }],
        preSubmitHook() {
            return {
                "Approved": true,
                "ApprovedBy": store.getters.userID,
                "Date": {
                    "Approved": new Date().toISOString()
                }
            };
        }
    });
    form.toggle();
};

export const requestInLieu = ({ form, store }) => {
    form.setConfig({
        "title": "Request time off in lieu",
        "type": "push",
        "location": `Leave/Requests`,
        "fields": [{
            "label": "Number of days",
            "name": "Days",
            "type": "number"
        }, {
            "label": "Reason",
            "name": "Reason",
            "type": "text",
            "divClass": "md:col-span-2"
        }],
        preSubmitHook() {
            return {
                "UserID": store.getters.userID
            }
        }
    });
    form.toggle();
};

export const approveInLieu = ({ form, item, db, }) => {
    form.setValues(item);

    form.setConfig({
        "title": "Approve Time Off In Lieu",
        "type": "update",
        "location": `Leave/Requests/${item.ID}`,
        "fields": [{
            "label": "Name",
            "type": "value",
            value() {
                return item.Name
            }
        }, {
            "label": "Days",
            "type": "value",
            value() {
                return item.Days
            }
        }, {
            "label": "Reason",
            "type": "value",
            "divClass": "md:col-span-3",
            value() {
                return item.Reason
            }
        }],
        preSubmitHook() {
            return {
                "Completed": true,
                "ApprovedBy": store.getters.userID,
                "Date": {
                    "Approved": new Date().toISOString()
                }
            }
        },
        async postSubmitHook() {
            let a = await db.once(`Userdata/${item.UserID}/Leave/-${getFinancialYear()}/InLieuAllowance`);
            db.set(`Userdata/${item.UserID}/Leave/-${getFinancialYear()}/InLieuAllowance`, parseFloat(a) + parseFloat(item.Days));
        }
    });
    form.toggle();
};

export const addDaysInLieu = ({ form, item }) => {
    form.setConfig({
        "title": ""
    });
};

export const adjustAllowance = ({ form, store, state, route }) => {

    form.setValues({
        "Allowance": state.leave.holidayAllowance
    });

    form.setConfig({
        "title": "Update Allowance",
        "type": "update",
        "location": `Userdata/${route.params.id}/Leave/-${state.year}`,
        "fields": [{
            "label": "Allowance",
            "name": "Allowance",
            "type": "number"
        }]
    });

    form.toggle();
}
