
const reasons = [
    "Crew Training",
    "Delivering or exporting an aircraft",
    "Flying the aircraft for customer acceptance",
    "Flying the aircraft for authority acceptance",
    "Flying the aircraft to a location where maintenance or airworthiness review are to be performed, or to a place of storage",
    "Flying an aircraft for trouble - shooting purposes or to check the functioning of one or more systems, parts, or appliances after maintenance"
].map(reason => {
    return {
        id: reason,
        label: reason
    };
});

export default {
    "title": "Reason for Request",
    "submitButtonText": "Save & Next",
    "fields": [{
        "label": "Reason for Request",
        "name": "checked_reason",
        "type": "dropdown",
        "dropdownOptions": "reasons",
        "required": true
    }],
    dataLoader() {
        return {
            reasons: reasons
        };
    }
};
