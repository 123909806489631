import {
    appConfig,
    pad
} from "../../utils";

import {
    revisionQuality
} from "../Database/Documents/documentUtils.js";

export async function downloadFile({ db, rowItem, pageItem }) {

    // try down load from the FlightTestCrew folder first if not found then try the CCR/CVs folder

    let url = await db.download(`/FlightTestCrew/${pageItem.ID}/Files/${rowItem.File}`);

    if (url === null) {
        url = await db.download(`/CCR/CVs/${pageItem.raw.OldID || pageItem.ID}/${rowItem.File}`);
    }

    window.open(url, "__blank");
};

export async function downloadFile2({ db, rowItem, pageItem }) {
    try {
        let url = await db.download(`/OpenForms/FlightTestCrew/${pageItem.raw.OldID || pageItem.ID}/${rowItem.File}`);
        window.open(url, "__blank");
    } catch (err) {
    }
};

function ccrFtaPreSubmitHook(pageItem) {

    let ccrFta = {};

    if (pageItem.CCR) {
        ccrFta.TEMP_CCR = pageItem.CCR;
    }

    if (pageItem.FTA) {
        ccrFta.TEMP_FTA = pageItem.FTA;
    }

    return ccrFta;
}

export const addNewApplicationUser = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add New Application User",
        "type": "push",
        "location": "Home/NewFlightUser",
        "fields": [{
            "label": "First Name",
            "name": "FirstName",
            "type": "text"
        }, {
            "label": "Last Name",
            "name": "LastName",
            "type": "text"
        }, {
            "label": "Email",
            "name": "Email",
            "type": "text"
        }]
    });

    form.toggle();
};

export const updateApplication = ({ form, pageItem }) => {
    form.setValues({
        "User[DateOfBirth]": pageItem?.raw?.User?.DateOfBirth,
        "Credentials[Expiry]": pageItem?.raw?.Credentials?.Expiry,
        "CRM[Date]": pageItem?.raw?.CRM?.Date
    });
    form.setConfig({
        "title": "Update Application",
        "type": "update",
        "location": `FlightTestCrew/${pageItem.ID}`,
        "fields": [{
            "label": "Date of Birth",
            "type": "date",
            "name": "User[DateOfBirth]"
        }, {
            "label": "Role",
            "type": "dropdown",
            "name": "Role",
            "dropdownData": [{
                "label": "Pilot",
                "id": "Pilot"
            }, {
                "label": "Lead Flight Test Engineer",
                "id": "Lead Flight Test Engineer"
            }, {
                "label": "Flight Test Engineer",
                "id": "Flight Test Engineer"
            }, {
                "label": "Flight Engineer (Third crew member)",
                "id": "Flight Engineer (Third crew member)"
            }, {
                "label": "Other (Observer , Test Equipment Operator)",
                "id": "Other (Observer , Test Equipment Operator)"
            }]
        },{
            "label": "Medical Expiry",
            "type": "date",
            "name": "Credentials[Expiry]",
            show() {
                return pageItem.Role === 'Pilot';
            }
        }, {
            "label": "CRM Completed Date",
            "type": "date",
            "name": "CRM[Date]",
            "required": false,
            show() {
                return pageItem.Role === 'Pilot';
            }
        }],
        preSubmitHook() {
            return ccrFtaPreSubmitHook(pageItem);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const setLimitations = ({ form, pageItem }) => {
    form.setValues({
        "Limitations": pageItem.Limitations
    });
    form.setConfig({
        "title": "Update Application",
        "type": "update",
        "location": `FlightTestCrew/${pageItem.ID}`,
        "fields": [{
            "label": "Limitations",
            "type": "text",
            "name": "Limitations"
        }],
        preSubmitHook() {
            return ccrFtaPreSubmitHook(pageItem);
        }
    });
    form.toggle();
};

export const uploadFile = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add File",
        "type": "push",
        "location": `FlightTestCrew/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file",
            "accept": ".pdf,.doc,.docx"
        }],
        preSubmitHook() {
            return ccrFtaPreSubmitHook(pageItem);
        },
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.set(`FlightTestCrew/${pageItem.ID}/Files/${typeRef.key}/File`, file[0].name);
                await db.upload(`CCR/CVs/${pageItem.ID}`, file[0]);
            }
        }
    });
    form.toggle();
};

export const uploadFile2 = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add File",
        "type": "push",
        "location": `OpenForms/FlightTestCrew/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file",
            "accept": ".pdf,.doc,.docx"
        }],
        preSubmitHook() {
            return ccrFtaPreSubmitHook(pageItem);
        },
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.set(`OpenForms/FlightTestCrew/${pageItem.ID}/Files/${typeRef.key}/File`, file[0].name);
                await db.upload(`OpenForms/FlightTestCrew/${pageItem.ID}`, file[0]);
            }
        }
    });
    form.toggle();
};

export const accept = ({ form, pageItem, store, db }) => {
    form.setConfig({
        "title": "Accept Application",
        "type": "update",
        "location": `FlightTestCrew/${pageItem.ID}/`,
        "fields": [],
        preSubmitHook() {
            let ccrFta = ccrFtaPreSubmitHook(pageItem);
            return {
                ...pageItem.raw,
                ...ccrFta,
                "Accepted": true,
                "Approved": false,
                "AcceptedByID": store.getters.userID,
                "AcceptedBy": store.getters.userName,
                "Date": {
                    "Accepted": (new Date()).toISOString()
                }
            }
        },
        postSubmitHook() {
            db.update(`OpenForms/FlightTestCrew/${pageItem.ID}`, {
                "Accepted": true
            });
        }
    });
    form.toggle();
};

export const approve = ({ form, pageItem, db, store }) => {
    form.setConfig({
        "title": "Approve Application",
        "type": "update",
        "location": `FlightTestCrew/${pageItem.ID}/`,
        "fields": [],
        async preSubmitHook() {
            let additionalData = {
                "Approved": true,
                "Date": {
                    "Approved": (new Date()).toISOString()
                },
                "ApprovedBy": store.getters.userName,
                "ApprovedByID": store.getters.userID
            };
            if (!pageItem.raw.FTA) {
                if (
                    pageItem.Role === "Pilot" ||
                    pageItem.Role === "Flight Engineer (Third crew member)"
                ) {
                    const fta = await createFTA(db, store);
                    additionalData.TEMP_FTA = fta.Number;
                    additionalData.FTA = fta.Number;
                    additionalData.FTAID = fta.ID;
                }
            }
            if (!pageItem.raw.CCR) {
                const ccr = await createCCR(db, store);
                additionalData.TEMP_CCR = ccr.Number;
                additionalData.CCR = ccr.Number;
                additionalData.CCRID = ccr.ID;
            }
            return additionalData;
        }
    });
    form.toggle();
};

export const reappove = ({ form, pageItem, db, store }) => {
    form.setConfig({
        "title": "Re-Approve Application",
        "type": "update",
        "location": `FlightTestCrew/${pageItem.ID}/`,
        "fields": [],
        async preSubmitHook() {
            let additionalData = {
                "Approved": true,
                "Date": {
                    "Approved": (new Date()).toISOString()
                },
                "ApprovedBy": store.getters.userName,
                "ApprovedByID": store.getters.userID
            };
            return additionalData;
        }
    });
    form.toggle();
}

async function createFTA(db, store) {
    let ftaType = await db.once("Database/DocumentTypes/-LZ_IOR6HIrxJGP2jnT5");
    let baseDocumentData = getBaseDocument(store);
    let newFTA = {
        ...baseDocumentData,
        "Number": `${appConfig.docPrefix}FTA-${pad(ftaType.Count)}`,
        "Parent": `${appConfig.docPrefix}FTA-0000`,
        "ParentID": "-LZ_-c6evTCh8UQ9Y5VS",
        "ParentType": "Documents",
        "Links": {
            "-LZ_-c6evTCh8UQ9Y5VS": "---R1"
        },
        "Type": "FTA",
        "TypeID": "-LZ_IOR6HIrxJGP2jnT5"
    };
    let newDocRef = await db.push("Database/Documents", newFTA);
    let newDocData = await newDocRef.once("value");
    return newDocData.val();
}

async function createCCR(db, store) {
    let ccrType = await db.once("Database/DocumentTypes/-L_HDril6OpjoEnhaAIK");
    let baseDocumentData = getBaseDocument(store);
    let newCCR = {
        ...baseDocumentData,
        "Number": `${appConfig.docPrefix}CCR-${pad(ccrType.Count)}`,
        "Parent": appConfig.baseDesignChange,
        "ParentID": appConfig.baseDesignChangeID,
        "ParentType": "DesignChanges",
        "Links": {
            [appConfig.baseDesignChangeID]: "---R1"
        },
        "Type": "CCR",
        "TypeID": "-L_HDril6OpjoEnhaAIK"
    };
    let newDocRef = await db.push("Database/Documents", newCCR);
    let newDocData = await newDocRef.once("value");
    return newDocData.val();
}

function getBaseDocument(store) {
    return {
        "Cancelled": false,
        "CreatedBy": store.getters.userName,
        "ExpectedTime": 1,
        "Date": {
            "Created": (new Date()).toISOString(),
            "Cancelled": "N/A",
            "Signedoff": "N/A"
        },
        "SignedOff": true,
        "Revision": "1",
        "RevisionID": "---R1",
        "Revisions": {
            "---R1": {
                "Revision": "1",
                "Reason": "Document Created",
                "ID": "---R1",
                "Date": (new Date()).toISOString(),
                "Quality": revisionQuality()
            }
        }
    };
};

export const sendApprovalEmail = ({ form }) => {
    form.setConfig({
        "type": "update",
        "location": `FlightTestCrew/${pageItem.ID}/`,
        "fields": [{
            "label": "Optional Message",
            "required": false,
            "name": "ApprovedMessage",
            "type": "text",
            "class": "md:col-span-3"
        }],
        preSubmitHook() {
            return {
                "SendApprovalEmail": true,
            }
        }
    });
};

export const removeApplication = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Remove Application",
        "description": "Are you sure you want to remove this application?",
        "type": "delete",
        "location": `OpenForms/FlightTestCrew/${pageItem.ID}`,
        "fields": []
    });
    form.toggle();
};

export const updateEmergencyContacts = initalLocation => ({ form, pageItem }) => {
    form.setValues({
        ...pageItem.EmergencyContact
    });
    form.setConfig({
        "title": "Update Emergency Contact",
        "type": "update",
        "location": `${initalLocation}/${pageItem.ID}/EmergencyContact`,
        "fields": [{
            "label": "First Name",
            "name": "FirstName",
            "type": "text"
        }, {
            "label": "Last Name",
            "name": "LastName",
            "type": "text"
        }, {
            "label": "Number",
            "name": "Phone",
            "type": "text"
        }, {
            "label": "Relation",
            "name": "Relation",
            "type": "text"
        }],
        preSubmitHook() {
            return ccrFtaPreSubmitHook(pageItem);
        }
    });
    form.toggle();
};

export const override = ({ form, pageItem }) => {

    form.setValues(pageItem.raw.Override || {
        "CatOneCS23": "No Override",
        "CatOneCS23P": "No Override",
        "CatOneCS25": "No Override",
        "CatOneCS27": "No Override",
        "CatOneCS29": "No Override",
        "CatTwoCS23": "No Override",
        "CatTwoCS23P": "No Override",
        "CatTwoCS25": "No Override",
        "CatTwoCS27": "No Override",
        "CatTwoCS29": "No Override",
        "CatFourCS23": "No Override",
        "CatFourCS23P": "No Override",
        "CatFourCS25": "No Override",
        "CatFourCS27": "No Override",
        "CatFourCS29": "No Override",
        "OverrideInfo": ""
    });

    let cats = [
        "One",
        "Two",
        "Four"
    ];

    let acTypes = [
        "CS23",
        "CS23P",
        "CS25",
        "CS27",
        "CS29"
    ];

    let fields = [];

    cats.forEach((cat) => {
        acTypes.forEach(ac => {
            fields.push({
                "name": `Cat${cat}${ac}`,
                "label": `Override: Cat ${cat} ${ac}`,
                "type": "dropdown",
                "dropdownData": [{
                    "label": "No Override",
                    "id": "No Override"
                }, {
                    "label": "PiC",
                    "id": "PiC"
                }, {
                    "label": "CoP",
                    "id": "CoP"
                }]
            })
        });
    });

    form.setConfig({
        "title": "Select Override",
        "type": "update",
        "location": `FlightTestCrew/${pageItem.ID}/Override`,
        "fields": [...fields, {
            "label": "Override Information",
            "name": "OverrideInfo",
            "type": "text",
            "divClass": "md:col-span-3"
        }]
    });

    form.getDropdownData();

    form.toggle();
}

export const updateLast12Months = ({ form, pageItem, rowItem }) => {
    form.setValues(rowItem);

    let index = pageItem.raw.FlightHours.Last.findIndex(i => i.Type === rowItem.Type);

    form.setConfig({
        "title": "Update Last 12 Months",
        "type": "update",
        "location": `FlightTestCrew/${pageItem.ID}/FlightHours/Last/${index}`,
        "fields": [{
            "label": "Expiry Date",
            "name": "Expiry",
            "type": "date"
        }, {
            "name": "FlightHoursCO",
            "label": "Flight Hours CO",
            "type": "number"
        }, {
            "name": "FlightHoursCOTest",
            "label": "Flight Hours CO (Test)",
            "type": "number"
        }, {
            "name": "FlightHoursPIC",
            "label": "Flight Hours PIC",
            "type": "number"
        }, {
            "name": "FlightHoursCOTest",
            "label": "Flight Hours CO (Test)",
            "type": "number"
        }],
    });
    form.toggle();
}

export const sendBackToApplicant = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Send Back To Applicant",
        "description": "Are you sure you want to send this application back to the applicant?",
        "type": "update",
        "location": `/OpenForms/FlightTestCrew/${pageItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return pageItem.raw;
        }
    });
    form.toggle();
};

export const deleteIncompleteApplication = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Delete Incomplete Application",
        "description": "Are you sure you want to delete this incomplete application?",
        "type": "delete",
        "location": `/OpenForms/FlightTestCrew/${pageItem.ID}`,
        "fields": []
    });
    form.toggle();
}

const explainer = `
<h2><strong>Competency Levels</strong></h2>
<ul>
  <li><strong>Competency Level 1</strong>: The pilot has at least 100 test flight hours, 50 total flight hours in the last period, and 20 test flight hours in the last period for the specified aircraft type.</li>
  <li><strong>Competency Level 2</strong>: The pilot has at least 50 test flight hours, 50 total flight hours in the last period, and 20 test flight hours in the last period for the specified aircraft type.</li>
  <li><strong>Competency Level 4</strong>: The pilot has at least 1000 Pilot-in-Command (PIC) flight hours and 50 total flight hours in the last period for the specified aircraft type.</li>
</ul>
`;

export const viewCatOneRequirements = ({ form }) => {
    form.setConfig({
        "title": "Requirements",
        "type": "view",
        "description": explainer,
        "fields": []
    });
    form.toggle();
}

export const editAircraftType = async ({ form, pageItem, rowItem }) => {

    form.setValues(rowItem);

    form.setConfig({
        "title": `Add Flight Hours for ${rowItem.Type}`,
        "type": "update",
        "location": `FlightTestCrew/${pageItem.ID}/NewFlightHours/${rowItem.ID}`,
        "fields": [{
            "label": "Total Flight Hours (All Time)",
            "info": "This is the total number of hours you have flown this aircraft type. This includes all PIC, CO, Test and Training hours.",
            "name": "Hours",
            "type": "number"
        }, {
            "label": "Test Flight Hours (All Time)",
            "info": "This is the total number of hours you have flown this aircraft type as a TEST pilot.",
            "name": "Test",
            "type": "number"
        }, {
            "type": "checkbox",
            "label": "Do you have more than 1000 hours as Pilot in Command?",
            "name": "MoreThan1000PiC",
            "info": "If you have more than 1000 hours as Pilot in Command, you can skip the next section.",
            "required": false
        },{
            "type": "sectionHeader",
            "label": "Pilot in Command Hours - Last 12 Months",
            "divClass": "md:col-span-3"
        }, {
            "label": "Total PIC Hours (Last 12 Months)",
            "info": "This is the total number of hours you have flown this aircraft type as a pilot in command in the last 12 months as of today.",
            "name": "FlightHoursPIC",
            "type": "number"
        }, {
            "label": "Total PIC Test Hours (Last 12 Months)",
            "info": "This is the total number of hours you have flown this aircraft type as a TEST pilot in command in the last 12 months as of today.",
            "name": "FlightHoursPICTest",
            "type": "number"
        }, {
            "type": "sectionHeader",
            "label": "Co-Pilot Hours - Last 12 Months",
            "divClass": "md:col-span-3"
        }, {
            "label": "Total CO Hours (Last 12 Months)",
            "info": "This is the total number of hours you have flown this aircraft type as a co-pilot in the last 12 months as of today.",
            "name": "FlightHoursCO",
            "type": "number"
        }, {
            "label": "Total CO Test Hours (Last 12 Months)",
            "info": "This is the total number of hours you have flown this aircraft type as a TEST co-pilot in the last 12 months as of today.",
            "name": "FlightHoursCOTest",
            "type": "number"
        }],
        preSubmitHook() {
            return {
                "LastUpdated": new Date().toISOString(),
            };
        }
    });

    form.toggle();
};

export const editApplicationNotes = async ({ form, pageItem }) => {

    form.setValues({
        "Notes": pageItem.raw.Notes
    });

    form.setConfig({
        "title": "Edit Application Notes",
        "type": "update",
        "location": `FlightTestCrew/${pageItem.ID}`,
        "fields": [{
            "label": "Notes",
            "name": "Notes",
            "type": "richtext",
            "divClass": "md:col-span-3"
        }]
    });

    form.toggle();

};
