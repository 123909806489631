import { makeAttached, next } from "../../../utils";

import changeIdentifier from "./changeIdentifier";
import {
    get
} from "lodash";

async function getRequiredData(db, store) {
    await db.watch("Matrices/SignatoryMatrix/Panels", store);
    await db.watch("Matrices/SignatoryMatrix/Types", store);

    const panels = Object.values(store.state["Matrices/SignatoryMatrix/Panels"]).map(panel => {
        return {
            "type": "confirmationInput",
            "label": `${panel.NamePretty} - Limitations`,
            "name": `Panels[${panel.ID}][Answer]`,
            "inputName": `Panels[${panel.ID}][Limitations]`,
            "inputValue": true
        }
    });

    const types = Object.values(store.state["Matrices/SignatoryMatrix/Types"]).map(type => {
        return {
            "label": type.Type,
            "id": type.Type
        }
    });

    return {
        panels,
        types
    }
}

const personalDataFields = [{
    "label": "First Name",
    "name": "FirstName",
    "type": "text"
}, {
    "label": "Last Name",
    "name": "LastName",
    "type": "text"
}, {
    "label": "Email",
    "name": "Email",
    "type": "text"
}, {
    "label": "Position",
    "name": "Position",
    "type": "text"
}, {
    "label": "Link to Account",
    "name": "AccountID",
    "type": "dropdown",
    "dataLocation": "users",
    "idKey": "key",
    "labelKey": "name"
}, {
    "label": "Is an internal employee",
    "name": "IsInternal",
    "type": "toggle",
}];

async function getPanelFields(db, store) {
    const {
        panels
    } = await getRequiredData(db, store);

    return [{
        "label": "Discipline: Areas of Expertise",
        "divClass": "col-span-3",
        "type": "sectionHeader"
    }, ...panels];
}

const aircraftFields = [{
    "label": "Aeroplane Types",
    "divClass": "col-span-3",
    "type": "sectionHeader"
}, {
    "label": "CS-23",
    "name": "CS23",
    "type": "toggle",
    "required": false
}, {
    "label": "CS-25",
    "name": "CS25",
    "type": "toggle",
    "required": false
}, {
    "label": "CS-27",
    "name": "CS27",
    "type": "toggle",
    "required": false
}, {
    "label": "CS-29",
    "name": "CS29",
    "type": "toggle",
    "required": false
}];

const activityFields = [{
    "label": "CVE",
    "divClass": "col-span-3",
    "type": "sectionHeader"
}, {
    "label": "CVE",
    "name": "CVE",
    "type": "toggle",
    "required": false
}, {
    "label": "Activity",
    "divClass": "col-span-3",
    "type": "sectionHeader"
}, {
    "label": "Test Witness",
    "name": "TestWitness",
    "type": "toggle",
    "required": false
}, {
    "label": "ICA",
    "name": "ICA",
    "type": "toggle",
    "required": false
}];

const basePanelValues = (store) => {
    return Object
        .values(store.state["Matrices/SignatoryMatrix/Panels"])
        .reduce((obj, panel) => {
            obj[panel.ID] = {};
            obj[panel.ID].Limitations = "";
            obj[panel.ID].Answer = false;
            obj[panel.ID].ID = panel.ID;
            return obj;
        }, {});
};

export const addEmployee = async ({ db, store, form }) => {

    const fs = [{
        "label": "TSR Number",
        "name": "CSR",
        "type": "value",
        value(_, store) {
            return store.state.counts.CSR.amount;
        }
    }, ...personalDataFields];

    form.setConfig({
        "title": "Add to Signatory Matrix",
        "submitButtonLabel": "Save",
        "type": "push",
        "location": "Matrices/SignatoryMatrix/Signatories",
        "fields": fs,
        preSubmitHook() {
            return {
                "Date": {
                    "Created": new Date()
                },
                "Approved": true,
                "Accepted": true,
                "Cancelled": false,
                "PointsExempt": true,
                "Panels": basePanelValues(store)
            }
        },
        async postSubmitHook({ typeRef }) {
            await db.set(`counts/CSR/amount`, store.state.counts.CSR.amount + 1);
        }
    });
    form.getDropdownData();
    form.toggle();
};

const panelsToValues = (store, pageItem) => {
    let acc = []
    Object
        .values(store.state["Matrices/SignatoryMatrix/Panels"])
        .forEach((panel) => {
            acc.push([`Panels[${panel.ID}][Limitations]`, get(pageItem, `Panels.${panel.ID}.Limitations`, '')]);
            acc.push([`Panels[${panel.ID}][Answer]`, get(pageItem, `Panels.${panel.ID}.Answer`, "Compiler")]);
        })
        console.log(acc);
    return Object.fromEntries(acc);
};

export const updateEmployee = async ({ db, item, store, form }) => {

    form.setValues({
        ...item
    });

    form.setConfig({
        "title": "Update Employee",
        "submitButtonLabel": "Save",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Signatories/${item.ID}`,
        "fields": personalDataFields
    });
    form.getDropdownData();
    form.toggle();
};

export const updatePanels = async ({ form, pageItem, store, db }) => {
    const fields = await getPanelFields(db, store);

    form.setValues({
        ...pageItem,
        ...panelsToValues(store, pageItem)
    });

    form.setConfig({
        "title": "Update Panels",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}`,
        "fields": fields
    });

    form.toggle();
};

export const updateApprovedFunctions = ({ form, pageItem }) => {
    const totalPoints = Object.values(pageItem.InterviewQuestions || {}).reduce((acc, q) => {
        return acc + parseFloat(q.Points || 0);
    }, 0);
    form.setValues({
        "ApprovedFunctions": Object.keys(pageItem.ApprovedFunctions || {})
    });
    form.setConfig({
        "title": "Update Approved Functions",
        "type": "update",
        "set": true,
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}`,
        "fields": [{
            "label": "Approved Functions",
            "name": "ApprovedFunctions",
            "type": "dropdown",
            "dataLocation": "Matrices/SignatoryMatrix/ApprovedFunctions",
            "labelKey": "Name",
            "idKey": "ID",
            "multiple": true,
            "divClass": "md:col-span-3"
        }],
        preSubmitHook({ data }) {
            return {
                "ApprovedFunctions": makeAttached(data.ApprovedFunctions)
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const updatePanel = async ({ form, rowItem, pageItem, store }) => {

    let af = store.state["Matrices/SignatoryMatrix/ApprovedFunctions"];

    const approvedFunctions = Object.keys(pageItem.ApprovedFunctions || {}).map(key => {
        return af[key];
    });

    const options = ([{
        "label": "Compiler",
        "id": "Compiler"
    }, {
        "label": "Checker",
        "id": "Checker"
    }, {
        "label": "Approver",
        "id": "Approver"
    }, {
        "label": "CVE",
        "id": "CVE"
    }]).filter(i => {
        let x = approvedFunctions.find(a => {

            return a?.Name === i?.label
        });
        return x;
    });

    form.setValues(rowItem);

    form.setConfig({
        "title": "Update Panels",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Panels/${rowItem.ID}`,
        "fields": [{
            "name": "Answer",
            "label": "Checker / Approver / CVE",
            "type": "dropdown",
            "dropdownData": [{
                "label": "None",
                "id": "None"
            }, ...options]
        }]
    });

    form.getDropdownData();
    form.toggle();
};

export const addPanelLimitations = async ({ form, rowItem, pageItem, store }) => {
    form.setValues(rowItem);
    form.setConfig({
        "title": "Add Panel Limitation",
        "type": "push",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Panels/${rowItem.ID}/Limitations`,
        "fields": [{
            "label": "Limitation",
            "name": "Limitation",
            "type": "text",
            "divClass": "md:col-span-3"
        }]
    });
    form.toggle();
};

export const updatePanelLimitations = ({ form, rowItem, pageItem, store }) => {

    const fields = Object.values(rowItem.Limitations || {}).map((limitation, index) => {
        return {
            "label": "Limitation (Leave blank to delete)",
            "name": `Limitations[${limitation.ID}]`,
            "type": "text",
            "required": false,
            "divClass": "md:col-span-3",
            "value": limitation.Limitation
        };
    });

    form.setValues(Object.fromEntries(fields.map(f => [f.name, f.value])));

    form.setConfig({
        "title": "Update Panel Limitations",
        "type": "set",
        "description": "Leave blank to delete",
        "set": true,
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Panels/${rowItem.ID}/Limitations`,
        "fields": fields,
        preSubmitHook({ data }) {
            let entries = Object.entries(data.Limitations || {}).filter(([key, value]) => {
                return value.Limitation !== "";
            }).map(([key, value]) => {
                return [key, {
                    "ID": key,
                    "Limitation": value
                }];
            });

            return {
                "Limitations": null,
                ...(Object.fromEntries(entries))
            };
        }
    });

    form.toggle();
};

export const updatePanelLimitations2 = ({ form, rowItem, pageItem }) => () => {
    updatePanelLimitations({ form, rowItem, pageItem });
};

export const uploadFile = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add File",
        "type": "push",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file",
            "accept": ".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif"
        }],
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.set(`Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Files/${typeRef.key}/File`, file[0].name);
                await db.upload(`people/${pageItem.ID}`, file[0]);
            }
        }
    });
    form.toggle();
};

export const downloadFile = async ({ rowItem, pageItem, db }) => {
    const url = await db.download(`people/${pageItem.ID}/${rowItem.File}`);
    window.open(url, "_blank");
};

export const downloadFile2 = async ({ rowItem, pageItem, db }) => {
    const url = await db.download(`OpenForms/Signatories/${pageItem.ID}/${rowItem.File}`);
    window.open(url, "_blank");
};

export const deleteFile = ({ form, rowItem, pageItem }) => {
    form.setConfig({
        "title": "Add File",
        "type": "delete",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Files/${rowItem.ID}`,
        "fields": [],
        async postSubmitHook({ db }) {
            await db.remove(`people/${pageItem.ID}`, rowItem.File);
        }
    });
    form.toggle();
};

export const makeInactive = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Make Signatory Inactive",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": true
            }
        }
    });
    form.toggle();
};

export const makeActive = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Make Signatory Active",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": false
            }
        }
    });
    form.toggle();
};

export const addNote = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add Note",
        "type": "push",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Notes`,
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text",
            "divClass": "md:col-span-3"
        }, {
            "label": "Note",
            "name": "Note",
            "type": "richtext",
            "divClass": "md:col-span-3"
        }],
        preSubmitHook() {
            return {
                "Date": (new Date()).toISOString()
            }
        }
    });
    form.toggle();
};

export const updateNote = ({ form, rowItem, pageItem }) => {
    form.setValues({
        "Note": rowItem.Note,
        "Title": rowItem.Title,
    });

    form.setConfig({
        "title": "Edit Note",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Notes/${rowItem.ID}`,
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text",
            "divClass": "md:col-span-3"
        }, {
            "label": "Note",
            "name": "Note",
            "type": "richtext",
            "divClass": "md:col-span-3"
        }],
        preSubmitHook() {
            return {
                "Date": (new Date()).toISOString()
            }
        }
    });
    form.toggle();
};

export const viewNote = ({ form, rowItem, pageItem }) => {
    form.setValues({
        "Note": rowItem.Note,
        "Title": rowItem.Title,
    });

    form.setConfig({
        "title": " Note",
        "type": "view",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Notes/${rowItem.ID}`,
        "submitButtonLabel": "Close",
        "submitButtonClass": "primary",
        "fields": [{
            "label": "Title",
            value() {
                return rowItem.Title
            },
            "type": "value",
            "divClass": "md:col-span-3"
        }, {
            "label": "Note",
            value() {
                return rowItem.Note
            },
            "type": "value",
            "grow": true,
            "divClass": "md:col-span-3"
        }]
    });
    form.toggle();
};

export const updateAdditionalLimitations = ({ form, pageItem }) => {
    form.setValues({
        "Limitations": pageItem.Limitations
    });

    form.setConfig({
        "title": "Edit Additional Limitations",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}`,
        "fields": [{
            "label": "Additional Limitations",
            "name": "Limitations",
            "type": "richtext",
            "divClass": "md:col-span-3"
        }]
    });
    form.toggle();
};

export const addFormFourFile = type => ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add Form Four File",
        "type": "push",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file",
            "accept": ".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif"
        }],
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.upload(`people/${pageItem.ID}`, file[0]);

                await db.set(`Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/Files/${typeRef.key}/File`, file[0].name);

                await db.set(`Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/FormFourFiles/${type}/`, file[0].name);
            }
        }
    });
    form.toggle();
};

export const linkFormFourFile = type => ({ form, pageItem, store }) => {
    form.setConfig({
        "title": "Link File",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}/FormFourFiles/`,
        "fields": [{
            "label": "File",
            "name": type,
            "type": "dropdown",
            "dropdownData": Object.values(pageItem.Files).map(
                i => ({ label: i.File, id: i.File })
            )
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const reviseSignatoryMatrix = ({ form, store, db }) => {

    let changesMade = "";    
    const nextRev = next(store.state["Matrices/SignatoryMatrix/Revisions/Revision"]) || 1;

    if (nextRev === 1) {
        changesMade = "Initial Revision - Signatory Matrix moved from Handbook 2 Rev 43";
    } else {
        changesMade = changeIdentifier(store?.state?.currentSignatoryMatrixRevision?.Signatories, store?.state['Matrices/SignatoryMatrix/Signatories']);
    }

    form.setConfig({
        "title": "Revise Signatory Matrix",
        "description": `Revise to Revision ${nextRev}`,
        "type": "push",
        "location": "Matrices/SignatoryMatrix/Revisions/Revisions",
        "fields": [{
            "label": "Changes Made",
            "type": "value",
            "grow": true,
            "divClass": "md:col-span-3",
            "value":() => changesMade
        }],
        preSubmitHook() {
            return {
                "Signatories": store.state["Matrices/SignatoryMatrix/Signatories"],
                "Revision": nextRev,
                "Date": (new Date()).toISOString(),
                "By": store.getters.userID,
                "ChangesMade": changesMade
            }
        },
        postSubmitHook({ typeRef }) {
            db.set("Matrices/SignatoryMatrix/Revisions/Revision", next(store.state["Matrices/SignatoryMatrix/Revisions/Revision"]) || "A");
            db.set("Matrices/SignatoryMatrix/Revisions/RevisionID", typeRef.key);
            db.set(`Matrices/SignatoryMatrix/Revisions/Refs/${typeRef.key}`, next(store.state["Matrices/SignatoryMatrix/Revisions/Revision"]));
        }
    });
    form.toggle();
};

export const reviewAnswer = locationStart => ({ form, rowItem, pageItem, store, db }) => {
    const question = store.state["Matrices/SignatoryMatrix/InterviewQuestions"][rowItem.ID];

    let fields = [];

    if (question.Answer) fields.push({
        "label": "Answer",
        "name": "Answer",
        "type": "value",
        value() {
            return (rowItem.Answer == true || rowItem.Answer == "true") ? "Yes" : "No"
        }
    })

    if (question?.SupportingInformation) fields.push({
        "label": "Supporting Information",
        "name": "SupportingInformation",
        "type": "value",
        "grow": true,
        "divClass": "md:col-span-3",
        value() {
            return rowItem.SupportingInformation
        }
    })
    form.setValues({
        "Points": rowItem.Points || 0
    })
    form.setConfig({
        "title": "Review Answer",
        "description": rowItem.Question,
        "type": "update",
        "location": `${locationStart}/Signatories/${pageItem.ID}/InterviewQuestions/${rowItem.ID}`,
        "fields": [...fields, {
            "break": true,
            "divClass": "md:col-span-3",
        }, {
            "label": `Award Points (Max: ${question?.MaxPoints || 1})`,
            "type": "number",
            "name": "Points",
            "step": "1",
            "min": 0,
            "max": question?.MaxPoints || 1
        }],
        preSubmitHook({ data }) {
            return {
                "Points": data.Points,
                "Date": {
                    "Reviewed": (new Date()).toISOString()
                },
                "ReviewedBy": store.getters.userID,
                "Answer": rowItem?.Answer || "",
                "SupportingInformation": rowItem?.SupportingInformation || ""
            }
        }
    });
    form.toggle();
};

export const answerQuestion = locationStart => ({ form, rowItem, pageItem, store, db }) => {

    form.setValues(rowItem);

    const question = store.state["Matrices/SignatoryMatrix/InterviewQuestions"][rowItem.ID];

    let fields = [];

    if (question.Answer) fields.push({
        "label": "Answer",
        "name": "Answer",
        "type": "dropdown",
        "dropdownData": [{
            "label": "Yes",
            "id": true
        }, {
            "label": "No",
            "id": false
        }]
    })

    if (question.SupportingInformation) fields.push({
        "label": "Supporting Information",
        "name": "SupportingInformation",
        "type": "richtext",
        "divClass": "md:col-span-3"
    })

    form.setConfig({
        "title": "Answer Answer",
        "description": rowItem.Question,
        "type": "update",
        "location": `${locationStart}/Signatories/${pageItem.ID}/InterviewQuestions/${rowItem.ID}`,
        "fields": fields
    });
    form.getDropdownData();
    form.toggle();
};

export const approveSignatoryApplication = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Approve Signatory Application",
        "type": "update",
        "description": `Are you sure you want to approve ${pageItem.FirstName} ${pageItem.LastName}?`,
        "location": `OpenForms/Signatories/${pageItem.ID}`,
        "fields": [{
            "name": "CSR",
            "type": "text",
            "label": "TSR Number"
        }, {
            "name": "Approved",
            "type": "checkbox",
            "label": "I confirm"
        }],
        preSubmitHook() {
            return {
                "Cancelled": false,
                "Date": {
                    "Approved": (new Date()).toISOString()
                }
            }
        }
    });
    form.toggle();
};

export const addTester = ({ db, store, form }) => {
    form.setConfig({
        "title": "Add Tester",
        "submitButtonLabel": "Save",
        "type": "push",
        "location": "Matrices/SignatoryMatrix/Testers",
        "fields": [{
            "label": "Full Name",
            "name": "Name",
            "type": "text"
        }, {
            "label": "Types",
            "name": "Types",
            "type": "dropdown",
            "multiple": true,
            "dataLocation": "Matrices/SignatoryMatrix/TesterTypes",
            "labelKey": "Name",
            "idKey": "Name",
        }],
        preSubmitHook({ data }) {
            return {
                "Types": Array.isArray(data.Types) ? Object.values(data.Types).join(",") : data.Types
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editTester = ({ form, item }) => {
    form.setValues({
        ...item,
        "Types": item?.Types?.split(", ")
    });
    form.setConfig({
        "title": "Edit Tester",
        "submitButtonLabel": "Save",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Testers/${item.ID}`,
        "fields": [{
            "label": "Full Name",
            "name": "Name",
            "type": "text"
        }, {
            "label": "Types",
            "name": "Types",
            "type": "dropdown",
            "multiple": true,
            "dataLocation": "Matrices/SignatoryMatrix/TesterTypes",
            "labelKey": "Name",
            "idKey": "Name",
        }],
        preSubmitHook({ data }) {
            return {
                "Types": Array.isArray(data.Types) ? Object.values(data.Types).join(", ") : data.Types
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const addFileToTester = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add File",
        "submitButtonLabel": "Add",
        "type": "push",
        "location": `Matrices/SignatoryMatrix/Testers/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file"
        }],
        preSubmitHook({ files }) {
            return {
                "Date": (new Date()).toISOString(),
                "File": files[0][0].name
            };
        },
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.upload(`Matrices/SignatoryMatrix/Testers/${pageItem.ID}/Files/${typeRef.key}`, file[0]);
            }
        }
    });
    form.toggle();
}

export const downloadTesterFile = async ({ rowItem, pageItem, db }) => {
    const url = await db.download(`Matrices/SignatoryMatrix/Testers/${pageItem.ID}/Files/${rowItem.ID}/${rowItem.File}`);
    window.open(url, "_blank");
};


const dropdownData = [{
    "id": "Design",
    "label": "Design"
}, {
    "id": "Certification",
    "label": "Certification"
}, {
    "id": "Project Management",
    "label": "Project Management"
}, {
    "id": "Design certification",
    "label": "Design certification"
}, {
    "id": "Other",
    "label": "Other"
}];

const fields = [{
    "label": "Date Start",
    "name": "Date[Start]",
    "type": "date"
}, {
    "label": "Date Completed",
    "name": "Date[Complete]",
    "type": "date"
}, {
    "label": "Type",
    "name": "Type",
    "type": "dropdown",
    "dropdownData": dropdownData
}, {
    "type": "text",
    "label": "Approved By",
    "name": "ApprovedBy"
}, {
    "label": "Training / Achievement / Experience",
    "name": "Training",
    "type": "text",
    "divClass": "md:col-span-2"
}, {
    "label": "Outcomes / Comments",
    "name": "Outcomes",
    "type": "text",
    "divClass": "md:col-span-3"
}, {
    "label": "Certificate / Proof",
    "name": "Certificate",
    "type": "file",
    "required": false,
    "divClass": "md:col-span-2"
}];

export const addRecord = ({ form, router, store, db }) => {
    const date = (new Date()).toISOString();
    form.setConfig({
        "title": "Add New Training Record",
        "type": "push",
        "description": `Userdata/${router?.currentRoute?.value?.params.id}/Training/Records`,
        "location": `Userdata/${router?.currentRoute?.value?.params.id}/Training/Records`,
        "fields": fields,
        async postSubmitHook({ files, typeRef }) {
            for (const file of files) {
                if (!file[0]) continue;
                await db.upload(`StaffTrainingRecords/${router?.currentRoute?.value?.params.id}/${typeRef.key}`, file[0]);
                await db.update(`Userdata/${router?.currentRoute?.value?.params.id}/Training/Records/${typeRef.key}`, {
                    "Certificate": file[0].name,
                    "AddedBy": store.getters.userID,
                })
            };
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const removeRecord = ({ form, pageItem, rowItem }) => {
    form.setConfig({
        "title": "Remove Training Record",
        "type": "delete",
        "description": "Are you sure you want to remove this record?",
        "location": `Userdata/${pageItem.Basic.key}/Training/Records/${rowItem.ID}`
    });

    form.toggle();
};

export const updateRecord = ({ form, pageItem, rowItem, store, db, router }) => {
    const date = (new Date()).toISOString();

    form.setValues({
        ...rowItem,
        "Date[Start]": rowItem?.Date?.Start,
        "Date[Complete]": rowItem?.Date?.Complete
    });

    form.setConfig({
        "title": "Revise Handbook",
        "location": `Userdata/${router?.currentRoute?.params.id}/Training/Records/${rowItem.ID}`,
        "type": "update",
        "fields": fields,

        async postSubmitHook({ files }) {
            for (const file of files) {

                if (!file[0]) continue;
                await db.upload(`StaffTrainingRecords/${router?.currentRoute?.params.id}/${rowItem.ID}`, file[0]);
                await db.update(`Userdata/${router?.currentRoute?.params.id}/Training/Records/${rowItem.ID}`, {
                    "Certificate": file[0].name,
                    "AddedBy": store.getters.userID,
                })
            };
        }
    });
    form.getDropdownData();

    form.toggle();
};

export const downloadCertificate = ({ pageItem, rowItem, db }) => {
    try {

        let rev = rowItem.Revision;
        // let url = await db.download()
        window.open(url, "__blank")
    } catch (err) {
        toast.error("Archive file found");
    }
}


export const updateAdditionalRoles = ({ form, pageItem }) => {
    form.setValues({
        "Roles": pageItem.Roles
    });

    form.setConfig({
        "title": "Edit Additional Roles",
        "type": "update",
        "location": `Matrices/SignatoryMatrix/Signatories/${pageItem.ID}`,
        "fields": [{
            "label": "Additional Roles",
            "name": "Roles",
            "type": "richtext",
            "divClass": "md:col-span-3"
        }]
    });
    form.toggle();
}
