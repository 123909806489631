
const appConfig = JSON.parse(import.meta.env.VITE_APP_CONFIG);

export const back = {
    "label": "Close Item / Back",
    "class": "text-red font-medium hover:bg-red",
    "break": true,
    action(contextData) {
        contextData.router.back();
    }
};

export const openInFirebase = {
    "label": "Open in Firebase",
    "class": "text-orange font-medium hover:bg-orange",
    "break": true,
    action({ currentRoute }) {
        window.open(`https://console.firebase.google.com/u/0/project/stc-21-app/database/${appConfig.firebaseConfig.databaseURL.split("://")[1].split(".")[0]}/data${currentRoute.path}`, "_blank")
    },
    show({ store }) {
        return [store?.state?.user?.Security?.General?.ApplicationAdmin, "Not an Admin"];
    }
};

export default {
    back,
    openInFirebase
}
