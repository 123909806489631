import * as cycles from "../Cycles/cycles.js";

import {
    addNonCon
} from "./actions";

const actions = [{
    "label": "Add Sub Non Conformity",
    "action": addNonCon,
    show( { meta } ) {
        return [meta.ParentType === "SubReports", "Can only add a Sub Non Conformity to a Report"]
    }
}];

const headers = [{
    "headerName": "Number",
    "field": "Number"
}, {
    "headerName": "Procedure",
    "field": "Procedure"
}, {
    "headerName": "Cycle",
    "field": "Date",
    valueFormatter(params) {
        return cycles.getCycle(params.value.Created);
    }
}, {
    "headerName": "Completed",
    "field": "Completed",
    "type": "bool"
}];

const rowActions = [{
    "label": "View Sub Non Conformity",
    action({ router, rowItem }) {
        router.push(`/Audits/SubNonConformities/${rowItem.ID}`)
    }
}];

export default {
    "label": "Sub Non Conformities",
    "key": "NonConformities",
    "location": "/Audits/SubNonConformities/",
    actions,
    rowActions,
    headers
};
