import {
    createStore
} from 'vuex';

const store = createStore({
    state() {
        return {
            "appConfig": JSON.parse(import.meta.env.VITE_APP_CONFIG),
            "user": null,
            "failedUser": false,
            "sigMatrixViewingNew": false,
            "sideNavOpen": true
        }
    },
    mutations: {
        save(state, data) {
            Object.entries(data).forEach(([key, value]) => {
                state[key] = value;
            });
        }
    },
    getters: {
        userName(state) {
            return state.user && state.user.Basic ? state.user.Basic.name : "Unknown";
        },
        userID(state) {
            return state.user && state.user.Basic ? (state.user.Basic.key || state.user.Basic.ID) : "Unknown";
        },
        userObject(state) {
            return {
                "Name": state.user && state.user.Basic ? state.user.Basic.name : "Unknown",
                "ID": state.user && state.user.Basic ? state.user.Basic.key : "Unknown"
            }
        },
        authenticated(state) {
            return !!state.user
        },
        recents(state) {
            return Object.entries(state.user.Recents || {});
        },
        currency(state) {
            return state.appConfig.currency || "£";
        }
    }
});

export default store;
