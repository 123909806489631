<template>
  <div class="w-12">
    <p :class="displayClasses" v-html="displayValue" style="height: 30px; width: 30px; text-shadow: 1px 1px 3px #999"></p>
  </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "BooleanValue",
  computed: {
    displayValue() {
      if (
        this.params.valueFormatted !== null &&
        this.params.valueFormatted !== undefined
      )
        return this.params.valueFormatted === "N/A" ? "n/a" : this.params.valueFormatted ? "&#10004;" : "&#10006";
      else return this.params.value ? "&#10004;" : "&#10006";

    },
    displayClasses() {
      let yes =
        "shadow text-center px-2 bg-gradient-to-br from-green-300 to-green-400 rounded-full text-white ";
      let no =
        "shadow text-center px-2 bg-gradient-to-br from-red-300 to-red-400 rounded-full text-white ";
      let na = "shadow text-center font-xs  bg-gray-300 rounded-full text-white ";


      if (
        this.params.valueFormatted !== null &&
        this.params.valueFormatted !== undefined
      ) {

        return this.params.valueFormatted === "N/A" ? na : this.params.valueFormatted ? yes : no;
      } else {
        return this.params.value ? yes : no;
      }
    },
  },
});
</script>
