import {
    addSADD, editSADD, cancelSADD, linkSADD
} from "./actions";

function warning(item) {
    let warningMessage = "";
    if (item?.Signedoff === false) {
        warningMessage = warningMessage += `${item.Number} is not signedoff.<br>`;
    }
    return warningMessage === "" ? false : warningMessage;
}

const cellClassRules = {
    "text-orange font-bold": params => {
        return warning(params.data);
    }
}


const actions = [{
    "label": "Add SADD",
    "action": addSADD,
    show({ meta }) {
        return [["DesignChanges", "FlightConditions", "Repairs"].includes(meta?.ParentType), "SADDs cannot be added to this, they must be linked."]
    }
}, {
    "label": "Link SADD",
    "action": linkSADD
}];

const headers = [{
    "headerName": "Number",
    "field": "Number"
}, {
    "headerName": "POA/Arrangement",
    "field": "To"
}, {
    "headerName": "Date of Issue",
    "field": "Date.Issued",
    "type": "date"
}];

const rowActions = [{
    "label": "View SADD",
    action({ router, rowItem }) {
        router.push(`/Database/SADDs/${rowItem.ID}`)
    }
}, {
    "label": "Update SADD",
    "break": true,
    "action": editSADD,
    show({ meta }) {
        return [["DesignChanges"].includes(meta?.ParentType), "Can only be edited whilst in the parent"]
    }
}, {
    "label": "Cancel SADD",
    "break": true,
    "action": cancelSADD,
    "class": "text-red"
}];

export default {
    "label": "SADDs",
    "key": "SADDs",
    "location": "Database/SADDs/",
    actions,
    rowActions,
    headers,
    warn({ items }) {
        let warningMessage = items.map(warning).filter(i => i !== false).join("");
        return [warningMessage !== "", warningMessage];
    }
};
