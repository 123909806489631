import { get } from "lodash";
import { jobCardSignoffCheckList } from "./actions";

export const engineerResponsibilities = {
    "label": "Engineer Responsibilities",
    "key": "EngineerResponsibilities",
    "location": "Database/EngineerResponsibilities/",
    computedData(cd) {
        return Object.entries(cd?.pageItem?.EngineerResponsibilities || {}).map(([key, value]) => {
            return [get(cd.store.state["Database/EngineerResponsibilities"], key, ""), value];
        });
    },
    "actions": [{
        "label": "Engineer Responsibility Checklist",
        "action": jobCardSignoffCheckList("Engineer", "EngineerResponsibilities")
    }],
    "headers": [{
        "headerName": "Responsibility",
        "field": "Responsibility",
        valueFormatter(params) {
            return params.data[0].Responsibility
        }
    }, {
        "headerName": "Answer",
        "field": "Answer",
        valueFormatter(params) {
            return params.data[1];
        }
    }]
};

export const technicalSecretaryResponsibilities = {
    "label": "Technical Secretary Responsibilities",
    "key": "TechnicalSecretaryResponsibilities",
    "location": "Database/TechnicalSecretaryResponsibilities/",
    computedData(cd) {
        return Object.entries(cd?.pageItem?.TechnicalSecretaryResponsibilities || {}).map(([key, value]) => {
            return [get(cd.store.state["Database/TechnicalSecretaryResponsibilities"], key, ""), value];
        });
    },
    "actions": [{
        "label": "Technical Secretary Responsibility Checklist",
        "action": jobCardSignoffCheckList("TechnicalSecretary", "TechnicalSecretaryResponsibilities")
    }],
    "headers": [{
        "headerName": "Responsibility",
        "field": "Responsibility",
        valueFormatter(params) {
            return params.data[0].Responsibility
        }
    }, {
        "headerName": "Answer",
        "field": "Answer",
        valueFormatter(params) {
            return params.data[1];
        }
    }]
};
