export const addCustomer = ({ form }) => {
    form.setConfig({
        "title": "Add Customer",
        "type": "push",
        "location": "Database/Customers",
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
}

export const editCustomer = ({ form, rowItem }) => {
    form.setValues({
        "Name": rowItem.Name
    });
    form.setConfig({
        "title": "Edit Customer",
        "type": "update",
        "location": `Database/Customers/${rowItem.ID}`,
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
};

export const removeCustomer = ({ rowItem }) => {
    form.setConfig({
        "title": "Remove Customer",
        "type": "delete",
        "location": `Database/Customers/${rowItem.ID}`,
        "description": `Are you sure you want to remove ${rowItem.Name}?`,
        "fields": []
    });
    form.toggle();
};
