import ReportPlanner from "./ReportPlanner.vue";

export default [{
    "path": "/Audits/ReportPlanner",
    "component": ReportPlanner,
    "name": "Audits Schedule",
    "meta": {
        "app": "Audits",
        "storeData": ["Audits/Procedures", "Audits/Cycles"],

        "sideNav": true,
        "appSwitcher": true,
        "appSwitcherPosition": 4,
        "icon": "check-circle",
        "security": "Audits.Read",
        "name": "Audits Schedule",
        "sideNavSection": "Audits"
    },
    lock(user) {
        return [user?.Security?.Matrices?.Reviewer !== true, "Cannot view this area"]
    }
}];
