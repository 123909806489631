import {
    getAllAssociatedItems
} from "./performanceUtils";
import { itemBreakdown } from "./popups.mjs";

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'gbp'
});

function getCombinedPrice(attachedItems, store) {
    let voCosts = 0;
    let poCosts = 0;
    // if (attachedItems.VariationOrders) {
    //     voCosts += attachedItems
    //         .VariationOrders
    //         .items
    //         .map(key => store.state[key])
    //         .reduce((acc, vo) => acc + parseFloat(vo?.PriceGBP || vo?.Quote?.Price), 0);
    // };
    if (attachedItems.PurchaseOrders) {
        poCosts += attachedItems
            .PurchaseOrders
            .items
            .map(key => store.state[key])
            .reduce((acc, vo) => acc + parseFloat(vo?.PriceGBP || vo?.Price), 0);
    }
    return voCosts + poCosts;
}

function getTime(attachedItems, store) {
    let totalTime = 0;
    if (attachedItems.allItems) {
        totalTime = attachedItems
            .allItems
            .items
            .map(key => {
                const item = store.state[key];
                return item?.Time ? Object.values(item.Time).reduce((acc, i) => acc + parseFloat(i.Time), 0) : 0;
            })
            .reduce((acc, i) => acc + i, 0)
    }
    return `${totalTime}h`;
}

function getCost(attachedItems, store) {
    let totalTime = 0;
    if (attachedItems.allItems) {
        totalTime = attachedItems
            .allItems
            .items
            .map(key => {
                const item = store.state[key];
                return item?.Time ? Object.values(item.Time).reduce((acc, i) => acc + parseFloat(i.Time), 0) : 0;
            })
            .reduce((acc, i) => acc + i, 0)
    };
    return totalTime * 60;
}

export const costTime = {
    "label": "Cost & Time Performance",
    "key": "costTime",
    "fields": [{
        "label": "Combined PO & VO Price",
        "type": "currency",
        compute({ attachedItems, store }) {
            return getCombinedPrice(attachedItems, store);
        }
    }, {
        "label": "Time Spent on Job",
        compute({ attachedItems }) {
            return (attachedItems.timeBreakdown?.items?.reduce((acc, i) => acc += parseFloat(i.hours), 0) || 0) + ' hours';
        }
    }, {
        "label": "Cost of Job",
        compute({ attachedItems }) {
            let c = (attachedItems.timeBreakdown?.items?.reduce((acc, i) => acc += parseFloat(i.hours), 0) || 0) * 60;
            return `<p>${formatter.format(c)}</p>`
        }
    }, {
        "label": "Approximate Profit / Loss",
        compute({ attachedItems, store }) {
            const price = getCombinedPrice(attachedItems, store);
            const cost = (attachedItems.timeBreakdown?.items?.reduce((acc, i) => acc += parseFloat(i.hours), 0) || 0) * 60;
            const profLoss = Math.round(parseFloat(price) - parseFloat(cost));
            const textclass = profLoss >= 0 ? "text-green" : "text-red";
            return `<p class="${textclass}">${formatter.format(profLoss)}</p>`
        }
    }],
    "field": true,
    "actions": [],
    "rowActions": []
};

export const allItems = {
    "label": "All Items",
    "key": "allItems",
    "fields": [{
        "label": "Total Items",
        compute({ items }) {
            return items.length || 0;
        }
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Parent",
        "field": "Parent"
    }, {
        "headerName": "Item Type",
        "field": "__location",
        valueFormatter(params) {
            return params.data.__location.split("/")[1]
        }
    }, {
        "headerName": "Total Time Spent (Hours)",
        "field": "Items",
        valueFormatter(params) {
            return Object.values(params.data.Time || {}).reduce((acc, i) => acc += parseFloat(i.Time), 0)
        }
    }],
    computedRows(contextData) {
        return getAllAssociatedItems(contextData);
    },
    "rowActions": [{
        "label": "Go to item",
        action({ router, rowItem }) {
            router.push(`/${rowItem.__location}`)
        }
    }, {
        "break": true,
        "label": "View Item Breakdown",
        "action": itemBreakdown
    }]
};

export const timeBreakdown = {
    "label": "Time Breakdown",
    "local": true,
    "key": "timeBreakdown",
    "headers": [{
        "headerName": "Name",
        "field": "name"
    }, {
        "headerName": "Hours",
        "field": "hours"
        }, {
            "headerName": "Card",
            "field": "card"
        }],
    computedData({ attachedItems, store }) {
        
        if(!attachedItems?.JobCards || !store.state.users) return [];


        let jobCards = attachedItems.JobCards.items.map(key => store.state[key]);

        return jobCards.reduce((acc, i) => {

            let people = Object.entries(i?.Time || {}).reduce((jcAcc, [key, value]) => {

                let hours = parseFloat(value);

                if (hours <= 0) return jcAcc;

                jcAcc.push({
                    "name": store.state.users[key].name,
                    "hours": hours,
                    "card": `${i.Number} (${i.Type})`,
                });

                return jcAcc;

            }, []);


            acc = [...acc, ...people];
            return acc;
        }, []);
        
    }
};
