export const addFlightConditionPurpose = ({ form }) => {
    form.setConfig({
        "title": "Add FlightConditionPurpose",
        "type": "push",
        "location": "Database/FlightConditionPurposes",
        "fields": [{
            "label": "Name",
            "name": "name",
            "type": "text"
        }]
    });
    form.toggle();
}

export const editFlightConditionPurpose = ({ form, rowItem }) => {
    form.setValues({
        "name": rowItem.name
    });
    form.setConfig({
        "title": "Edit FlightConditionPurpose",
        "type": "update",
        "location": `Database/FlightConditionPurposes/${rowItem.ID}`,
        "fields": [{
            "label": "Name",
            "name": "name",
            "type": "text"
        }]
    });
    form.toggle();
}
