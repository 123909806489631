import { downloadFile, downloadFile2, editAircraftType, editApplicationNotes, override, updateEmergencyContacts, uploadFile, uploadFile2, viewCatOneRequirements } from "./actions";

import _ from "lodash";

export const uploadedFiles = {
    "label": "Uploaded Files",
    "key": "Files",
    "local": true,
    "headers": [{
        "headerName": "File",
        "field": "File"
    }],
    "actions": [{
        "label": "Upload File",
        "action": uploadFile
    }],
    "rowActions": [{
        "label": "Download",
        "action": downloadFile
    }]
};

export const emergencyContact = initalLocation => ({
    "label": "Emergency Contact",
    "key": "emCon",
    "field": true,
    "fields": [{
        "label": "Name",
        compute({ pageItem }) {
            return `${pageItem?.EmergencyContact?.FirstName} ${pageItem?.EmergencyContact?.LastName}`
        }
    }, {
        "label": "Phone Number",
        "key": "EmergencyContact.Phone"
    }, {
        "label": "Relation",
        "key": "EmergencyContact.Relation"
    }],
    "actions": [{
        "label": "Update Emergency Contact Details",
        "action": updateEmergencyContacts(initalLocation)
    }]
});

export const uploadedFiles2 = {
    "label": "Uploaded Files",
    "key": "Files",
    "local": true,
    "headers": [{
        "headerName": "File",
        "field": "File"
    }],
    "actions": [{
        "label": "Upload File",
        "action": uploadFile2
    }],
    "rowActions": [{
        "label": "Download",
        "action": downloadFile2
    }]
};

export const generatedFiles = {
    "label": "Documents",
    "key": "generatedFiles",
    "field": true,
    "fields": [{
        "label": "CCR",
        "key": "CCR"
    }, {
        "label": "FTA",
        "key": "FTA"
    }],
    "actions": [{
        "label": "Download CCR",
        async action({ db, pageItem }) {
            let url = await db.download(`Database/Documents/CCRs/${pageItem?.CCR}.docx`);
            window.open(url, "__blank");
        },
        show({ pageItem }) {
            return [pageItem?.CCR]
        }
    }, {
        "label": "Download FTA",
        async action({ db, pageItem }) {
            let url = await db.download(`Database/Documents/FTAs/${pageItem?.FTA}.docx`);
            window.open(url, "__blank");
        },
        show({ pageItem }) {
            return [pageItem?.FTA !== "Not Applicable", "No FTA Found"]
        }
    }]
}

export const licenceAndRatings = {
    "label": "Licences & Ratings",
    "key": "liceneAndRatings",
    "field": true,
    "fields": [{
        "label": "Pilots Licence Number",
        "key": "Licence"
    }, {
        "label": "Medical Certificate Number",
        "key": "MedicalNumber"
    }, {
        "label": "Class of Medical Certificate",
        "key": "MedicalClass"
    }, {
        "label": "Medical Certificate Expiry Date",
        "key": "MedicalExpiry",
        "type": "date"
    }, {
        "label": "Flight Test Rating (Aeroplane)",
        "key": "raw.Ratings.AeroplaneCategory"
    }, {
        "label": "Flight Test Rating (Helicopter)",
        "key": "raw.Ratings.HelicopterCategory"
    }, {
        "label": "Licences",
        "class": "md:col-span-3",
        compute({ pageItem }) {
            return typeof pageItem?.raw?.Licences === "string" ? pageItem?.raw?.Licences : Object.values(pageItem?.raw?.Licences || {}).join(", ") || "None";
        }
    }, {
        "label": "Other Ratings",
        "class": "md:col-span-3",
        compute({ pageItem }) {
            return pageItem?.raw?.Ratings?.Others?.map(i => i.split("_").join(" ")).join(", ") || "None"
        }
    }],
    show({ pageItem }) {
        return pageItem && pageItem?.Role == "Pilot";
    }
}

export const flightHours = {
    "label": "Flight Hours",
    "key": "NewFlightHours",
    "local": true,
    "headers": [{
        "headerName": "CS Type",
        "field": "CS"
    }, {
        "headerName": "Aircraft Type",
        "field": "Type"
    }, {
        "headerName": "Total Flight Hours",
        "field": "Hours"
    }, {
        "headerName": "Total Test Hours",
        "field": "Test"
    }, {
        "headerName": "Total PIC Hours (Last 12 Months)",
        "field": "FlightHoursPIC"
    }, {
        "headerName": "Total CO Hours (Last 12 Months)",
        "field": "FlightHoursCO"
    }, {
        "headerName": "Total PIC Test Hours (Last 12 Months)",
        "field": "FlightHoursPICTest"
    }, {
        "headerName": "Total CO Test Hours (Last 12 Months)",
        "field": "FlightHoursCOTest"
    }, {
        "headerName": "Last Updated",
        "field": "LastUpdated",
        "type": "date"
    }],
    "rowActions": [{
        "label": "Edit",
        "action": editAircraftType
    }
    ],
    warn({ items }) {
        let warnText = "";

        for (const item of items) {

            let total = parseFloat(item.Hours);

            let last12Total =
                parseFloat(item.FlightHoursPIC) +
                parseFloat(item.FlightHoursCO) +
                parseFloat(item.FlightHoursPICTest) +
                parseFloat(item.FlightHoursCOTest);

            if (item.Test > total) {
                warnText += `The total test hours for ${item.Type} is greater than the total flight hours.\n`;
            };

            if (last12Total > total) {
                warnText += `The total PIC and CO hours for ${item.Type} is greater than the total flight hours.\n`;
            };
        };

        return [warnText !== "", warnText];
    }
};

export const notes = {
    "label": "Notes",
    "key": "",
    "field": true,
    "fields": [{
        "label": "Notes",
        "key": "raw.Notes",
        "grow": true,
        "class": "md:col-span-3"
    }],
    "actions": [{
        "label": "Edit Notes",
        "action": editApplicationNotes
    }]
}

function getOveride(override, type, cat) {
    return override && override[`${cat}${type}`] === "No Override" ? false : (_.get(override, `${cat}${type}`, false)) + " (Override)";
}

export const flightTestPermissions = {
    "label": "Flight Test Permissions",
    "key": "flightTestPerm",
    "field": true,

    "fields": [{
        "label": "CS-23 Test Flight Permissions",
        "grow": true,
        compute({ item }) {

            // PiC is Pilot in Command
            // CoP is Co_pilot

            if (item.Role !== "Pilot") return "Not Applicable";


            const compLevel = item?.Competency['CS-23'];
            const hasCatOne = item?.AeroplaneCategory === "Category1";
            const hasCatTwo = item?.AeroplaneCategory === "Category2";

            const isCompetent = compLevel === 1 || compLevel === 2;

            const c1o = getOveride(item?.raw?.Override || {}, "CS23", "CatOne");
            const c2o = getOveride(item?.raw?.Override || {}, "CS23", "CatTwo");
            const c4o = getOveride(item?.raw?.Override || {}, "CS23", "CatFour");

            const c4PorC = item.FlightHours["CS-23"].Total > 1000 && item.FlightHours["CS-23"].TotalLast > 50 ? "PiC" :
                item.FlightHours.Total > 1000 && item.FlightHours["CS-23"].Total > 50 ? "CoP" : "Not Applicable";

            let c1T = `Cat 1: ${c1o ? c1o : isCompetent && hasCatOne ? 'PiC' : isCompetent && (hasCatOne || hasCatTwo) ? 'CoP' : 'Not Applicable'}`;

            let c2T = `Cat 2: ${c2o ? c2o : isCompetent && (hasCatOne || hasCatTwo) ? 'PiC' : compLevel === 4 ? 'CoP*' : 'Not Applicable'}`;

            let c4T = `Cat 4: ${c4o ? c4o : compLevel === 4 ? c4PorC : 'Not Applicable'}`;

            return `${c1T} <br> ${c2T} <br> ${c4T}`;
        }
    }, {
        "label": "CS-23P Test Flight Permissions",
        // "class": "md:col-span-3",
        "grow": true,
        compute({ item }) {
            if (item.Role !== "Pilot") return "Not Applicable";

            const compLevel = item?.Competency['CS-23P'];
            const hasCatOne = item?.AeroplaneCategory === "Category1";
            const hasCatTwo = item?.AeroplaneCategory === "Category2";


            const c1o = getOveride(item?.raw?.Override || {}, "CS23P", "CatOne");
            const c2o = getOveride(item?.raw?.Override || {}, "CS23P", "CatTwo");
            const c4o = getOveride(item?.raw?.Override || {}, "CS23P", "CatFour");

            const c4PorC = item.FlightHours["CS-23P"].Total > 1000 && item.FlightHours["CS-23P"].TotalLast > 50 ? "PiC" :
                item.FlightHours.Total > 1000 && item.FlightHours["CS-23P"].Total > 50 ? "CoP" : "Not Applicable";

            let c1T = `Cat 1: ${c1o ? c1o : compLevel === 1 && hasCatOne ? 'PiC'
                : compLevel <= 2 && (hasCatOne) ? 'CoP' : 'Not Applicable'}`;

            let c2T = `Cat 2: ${c2o ? c2o : compLevel <= 2 && (hasCatOne) ? 'PiC'
                : (compLevel === 1 || compLevel === 2) && (hasCatOne || hasCatTwo) ? 'CoP'
                    : compLevel === 4 ? 'CoP*' : 'Not Applicable'}`;

            let c4T = `Cat 4: ${c4o ? c4o : compLevel <= 4 ? c4PorC : 'Not Applicable'}`;

            return `${c1T} <br> ${c2T} <br> ${c4T}`;
        }
    }, {
        "label": "CS-25 Test Flight Permissions",
        // "class": "md:col-span-3",
        "grow": true,
        compute({ item }) {
            if (item.Role !== "Pilot") return "Not Applicable";

            const compLevel = item?.Competency['CS-25'];
            const hasCatOne = item?.AeroplaneCategory === "Category1";
            const hasCatTwo = item?.AeroplaneCategory === "Category2";


            const c1o = getOveride(item?.raw?.Override || {}, "CS23P", "CatOne");
            const c2o = getOveride(item?.raw?.Override || {}, "CS23P", "CatTwo");
            const c4o = getOveride(item?.raw?.Override || {}, "CS23P", "CatFour");

            const c4PorC = item.FlightHours["CS-25"].Total > 1000 && item.FlightHours["CS-25"].TotalLast > 50 ? "PiC" :
                item.FlightHours.Total > 1000 && item.FlightHours["CS-25"].Total > 50 ? "CoP" : "Not Applicable";

            let c1T = `Cat 1: ${c1o ? c1o : compLevel === 1 && hasCatOne ? 'PiC'
                : compLevel <= 2 && (hasCatOne) ? 'CoP' : 'Not Applicable'}`;

            let c2T = `Cat 2: ${c2o ? c2o : compLevel <= 2 && (hasCatOne) ? 'PiC'
                : (compLevel === 1 || compLevel === 2) && (hasCatOne || hasCatTwo) ? 'CoP'
                    : compLevel === 4 ? 'CoP*' : 'Not Applicable'}`;

            let c4T = `Cat 4: ${c4o ? c4o : compLevel <= 4 ? c4PorC : 'Not Applicable'}`;

            return `${c1T} <br> ${c2T} <br> ${c4T}`;
        }
    }, {
        "label": "CS-27 Test Flight Permissions",
        // "class": "md:col-span-3",
        "grow": true,
        compute({ item }) {
            if (item.Role !== "Pilot") return "Not Applicable";

            const compLevel = item?.Competency['CS-27'];
            const hasCatOne = item?.HelecopterCategory === "Category1";
            const hasCatTwo = item?.HelecopterCategory === "Category2";


            const c1o = getOveride(item?.raw?.Override || {}, "CS23P", "CatOne");
            const c2o = getOveride(item?.raw?.Override || {}, "CS23P", "CatTwo");
            const c4o = getOveride(item?.raw?.Override || {}, "CS23P", "CatFour");

            const c4PorC = item.FlightHours["CS-27"].Total > 1000 && item.FlightHours["CS-27"].TotalLast > 50 ? "PiC" :
                item.FlightHours.Total > 1000 && item.FlightHours["CS-27"].Total > 50 ? "CoP" : "Not Applicable";

            let c1T = `Cat 1: ${c1o ? c1o : compLevel === 1 && hasCatOne ? 'PiC'
                : compLevel <= 2 && (hasCatOne) ? 'CoP' : 'Not Applicable'}`;

            let c2T = `Cat 2: ${c2o ? c2o : compLevel <= 2 && (hasCatOne) ? 'PiC'
                : (compLevel === 1 || compLevel === 2) && (hasCatOne || hasCatTwo) ? 'CoP'
                    : compLevel === 4 ? 'CoP*' : 'Not Applicable'}`;

            let c4T = `Cat 4: ${c4o ? c4o : compLevel <= 4 ? c4PorC : 'Not Applicable'}`;

            return `${c1T} <br> ${c2T} <br> ${c4T}`;
        }
    }, {
        "label": "CS-29 Test Flight Permissions",
        // "class": "md:col-span-3",
        "grow": true,
        compute({ item }) {
            if (item.Role !== "Pilot") return "Not Applicable";

            const compLevel = item?.Competency['CS-29'];
            const hasCatOne = item?.HelicopterCategory === "Category1";
            const hasCatTwo = item?.HelicopterCategory === "Category2";


            const c1o = getOveride(item?.raw?.Override || {}, "CS23P", "CatOne");
            const c2o = getOveride(item?.raw?.Override || {}, "CS23P", "CatTwo");
            const c4o = getOveride(item?.raw?.Override || {}, "CS23P", "CatFour");

            const c4PorC = item.FlightHours["CS-29"].Total > 1000 && item.FlightHours["CS-29"].TotalLast > 50 ? "PiC" :
                item.FlightHours.Total > 1000 && item.FlightHours["CS-29"].Total > 50 ? "CoP" : "Not Applicable";

            let c1T = `Cat 1: ${c1o ? c1o : compLevel === 1 && hasCatOne ? 'PiC'
                : compLevel <= 2 && (hasCatOne) ? 'CoP' : 'Not Applicable'}`;

            let c2T = `Cat 2: ${c2o ? c2o : compLevel <= 2 && (hasCatOne) ? 'PiC'
                : (compLevel === 1 || compLevel === 2) && (hasCatOne || hasCatTwo) ? 'CoP'
                    : compLevel === 4 ? 'CoP*' : 'Not Applicable'}`;

            let c4T = `Cat 4: ${c4o ? c4o : compLevel <= 4 ? c4PorC : 'Not Applicable'}`;

            return `${c1T} <br> ${c2T} <br> ${c4T}`;
        }
    }],


    // "fields": [{
    //     "label": "Cat One - CS23",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryOne.CS23)
    //     }
    // }, {
    //     "label": "Cat Two - CS23",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryTwo.CS23)
    //     }
    // }, {
    //     "label": "Cat Four - CS23",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryFour.CS23)
    //     }
    // }, {
    //     "label": "Cat One - CS23P",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryOne.CS23P)
    //     }
    // }, {
    //     "label": "Cat Two - CS23P",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryTwo.CS23P)
    //     }
    // }, {
    //     "label": "Cat Four - CS23P",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryFour.CS23P)
    //     }
    // }, {
    //     "label": "Cat One - CS25",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryOne.CS25)
    //     }
    // }, {
    //     "label": "Cat Two - CS25",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryTwo.CS25)
    //     }
    // }, {
    //     "label": "Cat Four - CS25",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryFour.CS25)
    //     }
    // }, {
    //     "label": "Cat One - CS27",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryOne.CS27)
    //     }
    // }, {
    //     "label": "Cat Two - CS27",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryTwo.CS27)
    //     }
    // }, {
    //     "label": "Cat Four - CS27",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryFour.CS27)
    //     }
    // }, {
    //     "label": "Cat One - CS29",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryOne.CS29)
    //     }
    // }, {
    //     "label": "Cat Two - CS29",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryTwo.CS29)
    //     }
    // }, {
    //     "label": "Cat Four - CS29",
    //     compute({ item }) {
    //         return best(item?.TestFlightPermissions?.CategoryFour.CS29)
    //     }
    // }, {
    //     "label": "Override Reasons",
    //     "class": "md:col-span-3",
    //     "grow": true,
    //     "key": "OverrideInfo"
    // }],
    "actions": [{
        "label": "View Requirements",
        "action": viewCatOneRequirements
    }, { "label": "Set Override", "action": override }],
    show({ pageItem }) {
        return pageItem && pageItem?.Role == "Pilot";
    }
}
