import {
    addUploadStatus,
    editUploadStatus
} from "./actions";

export default {
    "label": "Upload Statuses",
    "key": "UploadStatuses",
    "location": "Database/UploadStatuses",
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "actions": [{
        "label": "Add UploadStatus",
        "action": addUploadStatus
    }],
    "rowActions": [{
        "label": "Edit UploadStatus",
        "action": editUploadStatus
    }]
}
