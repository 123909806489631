const cardTypes = [{
    "label": "Yellow",
    "id": "Yellow"
}, {
    "label": "Blue",
    "id": "Blue"
}, {
    "label": "Red",
    "id": "Red"
}, {
    "label": "Green",
    "id": "Green"
}];

export const addResponsibility = ({ form }) => {
    form.setConfig({
        "title": "Add Responsibility",
        "type": "push",
        "location": "Database/TechnicalSecretaryResponsibilities",
        "fields": [{
            "label": "Responsibility",
            "name": "Responsibility",
            "type": "text"
        }, {
            "label": "Card Colours",
            "type": "dropdown",
            "multiple": true,
            "dropdownData": cardTypes,
            "name": "Colours"
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const editResponsibility = ({ form, rowItem }) => {
    form.setValues(rowItem);
    form.setConfig({
        "title": "Edit Responsibility",
        "type": "update",
        "location": `Database/TechnicalSecretaryResponsibilities/${rowItem.ID}`,
        "fields": [{
            "label": "Responsibility",
            "name": "Responsibility",
            "type": "text"
        }, {
            "label": "Card Colours",
            "type": "dropdown",
            "dropdownData": cardTypes,
            "multiple": true,
            "name": "Colours"
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const deleteResponsibility = ({ form, rowItem }) => {
    form.setConfig({
        "title": "Delete Responsibility",
        "type": "delete",
        "location": `Database/TechnicalSecretaryResponsibilities/${rowItem.ID}`,
        "description": `Are you sure you want to delete ${rowItem.Responsibility}?`,
        "fields": []
    });
    form.toggle();
}
