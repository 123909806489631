// Children Imports

import CoreProcedures from "./CoreProcedures/routes.js";
import NonCons from "./NonCons/routes.js";
import Procedures from "./Procedures/routes.js";
import ReportPlanner from "./ReportPlanner/routes.js";
import Reports from "./Reports/routes.js";
import Settings from "./Settings/routes.js";
import SubNonCons from "./SubNonCons/routes";
import SubReports from "./SubReports/routes";

export default [...ReportPlanner, ...Reports, ...NonCons, ...Procedures, ...CoreProcedures, ...SubReports, ...SubNonCons, ...Settings];
