<template>
    <div class="flex flex-col container max-w-[600px] mx-auto">
        <div class="bg-white rounded shadow m-2 p-2">
            <img class="mt-4 mb-2 mx-auto" :src="imageURL()" alt="" width="180" />
            <p class="p-2 font-bold">Flight Condition Application</p>
            <p class="p-2 text-sm" v-show="step > 0 && step != state.maxSteps">
                <span>Step&nbsp;</span>
                <span class="font-bold">{{ step }}</span>
                <span>&nbsp;of&nbsp;</span>
                <span class="font-bold">{{ state.maxSteps }}</span>
            </p>
            <p class="p-2 text-sm font-light text-primary hover:font-bold cursor-pointer" v-show="step > 1 && step != state.maxSteps" @click="router.back">Go back</p>
            <Loading message="Submitting Data" v-if="loading.loading" />
            <FormContainer v-if="!loading.loading" :fields="currentStep.fields" :title="currentStep.title"
                :loading="loading.value" :formData="formData.value" :description="currentStep.description"
                :submitButtonText="currentStep.submitButtonText" :dropdownData="dropdownData.value"
                @submit="onFormSubmit" />
        </div>
        <span class="my-2 min-h-12">&nbsp;</span>
    </div>
</template>

<script setup>
import {
    Loading
} from "@comp";

import { onMounted, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { db } from '../../../db';

//forms
import FormContainer from './FormContainer.vue';
import contact from "./forms/contact.js";
import reason from "./forms/reason.js";
import startNew from "./forms/startNew.js";
import supportingData from "./forms/supportingData.js";
import aircraftDetails from "./forms/aircraftDetails";
import aircraftFerryDetails from "./forms/aircraftFerryDetails";
import camoDetails from "./forms/camoDetails";
import cameDetails from "./forms/cameDetails";
import ampDetails from "./forms/ampDetails";
import airworthinessSchedule from "./forms/airworthinessSchedule";
import ongoingModifications from "./forms/ongoingModifications";
import airworthinessRestrictions from "./forms/airworthinessRestrictions";
import confirmation from "./forms/confirmation";
import complete from "./forms/complete";

// essentials
const appConfig = computed(() => store.state.appConfig);
const route = useRoute();
const router = useRouter();
const store = useStore();
const steps = [
    startNew,
    contact,
    reason,
    supportingData,
    aircraftDetails,
    aircraftFerryDetails,
    camoDetails,
    cameDetails,
    ampDetails,
    airworthinessSchedule,
    ongoingModifications,
    airworthinessRestrictions,
    confirmation,
    complete
];

const loading = reactive({
    loading: false,
});

const step = computed(() => {
    return route.query.step || 0;
});

//form state
const state = reactive({
    maxSteps: steps.length - 1,
});


const currentStep = computed(() => ({
    ...steps[step.value],
    title: steps[step.value].title,
    fields: steps[step.value].fields,
    submitButtonText: steps[step.value].submitButtonText,
    description: steps[step.value].description || ''
}));

const dropdownData = reactive({

});

const formData = reactive({

});

/**
 * Handles form submission and updates the database accordingly.
 * @param {Object} submittedData - The data submitted by the form.
 */
async function onFormSubmit(submittedData) {

    loading.loading = true;

    const formId = route.params.id;

    if (!formId) {
        // Create a new form in the database if formId is not provided
        const newFormId = await db.push('OpenForms/FlightConditionApplications', {
            "approved": false,
            "submitted": false,
            "date": new Date().toISOString(),
        });

        formData.value = {
            ...submittedData,
            dateCreated: new Date().toISOString(),
            last_updated_date: new Date().toISOString(),
            formId: newFormId.key
        };

        // Redirect to the new form with the next step
        router.push({
            params: {
                id: newFormId.key
            },
            query: {
                step: parseInt(step.value) + 1
            }
        });
        loading.loading = false;
        return;
    }

    // Upload the files to the database
    let hasUploadedFiles = false;

    if(submittedData.__supportingDocuments) {
        
        for(const file of submittedData.__supportingDocuments) {
            
            if(file.name === undefined) continue;  
            const fileRef = await db.push(`OpenForms/FlightConditionApplications/${formId}/files`, {
                File: file.name
            });

            await db.upload(`OpenForms/FlightConditionApplications/${formId}/files/${fileRef.key}`, file);
            submittedData.__supportingDocuments = null;
            hasUploadedFiles = true;
        };
    };

    let additionalData = currentStep.value.additionalData ? currentStep.value.additionalData : {};
    
    // Update the existing form in the database
    await db.update(`OpenForms/FlightConditionApplications/${formId}`, {
        ...submittedData,
        ...additionalData,
        last_updated_date: new Date().toISOString(),
    });

    let latestChanges = await db.once(`OpenForms/FlightConditionApplications/${formId}`);

    formData.value = {
        ...latestChanges
    };  

    if (hasUploadedFiles) {
        loading.loading = false;
        formData.value.__supportingDocuments = '';
        return;
    }

    let nextStep = steps[parseInt(step.value) + 1];

    if (nextStep && nextStep.dataLoader) {
        // Load additional data for the next step if available
        const data = await nextStep.dataLoader();
        dropdownData.value = {
            ...dropdownData.value,
            ...data
        };        
    };

    router.push({
        query: {
            step: parseInt(step.value) + 1
        }
    });

    loading.loading = false;
};

/**
 * Executes when the component is mounted.
 * Fetches the form data if formId is provided and redirects to the appropriate step.
 */
onMounted(async () => {
    const formId = route.params.id;

    if (formId) {
        const continuedFormData = await db.once(`OpenForms/FlightConditionApplications/${formId}`);

        if(!continuedFormData) {
            // Redirect to the homepage if the form data does not exist
            router.push({
                query: null,
                params: {
                    id: ""
                }
            });
            return;
        }

        formData.value = {
            ...continuedFormData,
            "files": Object.values(continuedFormData.files || {})
        };

        router.push({
            query: {
                step: route.query.step || 1
            }
        });
    }

    loading.loading = false;

});

/**
 * Returns the URL of the image based on the app configuration.
 * @returns {string} - The URL of the image.
 */
const imageURL = () => {
  const imgURL = new URL(
    `../../../assets/${appConfig.value.appPrefix}_logo_main.png`,
    import.meta.url
  );
  return imgURL;
};
</script>
