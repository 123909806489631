import {
    addDrawing,
    cancelDrawing,
    changeLinkedRevision,
    checkDrawing,
    editDrawing,
    linkDrawing,
    makePrototype,
    reviseDrawing,
    reviseDrawingToA,
    revisionRollback,
    unlinkDrawing
} from "./actions";

import {
    get
} from "lodash";
import store from "../../../store";

const appConfig = JSON.parse(import.meta.env.VITE_APP_CONFIG);

function warning(key, pageItem) {
    let item = store.state[key];
    if (!item) return false;
    if (item.Cancelled) return false;

    if (!item?.Revisions) return "No revisions found"
    let warningMessage = "";

    if (!item.Revisions[item?.Links[pageItem.ID]]?.Quality?.Approver?.Completed && !item.Cancelled) {
        warningMessage = warningMessage += `${item.Number} not signed off by approver.<br>`;
    }

    if(item.RequiresStressCheck && !item.Revisions[item?.Links[pageItem.ID]]?.Quality?.StressChecker?.Completed && !item.Cancelled) {
        warningMessage = warningMessage += `${item.Number} not signed off by stress checker.<br>`;
    }

    if (item.Revision.includes("P")) {
        warningMessage = warningMessage += `${item.Number} at a prototype revision.<br>`;
    }

    return warningMessage === "" ? false : warningMessage;
}

const cellClassRules = {
    "text-orange font-bold": params => {
        return warning(params.data);
    }
}

const actions = [{
    "label": "Add Drawing",
    "action": addDrawing,
    show({ meta }) {
        return [["DesignChanges", "FlightConditions", "Repairs"].includes(meta?.ParentType), "Drawings cannot be added here, they must be linked."]
    }
}, {
    "label": "Link Drawing",
    "action": linkDrawing
}];

const headers = [{
    "headerName": "Number",
    "field": "Number",
    "sort": "asc",
    cellClassRules
}, {
    "headerName": "Title",
    "field": "Title",
    cellClassRules
}, {
    "headerName": "Linked Revision",
    cellClassRules,
    fieldGetter({ pageItem }) {
        return `Links.${pageItem.ID}`
    },
    valueFormatter(params) {
        let linkedRev = get(params.data, params.column.colDef.field);
        let revision = get(params.data, `Revisions.${linkedRev}`);
        return revision?.Revision || params.data.Revision;
    }
}, {
    "headerName": "Compiler Sign Off",
    "type": "bool",
    fieldGetter({ pageItem }) {
        return `Links.${pageItem.ID}`
    },
    valueFormatter(params) {
        let linkedRev = get(params.data, params.column.colDef.field);
        return get(params.data, `Revisions.${linkedRev}.Quality.Compiler.Completed`, false);
    }
}, {
    "headerName": "Checker Sign Off",
    "type": "bool",
    fieldGetter({ pageItem }) {
        return `Links.${pageItem.ID}`
    },
    valueFormatter(params) {
        let linkedRev = get(params.data, params.column.colDef.field);
        return get(params.data, `Revisions.${linkedRev}.Quality.Checker.Completed`, false);
    }
}, {
    "headerName": "Approver Sign Off",
    "type": "bool",
    fieldGetter({ pageItem }) {
        return `Links.${pageItem.ID}`
    },
    valueFormatter(params) {
        let linkedRev = get(params.data, params.column.colDef.field);
        return get(params.data, `Revisions.${linkedRev}.Quality.Approver.Completed`, false);
    }
    }, {
        "headerName": "Stress Checked",
        "type": "bool",
        fieldGetter({ pageItem }) {
            return `Links.${pageItem.ID}`
        },
        valueFormatter(params) {
            let linkedRev = get(params.data, params.column.colDef.field);

            if(params.data.RequiresStressCheck === false) return "N/A";

            return get(params.data, `Revisions.${linkedRev}.Quality.StressChecker.Completed`, false);
        }
    }
];

const rowActions = [{
    "label": "View Drawing",
    action({ router, rowItem }) {
        router.push(`/Database/Drawings/${rowItem.ID}`)
    }
}, {
    "break": true,
    "label": "Edit Drawing",
    "action": editDrawing
}, {
    "label": "Revise Drawing",
    "break": true,
    "action": reviseDrawing,
    show({ item }) {
        return item.Revisions[item.RevisionID].Quality.Approver.Completed ?
            [true, ""] :
            [false, "Cannot revise this drawing until it has been approved."]
    }
}, {
    "label": "Revise from Prototype",
    "action": reviseDrawingToA,
    show({ item }) {
        return item.Revision.includes("P") ? [true, ""] : [false, "Revision not at prototype"];
    }
}, {
    "label": "Revise to Prototype",
    "action": makePrototype,
    show({ item }) {
        return item.Revision.includes("P") ? [false, "Revision already a prototype"] : [true, ""];
    }
}, {
    "label": "Rollback Revision",
    "action": revisionRollback,
    show({ item }) {
        return [Object.keys(item.Revisions).length > 1, "No previous revisions found"];
    }
}, {
    "label": "Change Linked Revision",
    "action": changeLinkedRevision
}, {
    "label": "Compiler Check",
    "action": checkDrawing("Compiler"),
    "break": true,
    show({ item }) {
        return item.Revisions[item.RevisionID].Quality.Compiler.Completed === true ? [false, "Already Complier Checked"] : [true, ""];
    }
},
{
    "label": "Checker Check",
    "action": checkDrawing("Checker"),
    "security": "Database.DrawingChecker",
    show({ item }) {
        return item.Revisions[item.RevisionID].Quality.Checker.Completed === true ?
            [false, "Already Complier Checked"] :
            !item.Revisions[item.RevisionID].Quality.Compiler.Completed ?
                [false, "Drawing not checked by the complier"] :
                [true, ""]
    }
},
{
    "label": "Approver Check",
    "security": "Database.DrawingApprover",
    "action": checkDrawing("Approver"),
    show({ item }) {
        return item.Revisions[item.RevisionID].Quality.Approver.Completed === true ?
            [false, "Already Approver Checked"] :
            !item.Revisions[item.RevisionID].Quality.Checker.Completed ?
                [false, "Drawing not checked by the checker"] :
                [true, ""];
    }
},
{
    "label": "Stress Check",
    "action": checkDrawing("StressChecker"),
    show({ item }) {
        return item.RequiresStressCheck === false ?
            [false, "Drawing does not require stress checking."] :
            item.Revisions[item.RevisionID].Quality.StressChecker.Completed === true ?
                [false, "Already stress checked"] :
                [true, ""];
    }
}, {
    "label": "View Archived File",
    "break": true,
    async action({ toast, rowItem, db }) {
        try {
            
            let rev = rowItem.Revision;
            let url = await db.download(`Database/Drawings/${rowItem.ID}/${rowItem.Number}_${rev}.pdf`);
            window.open(url, "__blank")
        } catch (err) {
            toast.error("Archive file found");
        }
    }
}, {
    "label": "Unlink Drawing from Parent",
    "break": true,
    "action": unlinkDrawing,
    show({ rowItem, pageItem }) {
        return [rowItem.ParentID !== pageItem.ID, "Cannot unlink from the main parent"]
    }
}, {
    "label": "Cancel Drawing",
    "break": true,
    "action": cancelDrawing,
    "class": "text-red font-bold",
}];

export default {
    "label": "Drawings",
    "key": "Drawings",
    "location": "Database/Drawings/",
    actions,
    rowActions,
    headers,
    warn({ items, pageItem }) {
        let warningMessage = items.map(i => warning(i, pageItem)).filter(i => i !== false).join("<br>");
        return [warningMessage !== "", warningMessage];
    }
};
