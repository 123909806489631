import { addVariationOrder, editVariationOrder } from "./actions";

export default {
    "label": "Variation Orders",
    "key": "VariationOrders",
    "location": "Database/VariationOrders/",
    show() {
        return true
    },
    "actions": [{
        "label": "Add Variation Order",
        "action": addVariationOrder
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Price",
        "type": "currency",
        valueFormatter(params) {
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: params.data.Currency || "GBP",
            });

            let ogPrice = formatter.format(parseFloat(params.data.Quote.Price));
            let gbpPrice = "";
            if (params.data.Currency && params.data.Currency !== "gbp") {
                var gbpFormatter = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "GBP",
                });
                isNaN(parseFloat(params.data.PriceGBP)) ? gbpPrice = "" : gbpPrice = gbpFormatter.format(parseFloat(params.data.PriceGBP));
                gbpPrice = gbpFormatter.format(parseFloat(params.data.PriceGBP));
   
            } else if(!params.data.Currency) {
                gbpPrice = "No Currency Specified";
            }

            return `${ogPrice} ${gbpPrice.length > 0 ? `(${gbpPrice})` : ""}`;
        }
    }, {
        "headerName": "Percentage Invoiced",
        "field": "PercentageInvoiced"
    }, {
        "headerName": "Percentage Paid",
        "field": "PercentagePaid"
    }],
    "rowActions": [{
        "label": "Open Variation Order",
        action({ router, rowItem }) {
            router.push(`/Database/VariationOrders/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Variation Order",
        "action": editVariationOrder
    }]
};
