export const addEWIS = ({ form, db  }) => {
    form.setConfig({
        "title": "Add EWIS",
        "type": "push",
        "location": "Database/EWIS/saves",
        "fields": [{
            "label": "Design Change",
            "name": "parent_id",
            "type": "dropdown",
            "dataLocation": "Database/Refs/DesignChanges",
            "labelKey": "Number",
            "idKey": "ID",
            "search": false
        }, {
            "label": "Description",
            "name": "description",
            "type": "text"
        }],
        preSubmitHook({  selectedDropdowns }) {
            return {
                "parent": selectedDropdowns.parent_id.label,
                "date": new Date().toISOString()
            }
        },
        async postSubmitHook({ typeRef }){
            db.set(`Database/EWIS/saves/${typeRef.key}/key`, typeRef.key);
            let firstQuestion = await db.once("Database/EWIS/questions/_01");
            db.set(`Database/EWIS/saves/${typeRef.key}/questions/`, [firstQuestion]);
        }
    });
    form.getDropdownData();
    form.toggle();
};
