import {
    db
} from "../../../../db.js";

export default {
    "title": "Aircraft Ferry Details",
    "submitButtonText": "Save & Next",
    "description": "search by airport code",
    "fields": [{
        "label": "Current Location",
        "name": "ac_location_from",
        "type": "dropdown",
        "dropdownOptions": "airports",
        "matchKeys": ["code"]
    }, {
        "label": "Desination Location",
        "name": "ac_location_to",
        "type": "dropdown",
        "dropdownOptions": "airports",
        "matchKeys": ["code"]
    }],
    async dataLoader() {
        const data = Object.values(await db.once("REST/Airports"));
        return {
            "airports": data.map(airport => {
                return {
                    id: `${airport.code} - ${airport.city}`,
                    label: airport.string,
                    code: airport.code,
                };
            })
        };
    }
}
