import { reviseForm, updateFormRevision, viewFormRevision } from "./actions";

import {
    DateValue
} from "@comp/index.js";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

export default {
    "label": "Revisions",
    "key": "Revisions",
    "local": true,
    "headers": [{
        "headerName": "Revision",
        "field": "Revision",
        "sort": "desc"
    }, {
        "headerName": "Date",
        "field": "Date",
        "filter": "agDateColumnFilter",
        "cellRenderer": DateValue
    }, {
        "headerName": "Reason",
        "field": "Reason"
    }],
    "rowActions": [{
        "label": "Update Revision",
        "action": updateFormRevision
    }, {
        "label": "Download Form Template",
        async action({ pageItem, rowItem, db }) {
            let rev = pageItem.Revisions[pageItem.RevisionID];
            let url = await db.download(`Database/DocumentTypes/${pageItem.ID}/Revisions/${pageItem.RevisionID}/${rev.File}`);
            window.open(url, "__blank");
        },
        show({ rowItem, pageItem }) {
            return [rowItem.ID === pageItem.RevisionID, "You can only download the latest revision"]
        }
    }, {
        "label": "Download MFAR",
        async action({ db, rowItem, pageItem, store }) {
            if (!store.state["Database/MFARQuestions"]) {
                await db.store("Database/MFARQuestions", store);
            }
            if (!rowItem.MFAR) {
                return;
            };
            if (typeof rowItem.MFAR === "string") {
                let url = await db.download(`Database/Handbooks/${pageItem.ID}/Revisions/${rowItem.ID}/${rowItem.MFAR}`);
                window.open(url, "__blank");
            } else if (typeof rowItem.MFAR === "object") {

                const mfarURL = await db.download("Database/DocumentTypes/mfar_test.docx");

                let fileBinary = await new Promise((resolve, reject) => {
                    PizZipUtils.getBinaryContent(mfarURL, (err, content) => {
                        if (err) reject(err);
                        resolve(content);
                    });
                });

                let zip = new PizZip(fileBinary);

                const doc = new Docxtemplater(zip);

                doc.setData({
                    title: pageItem.Name,
                    number: pageItem.Number || pageItem.Name,
                    revision: rowItem.Revision,
                    date: rowItem.Date,
                    reason: rowItem.Reason,
                    questions:
                        Object.entries(rowItem.MFAR).map(([key, value]) => {
                            return {
                                question: store.state["Database/MFARQuestions"][key].Question,
                                ans: value
                            };
                        })
                });

                doc.render();

                const out = doc.getZip().generate({
                    type: "blob",
                    mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                });
                // Output the document using Data-URI
                saveAs(out, "mfar.docx");
            }
        },
        show({ rowItem }) {
            return [!!rowItem.MFAR, "No MFAR found"];
        }
    }, {
        "label": "View Revision Details",
        "action": viewFormRevision,
        show({ rowItem }) {
            return [!!rowItem.MFAR, "No MFAR found"];
        }
    }],
    "actions": [
        {
            "label": "Revise Form",
            "action": reviseForm
        },
        {
            "label": "Download Latest",
            async action({ db, pageItem }) {
                let rev = pageItem.Revisions[pageItem.RevisionID];
                let url = await db.download(`Database/DocumentTypes/${pageItem.ID}/Revisions/${pageItem.RevisionID}/${rev.File}`);
                window.open(url, "__blank");
            }
        }
    ]
};
