import {
    standardDateLocal
} from "../../../utils.js";

export const printDeclaration = async ({ pageItem }) => {
    let docDef = {
        "content": []
    };
    let con = docDef.content;

    con.push({
        "text": `UK Flight Condition Approval Form Declaration`,
        "fontSize": 14,
        "bold": true,
        "margin": [0, 8]
    });

    con.push({
        "text": `Unless otherwise specified (in the request) I hereby confirm the following:`,
        "fontSize": 12,
        "bold": true,
        "margin": [0, 8]
    });

    con.push({
        ul: [
            "There is a valid Certificate of Airworthiness (C of A) for the aircraft",
            "There is a valid Airworthiness Review Certificate (ARC) for the aircraft",
            "All applicable Airworthiness Directives have been identified and complied with",
            "All life limited and hard time components are within the specified MPD life limitations",
            "The aircraft has been maintained to an approved maintenance programme and that there is no outstanding maintenance",
            "All repairs approved",
            "All aircraft Design Changes (Modifications) are approved",
            "The aircraft is free from any defects (including MMEL items)",
            "There are no missing items as per the configurations deviations list (CDL)"
        ]
    })

    con.push({
        "columns": [{
            "width": "33%",
            "text": "Name",
            "bold": true
        }, {
            "width": "33%",
            "text": "Position",
            "bold": true
        }, {
            "width": "33%",
            "text": "Declaration Date",
            "bold": true
        }],
        "margin": [0, 8]
    })

    con.push({
        "columns": [{
            "width": "33%",
            "text": pageItem.accepted_name
        }, {
            "width": "33%",
            "text": pageItem.accepted_position
        }, {
            "width": "33%",
            "text": (new Date(pageItem.date_completed)).toLocaleDateString("en-gb", standardDateLocal)
        }],
        "margin": [0, 12]
    });

    const {
        downloadPdf
    } = await import("../../../pdfDownloader.js");

    downloadPdf(docDef)
};


export const uploadFile = ({ form, pageItem, db }) => {
    form.setConfig({
        "title": "Upload File",
        "location": `Database/FlightConditionsApprovalForms/${pageItem.ID}/files`,
        "type": "ignoreAutoAction",
        "fields": [{
            "label": "File(s)",
            "type": "file",
            "name": "File",
            "multiple": true
        }],
        async preSubmitHook({ files }) {
            
            for (let file of files[0]) {
                
                await db.upload(`fcaApplications/${pageItem.ID}`, file);
                await db.push(`Database/FlightConditionsApprovalForms/${pageItem.ID}/files`, {
                    "File": file.name
                });
            }
        }
    });

    form.toggle();
}

export const acceptApplication = async ({ form, pageItem, db, router }) => {
    form.setConfig({
        "title": "Accept Application",
        "type": "update",
        "location": `Database/FlightConditionsApprovalForms/${pageItem.ID}`,
        "fields": [],
        async preSubmitHook() {
            return {
                "status": "Accepted",
                ...pageItem
            };
        },
        async postSubmitHook() {
            await db.delete(`OpenForms/FlightConditionApplications/${pageItem.ID}`);
            router.push("/Database/FlightConditionsApprovalForms/" + pageItem.ID);
        }
    });

    form.toggle();
}

export const addApplicationBackToOpen = async ({ form, pageItem, db, router }) => {
    form.setConfig({
        "title": "Add Application Back To Open",
        "type": "update",
        "location": `OpenForms/FlightConditionApplications/${pageItem.ID}`,
        "fields": [],
        async preSubmitHook() {
            return {
                "status": "Open",
                ...pageItem
            };
        },
        async postSubmitHook() {
            db.update("/Database/FlightConditionsApprovalForms/" + pageItem.ID, {
                "Status": "Open"
            });
        }
    });

    form.toggle();
}
