import {
    appConfig,
    makeAttached,
    makeDropdown,
    next,
    pad
} from "../../../utils.js";

const revisionQuality = {
    "Approver": {
        "By": "N/A",
        "Completed": false,
        "Date": "N/A"
    },
    "Checker": {
        "By": "N/A",
        "Completed": false,
        "Date": "N/A"
    },
    "Compiler": {
        "By": "N/A",
        "Completed": false,
        "Date": "N/A"
    }
};

export const addDOI = ({ form, pageItem, store, attachedItems, meta }) => {
    form.setConfig({
        "title": "Add DOI",
        "type": "push",
        "location": "Database/DOIs",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}DOI-${pad(store.state.counts.database.doi.count)}`
            }
        }, {
            "label": "Comment",
            "name": "Comment",
            "type": "text",
            "divClass": "col-span-2"
        }, {
            "label": "Select Documents",
            "name": "Documents",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Documents.items, "Number", "ID")
        }, {
            "label": "Select Drawings",
            "name": "Drawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Drawings.items, "Number", "ID")
        }, {
            "label": "Select Effectivities",
            "name": "Effectivities",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Effectivities.items, "Number", "ID")
        }],
        preSubmitHook({ data }) {
            return {
                "Date": {
                    "Created": new Date().toISOString(),
                    "Cancelled": "N/A",
                    "Signedoff": "N/A"
                },
                "Cancelled": false,
                "Signedoff": false,
                "Author": store.getters.userName,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "ParentType": meta.ParentType,
                "Number": `${appConfig.docPrefix}DOI-${pad(store.state.counts.database.doi.count)}`,
                "Documents": makeAttached(data.Documents),
                "Drawings": makeAttached(data.Drawings),
                "Effectivities": makeAttached(data.Effectivities),
                "Revision": 1,
                "RevisionID": "--R1",
                "Revisions": {
                    "--R1": {
                        "Revision": 1,
                        "Reason": "Document Created",
                        "ID": "--R1",
                        "Date": new Date().toISOString(),
                        "Quality": revisionQuality
                    }
                },
            };
        },
        postSubmitHook({ db, data, typeRef }) {
            data.ID = typeRef.key;
            db.set(`Database/${meta.ParentType}/${pageItem.ID}/DOIs/${data.ID}`, true);
            db.utils.addAllTo(data, "Database/Documents", "Documents", "DOIs");
            db.utils.addAllTo(data, "Database/Drawings", "Drawings", "DOIs");
            db.utils.addAllTo(data, "Database/Effectivities", "Effectivities", "DOIs");
            db.utils.upCount("counts/database/doi/count");
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editDOI = async ({ form, item, attachedItems, store }) => {

    form.setValues({
        "Comment": item.Comment,
        "Documents": Object.keys(item.Documents || {}),
        "Drawings": Object.keys(item.Drawings || {}),
        "Effectivities": Object.keys(item.Effectivities || {})
    });
    form.setConfig({
        "title": "Update DOI",
        "type": "update",
        "location": `Database/DOIs/${item.ID}`,
        "set": true,
        "fields": [{
            "label": "Comment",
            "name": "Comment",
            "type": "text",
            "divClass": "col-span-3"
        }, {
            "label": "Select Documents",
            "name": "Documents",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Documents.items, "Number", "ID")
        }, {
            "label": "Select Drawings",
            "name": "Drawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Drawings.items, "Number", "ID")
        }, {
            "label": "Select Effectivities",
            "name": "Effectivities",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Effectivities.items, "Number", "ID")
        }],
        preSubmitHook({ data }) {
            return {
                "Documents": makeAttached(data.Documents),
                "Drawings": makeAttached(data.Drawings),
                "Effectivities": makeAttached(data.Effectivities)
            };
        },
        postSubmitHook({ db, data, typeRef }) {
            db.utils.addAllTo(data, "Database/Documents", "Documents", "DOIs");
            db.utils.addAllTo(data, "Database/Drawings", "Drawings", "DOIs");
            db.utils.addAllTo(data, "Database/Effectivities", "Effectivities", "DOIs");
        }
    });
    await form.getDropdownData();
    
    form.toggle();
};

export const cancelDOI = ({ form, item }) => {
    form.setConfig({
        "title": "Cancel DOI",
        "description": `Are you sure you want to cancel DOI ${item.Number}?`,
        "type": "update",
        "location": `Database/DOIs/${item.ID}`,
        "fields": [],
        preSubmitHook(){
            return {
                "Cancelled": true,
                "Date/Cancelled": new Date().toISOString(),
                revisionQuality
            };
        },
    });
    form.toggle();
};

export const signoffDOI = ({ form, item }) => {
    form.setConfig({
        "title": "Signoff DOI",
        "description": `Are you sure you want to signoff DOI ${item.Number}?`,
        "type": "update",
        "location": `Database/DOIs/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Signedoff": true,
                "Date/Signedoff": new Date().toISOString()
            };
        },
    });
    form.toggle();
};

export const linkDOI = ({ form, pageItem, meta }) => {
    form.setConfig({
        "title": "Link DOI",
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}`,
        "fields": [{
            "label": "DOI",
            "name": "DOI",
            "type": "dropdown",
            "labelKey": "Number",
            "idKey": "ID",
            "dataLocation": "Database/DOIs"
        }],
        preSubmitHook({liveInputData}) {
            return {
                "DOI": null,
                [`DOIs/${liveInputData.DOI}`]: true
            }
        },
        postSubmitHook({ db, liveInputData }) {
            db.set(`Database/DOIs/${liveInputData.DOI}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const reviseDOI = ({form, item, store }) => {

    const nextRevision = next(item.Revision);
    const nextRevisionID = `--R${nextRevision}`;

    form.setConfig({
        "title": "Revise DOI",
        "type": "update",
        "location": `Database/DOIs/${item.ID}`,
        "description": `Revise ${item.Number} from ${item.Revision} to ${nextRevision}`,
        "fields": [{
            "label": "Reason",
            "name": `Revisions[${nextRevisionID}][Reason]`,
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/DocumentRevisionTypes",
            "divClass": "cols-span-3"
        }],
        preSubmitHook({ data }) {
            return {
                "Revision": nextRevision,
                "RevisionID": nextRevisionID,
                "Revisions": {
                    [nextRevisionID]: {
                        "ID": nextRevisionID,
                        "Date": (new Date()).toISOString(),
                        "Revision": nextRevision,
                        "RevisedBy": store.state.user.Basic.name,
                        "Quality": revisionQuality,
                        "Reason": data.Revisions[nextRevisionID].Reason
                    }
                }
            };
        }
    });

    form.getDropdownData();
    form.toggle();
};

export const checkDOI = type => ({ form, item, store,  }) => {

    
    form.setConfig({
        "title": `${type} Check DOI`,
        "type": "update",
        "descriptionStyles": "bg-orange rounded font-bold m-2",
        "location": `Database/DOIs/${item.ID}`,
        "submitButtonLabel": "I confirm",
        "fields": [],
        preSubmitHook() {
            return {
                [type]: true,
                [`Revisions/${item.RevisionID}/Quality/${type}/By`]: store.getters.userName,
                [`Revisions/${item.RevisionID}/Quality/${type}/Completed`]: true,
                [`Revisions/${item.RevisionID}/Quality/${type}/Date`]: (new Date()).toISOString()
            };
        },
    });
    form.toggle();
};
