import {
    addDocumentRevisionReason,
    editDocumentRevisionReason
} from "./actions";

export default {
    "label": "Document Revision Reasons",
    "location": "Database/DocumentRevisionTypes/",
    "key": "DocumentRevisionTypes",
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "actions": [{
        "label": "Add Revision Reason",
        "action": addDocumentRevisionReason
    }],
    "rowActions": [{
        "label": "Edit Revision Reason",
        "action": editDocumentRevisionReason
    }]
}
