import _ from "lodash";
export const purchaseOrderValue = {
    "title": {
        "text": "PO's Value by Customer"
    },
    "yearSelector": true,
    dataFilter({ pos, state }) {
        let data = _.chain(pos)
            .values()
            .filter(i => {
                if (state.year === "Unknown") return i?.Date?.Created == "Unknown"
                return (new Date(i?.Date?.Created).getFullYear() == state.year);
            })
            .sortBy("Customer")
            .groupBy("Customer").toPairs().map(([key, value]) => {
                return {
                    "customer": key,
                    "amount": value.reduce((acc, i) => acc + parseFloat(i.Price), 0)
                };
            })
            .value();
        return data;
    },
    "chart": {

        "title": {
            "text": "PO's Value by Customer"
        },
        "series": [{
            "type": "column",
            "xKey": "customer",
            "yKey": "amount"
        }],
        "axes": [{
            "type": 'category',
            "position": 'bottom',
            "title": {
                "text": 'Customer'
            }
        }, {
            "type": 'number',
            "position": 'left',
            "title": {
                "text": 'Amount'
            }
        }]
    },
    "table": {
        "paginationPageSize": 150,
        "defaultColDef": {
            "sortable": true,
            "filterFramework": true,
            "filter": true,
            "flex": 1,
            "resizable": true
        },
        "columnDefs": [{
            "headerName": "Customer",
            "field": "customer",
            "width": 300
        }, {
            "headerName": "Price",
            "field": "amount",
            "width": 300,
            "type": "currency",
            "sortIndex": 1,
            "sort": "desc"
        }]
    }
};

export const purchaseOrdersNumbersByCustomer = {
    "title": {
        "text": "PO Numbers by Customer"
    },
    "yearSelector": true,
    dataFilter({ pos, state }) {

        let data = _.chain(pos)
            .values()
            .filter(i => {
                if (state.year === "Unknown") return i?.Date?.Created == "Unknown"
                return (new Date(i?.Date?.Created).getFullYear() == state.year);
            })
            .sortBy("Customer")
            .groupBy("Customer").toPairs().map(([key, value]) => {
                return {
                    "customer": key,
                    "amount": value.length
                };
            })
            .value();

        return data;
    },
    "chart": {
        "title": {
            "text": "PO Numbers by Customer"
        },
        "series": [{
            "type": "column",
            "xKey": "customer",
            "yKey": "amount"
        }]
    },
    "table": {
        "paginationPageSize": 150,
        "defaultColDef": {
            "sortable": true,
            "filterFramework": true,
            "filter": true,
            "flex": 1,
            "resizable": true
        },
        "columnDefs": [{
            "headerName": "Customer",
            "field": "customer",
            "width": 300,
            "sortIndex": 0,
            "sort": "desc"
        }, {
            "headerName": "Number of PO's",
            "field": "amount",
            "width": 300

        }]
    }

};

export const averageQuotePricePerYear = {
    "title": {
        "text": "Average Quote per Year"
    },
    "yearSelector": false,
    dataFilter({ pos }) {
        let data = _.chain(pos)
            .values()
            .groupBy(i => {
                if (i?.Date?.Created === "Unknown") return "Unknown";
                else return (new Date(i?.Date?.Created).getFullYear()).toString();
            })
            .toPairs()
            .map(([key, value]) => {
                return {
                    "year": key,
                    "averageQuote": Math.round(value.reduce((acc, i) => {
                        return acc + parseFloat(i.Price)
                    }, 0) / value.length || 1)
                }
            })
            .value();

        return data;
    },
    "chart": {
        "title": {
            "text": "Average Quote per Year"
        },
        "series": [{
            "type": "column",
            "xKey": "year",
            "yKey": "averageQuote"
        }],

    },
    "table": {
        "paginationPageSize": 150,
        "defaultColDef": {
            "sortable": true,
            "filterFramework": true,
            "filter": true,
            "flex": 1,
            "resizable": true
        },
        "columnDefs": [{
            "headerName": "Year",
            "field": "year",
            "width": 300
        }, {
            "headerName": "Average Quote",
            "field": "averageQuote",
            "width": 300,
            "type": "currency",
            "sortIndex": 1,
            "sort": "desc"
        }]
    }
};

export const totalPerYear = {
    "title": {
        "text": "Total per Year"
    },
    "yearSelector": false,
    dataFilter({ pos }) {
        let data = _.chain(pos)
            .values()
            .groupBy(i => {
                if (i?.Date?.Created === "Unknown") return "Unknown";
                else return (new Date(i?.Date?.Created).getFullYear()).toString();
            })
            .toPairs()
            .map(([key, value]) => {
                return {
                    "year": key,
                    "averageQuote": value.reduce((acc, i) => {
                        return acc + parseFloat(i.Price)
                    }, 0)
                }
            })
            .value();
        return data;
    },
    "chart": {
        "title": {
            "text": "PO's Value by Customer"
        },
        "series": [{
            "type": "column",
            "xKey": "year",
            "yKey": "averageQuote"
        }]
    },
    "table": {
        "paginationPageSize": 150,
        "defaultColDef": {
            "sortable": true,
            "filterFramework": true,
            "filter": true,
            "flex": 1,
            "resizable": true
        },
        "columnDefs": [{
            "headerName": "Year",
            "field": "year",
            "width": 300
        }, {
            "headerName": "Average Quote",
            "field": "averageQuote",
            "width": 300,
            "type": "currency",
            "sortIndex": 1,
            "sort": "desc"
        }]
    }
};

export const uptake = {
    "title": {
        "text": "Purchase Order Uptake"
    },
    "yearSelector": true,
    dataFilter({ commercials, state }) {
        let data = _.chain(commercials)
            .values()
            .filter(i => {
                if (state.year === "Unknown") return i?.Date?.Created == "Unknown"
                return (new Date(i?.Date?.Created).getFullYear() == state.year);
            })
            .reduce((acc, i) => {
                if (i.PurchaseOrders) acc[0].val++;
                else acc[1].val++;
                return acc;
            }, [{ val: 0, name: 'Purchase Order' }, { val: 0, name: 'No Purchase Order' }])
            .value();
        return data;
    },
    "chart": {
        "title": {
            "text": "Purchase Order Uptake"
        },
        "series": [{
            "colors": ["red, green"],
            "type": "pie",
            "angleKey": "val",
            "labelKey": "name",
            "fills": ["green", "red"]
        }]
    }
};

export const uptakeAllTime = {
    "title": {
        "text": "Purchase Order Uptake All Time"
    },
    "yearSelector": false,
    dataFilter({ commercials, state }) {
        let data = _.chain(commercials)
            .values()
            .filter(i => {
                return !isNaN(new Date(i?.Date?.Created).getFullYear());
            })
            .groupBy(i => {
                if (i?.Date?.Created === "Unknown") return "Unknown";
                else return (new Date(i?.Date?.Created).getFullYear()).toString();
            })
            .toPairs()
            .map(([key, value]) => {
                return {
                    "year": key,
                    "purchaseOrder": value.reduce((acc, i) => {
                        return acc + (i.PurchaseOrders ? 1 : 0)
                    }, 0),
                    "noPurchaseOrder": value.reduce((acc, i) => {
                        return acc + (i.PurchaseOrders ? 0 : 1)
                    }, 0)
                }
            })

            .value();
        return data;
    },
    "chart": {
        "title": {
            "text": "Purchase Order Uptake (All Years)"
        },
        "series": [{
            "fill": ["red", "green"],
            "type": "column",
            "xKey": "year",
            "yKey": "noPurchaseOrder",
            "yName": "No Purchase Order",
            "stacked": true,
            label: {
    formatter: function (params) {
        // if the data contains values that are not valid numbers,
        // the formatter's `value` will be `undefined`
        return params.value === undefined ? '' : params.value.toFixed(0);
    }
}
        }, {
            "type": "column",
            "xKey": "year",
            "yKey": "purchaseOrder",
            "yName": "Purchase Order",
            "stacked": true,
            label: {
    formatter: function (params) {
        // if the data contains values that are not valid numbers,
        // the formatter's `value` will be `undefined`
        return params.value === undefined ? '' : params.value.toFixed(0);
    }
}

        }]
    }
};

export const purchaseOrdersByMonthAndYear = {
    "title": {
        "text": "Purchase Order Uptake by Month"
    },
    "yearSelected": false,
    dataFilter({ pos, state }) {
        let _xxx = _.chain(pos)
            .filter(i => {
                return ((new Date(i?.Date?.Created)).getFullYear()) > 2016
            })
            .groupBy(i => {
                return ((new Date(i.Date.Created)).getMonth()).toString()
            })
            .toPairs()
            .map(([month, items]) => {
                let years = _.chain(items).groupBy(i => {
                    return ((new Date(i?.Date?.Created)).getFullYear()).toString();
                }).toPairs().map(([year, items]) => {
                    return [year, items.length];
                }).fromPairs().value();


                return {
                    "month": month,
                    ...years,
                    "average": Math.round((Object.values(years).reduce((acc, i) => acc + i, 0)) / Object.values(years).length)
                }

            }, [])
            .sortBy(i => parseInt(i.month))
            .map(i => {
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                return {
                    ...i,
                    "month": monthNames[i.month]
                }
            })
            .value();

        return _xxx
    },
    "chart": {
        "title": {
            "text": "Purchase Order Uptake by Month"
        },
        series({ pos, type }) {
            return [...(_
                .chain(pos)
                .filter(i => {
                    return ((new Date(i?.Date?.Created)).getFullYear()) > 2016
                })
                .groupBy(i => {
                    let createdDate = new Date(i.Date.Created);
                    return (createdDate.getFullYear()).toString();
                })
                .keys()
                .map(i => {
                    return {
                        "type": type || "column",
                        "xKey": "month",
                        "yKey": i,
                        "yName": i,
                        // "stacked": true
                    };
                })
                .value()), {
                "type": "line",
                "xKey": "month",
                "yKey": "average",
                "yName": "Average"
            }];
        }
    }
};

export const completedDesignChangesByMonth = {
    "title": {
        "text": "Completed Design Changes by Month"
    },
    "yearSelected": false,
    dataFilter({ designs, state }) {
        return _.chain(designs)
            .filter(i => {
                return ((new Date(i?.Date?.Completed)).getFullYear()) > 2016
            })
            .groupBy(i => {
                return ((new Date(i.Date.Completed)).getMonth()).toString()
            })
            .toPairs()
            .map(([month, items]) => {
                let years = _.chain(items).groupBy(i => {
                    return ((new Date(i?.Date?.Completed)).getFullYear()).toString();
                }).toPairs().map(([year, items]) => {
                    return [year, items.length];
                }).fromPairs().value();


                return {
                    "month": month,
                    ...years,
                    "average": Math.round((Object.values(years).reduce((acc, i) => acc + i, 0)) / Object.values(years).length)
                }

            }, [])
            .sortBy(i => parseInt(i.month))
            .map(i => {
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                return {
                    ...i,
                    "month": monthNames[i.month]
                }
            })
            .value();

    },
    "chart": {
        "title": {
            "text": "Completed Design Changes by Month"
        },
        series({ designs, type }) {
            return [...(_
                .chain(designs)
                .filter(i => {
                    return ((new Date(i?.Date?.Completed)).getFullYear()) > 2016
                })
                .groupBy(i => {
                    let completedDate = new Date(i.Date.Completed);
                    return (completedDate.getFullYear()).toString();
                })
                .keys()
                .map(i => {
                    return {
                        "type": type || "column",
                        "xKey": "month",
                        "yKey": i,
                        "yName": i,
                        // "stacked": true
                    };
                })
                .value()), {
                "type": "line",
                "xKey": "month",
                "yKey": "average",
                "yName": "Average"
            }];
        }
    }

};

export const completedJobCardsByMonth = {
    "title": {
        "text": "Completed Job Cards by Month"
    },
    "yearSelected": false,
    dataFilter({ cards, state }) {
        return _.chain(cards)
            .filter(i => {
                return ((new Date(i?.Date?.Signedoff?.Engineer)).getFullYear()) > 2016
            })
            .groupBy(i => {
                return ((new Date(i.Date.Signedoff?.Engineer)).getMonth()).toString()
            })
            .toPairs()
            .map(([month, items]) => {
                let years = _.chain(items).groupBy(i => {
                    return ((new Date(i?.Date?.Signedoff?.Engineer)).getFullYear()).toString();
                }).toPairs().map(([year, items]) => {
                    return [year, items.length];
                }).fromPairs().value();


                return {
                    "month": month,
                    ...years,
                    "average": Math.round((Object.values(years).reduce((acc, i) => acc + i, 0)) / Object.values(years).length)
                }

            }, [])
            .sortBy(i => parseInt(i.month))
            .map(i => {
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                return {
                    ...i,
                    "month": monthNames[i.month]
                }
            })
            .value();

    },
    "chart": {
        "title": {
            "text": "Completed Job Cards by Month"
        },
        series({ cards, type }) {
            return [...(_
                .chain(cards)
                .filter(i => {
                    return ((new Date(i?.Date?.Signedoff?.Engineer)).getFullYear()) > 2016
                })
                .groupBy(i => {
                    let completedDate = new Date(i.Date.Signedoff?.Engineer);
                    return (completedDate.getFullYear()).toString();
                })
                .keys()
                .map(i => {
                    return {
                        "type": type || "column",
                        "xKey": "month",
                        "yKey": i,
                        "yName": i,
                        // "stacked": true
                    };
                })
                .value()), {
                "type": "line",
                "xKey": "month",
                "yKey": "average",
                "yName": "Average"
            }];
        }
    }

};
