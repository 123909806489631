import {
    appConfig,
    pad
} from "../../../utils.js";

import _aeroplane from "../../../../../resources/data/aeroplanes.json";
import {
    createBasicDocuments
} from "../Documents/actions.js";
import {
    createBasicRedCard
} from "../JobCards/actions.js";

const aeroplanes = _aeroplane.map(i => {
    return {
        "label": `${i.TCHolder} - ${i.Type}`,
        "id": `${i.TCHolder} - ${i.Type}`
    };
});

const categories = ["Approval of the flight conditions is related to the safety of the design", "Approval of the flight conditions is not related to the safety of the design"].map(i => ({ label: i, id: i }));

const types = [`${appConfig.approvalPrefix === "UK" ? "UK CAA" : "EASA"} Form 18A will be completed and issued to ${appConfig.approvalPrefix === "UK" ? "UK CAA" : "EASA"} with Form 37 (for cases excluded by 21A.263(c)(6))`, `${appConfig.approvalPrefix === "UK" ? "UK CAA" : "EASA"} Form 18A will be completed for items 1 thru 10 above`].map(i => ({ label: i, id: i }));

let fields = [{
    "label": "Title",
    "name": "Title",
    "type": "text",
    "divClass": "col-span-2"
}, {
    "label": "Responsible Engineer",
    "name": "ResponsibleEngineer",
    "type": "dropdown",
    "labelKey": "name",
    "idKey": "name",
    "dataLocation": "users",
    dataFilter(user) {
        return user.main;
    }
}, {
    "label": "Purpose",
    "name": "Purpose",
    "type": "dropdown",
    "labelKey": "name",
    "idKey": "name",
    "dataLocation": "Database/FlightConditionPurposes",
}, {
    "label": "Category",
    "name": "Category",
    "type": "dropdown",
    "dropdownData": categories
}, {
    "label": "IssueType",
    "name": "IssueType",
    "type": "dropdown",
    "dropdownData": types
    }, {
        "label": "Aircraft",
        "name": "Aircraft",
        "type": "dropdown",
        "labelKey": "NameFull",
        "idKey": "NameFull",
        "dataLocation": "Database/AircraftCodes"
    }, {
    "label": "Manufacturers Serial Number",
    "name": "SerialNumber",
    "type": "text"
}, {
    "label": "Nationality and Registration Marks",
    "name": "Registration",
    "type": "text"
}];

export const addFlightCondition = ({ form, pageItem, store }) => {
    form.setConfig({
        "title": "Add Flight Condition",
        "type": "push",
        "location": "Database/FlightConditions",
        "fields": fields,
        preSubmitHook() {
            return {
                "Number": `FC${pad(store.state.counts.database.fc.count, 4)}`,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "Customer": pageItem.Customer,
                "Date": {
                    "Created": (new Date()).toISOString(),
                    "Cancelled": "N/A",
                    "Completed": "N/A"
                },
                "Cancelled": false,
                "Completed": false,
                "Commercials": {
                    [pageItem.ID]: true
                }
            }
        },
        async postSubmitHook({ db, typeRef, data}) {
            data.ID = typeRef.key;

            await db.set("counts/database/fc/count", store.state.counts.database.fc.count + 1);

            await db.set(`Database/Commercials/${pageItem.ID}/FlightConditions/${data.ID}`, true);

            db.utils.updateRefs(data, "Database/Refs/FlightConditions", ["Number", "ID", "Title", "Date", "Customer", "Cancelled", "Completed", "ResponsibleEngineer", "Parent", "EASANumber", "Type"]);

            let newDocs = await createBasicDocuments({ store, db, parent: data, parentType: "FlightConditions" });
            for (let doc of newDocs) {
                let ref = await db.push(`Database/Documents`, doc);
                doc.ID = ref.key;
                db.utils.updateRefs(doc, "Database/Refs/Documents", ["Number", "ID", "Title", "Date", "Revision", "Parent", "ParentID", "SubDocumentType", "MPDS"]);
                db.utils.updateRefs({
                    "key": ref.key,
                    "c": doc.Number
                }, "Database/SuperRefs/Documents", ["k", "c"]);
                await db.set(`Database/FlightConditions/${typeRef.key}/Documents/${ref.key}`, true);
                //update the count for the document type
                db.set(`Database/DocumentTypes/${doc.TypeID}/Count`, doc.Count + 1);
            };

            const redCard = createBasicRedCard({ store, db, parent: data, parentType: "FlightConditions" });
            await db.set(`Database/FlightConditions/${typeRef.key}/JobCards/${typeRef.key}`, true);
            db.update(`Database/JobCards/${typeRef.key}`, redCard );
            await db.set("counts/cards/count", store.state.counts.cards.count + 1);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editFlightCondition = ({ item, form, }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Edit Flight Condition",
        "description": `Edit Flight Condition ${item.Number}`,
        "type": "update",
        "location": `Database/FlightConditions/${item.ID}`,
        "fields": fields,
        postSubmitHook( {db, data} ) {
            db.utils.updateRefs(
                data, 
                "Database/Refs/FlightConditions", 
                ["Number", "ID", "Title", "Date", "Customer", "Cancelled", "Completed", "ResponsibleEngineer", "Parent", "EASANumber", "Type"]
            );
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const completeFlightCondition = ({ item, form, }) => {
    form.setConfig({
        "title": "Complete Flight Condition",
        "description": `Complete Flight Condition ${item.Number}`,
        "type": "update",
        "location": `Database/FlightConditions/${item.ID}`,
        "fields": [],
        preSubmitHook(){
            return {
                "Completed": true,
                "Date/Completed": (new Date()).toISOString()
            }
        }
    });
    form.toggle();
};

export const cancelFlightCondition = ({ item, form, }) => {
    form.setConfig({
        "title": "Cancel Flight Condition",
        "description": `Cancel Flight Condition ${item.Number}`,
        "type": "update",
        "location": `Database/FlightConditions/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": true,
                "Date/Cancelled": (new Date()).toISOString()
            }
        }
    });
    form.toggle();
};

export const linkFlightCondition = ({ form, pageItem, db, meta }) => {
    form.setConfig({ 
        "title": "Link Flight Condition",
        "description": `Link a Flight Condition to ${pageItem.Number}`,
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/FlightConditions`,
        "fields": [{
            "label": "Flight Condition",
            "name": "_doc",
            "type": "dropdown",
            "dataLocation": "Database/Refs/FlightConditions",
            "labelKey": "Number",
            "idKey": "ID",
            "search": false
        }],
        preSubmitHook({ data }) {
            return {
                "_doc": null,
                [data._doc]: true
            }
        },
        postSubmitHook({ liveInputData }) {
            db.set(`Database/FlightConditions/${liveInputData._doc}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const linkFlightConditionToDesignChange = ({ pageItem, form }) => {
    form.setValues({
        "DesignChangeID": pageItem.DesignChangeID
    });
    form.setConfig({
        "title": `Link a Design Change to ${pageItem.Number}`,
        "type": "update",
        "location": `Database/FlightConditions/${pageItem.ID}`,
        "fields": [{
            "label": "Design Change",
            "name": "DesignChangeID",
            "type": "dropdown",
            "labelKey": "Number",
            "idKey": "ID",
            "dataLocation": "Database/Refs/DesignChanges"
        }],
        preSubmitHook({ data, selectedDropdowns }) {
            return {
                "DesignChange": selectedDropdowns.DesignChangeID.label
            };
        } 
    });
    form.getDropdownData();
    form.toggle();

};
