import {
    addDocumentType, editDocumentType
} from "./actions";

export default {
    "label": "Document Types",
    "key": "DocumentTypes",
    "location": "Database/DocumentTypes/",
    "actions": [{
        "label": "Add Document Type",
        "action": addDocumentType
    }],
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }, {
        "headerName": "Acronym",
        "field": "NameShort"
    }, {
        "headerName": "Average Time to Complete",
        "field": "ExpectedTime"
    }],
    "rowActions": [{
        "label": "Edit Document Type",
        "action": editDocumentType
    }]
};
