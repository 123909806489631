import {
    addFile,
    editServiceOrder,
    removeFile
} from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import standardMenuActions from "../../standardMenuActions";

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Primary Commercial / Job Number",
    "key": "Commercial",
    "link": "Database/Commercials/:CommercialID"
}, {
    "label": "Customer",
    "key": "Customer"
}, { 
    "label": "Supplier",
    "key": "Supplier"
},{
    "label": "Supplier Reference",
    "key": "SupplierReference"
}, {
    "label": "Price",
    "key": "Price"
}];

const menuActions = [
    {
        "label": "Edit Service Order",
        "action": editServiceOrder
    },
    standardMenuActions.back
];

const files = {
    "label": "Supporting Files",
    "key": "Files",
    "local": true,
    "actions": [{
        "label": "Add File",
        "action": addFile
    }],
    "headers": [{
        "headerName": "File Name",
        "field": "Name"
    }],
    "rowActions": [{
        "label": "Open File",
        async action({ db, rowItem, pageItem }) {
            let url = await db.download(`Database/ServiceOrders/${pageItem.ID}/Files/${rowItem.ID}/${rowItem.Name}`);
            window.open(url, "__blank");
        }
    }, {
        "label": "Remove File",
        "action": removeFile
    }]

};

export default [{
    "path": "/Database/ServiceOrders",
    "component": { ...ItemListView },
    "name": "Service Orders",
    "meta": {
        "app": "Database",
        "name": "Service Orders",
        "sideNav": true,
        "sideNavSection": "Projects",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "ServiceOrders",
        "dataPath": "Database/ServiceOrders",
        "selectionRoute": "Database/ServiceOrders/{ID}",
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Database/ServiceOrders/:id",
    "component": ItemView,
    "name": "Service Order",
    "meta": {
        "app": "Database",
        "name": "Service Orders",
        "dataPath": "Database/ServiceOrders",
        "selectionRoute": "Database/ServiceOrders/{ID}",
        title(item) {
            return `Service Order: ${item.Number}`
        },
        "attachedItems": [],
        "dataFields": dataFields,
        "menuActions": menuActions,
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        },
        "attachedItems": {
            "Files": {
                "attachables": [files]
            }
        }
    }
}];
