const appConfig = JSON.parse(import.meta.env.VITE_APP_CONFIG);

import {
    addCommercial,
    amendQuote,
    cancelCommercial,
    editCommercial
} from "./actions";
import { costTime, timeBreakdown } from "./Performance/internalAttachables";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import _designChangeAttachable from "../DesignChanges/attachable.js";
import _flightConditionAttachable from "../FlightConditions/attachable.js";
import _jobCardAttachable from "../JobCards/attachable.js";
import _purchaseOrderAttachable from "../PurchaseOrders/attachable.js";
import _repairsAttachable from "../Repairs/attachable.js";
import _serviceOrderAttachable from "../ServiceOrders/attachable.js";
import _sowAttachable from "../SOWs/attachable.js";
import _variationOrdereAttachable from "../VariationOrders/attachable.js";

let purchaseOrderAttachable = {
    ..._purchaseOrderAttachable, 
    warn({ pageItem }) {
        return [!pageItem?.PurchaseOrders, "Purchase Order Not Added"];
    }
}

let show = ({ pageItem }) => !!pageItem?.PurchaseOrders;

let designChangeAttachable = { ..._designChangeAttachable, show: show };
let flightConditionAttachable = { ..._flightConditionAttachable, show: show };
let variationOrdereAttachable = { ..._variationOrdereAttachable, show: show };
let sowAttachable = { ..._sowAttachable, show: show };
let repairsAttachable = { ..._repairsAttachable, show: show };
let jobCardAttachable = { ..._jobCardAttachable, show: show };
let serviceOrderAttachable = { ..._serviceOrderAttachable };

// Commercials set up configs
const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Customer",
    "key": "Customer"
}, {
    "label": "Quote Price",
    compute({ item }) {
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: item.Quote.Currency || "GBP",
        });

        let ogPrice = formatter.format(parseFloat(item.Quote.Price));
        let gbpPrice = "";
        if (item?.Quote?.Currency !== undefined && item?.Quote?.Currency !== "gbp") {
            var gbpFormatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "GBP",
            });
            gbpPrice = gbpFormatter.format(parseFloat(item.Quote.PriceGBP));
        }

        return `${ogPrice} ${gbpPrice.length > 0 ? `(${gbpPrice})` : ""}`;
    }
}, {
    "label": "Title",
    "key": "Title",
    "class": "md:col-span-3"
}];

const commercialsMenuActions = [
    {
        "label": "Add Commercial",
        "action": addCommercial
    }
];

const commercialMenuActions = [
    {
        "label": "Edit Commercial",
        "action": editCommercial
    },
    {
        "label": "Amend Quote",
        "action": amendQuote
    },
    {
        "label": "Cancel Commercial",
        "class": "text-red",
        "action": cancelCommercial
    }
];

export default [{
    "path": "/Database/Commercials",
    "component": {...ItemListView},
    "name": "Commercials",
    "meta": {
        "app": "Database",
        "name": "Commercials",
        "sideNavSection": "Projects",
        "sideNav": true,
        "icon": "database",
        "tableSettings": "Commercials",
        "dataPath": "Database/Refs/Commercials",
        "selectionRoute": "Database/Commercials/{ID}",
        "menuActions": commercialsMenuActions,
        filter(item) {
            return item.Cancelled !== true
        },
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Database/Commercials/:id",
    "component": ItemView,
    "name": "Commercial",
    "meta": {
        "app": "Database",
        "name": "Commercials",
        "dataPath": "Database/Commercials",
        "selectionRoute": "Database/Commercials/{ID}",
        title(item) {
            return `Commercial: ${item?.Number}`
        },
        "attachedItems": {
            "Jobs and Orders": {
                "attachables": [purchaseOrderAttachable, variationOrdereAttachable, jobCardAttachable, sowAttachable, ...(appConfig.appPrefix === 'doa' ? [serviceOrderAttachable] : [])]
            },
            "Designs": {
                "attachables": [designChangeAttachable, flightConditionAttachable, repairsAttachable]
            },
            "Performance Metrics": {
                "attachables": [costTime, timeBreakdown]
            }
        },
        "dataFields": dataFields,
        "menuActions": commercialMenuActions,
        "formMetadata": {
            "ParentType": "Commercials"
        },
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        }
    }
}];
