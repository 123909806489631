<template>
  <div class="container mx-auto flex flex-col items-center">
    <div
      id="login-box"
      class="m-4 p-4 flex flex-col items-center bg-white shadow rounded w-full max-w-screen-md"
    >
      <slot></slot>

      <h2 class="text-xl text-primary font-medium">{{ appConfig.title }}</h2>
      <div class="flex flex-col w-full">
        <!-- LOGIN BOX -->
        <Form @submit="submit" v-if="!loading">
          <Input class="flex flex-grow" name="email" label="Email Address" />
          <Input
            class="flex flex-grow"
            name="password"
            label="Password"
            type="password"
            v-if="!state.reset"
          />
          <p
            class="p-2 text-sm text-primary italic hover:underline cursor-pointer"
            @click="state.reset = !state.reset"
          >
            {{ state.reset ? "Enter Password" : "Forgotten Password?" }}
          </p>
          <button class="primary">
            {{ state.reset ? "Send Reset Password Email" : "Login" }}
          </button>
        </Form>
        <Loading message="Checking credientials" v-if="loading" />
      </div>
    </div>
  </div>
</template>
<script setup="props">
import { defineProps, reactive } from "vue";
import { Input } from "../Inputs";
import { Form } from "../Form";
import { Loading } from "../Loading";
// Setup prop
const props = defineProps({
  auth: Object,
  appConfig: Object,
});

const emit = defineEmits(["login", "reset", "error"]);

// check loading status
let loading = !!localStorage.getItem("expectSignIn");

const state = reactive({
  loading: !!localStorage.getItem("expectSignIn"),
  reset: false,
});

// handle login
function submit(data) {
  state.loading = true;
  if (state.reset) {
    props.auth.sendPasswordResetEmail(data.email);
    emit("reset");
    return;
  }

  props.auth
    .signInWithEmailAndPassword(data.email, data.password)
    .then((userCredential) => {
      console.log("LOGGED IN", userCredential);
    })
    .catch((err) => {
      
      if (err.code == 'auth/multi-factor-auth-required') {
        // The user is a multi-factor user. Second factor challenge is required.
        resolver = auth.getMultiFactorResolver(props.auth, error);
        // ...
      }
      console.log("ERROR", err);
      state.loading = false;
      emit("error", err);
    });
}
</script>
