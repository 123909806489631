import {
    createBasicYellowCard,
    jobCardSignoffCheckList
} from "../JobCards/actions.js";
import { makeAttached, pad } from "../../../utils";

import { createBasicDocuments } from "../Documents/actions";

export const addRepair = ({ form, pageItem, store, db }) => {
    form.setItem(pageItem);
    form.setConfig({
        "title": "Add Repair",
        "description": `Add a Repair to Commercial ${pageItem.Number}`,
        "type": "push",
        "location": "Database/Repairs",
        "fields": [{
            "label": "Commercial",
            "type": "value",
            value() {
                return pageItem.Number
            }
        }, {
            "label": "New Repair Number",
            "type": "value",
            value({ dropdownData, liveInputData }) {
                return `REP-${pad(store.state.counts.database.rep.count)}`
            }
        }, {
            "label": "Responsible Engineer",
            "name": "ResponsibleEngineer",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dropdownData": [{
                "label": "Minor",
                "id": "Minor"
            }, {
                "label": "Major",
                "id": "Major"
            }]
        }, {
            "label": "ATA Chapter",
            "name": "ATAChapter",
            "type": "dropdown",
            "labelKey": "NameFull",
            "idKey": "Number",
            "dataLocation": "Database/ATAChapters"
        }, {
            "label": "Aircraft Code",
            "name": "AircraftCode",
            "type": "dropdown",
            "labelKey": "NameFull",
            "idKey": "Number",
            "dataLocation": "Database/AircraftCodes"
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text",
            "divClass": "col-span-3"
        }],
        preSubmitHook({ dropdownData, liveInputData }) {
            let ata = dropdownData.ATAChapter.find(i => i.meta.Number == liveInputData.ATAChapter);
            let ac = dropdownData.AircraftCode.find(i => i.meta.Number == liveInputData.AircraftCode);
            return {
                "ATAChapterID": ata.meta.ID,
                "AircraftCodeID": ac.meta.ID,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "Number": `REP-${pad(store.state.counts.database.rep.count)}`,
                "Commercials": {
                    [pageItem.ID]: true
                },
                "Customer": pageItem.Customer,
                "Cancelled": false,
                "Date": {
                    "Cancelled": "N/A",
                    "Completed": "N/A",
                    "Transferred": "N/A",
                    "Created": (new Date()).toISOString()
                },
                "Transferred": false,
                "Completed": false,
                "EASANumber": liveInputData.Type == "Minor" ? "N/A" : "Input on Issue"
            }
        },
        async postSubmitHook({ newData, typeRef }) {
            await db.utils.upCount("counts/database/rep/count");
            await db.set(`Database/Commercials/${pageItem.ID}/Repairs/${typeRef.key}`, true);

            const docs = await createBasicDocuments({
                db,
                store,
                parent: newData,
                parentType: "Repairs",
            });

            for(let doc of docs) {
                //push a new doc to the database
                let ref = await db.push(`Database/Documents`, doc);

                //set the ID of the doc to the key of the push
                doc.ID = ref.key;

                //update the doc refs
                await db.utils.updateRefs(doc, "Database/Refs/Documents", ["Number", "ID", "Title", "Date", "Revision", "Parent", "ParentID", "SubDocumentType", "MPDS"]);

                // update superrefs
                await db.utils.updateRefs({
                    "key": ref.key,
                    "c": doc.Number
                }, "Database/SuperRefs/Documents", ["k", "c"]);

                //add to parent
                await db.set(`Database/Repairs/${newData.ID}/Documents/${ref.key}`, true);

                await db.utils.upCount(`Database/DocumentTypes/${doc.TypeID}/Count`);
            }

            //create the yellow card
            const card = await createBasicYellowCard({
                db,
                store,
                parent: newData,
                parentType: "Repairs",
            });

            await db.set(`Database/Repairs/${newData.ID}/JobCards/${card.ID}`, true);
            await db.set(`Database/JobCards/${card.ID}/`, card);
            await db.utils.upCount("counts/cards/count");
        }
    });
    form.getDropdownData();
    form.toggle();
}

export const editRepair = ({ form, item }) => {
    form.setItem(item);
    form.setConfig({
        "title": "Edit Repair",
        "description": `Edit Repair ${item.Number}`,
        "type": "update",
        "location": `Database/Repairs/${item.ID}`,
        "fields": [{
            "label": "Responsible Engineer",
            "name": "ResponsibleEngineer",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dropdownData": [{
                "label": "Minor",
                "id": "Minor"
            }, {
                "label": "Major",
                "id": "Major"
            }]
        }, {
            "label": "Major Repair Approval Number",
            "name": "EASANumber",
            "type": "text",
            show() {
                return item.Type === "Major"
            }
        },{
            "label": "Title",
            "name": "Title",
            "type": "text",
            "divClass": "col-span-3"
        }]
    });
    form.autoSetValues();
    form.getDropdownData();
    form.toggle();
}

export const cancelRepair = ({ form, item }) => {
    form.setItem(item)
    form.setConfig({
        "title": "Cancel Repair",
        "description": `Are you sure you want to cancel Repair ${item.Number}?`,
        "submitButtonLabel": "Cancel",
        "submitButtonClass": "cancel",
        "type": "update",
        "location": `Database/Repairs/${item.ID}`,
        preSubmitHook() {
            return {
                "Cancelled": true
            }
        }
    });
    form.toggle();
}

export const completeRepair = ({ form, item, db, store }) => {
    form.setItem(item)
    form.setConfig({
        "title": "Complete Repair",
        "description": `Are you sure you want to complete this Repair ${item.Number}?`,
        "submitButtonLabel": "Complete",
        "type": "update",
        "location": `Database/Repairs/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Completed": true,
                "Date/Completed": (new Date()).toISOString()
            };
        },
        async postSubmitHook() {
            Object.keys(item.JobCards).forEach(key => {
                db.set(`Database/JobCards/${key}/Signedoff/Engineer`, true);
                db.set(`Database/JobCards/${key}/Date/Signedoff/Engineer`, (new Date()).toISOString());
            });

            const jobCard = await db.once(`Database/JobCards/${item.ID}`);
            jobCardSignoffCheckList("Engineer", "EngineerResponsibilities")({ form, item: jobCard, db, store });
        }
    });
    form.toggle();
};

export const linkRepair = ({ form, pageItem, db, meta }) => {
    form.setConfig({
        "title": "Link Repair",
        "description": `Link a Repair to ${pageItem.Number}`,
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/Repairs`,
        "fields": [{
            "label": "Repair",
            "name": "_doc",
            "type": "dropdown",
            "dataLocation": "Database/Repairs",
            "labelKey": "Number",
            "idKey": "ID",
            "search": false
        }],
        preSubmitHook({ data }) {
            return {
                "_doc": null,
                [data._doc]: true
            };
        },
        postSubmitHook({ liveInputData }) {
            db.set(`Database/Repairs/${liveInputData._doc}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
}; 

export const importRepairJobCards = ({ form, pageItem, rowItem, meta }) => {

    form.setValues({
        _cards: Object.keys(rowItem.JobCards)
    });

    form.setConfig({
        "title": "Import Job Cards",
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/JobCards`,
        "fields": [{
            "label": "Job Cards",
            "name": "_cards",
            "type": "dropdown",
            "dataLocation": "Database/JobCards/",
            "dataKeys": Object.keys(rowItem.JobCards),
            "idKey": "ID",
            "multiple": true,
            "labelKey": "Number"
        }],
        preSubmitHook({ liveInputData }) {
            return {
                ...makeAttached(liveInputData._cards),
                _cards: null
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};
