import { approveEvent, approveInLieu } from "./actions";

import ItemListView from "../../components/ItemListView/ItemListView.vue"
import get from "lodash/get";
import store from "../../store";

// Main Home View
export default [{
    "path": "/Leave/Planner",
    "component": () => import("./Planner/Planner.vue"),
    "name": "Planner",
    "meta": {
        "app": "Leave",
        "sideNav": true,
        "appSwitcher": true,
        "appSwitcherPosition": 4,
        "security": "Leave.Write",
        "icon": "calendar",
        "name": "Planner",
        "sideNavSection": "Leave",
    }
}, {
    "path": "/Leave/UserOverview",
    "component": () => import("./MySummary/MySummary.vue"),
    "name": "My Summary",
    "meta": {
        "app": "Leave",
        "sideNav": true,
        "appSwitcher": false,
        "name": "My Summary",
        "sideNavSection": "Leave",
    }
}, {
    "path": "/Leave/Users",
    "component": {...ItemListView},
    "name": "Users",
    "meta": {
        "app": "Leave",
        "sideNav": true,
        "appSwitcher": false,
        "name": "Users",
        "sideNavSection": "Users",
        "tableSettings": "UserManager",
        "dataPath": "users",
        lock(user) {
            return [!user?.Security?.Leave?.Authoriser, "You are unable to view this page"]
        },
        "selectionRoute": "Leave/Users/{key}",
        filter(item) {
            return item.main
        }
    }
}, {
    "path": "/Leave/Users/:id",
    "component": () => import("./Users/Users.vue"),
    "name": "User",
    "meta": {
        "app": "Leave",
        "sideNav": false,
        "appSwitcher": false,
        "name": "User",
        "sideNavSection": "User",
        "tableSettings": "User",
        "dataPath": "users",
        lock(user) {
            return [!user?.Security?.Leave?.Authoriser, "You are unable to view this page"]
        },
        "selectionRoute": "Leave/{ID}",
    }
}, {
    "path": "/Leave/Requests",
    "component": {...ItemListView},
    "name": "Requests",
    "meta": {
        "app": "Leave",
        "sideNav": true,
        "appSwitcher": false,
        "name": "Requests",
        "sideNavSection": "Requests",
        "tableSettings": "Requests",
        "dataPath": "Leave/Events",
        "storeData": ["business/leave/events"],
        lock(user) {
            return [!user?.Security?.Leave?.Authoriser, "You are unable to view this page"]
        },
        
        "dataQuery": {
            "key": "Approved",
            "value": false
        },
        filter(i) {
            return i.Approved === false;
        },
        map(i) {
            
            let username = "";
            if (!i.Users || !store.state.users) username = "Uknown";
            else username = Object.keys(i.Users).map(i => store.state.users[i]?.name);

            
            return {
                ...i,
                "UserName": username,
                "Type": get(store.state["business/leave/events"], i.Type, { name: "Unknown" }).name,
                "Reason": i.Reason || "N/A",
                "Days": i?.Days?.Amount || "Unknown"
            }
        },
        "itemSelected": approveEvent,
        "selectionRoute": "Leave/Events/{ID}",
    }
}, {
    "path": "/Leave/InLieuRequests",
    "component": {...ItemListView},
    "name": "In Lieu Requests",
    "meta": {
        "app": "Leave",
        "sideNav": true,
        "appSwitcher": false,
        "name": "In Lieu Requests",
        "sideNavSection": "Requests",
        "tableSettings": "LeaveRequests",
        "dataPath": "Leave/Requests",
        lock(user) {
            return [!user?.Security?.Leave?.Authoriser, "You are unable to view this page"]
        },
        filter(i) {
            return !i.Completed;
        },
        map(i) {
            return {
                ...i,
                "Name": store.state.users[i.UserID]?.name
            }
        },
        "selectionRoute": "Leave/{ID}",
        "itemSelected": approveInLieu
    }
}]


