export function getCurrentCycle() {
    const date = new Date();
    return getCycle(date);
};

export function getCycle(date) {
    // 1. Get the current year
    const currentYear = (new Date(date)).getFullYear();

    // each cycle is two year long and always starts in an even year
    // 2. Get the first year of the current cycle
    const firstYear = currentYear - (currentYear % 2);
    const secondYear = firstYear + 1;
    return `${firstYear}/${secondYear}`;
};

export function hasCompleteLastestCycle(date) {
    if(!date) return false;
    const currentCycle = getCurrentCycle();
    const cycleToCheck = getCycle(date);

    return currentCycle === cycleToCheck;
};
