import {
    addServiceBulletin,
    cancelServiceBulletin,
    editServiceBulletin,
    linkServiceBulletin,
    reviseServiceBulletin,
    signoffServiceBulletin,
    unlinkServiceBulletin
} from "./actions";

import store from "../../../store";

function warning(key) {
    let item = store.state[key];
    if (!item || item.Cancelled === true) return false;
    let warningMessage = "";
    if (item && item.Signedoff === false) {
        warningMessage = warningMessage += `${item.Number} is not signedoff.<br>`;
    }
    return warningMessage === "" ? false : warningMessage;
}

const cellClassRules = {
    "text-orange font-bold": params => {
        return warning(params.data);
    }
}


const actions = [{
    "label": "Add Service Bulletin",
    "action": addServiceBulletin,
    show({ meta }) {
        return [["DesignChanges", "FlightConditions", "Repairs"].includes(meta?.ParentType), "Service Bulletins cannot be added here, they must be linked."]
    }
}, {
    "label": "Link Service Bulletin",
    "action": linkServiceBulletin
}];

const headers = [{
    "headerName": "Number",
    "field": "Number",
    cellClassRules
}, {
    "headerName": "Comment",
    "field": "Comment",
    cellClassRules
}, {
    "headerName": "Revision",
    "field": "Revision",
    cellClassRules
}, {
    "headerName": "Resolved",
    "field": "Signedoff",
    "type": "bool"
}];

const rowActions = [{
    "label": "View Service Bulletin",
    action({ router, rowItem }) {
        router.push(`/Database/ServiceBulletins/${rowItem.ID}`)
    }
}, {
    "label": "Update Service Bulletin",
    "break": true,
    "action": editServiceBulletin
}, {
    "label": "Signoff Service Bulletin",
    "action": signoffServiceBulletin,
    show({ rowItem }) {
        return [!rowItem.Signedoff, "This Service Bulletin is already signed off"];
    }
}, {
    "label": "Revise Service Bulletin",
    "break": true,
    "action": reviseServiceBulletin
}, {
    "label": "Unlink Service Bulletin",
    "break": true,
    "action": unlinkServiceBulletin,
    show({ rowItem, pageItem }) {
        
        return [rowItem.ParentID !== pageItem.ID, "Cannot unlink this Service Bulletin"];
    }
},{
    "label": "Cancel Service Bulletin",
    "break": true,
    "action": cancelServiceBulletin,
    "class": "text-red"
}];

export default {
    "label": "Service Bulletins",
    "key": "ServiceBulletins",
    "location": "Database/ServiceBulletins/",
    actions,
    rowActions,
    headers,
    warn({ items }) {
        let warningMessage = items.map(warning).filter(i => i !== false).join("");
        return [warningMessage !== "", warningMessage];
    }
};
