import { get } from "lodash";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

import {
    editMiscellaneous, linkDocument, linkDesignChange
} from "./actions";

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Type",
    "key": "Type"
}, {
    "label": "Revision",
    "key": "Revision"
}, {
    "label": "Parent",
    "key": "Parent",
    "link": "Database/:ParentType/:ParentID"
}, {
    "label": "Date Created",
    "key": "Date.Created"
}, {
    "label": "Title",
    "key": "Title",
    "class": "md:col-span-3"
}];

const menuActions = [
    {
        "label": "Edit Miscellaneous",
        "action": editMiscellaneous
    }
];

export default [{
    "path": "/Database/Miscellaneous",
    "component": { ...ItemListView },
    "name": "Miscellaneous",
    "meta": {
        "app": "Database",
        "name": "Miscellaneous",
        "sideNav": true,
        "sideNavSection": "Misc",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "Miscellaneous",
        "dataPath": "Database/Miscellaneous",
        "selectionRoute": "Database/Miscellaneous/{ID}"
    }
}, {
    "path": "/Database/Miscellaneous/:id",
    "component": ItemView,
    "name": "MiscellaneousItem",
    "meta": {
        "app": "Database",
        "name": "MiscellaneousItem",
        "dataPath": "Database/Miscellaneous",
        "selectionRoute": "Database/Miscellaneous/{ID}",
        title(item) {
            return `Miscellaneous: ${item.Number}`
        },
        // "attachedItems": {},
        "attachedItems": {
            "Item Links": {
                "attachables": [{
                    "label": "Links",
                        "key": "Links",
                        "local": true,
                        "headers": [{
                            "headerName": "Number",
                            "field": "Number",
                        }, {
                                "headerName": "Type",
                                "field": "Type",
                            }],
                        "actions": [{
                            "label": "Link Document",
                            "action": linkDocument
                        }],
                        "rowActions": [{
                            "label": "View Linked Item",
                            action({ router, rowItem }) {
                                router.push(`/Database/${rowItem.Type}/${rowItem.ID}`)
                            }
                        }]
                    }]
            }
        },
        "dataFields": dataFields,
        "menuActions": menuActions
    }
}];
