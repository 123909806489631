import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

import {
    addMOR, checkMOR,
} from "./actions";

import standardMenuActions from "../../standardMenuActions";


const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Occurrence",
    "key": "Occurrence",
    "link": `Database/Occurrences/:OccurrenceID`
}, {
    "label": "Date Created",
    "key": "Date.Created",
    "type": "date"
    }, {
        "label": "Date Reported",
        "key": "Date.Reported",
        "type": "date"
    }, {
        "label": "Date Updated",
        "key": "Date.Updated",
        "type": "date"
    }, {
        "label": "Date Completed",
        "key": "Date.Completed",
        "type": "date"
    }];

const menuActions = [
    {
        "label": "Report MOR",
        "action": checkMOR("Reported"),
        show({ pageItem }) {
            return [!pageItem.Reported, "MOR is already Reported"]
        }
    },
    {
        "label": "Complete MOR",
        "action": checkMOR("Completed"),
        show({ pageItem }) {
            return [!pageItem.Completed, "MOR is already Completed"]
        }
    },
    {
        "label": "Update MOR",
        "action": checkMOR("Updated"),
        show({ pageItem }) {
            return [!pageItem.Updated, "MOR is already Updated"]
        }
    },
    standardMenuActions.back
];

export default [{
    "path": "/Database/MORs",
    "component": { ...ItemListView },
    "name": "MORs",
    "meta": {
        "app": "Database",
        "name": "MORs",
        "sideNav": true,
        "sideNavSection": "Occurrences",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "MORs",
        "dataPath": "Database/MORs",
        "selectionRoute": "Database/MORs/{ID}",
        "menuActions": [{
            "label": "Add MOR",
            "action": addMOR
        }]
    }
}, {
    "path": "/Database/MORs/:id",
    "component": ItemView,
    "name": "MOR",
    "meta": {
        "app": "Database",
        "name": "MORs",
        "dataPath": "Database/MORs",
        "selectionRoute": "Database/MORs/{ID}",
        title(item) {
            return `Occurrence: ${item.Number}`
        },
        "attachedItems": {
            
        },
        "dataFields": dataFields,
        "menuActions": menuActions
    }
}];
